const ModalHeaderMutation = (props) => {
  return (
    <div className="displayFlex backgroundLight borderRadiusTop width100">
      <div className="displayFlex EntryHeader">
        <p className="ListP">
          {
            props.modalHeaderParams.modalItemNameActionIsSkipped ? "" : 
              props.modalHeaderParams.modalItemId ? "Edit " : "New "
          }
          {props.modalHeaderParams.modalItemName}
        </p>
      </div>
    </div>  
  )
}

export default ModalHeaderMutation;
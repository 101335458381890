function TriggerFunc(e, document) {
  let element = document.getElementById("TriggerNewTab").value;

  if (element == "ConsumptionMigration") {
  }
}

const makeTabActive = (id) => {
  if (document.querySelector("#home")) {
    if (id == "Home") {
      document.querySelector("#home").classList.add("homeActive");
    } else {
      document.querySelector("#home").classList.remove("homeActive");
    }
  }

  if (document.querySelector(".tabActive")) {
    document.querySelector(".tabActive").classList.remove("tabActive");
  }
  if (document.querySelector(".activeDiv")) {
    document.querySelector(".activeDiv").className = "hideDiv";
  }
  if (document.querySelector("#tab" + id)) {
    document.querySelector("#tab" + id).classList.add("tabActive");
  }
  if (document.querySelector("#div" + id)) {
    document.querySelector("#div" + id).className = "activeDiv";
  }
};

const removeTab = (
  id,
  openedTabsList,
  setOpenedTabsList,
) => {
  let currentTab = document.querySelector("#tab" + id);
  let currentDiv = document.querySelector("#div" + id);
  let prevTab = currentTab.previousElementSibling;
  let prevDiv = currentDiv.previousElementSibling;
  let isActive = currentTab.classList.contains("tabActive");

  currentTab.remove();
  currentDiv.remove();
  if (isActive && prevTab) {
    prevTab.classList.add("tabActive");
    prevDiv.className = "activeDiv";
  }

  const newOpenedTabsList = openedTabsList.filter((val) => val != id);
  setOpenedTabsList(newOpenedTabsList);

  if (openedTabsList.length == 1) {
    if (document.querySelector("#home")) {
      document.querySelector("#home").classList.add("homeActive");
    }
  }
};

export {
  TriggerFunc,
  makeTabActive,
  removeTab,
};

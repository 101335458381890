const Administration = [
  {
    MenuID: "administration",
    ParentMenu: "0",
    MenuDisplayName: "Administration",
    MenuIconFont: "supervised_user_circle",
    MenuOrder: 1,
  },
  {
    MenuID: "user_management",
    ParentMenu: "administration",
    MenuDisplayName: "User management",
    MenuIconFont: "supervised_user_circle",
    MenuOrder: 2,
  },
  {
    MenuID: "users",
    ParentMenu: "user_management",
    MenuDisplayName: "Users",
    MenuIconFont: "person",
    MenuOrder: 3,
  },
  {
    MenuID: "master_data",
    ParentMenu: "0",
    MenuDisplayName: "MasterData",
    MenuIconFont: "assignment_ind",
    MenuOrder: 1,
  },
  {
    MenuID: "facility",
    ParentMenu: "master_data",
    MenuDisplayName: "Facility",
    MenuIconFont: "local_hospital",
    MenuOrder: 2,
  },
  {
    MenuID: "facilities",
    ParentMenu: "facility",
    MenuDisplayName: "Facilities",
    MenuIconFont: "local_hospital",
    MenuOrder: 3,
  },
  {
    MenuID: "master_forms",
    ParentMenu: "master_data",
    MenuDisplayName: "Master Forms",
    MenuIconFont: "supervised_user_circle",
    MenuOrder: 2,
  },
  {
    MenuID: "diagnosis_codes",
    ParentMenu: "master_forms",
    MenuDisplayName: "Diagnosis Codes",
    MenuIconFont: "post_add",
    MenuOrder: 3,
  },
  {
    MenuID: "call_center",
    ParentMenu: "0",
    MenuDisplayName: "Call Center Operations ",
    MenuIconFont: "headset_mic",
    MenuOrder: 1,
  },
  {
    MenuID: "provider_services",
    ParentMenu: "0",
    MenuDisplayName: "Provider Services",
    MenuIconFont: "local_hospital",
    MenuOrder: 1,
  },
];

const Call_Center = [
  {
    MenuID: "call_center",
    ParentMenu: "0",
    MenuDisplayName: "Call Center Operations ",
    MenuIconFont: "headset_mic",
    MenuOrder: 1,
  },
];

const Provider = [
  {
    MenuID: "provider_services",
    ParentMenu: "0",
    MenuDisplayName: "Provider Services",
    MenuIconFont: "local_hospital",
    MenuOrder: 1,
  },
];

const Billing = [
  {
    MenuID: "provider_services",
    ParentMenu: "0",
    MenuDisplayName: "Provider Services",
    MenuIconFont: "local_hospital",
    MenuOrder: 1,
  },
];

export { Administration, Call_Center, Provider, Billing };

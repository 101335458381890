import AccordionSummary from "@material-ui/core/AccordionSummary";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";

import InputSearch from "../_helperComponents/InputSearch";
import AddBtn from "../Buttons/AddButton";

const TableViewHeader = (props) => {
  return (
    <AccordionSummary>
      <div className="padding displayFlex backgroundDark borderRadiusTop width100">
        <div style={{ width: "50%" }}>
          <div className="displayFlex">
            <div className="ListHeader displayFlex">
              <p className="ListP">{props.tableViewHeaderParams.tableName} ({props.tableViewHeaderParams.itemsTotalNumber})</p>
            </div>
          </div>
        </div>
        
        <div style={{ width: "40%" }}>
        { 
          !props.tableViewHeaderParams.skipButtonSearch && (
          <div className="searchbar" id="FacilityRegistryTbsearch">
            <InputSearch
              className="search_input"
              type="text"
              placeholder="Search"
              value={props.tableViewHeaderParams.searchInput}
              onChange={ props.tableViewHeaderParams.searchHandler }              
            />
            <div className="search_icon">
              {props.tableViewHeaderParams.searchInput ? (
                <CloseIcon
                  className="InputSearchIcon"
                  onClick={ props.tableViewHeaderParams.closeSearchHandler }
                />
              ) : (
                <SearchIcon className="InputSearchIcon" />
              )}
            </div>
          </div> 
          )
        }
        </div>
         
        
            <div style={{ width: "10%" }}>
            { 
          !props.tableViewHeaderParams.skipButtonAdd && (
              <AddBtn
                className="L1AddBtnCommon"
                onClick={props.tableViewHeaderParams.onClickAdd}
                disabled={false}
              />
              )
            }
            </div>
         
      </div>
    </AccordionSummary>
  )
}

export default TableViewHeader;
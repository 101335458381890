
import {
  getFacilitiesAction,
  getListFacilitiesAction,
  searchFacilitiesAction,
  createFacilitiesAction,
  updateFacilitiesAction,
  deleteFacilitiesAction,
  api2FrontMapper,
  front2ApiMapper,
} from "../../../../../../Facilities/actions/actions";

import {
  getProviderStatisticsAction
} from "../../../../../../Meetings/actions/actions";

import {
  getUserAction,
  getProvidersListAction,
  searchUserAction,
  createUserAction,
  updateUserAction,
  deleteUserAction,
} from "../../../../../../Users/actions/actions";

const getDashboardStatistics = async (
  setProvidersCounter,
  setFacilitiesCounter,
  setTotalMeetingsCounter,
  setCompletedMeetingsCounter,
  setChartOptions,
  loading
) => {
  loading(true);

  const defaultFilters = {
    statuses: {
      'Active': "true",
      'Inactive': "false"
    }
  }

  const pageNo = 1;
  const getPerPage = 50000;

  const responseProviders = await getProvidersListAction(
    {
      usersFilters: defaultFilters
    },
    {
      pageNo,
      getPerPage
    }
  );

  const providersList = responseProviders?.nodes ? responseProviders.nodes : [];
  setProvidersCounter(providersList.length);

  // if (type == "TotalFacilityCount") {
  const responseFacilities = await getListFacilitiesAction(
    {
      facilitiesFilters: defaultFilters
    },
    {
      pageNo,
      getPerPage
    }
  );

  const facilitiesList = responseFacilities?.nodes ? responseFacilities.nodes : [];
  setFacilitiesCounter(facilitiesList.length);


  const meetingsStatistics = await getProviderStatisticsAction();

  const totalMeetingsCounter = meetingsStatistics?.totalMeetingsCounter ? meetingsStatistics?.totalMeetingsCounter : 0;
  setTotalMeetingsCounter(totalMeetingsCounter);

  const completedMeetingsCounter = meetingsStatistics?.completedMeetingsCounter ? meetingsStatistics?.completedMeetingsCounter : 0;
  setCompletedMeetingsCounter(completedMeetingsCounter);


  // if (type == "MonthlyBarChart") {
  const chartData = getChartOptions(totalMeetingsCounter, completedMeetingsCounter);
  setChartOptions(chartData);

  loading(false);
};

const getChartOptions = (totalMeetingsCounter, completedMeetingsCounter) => {
  return {
    animationEnabled: true,
    height: 190,
    toolTip: {
      shared: true,
    },

    axisX: {
      gridThickness: 0,
    },
    axisY: {
      gridThickness: 0,
    },
    data: [
      {
        indexLabel: "{y}",
        indexLabelPlacement: "outside",
        indexLabelFontColor: "red",
        indexLabelFontSize: 9,
        indexLabelFontWeight: 800,
        type: "column",
        name: "Total Consults Booked",
        legendText: "Total Consults Booked",
        showInLegend: true,
        indexLabelFontColor: "#5A5757",
        dataPoints: totalMeetingsCounter,
      },
      {
        indexLabel: "{y}",
        indexLabelPlacement: "outside",
        indexLabelFontColor: "green",
        indexLabelFontSize: 9,
        indexLabelFontWeight: 800,
        type: "column",
        name: "Closed Consults Booked",
        legendText: "Closed Consults Booked",
        showInLegend: true,
        indexLabelFontColor: "#5A5757",
        dataPoints: completedMeetingsCounter,
      },
    ],
  };
}

export { getDashboardStatistics };

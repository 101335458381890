import { config } from "../../../configs/config";
import { requestAxios } from "../../../services/axiosClient";
import { updateConditionQuery } from "../../../helpers/utils";

const showErrorMessage = (responseError, showAlert, setLoading) => {
  const errorMessage = responseError?.message
    ? responseError?.message
    : "Bad Request";
  showAlert({ open: true, color: "error", message: [errorMessage] });
  setLoading(false);
};

const createDiagnosisCodeAction = async (item, showAlert, setLoading) => {
  const url = "diagnosis-codes";
  const params = {
    ...item,
  };
  const apiUrl = `${config.API_URL}/${url}`;
  const requestOptions = {
    requestType: "POST",
  };
  const [response, responseError] = await requestAxios(
    apiUrl,
    params,
    null,
    requestOptions,
  );

  if (responseError) {
    showErrorMessage(responseError, showAlert, setLoading);
    return;
  }

  showAlert({ open: true, color: "success", message: ["Diagnosis Code was created"] });
  setLoading(false);

  return response;
};

const updateDiagnosisCodeAction = async (item, showAlert, setLoading) => {
  const url = "diagnosis-codes";
  const params = {
    ...item,
  };
  const apiUrl = `${config.API_URL}/${url}/${item.id}`;
  const requestOptions = {
    requestType: "PUT",
  };
  const [response, responseError] = await requestAxios(
    apiUrl,
    params,
    null,
    requestOptions,
  );

  if (responseError) {
    showErrorMessage(responseError, showAlert, setLoading);
    return;
  }

  showAlert({ open: true, color: "success", message: ["Diagnosis Code was updated"] });
  setLoading(false);

  return response;
};

const deleteDiagnosisCodeAction = async (
  item,
  showAlert = () => {},
  setLoading = () => {},
) => {
  const url = "diagnosis-codes";

  let apiUrl;
  if (item.id) {
    apiUrl = `${config.API_URL}/${url}/${item.id}`;
  } else {
    showErrorMessage(null, showAlert, setLoading);
    return;
  }

  const requestOptions = {
    requestType: "DELETE",
  };
  const [response, responseError] = await requestAxios(
    apiUrl,
    null,
    null,
    requestOptions,
  );

  if (responseError) {
    showErrorMessage(responseError, showAlert, setLoading);
    return;
  }

  showAlert({ open: true, color: "success", message: ["Diagnosis Code was deleted"] });
  setLoading(false);

  return response;
};

const getDiagnosisCodeAction = async (
  item,
  showAlert = () => {},
  setLoading = () => {},
) => {
  const url = "diagnosis-codes";

  let apiUrl;
  if (item.id) {
    apiUrl = `${config.API_URL}/${url}/id/${item.id}`;
  } else if (item.name) {
    apiUrl = `${config.API_URL}/${url}/name/${item.name}`;
  } else {
    showErrorMessage(null, showAlert, setLoading);
    return;
  }

  const requestOptions = {
    requestType: "GET",
  };
  const [response, responseError] = await requestAxios(
    apiUrl,
    null,
    null,
    requestOptions,
  );

  if (responseError) {
    showErrorMessage(responseError, showAlert, setLoading);
    return;
  }

  setLoading(false);

  return response;
};

const getListDiagnosisCodeAction = async (
  itemsFilters,
  currentPaginationParams,
  showAlert = () => {},
  setLoading = () => {},
) => {
  const url = "diagnosis-codes";

  let conditionQuery = '';

  conditionQuery = updateConditionQuery(conditionQuery, "statuses", itemsFilters?.diagnosisCodesFilters?.statuses);

  const paginationQuery = `page_no=${currentPaginationParams.pageNo}&get_per_page=${currentPaginationParams.getPerPage}`;
  const apiUrl = `${config.API_URL}/${url}?${paginationQuery}${conditionQuery}`;
  const requestOptions = {
    requestType: "GET",
  };
  const [response, responseError] = await requestAxios(
    apiUrl,
    null,
    null,
    requestOptions,
  );

  if (responseError) {
    showErrorMessage(responseError, showAlert, setLoading);
    return;
  }

  setLoading(false);

  return response;
};

const searchDiagnosisCodeAction = async (
  searchQuery,
  showAlert = () => {},
  setLoading = () => {},
) => {
  const url = "diagnosis-codes";

  const apiUrl = `${config.API_URL}/${url}/search/${searchQuery}`;
  const requestOptions = {
    requestType: "GET",
  };
  const [response, responseError] = await requestAxios(
    apiUrl,
    null,
    null,
    requestOptions,
  );

  if (responseError) {
    showErrorMessage(responseError, showAlert, setLoading);
    return;
  }

  setLoading(false);

  return response;
};

const api2FrontMapper = (item) => {
  return {
    DxRegistryDxID: item.id,
    DxIDPK: item.id,
    DxCode: item.diagnosisCode,
    Description: item.description,

    IsActive: item.isActive,
    DeleStat: item.deleStat,
    CreatedUserID: item.createdUserId,
    CreatedTtm: item.createdAt,
    UpdatedUserID: item.updatedUserId,
    UpdatedTtm: item.updatedAt,
  };
};

const front2ApiMapper = (item) => {
  return {
    id: !!item.id ? item.id : null,
    diagnosisCode: item.diagnosisCode,
    description: item.description,
    isActive: item.isActive,
  };
};

export {
  getDiagnosisCodeAction,
  getListDiagnosisCodeAction,
  searchDiagnosisCodeAction,
  createDiagnosisCodeAction,
  updateDiagnosisCodeAction,
  deleteDiagnosisCodeAction,
  api2FrontMapper,
  front2ApiMapper,
};

import { forwardRef } from "react";

// import GridItem from "../../../components/Grid/GridItem";
import GridItem from "../../../components/Grid/GridItemRoot";

const MeetingsStatisticsTableCell = forwardRef((props, ref) => {
  return (
    <GridItem
      xs={3}
      sm={3}
      md={3}
      style={{
        borderRight: "1px solid #eee",
        padding: "0px",
        backgroundColor: props.item.cellBgColor
      }}
    >
      <div className="entrybk ">
        <span style={{ color: props.item.styleColor }}>
          {props.item.cellName}
        </span>
        <p
          className="pointer"
          onClick={props.item.cellHandler}
          style={{ color: props.item.styleColor }}
        >
          {props.item.cellCounter}
        </p>
      </div>
    </GridItem>
  );
});
  
export default MeetingsStatisticsTableCell; 

import MUITable from "../../_helperComponents/MUITable";

const TableBody = (props) => {
  return (
    <div 
        id="tableBody" 
        className="displayFlex padding0"              
      >
      <div>
        <MUITable
            id={props.tableBodyParams.tableId}
            headerdata={props.tableBodyParams.TableVisibleItem}
            bodydata={props.tableBodyParams.bodyData}
            bodyitem={props.tableBodyParams.TableBodyItem}
            visibleitem={props.tableBodyParams.TableVisibleItem}
            isRowColorCalculated={props.tableBodyParams.isRowColorCalculated}

            isActionsVisible={props.tableBodyParams.isActionsVisible}
            isViewVisible={props.tableBodyParams.isViewVisible}
            viewHandler={props.tableBodyParams.viewHandler}
            isEditVisible={props.tableBodyParams.isEditVisible}
            editHandler={props.tableBodyParams.editHandler}
            isRemoveVisible={props.tableBodyParams.isRemoveVisible}
            removeHandler={props.tableBodyParams.removeHandler}
            isReassignVisible={props.tableBodyParams.isReassignVisible}
            reassignHandler={props.tableBodyParams.reassignHandler}
            isReasonForReassignVisible={props.tableBodyParams.isReasonForReassignVisible}
            isCheckinVisible={props.tableBodyParams.isCheckinVisible}
            checkinHandler={props.tableBodyParams.checkinHandler}
          />      
        </div>
    </div>    
  ) 
}

export default TableBody;
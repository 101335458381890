import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import SaveBtn from "../../../../components/Buttons/SaveButton";
import ClearBtn from "../../../../components/Buttons/ClearButton";
// import GridContainer from "../../../../components/Grid/GridContainer.js";
import GridContainer from "../../../../components/Grid/GridContainerRoot";
// import GridItem from "../../../../components/Grid/GridItem.js";
import GridItem from "../../../../components/Grid/GridItemRoot";

import DropDown from "../../../../components/_helperComponents/DropDown";

import { changeTimeZoneAction } from "./actions/changeTimeZoneAction";

const TimeZone = (props) => {
  const authStoreRedux = useSelector((state) => state.auth);
  const dispatchRedux = useDispatch();

  const timeZone = localStorage.getItem("timeZone");

  const [timeZoneModalId, setTimeZoneModalId] = useState(timeZone);
  //

  const [timeZoneModalValue, setTimeZoneModalValue] = useState('');

  useEffect(() => {
    const timeZoneInfo = getTimeZoneInfoById(authStoreRedux.timeZonesData, timeZoneModalId);
    timeZoneInfo && setTimeZoneModalValue(timeZoneInfo.value);
  }, [timeZoneModalId]);

  const getTimeZoneInfoById = (timeZonesData, timeZoneId) => {
    let tzResult = '';
 
    for (let tzItem of JSON.parse(timeZonesData)) {
      if (timeZoneId == tzItem.id) {
        tzResult = tzItem;
        break;
      }
    }

    return tzResult
  }

  const getTimeZoneInfoByValue = (timeZonesData, timeZoneValue) => {
    let tzResult = '';
 
    for (let tzItem of JSON.parse(timeZonesData)) {
      if (timeZoneValue == tzItem.value) {
        tzResult = tzItem;
        break;
      }
    }
    return tzResult
  }

  return (
    <div>
      <Dialog
        open={props.openModalTimeZone}
        keepMounted={props.keepMounted}
        disableEscapeKeyDown={props.disableEscapeKeyDown}
        onClose={() => {
          props.setOpenModalTimeZone(false);

          const timeZoneInfo = getTimeZoneInfoById(authStoreRedux.timeZonesData, timeZone);
          setTimeZoneModalId(timeZoneInfo.id);
          setTimeZoneModalValue(timeZoneInfo.value);
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogContent
          style={{ minWidth: "500px" }}
          id="classic-modal-slide-description"
          className={props.classes.modalBody}
        >
          <p id="welcome">Change TimeZone</p>
          <GridContainer>
            <GridItem style={{ padding: "0 15px" }} xs={12} sm={12} md={12}>
              <DropDown 
                placeholder="Time Zone"
                value={timeZoneModalValue}
                onChange={                    
                  (e)=>{ 
                    const timeZoneInfo = getTimeZoneInfoByValue(authStoreRedux.timeZonesData, e);
                    setTimeZoneModalId(timeZoneInfo.id);
                    setTimeZoneModalValue(timeZoneInfo.value);
                }}       
                data={JSON.parse(authStoreRedux.timeZonesData)}              
              />    
            </GridItem>
          </GridContainer>
        </DialogContent>
        
        <DialogActions>
          <ClearBtn
            disabled={false}
            onClick={() => {
              props.setOpenModalTimeZone(false);

              const timeZoneInfo = getTimeZoneInfoById(authStoreRedux.timeZonesData, timeZone);
              setTimeZoneModalId(timeZoneInfo.id);
              setTimeZoneModalValue(timeZoneInfo.value);
            }}
          />
          <SaveBtn
            name={"SAVE"}
            disabled={false}
            onClick={() => {
              changeTimeZoneAction(
                timeZoneModalId,
                timeZoneModalValue,
                props.userId,
                dispatchRedux,
                props.setOpenModalTimeZone,
                props.setOpenProfile,
              );
            }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TimeZone;

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const CollapseButton = (props) => {
  return (
    <div className="Menuopenall tooltitle">
      {
        !props.isCollapseButtonOpen ? (
          <>
            <ExpandMoreIcon
              className="MenuExpandicon"
              onClick={props.collabseAllOnClick}
            />
            <span className="tooltiptext tooltip-right">
              Open All
            </span>
          </>
        ) : (
          <>
            <ExpandLessIcon
              className="MenuExpandicon"
              onClick={props.collabseAllOnClick}
            />
            <span className="tooltiptext tooltip-right">
              Close All
            </span>
          </>
        )
      }
    </div>
  )
}

export default CollapseButton;

const toggleHeaderWidth = (isSideMenuHasMinWidth) => {
  if (isSideMenuHasMinWidth) {
    document.getElementById("horizontalNav").style.width = "90.5%";
  } else {
    document.getElementById("horizontalNav").style.width = "95%";
  }
}

const handleClick = (openProfile, setOpenProfile) => {
  setOpenProfile(!openProfile);
};

export { toggleHeaderWidth, handleClick };

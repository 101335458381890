import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";

import React from "react";

function GridItem({ ...props }) {
  const { children, className, ...rest } = props;

  return (
    <Grid item {...rest} className={className}>
      {children}
    </Grid>
  );
}

GridItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default GridItem;

// import TablePagination from "@material-ui/core/TablePagination";
import { withStyles } from "@material-ui/styles";
import MUIPagination from "@material-ui/lab/Pagination";
import MUISelect from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/TablePagination";
import InputBase from "@material-ui/core/InputBase";

const BootstrapInput = withStyles((theme) => ({
  root: {
     bottom: '15%',
    "label + &": {
      marginTop: 3
    }
  },
  input: {
    borderRadius: 2,
    position: "relative",
    border: "1px solid #ced4da",
    fontSize: 12,
    padding: "5px 0px",
    "&:focus": {
      borderRadius: 2,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0 rgba(0,123,255,.25)"
    }
  }
}))(InputBase);

const TablePaginationContainer = (props) => {
  return (
    <>
      <MUIPagination
        color="primary"
        count={props.tablePaginationContainerParams.pagination.pagesTotalNumber}
        page={props.tablePaginationContainerParams.pagination.pageNo}
        onChange={(e, val) => {
          props.tablePaginationContainerParams.onChangePageHandler(
            val, 
            props.tablePaginationContainerParams.pagination.getPerPage,
            props.tablePaginationContainerParams
          );          
        }}
      />
      {/* <MUISelect
        value={props.tablePaginationContainerParams.pagination.getPerPage}
        onChange={(val) => {
          props.tablePaginationContainerParams.onChangePageHandler(
            1, 
            val.target.value,
            props.tablePaginationContainerParams
          ); 
        }}
        input={<BootstrapInput />}
      >
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={20}>20</MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
      </MUISelect> 
      <p className="per-page">
        / per page of ({ props.tablePaginationContainerParams.pagination.itemsTotalNumber }){" "}
      </p> */}
    </>
    // <>
    //   <div className="tableTopPagination displayFlex floatOrder">
    //     { props.tablePaginationContainerParams.customPagination ? props.tablePaginationContainerParams.customPagination : null}
    //   </div>
    //   { !props.tablePaginationContainerParams.customPagination && ( 
    //     <div
    //       style={{
    //         position: "absolute",
    //         right: "0%",
    //         display: "flex",
    //         marginTop: "-7px",
    //       }}
    //     >
    //       <TablePagination
    //         style={{ color: "#777" }}
    //         rowsPerPageOptions={[]}
    //         component="div"
    //         count={Number(props.tablePaginationContainerParams.TableTotalCount)}
    //         page={props.tablePaginationContainerParams.page}
    //         onPageChange={props.tablePaginationContainerParams.handleChangePage}
    //         rowsPerPage={10}
    //         backIconButtonProps={{
    //           "aria-label": "Previous Page",
    //         }}
    //         nextIconButtonProps={{
    //           "aria-label": "Next Page",
    //         }}
    //         onRowsPerPageChange={props.tablePaginationContainerParams.handleChangeRowsPerPage}
    //       />
    //     </div>
    //   )}
    // </>
  )
}

export default TablePaginationContainer;
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

function CardBody({ ...props }) {
  const {
    className,
    children,
    ...rest
  } = props;

  const cardBodyClasses = classNames({
    [className]: className !== undefined,
  });
  return (
    <div className={cardBodyClasses} {...rest}>
      {children}
    </div>
  );
} 

CardBody.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default CardBody; 

import React, { createContext } from "react";

const usersContextDefault = {};

export const UsersContext = createContext(usersContextDefault);

export const UsersContextProvider = (props) => {
  return (
    <UsersContext.Provider value={props.value}>
      {" "}
      {props.children}{" "}
    </UsersContext.Provider>
  );
};

const isValidInputLogin = (username, password, setMessage) => {
  // todo use yup
  if (!username) {
    setMessage({
      open: true,
      color: "warning",
      message: ["Username Should Not Be Empty"],
    });

    return;
  }

  if (!password) {
    setMessage({
      open: true,
      color: "warning",
      message: ["Password Should Not Be Empty"],
    });

    return;
  }

  return true;
};

export { isValidInputLogin };

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import React, { useState } from "react";

import DateFnsUtils from "@date-io/date-fns"; // import
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const theme = createTheme({
  // palette: {
  //     secondary: {
  //         main: '#34ace0'
  //     },
  //     text:{
  //         secondary: '#34ace0',
  //         error:'#e34463'
  //     }
  //   },
  overrides: {
    MuiInput: {
      underline: {
        "&:before": {
          // borderBottom:'1px solid #e34463'
        },
        "&:after": {
          borderBottom: "2px solid #34ace0",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#34ace0",
      secondary: {
        main: "#34ace0",
      },
    },
  },
});
function BasicDatePicker(props) {
  const [newdate, setNewdate] = useState(null);

  const handleChange = (val) => {
    setNewdate(val);
    let datestring = val.toISOString().slice(0, 10);
    props.onChange(datestring);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          id={props.id}
          openTo={props.view && (props.view[0] === "year" ? "month" : "date")}
          autoOk
          // clearable={true}

          allowKeyboardControl
          fullWidth={!props.fullWidth ? true : false}
          placeholder={props.placeholder}
          label={props.label}
          variant={props.variant ? props.variant : "inline"}
          value={props.value ? props.value : null} //should be YYYY-MM-dd format
          format={props.format ? props.format : "dd-MM-yyyy"}
          maxDate={props.maxDate && new Date(props.maxDate)} //should be YYYY-MM-dd format eg : new Date('2020-03-31')
          minDate={props.minDate && new Date(props.minDate)}
          onChange={props.change ? props.change : handleChange}
          onClose={props.onClose}
          animateYearScrolling
          color={"primary"}
          disabled={props.disabled ? props.disabled : false}
          views={props.view ? props.view : ["date"]}
        />
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
}

export default BasicDatePicker;

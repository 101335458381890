import { Accordion, AccordionSummary } from "./assets/css/sideMenuStyles";

import DvrIcon from "@material-ui/icons/Dvr";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import {
  handleChangeExpanedSubMenuItem,
} from "./helpers/sideMenuHelpers";

import NextLevelMenuItem from "./NextLevelMenuItem";
import CollapseButton from "./CollapseButton";

const NextLevelMenuList = (props) => {
  return (
    <ul
      className="nav-flyout ulist-padding"
      id={"ul" + props.rootMenuItem.MenuID}
    >
      <>
        {
          props.rootMenuItem.Sub1.map((currentSubMenuItem) => {
            const isSubMenuItemArray = currentSubMenuItem?.Sub2.length > 0;

            if (!isSubMenuItemArray) {
              return (
                <NextLevelMenuItem
                  key={currentSubMenuItem.MenuID}
                  subMenuItem={currentSubMenuItem}
                  setMessage={props.setMessage}
                  openedTabsList={props.openedTabsList}
                  setOpenedTabsList={props.setOpenedTabsList}
                  setLoading={props.setLoading}
                  setConfirmationAlert={props.setConfirmationAlert}
                  dispatch={props.dispatch}
                  store={props.store}
                  persistor={props.persistor}   
                  closeTabHandler={props.closeTabHandler}                   
                />                
              );
            }

            return (
              <Accordion
                key={currentSubMenuItem.MenuID}
                expanded={
                  props.isCollapseButtonOpen ? props.isCollapseButtonOpen : props.subMenuItemIdExpanded === currentSubMenuItem.MenuID
                }
                onChange={handleChangeExpanedSubMenuItem(currentSubMenuItem.MenuID, props.setSubMenuItemIdExpanded)}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div className="Accmenucontent">
                    <DvrIcon className="Acciconsm" />
                    <span className="subitem-list">
                      {currentSubMenuItem.MenuDisplayName}
                    </span>                        
                    <CollapseButton 
                      isCollapseButtonOpen={props.isCollapseButtonOpen}
                      collabseAllOnClick={props.collabseAllOnClick}
                    />                         
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="width100">
                    {
                      currentSubMenuItem.Sub2.map((menuItemSub2) => {
                        return (
                          <NextLevelMenuItem
                            key={menuItemSub2.MenuID}
                            subMenuItem={menuItemSub2}
                            setMessage={props.setMessage}
                            openedTabsList={props.openedTabsList}
                            setOpenedTabsList={props.setOpenedTabsList}
                            setLoading={props.setLoading}
                            setConfirmationAlert={props.setConfirmationAlert}
                            dispatch={props.dispatch}
                            store={props.store}
                            persistor={props.persistor}   
                            closeTabHandler={props.closeTabHandler}                   
                          />               
                        );
                      })
                    }
                  </div>
                </AccordionDetails>
              </Accordion>
            ) 
          })
        }
      </>
    </ul>
  )
}

export default NextLevelMenuList;

import React, { useEffect, useState } from "react";

import ClearIcon from "@material-ui/icons/Clear";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import RefreshIcon from "@material-ui/icons/Refresh";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

export default function L2DropDown(props) {
  const [OpenList, setOpenList] = useState(false);
  const [InputValue, setInputValue] = useState("");
  const [type, setType] = useState("");
  let fieldID = props.id; //JSON.Property[props.SPName][props.FieldName].id;
  // let type = JSON.Property[props.SPName][props.FieldName].type;
  let mandatory = props.mandatory; //JSON.Property[props.SPName][props.FieldName].mandatory;
  let disabled = props.disabled; //JSON.Property[props.SPName][props.FieldName].disabled;
  let maxLength = props.maxLength; //JSON.Property[props.SPName][props.FieldName].maxLength;
  let labelname = props.labelname; //JSON.Property[props.SPName][props.FieldName].labelname;

  const handleClickAway = () => {
    setOpenList(false);
  };

  function OnChangeFunction(e) {
    if (!props.logincss) {
      if (mandatory) {
        if (!e.target.value) {
          e.target.classList.remove("successinput");
          e.target.classList.add("errorinput");
        } else {
          e.target.classList.add("successinput");
          e.target.classList.remove("errorinput");
        }
      } else if (!mandatory) {
        e.target.classList.add("successinput");
        e.target.classList.remove("errorinput");
      }
    }

    if (labelname.includes("%")) {
      if (e.target.value <= 100) {
        setOpenList(true);
        setInputValue(e.target.value.replace(/["']/g, ""));
        props.onChange(e.target.value.replace(/["']/g, ""));
      }
    } else {
      setOpenList(true);
      setInputValue(e.target.value.replace(/["']/g, ""));
      props.onChange(e.target.value.replace(/["']/g, ""));
    }
  }

  useEffect(() => {
    setInputValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (props.type === "password") {
      setType(props.type);
    }
    /*else{
        setType(JSON.Property[props.SPName][props.FieldName].type);
    }*/
  }, [props.type]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <div
          className={"floating-label " + props.className}
          style={{ marginTop: props.marginTop ? props.marginTop : "12%" }}
        >
          {props.Iscurr && (
            <span className="pretext">
              {/*localStorage.getItem('DefCurrenct')*/ "DefCurrenct"}
            </span>
          )}
          <input
            className={
              (mandatory && !InputValue
                ? "errorinput "
                : props.logincss
                  ? "logininput " + (InputValue && "loginactive ")
                  : "successinput ") +
              (props.type === "password"
                ? "floating-input floating-input-password "
                : "floating-input ") +
              (props.Iscurr && "paddingcurr")
            }
            placeholder=" "
            value={InputValue}
            id={fieldID}
            onChange={OnChangeFunction}
            onBlur={props.onBlur ? props.onBlur : null}
            type={props.type === "password" ? type : props.type}
            disabled={disabled ? "disabled" : ""}
            maxLength={maxLength ? maxLength : ""}
            onKeyPress={(event) => {
              if (event.key === "Enter" && props.keyPress) {
                props.onKeyPress(event.target.value);
              }
            }}
          />
          <label
            className={
              props.Iscurr
                ? "float-label shrinklabel floatTure"
                : (props.logincss ? "loginlabel " : "") + " float-label"
            }
          >
            {(!props.value ? "Enter " : " ") +
              labelname +
              (InputValue > 0 && maxLength && props.type !== "select"
                ? "(" + InputValue.length + "/" + maxLength + ")"
                : "")}
          </label>
          {props.type === "password" && type === "password" ? (
            <VisibilityOffIcon
              className="passwordVisibleIcon"
              onClick={() => setType("text")}
            />
          ) : (
            type === "text" && (
              <VisibilityIcon
                className="passwordVisibleIcon"
                onClick={() => setType("password")}
              />
            )
          )}
          <div
            className={
              props.logincss ? "loginInputClearDiv" : "L2DropDownInputClearDiv"
            }
          >
            {InputValue && !disabled ? (
              <ClearIcon
                title="clear"
                className="FilterClearIcon"
                onClick={(e) => {
                  setInputValue("");
                  props.onChange("");
                  props.clear(true, e);
                }}
              />
            ) : (
              ""
            )}
          </div>
          <span>{props.helpertext && props.helpertext}</span>
        </div>
      </div>
    </ClickAwayListener>
  );
}

import "antd/dist/antd.css";
import "./assets/css/login.css";
import loginStyle from "./assets/css/loginStyle.js";
import onlineDoctorAmico from "./assets/images/onlineDoctor-amico.png";
import reviveTelehealth_03 from "./assets/images/reviveTelehealth_03.png";

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";

import GridContainer from "../../../../components/Grid/GridContainerRoot";
import GridItem from "../../../../components/Grid/GridItemRoot";
import Card from "../../../../components/Card/CardRoot";
import L2TextField from "../../../../components/_helperComponents/L2TextFieldTable";
import Button from "../../../../components/CustomButtons/ButtonRoot";
import Notification from "../../../../components/_helperComponents/Notification";
import Loading from "../../../../components/_helperComponents/Loading";

import ForgetPassword from "../ForgetPassword/ForgetPassword";
import OtpPage from "./components/OtpPage/OtpPage";

import { loginAction } from "./actions/loginActions";

import { isValidInputLogin } from "./validations/loginValidator";

function Login(props) {
  const { classes } = props;

  const dispatchRedux = useDispatch();

  const [loading, setLoading] = useState(false);
  const [messages, setMessage] = useState({
    open: false,
    color: "",
    message: [],
  });

  const [isForgetPasswordPageActive, setIsForgetPasswordPageActive] = useState(false);
  const [isOtpPageActive, setIsOtpPageActive] = useState(false);
  
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const returnToLoginPageHandler = () => {
    setIsForgetPasswordPageActive(false);
  }
  
  return (
    <div className={classes.bgm}>
      <Notification
        open={messages.open}
        color={messages.color}
        message={[messages.message]}
        onClose={() =>
          setMessage({ open: false, color: messages.color, message: [] })
        }
      />
      <Loading loading={loading} />
      <GridContainer
        justifyContent="space-evenly"
        className={classes.Grdcontainerwid}
      >
        <GridItem
          xs={12}
          sm={12}
          md={12}
          style={{ margin: "2% 0%", padding: "0 2%" }}
        >
          <Card className={classes.cardstyle}>
            <Grid container className={classes.Grdcontainerwid}>
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                className={classes.pageBackground}
              >
                <Grid container style={{ marginTop: "1%" }}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    align="center"
                    style={{ paddingLeft: "5%" }}
                  >
                    <GridContainer>
                      <GridItem xs={9} sm={9} md={9} align="center">
                        <img
                          src={reviveTelehealth_03}
                          alt="logo"
                          className={classes.headerImgSize}
                        />
                      </GridItem>
                      <GridItem xs={10} sm={10} md={10} align="left">
                        <h3 className={classes.TextWeight500}>Welcome Back</h3>
                        <p className={classes.TextWeight400}>
                          Login in to your account
                        </p>
                      </GridItem>
                      <GridItem
                        xs={10}
                        sm={10}
                        md={10}
                        style={{ marginBottom: "6%" }}
                      >
                        {
                          isForgetPasswordPageActive ? (
                            <ForgetPassword
                              loading={(val) => setLoading(val)}
                              setMessage={setMessage}
                              returnToLoginPage={returnToLoginPageHandler}
                            />
                        ) : (
                          isOtpPageActive ? (
                            <OtpPage 
                              username={username}
                              password={password}
                              setLoading={setLoading}
                              setMessage={setMessage}
                              history={props.history}
                              dispatchRedux={dispatchRedux}
                            />
                          ) 
                          : (
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={8}>
                              <div style={{ margin: "0%", textAlign: "start" }}>
                                <L2TextField
                                  id="Username"
                                  marginTop="6%"
                                  type={"text"}
                                  labelname={"Username"}
                                  onChange={(e) => {
                                    setUsername(e);
                                  }}
                                  disabled={false}
                                  value={username}
                                  clear={(e) => {
                                    if (e) {
                                      setUsername("");
                                    }
                                  }}
                                  mandatory={false}
                                  keyPress={true}
                                  logincss="logininput"
                                  onKeyPress={() =>
                                    isValidInputLogin(
                                      username,
                                      password,
                                      setMessage,
                                    ) &&
                                    loginAction(
                                      username,
                                      password,
                                      setLoading,
                                      setMessage,
                                      setIsOtpPageActive,
                                      props.history,
                                      dispatchRedux,
                                    )
                                  }
                                />
                              </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={8}>
                              <div style={{ margin: "0%", textAlign: "start" }}>
                                <L2TextField
                                  id="Password"
                                  marginTop="6%"
                                  type={"password"}
                                  labelname={"Password"}
                                  onChange={(e) => {
                                    setPassword(e);
                                  }}
                                  disabled={false}
                                  value={password}
                                  clear={(e) => {
                                    if (e) {
                                      setPassword("");
                                    }
                                  }}
                                  mandatory={false}
                                  keyPress={true}
                                  logincss="logininput"
                                  onKeyPress={() =>
                                    isValidInputLogin(
                                      username,
                                      password,
                                      setMessage,
                                    ) &&
                                    loginAction(
                                      username,
                                      password,
                                      setLoading,
                                      setMessage,
                                      setIsOtpPageActive,
                                      props.history,
                                      dispatchRedux,
                                    )
                                  }
                                />
                              </div>
                            </GridItem>
                            <GridItem xs={10} sm={10} md={5}>
                              <div className={classes.margin5} align="left">
                                <a
                                  className={classes.HighliteText}
                                  onClick={() => setIsForgetPasswordPageActive(true)}
                                >
                                  Forgot Password?
                                </a>
                              </div>
                            </GridItem>
                            <GridItem xs={10} sm={10} md={3} align="right">
                              <div>
                                <Button
                                  style={{
                                    width: "100%",
                                    fontWeight: "600",
                                    background: "#33234E",
                                    padding: "10px 30px",
                                    marginTop: "5px",
                                    color: "white"
                                  }}
                                  disabled={(!!username && !!password) ? false : true}
                                  onClick={() =>
                                    isValidInputLogin(
                                      username,
                                      password,
                                      setMessage,
                                    ) &&
                                    loginAction(
                                      username,
                                      password,
                                      setLoading,
                                      setMessage,
                                      setIsOtpPageActive,
                                      props.history,
                                      dispatchRedux,
                                    )
                                  }
                                >
                                  login <Icon className="loginicon">login</Icon>
                                </Button>
                              </div>
                            </GridItem>
                          </GridContainer>
                          )
                        )}
                      </GridItem>
                    </GridContainer>
                  </Grid>
                </Grid>
              </Grid>

              <GridItem xs={12} sm={12} md={4}>
                <img
                  className={classes.imgFullsize}
                  src={onlineDoctorAmico}
                  alt="building"
                />
              </GridItem>
            </Grid>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(loginStyle)(Login);

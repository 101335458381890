import io from "socket.io-client";
import { config } from "../configs/config";

let socketIOClient;

const getSocketIOClient = (apiToken) => {
  // console.log('apiToken getSocketIOClient',apiToken)

  if (!socketIOClient) {
    // console.log('NEW getSocketIOClient')

    socketIOClient = io(
      config.API_URL,
      {
        auth: {
          token: apiToken
        },
        extraHeaders: {
          // authorization: `bearer ${myToken}`
          "x-api-token": apiToken
        },
        
      }
    );
  }

  return socketIOClient;
}

export default getSocketIOClient;

import Button from "../_helperComponents/Button.js";
import React from "react";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";

export default function SaveButton(props) {
  return (
    <Button
      formctrl_id={props.formctrl_id}
      className={props.className + " SaveBtnCommon boxShadowNone"}
      name={props.name}
      title={props.name}        
      disabled={props.disabled}
      onClick={props.onClick}
      startIcon={props.name == "Update" || props.name == "Save" ? <SaveOutlinedIcon className="SubmitBtnIcon" /> : ''}
    />
  );
}

import React, {useState} from "react";
import PropTypes from "prop-types";
import { Steps } from "antd";

import L2TextField from "../../../../../../components/_helperComponents/L2TextFieldTable";
import Button from "../../../../../../components/CustomButtons/ButtonRoot";
import GridContainer from "../../../../../../components/Grid/GridContainerRoot";
import GridItem from "../../../../../../components/Grid/GridItemRoot";

import { otpAction } from "./actions/otpActions";
import { isValidInputOtp } from "./validations/otpValidator";

function OtpPage(props) {
  const [otpValue, setOtpValue] = useState("");

  const { Step } = Steps;
  const steps = [
    {
      title: "Enter Username and Password",
    },
    {
      title: "Verify OTP",
    },
  ];

  return (
    <div style={{ marginTop: "2%", marginLeft: "50px", marginRight: "50px" }}>
      <Steps direction="vertical" current={2}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      
      <div className="stepInput">
          <GridContainer>
            <GridItem md={8} style={{ marginBottom: "0px" }}>               
                <L2TextField
                  id="yourOTP"
                  type={"text"}
                  labelname={"Your OTP"}
                  onChange={(e) => {
                    setOtpValue(e);
                  }}
                  disabled={false}
                  value={otpValue}
                  clear={(e) => {
                    if (e) {
                      setOtpValue("");
                    }
                  }}
                  mandatory={false}
                  logincss="logininput"
                  marginTop="6%"
                />             
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={10} sm={10} md={3} align="right">
              <div>
                <Button
                  style={{
                    width: "100%",
                    fontWeight: "600",
                    background: "#33234E",
                    padding: "10px 30px",
                    marginTop: "5px",
                    color: "white"
                  }}
                  disabled={(!!otpValue) ? false : true}
                  onClick={() =>
                    isValidInputOtp(
                      otpValue,
                      props.setMessage,
                    ) &&
                    otpAction(
                      otpValue,
                      props.username,
                      props.password,
                      props.setLoading,
                      props.setMessage,
                      props.history,
                      props.dispatchRedux,
                    )
                  }
                >
                  verify
                </Button>
              </div>
            </GridItem>
          </GridContainer>
      </div>
    </div>
  );
}

OtpPage.propTypes = {
  classes: PropTypes.object,
};

export default OtpPage; 

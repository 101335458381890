import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Loader from "../../assets/img/BuildingLoader.gif";

const divStyle = {
  position: "absolute",
  // left: '50%',
  textAlign: "center",
  top: "46%",
  width: "100%",
  // height: '100%',
  zIndex: "99",
  transform: "translate(-50%, -50%)",
  transform: "-webkit-translate(-50%, -50%)",
  transform: "-moz-translate(-50%, -50%)",
  transform: "-ms-translate(-50%, -50%)",
};

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    zIndex: "99",
    position: "fixed",
    background: "#fff",
    opacity: "0.7",
  },
  img: {
    width: "5%",
    webkitTouchCallout: "none" /* iOS Safari */,
    webkitUserSelect: "none" /* Safari */,
    khtmlUserSelect: "none" /* Konqueror HTML */,
    mozUserSelect: "none" /* Old versions of Firefox */,
    msUserSelect: "none" /* Internet Explorer/Edge */,
    userSelect: "none" /* Non-prefixed version, currently
                                      supported by Chrome, Opera and Firefox */,
  },
  font: {
    fontWeight: "400",
    userSelect: "none",
  },
});

function Customloading(props) {
  const { classes } = props;
  const [visible, setVisible] = useState("hidden");

  useEffect(() => {
    if (props.loading === true) {
      setVisible("block");
    } else {
      setVisible("none");
    }
  });

  return (
    <div
      className={classes.root}
      style={{ display: visible }}
      draggable="false"
    >
      <div style={divStyle} draggable="false">
        <img
          draggable="false"
          // src={Loader}
          // src='./static/media/BuildingLoader.20ed567d.gif'
          src={"static/media/BuildingLoader.a3164798.gif"}
          alt="Logo"
          className={classes.img}
        />

        {/* <div > <h4 className={classes.font}>loading .....</h4></div>   */}
      </div>
    </div>
  );
}

export default withStyles(styles)(Customloading);

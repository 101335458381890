import React, { useEffect, useState } from "react";

export default function InputText(props) {
  // useEffect(() => {setInputValue(props.value);},[props.value]);

  return (
    <div>
      <input
        className={props.className}
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        onChange={(e) => {
          props.onChange(e.target.value.replace(/["']/g, ""));
        }}
      />
    </div>
  );
}

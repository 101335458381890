import Datecomponents from "../_helperComponents/DateComponent";

import { 
  forwardRef,
  useImperativeHandle,
  useRef
} from "react";

import {
  useStyles,
} from "./helpers/filterHelper";

const FilterSectionBodyDatePicker = forwardRef((props, ref) => {
  const datePickerRef = useRef();

  const TableClass = useStyles();
  
  useImperativeHandle(ref, () => ({
    closeDatePicker: () => { 
      datePickerRef.current.closeDatePicker();
    }
  }));

  return (
    <div>
      <div className="FilterItems">
        <Datecomponents
          ref={datePickerRef}
          title={"Created Date"}
          SendDateVal={(rangeFrom, rangeto) => {
            props.onClickPickDate(rangeFrom, rangeto);
          }}
        />
      </div>
    </div>
  );
});

export default FilterSectionBodyDatePicker;

import React, {
  useEffect,
  useRef,
  forwardRef,
  useReducer,
} from "react";

import DiagnosisCodesTable from "./components/DiagnosisCodesTable";
import DiagnosisCodeMutation from "./components/DiagnosisCodeMutation";
import DiagnosisCodeView from "./components/DiagnosisCodeView";

import { DiagnosisCodesContextProvider } from "./context/diagnosisCodesContextProvider";

import { commonReactReducer, initialStateGlobal } from "../../reactReducers/commonReactReducer";

const DiagnosisCodes = forwardRef((props, ref) => {
  const mutationRef = useRef();
  const viewRef = useRef();
  const tableRef = useRef();

  const [commonReactState, commonReactDispatch] = useReducer(commonReactReducer, initialStateGlobal);

  useEffect(() => {
    props.loading(true);
    document.getElementById("DxRegistry-MainTable").style.width = "100%";
    document
      .getElementById("DxRegistry-MainDiv")
      .classList.remove("displayFlex");
    document.getElementById("DxRegistry-MainDiv").classList.add("displayGrid");
  }, []);

  const providerValue = {
    commonReactState, 
    commonReactDispatch,
    openModalMutation: (itemId) => {      
      const item = tableRef.current.getCurrentModalItem(itemId);
      mutationRef.current.openModal(item);
    },
    removeAlertModal: (itemId) => {
      mutationRef.current.removeAlertModal(itemId);
    },
    openModalView: (tableItem) => {
      const item = tableRef.current.getCurrentModalItem(tableItem);
      viewRef.current.openModal(item);
    },
    refreshTable: (filters) => tableRef.current.refreshTable(filters),
    applySectionFilters: (sectionFilters) => tableRef.current.applySectionFilters(sectionFilters),
    loading: (val) => props.loading(val),
    alert: (val) => props.alertmsg(val),
    ConfirmAlert: (val) => props.ConfirmAlert(val),
  };

  return (
    <>
      <DiagnosisCodesContextProvider value={providerValue}>
        <>
          <div id="DxRegistry-MainDiv" className="MainDivStyles displayFlex">
            <div id="DxRegistry-MainTable" className="Maincontent">
              <DiagnosisCodesTable ref={tableRef} />
            </div>
          </div>
          <DiagnosisCodeView ref={viewRef} />
          <DiagnosisCodeMutation ref={mutationRef} />
        </>
      </DiagnosisCodesContextProvider>
    </>
  );
});

export default DiagnosisCodes;

import React, { useEffect, useState, 
  forwardRef,
  useImperativeHandle,
} from "react";

import AppsRoundedIcon from "@material-ui/icons/AppsRounded";
import { Calendar } from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";

import DateIcon from "@material-ui/icons/DateRange";
import DateRangeIcon from "@material-ui/icons/DateRange";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import EventNoteIcon from "@material-ui/icons/EventNote";
import Filter3RoundedIcon from "@material-ui/icons/Filter3Rounded";
import Filter7RoundedIcon from "@material-ui/icons/Filter7Rounded";
// import GridContainer from "../../components/Grid/GridContainer.js";
import GridContainer from "../../components/Grid/GridContainerRoot";
// import GridItem from "../../components/Grid/GridItem";
import GridItem from "../../components/Grid/GridItemRoot";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import MatDatePicker from "../../components/_helperComponents/Mat_DatePicker";
import SwapHoriz from "@material-ui/icons/SwapHoriz";
import TodayIcon from "@material-ui/icons/Today";
import { format, differenceInMinutes, toDate } from "date-fns";
import { Button, Dialog, Modal } from "@material-ui/core";

const Datecomponent = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    closeDatePicker: () => {
      let rangeicon = document.getElementsByClassName("icondate activedate");
      rangeicon?.length > 0 && rangeicon[0] .classList.remove("activedate");
      let range = document.getElementsByClassName("Datemrg activedate");
      range?.length > 0 && range[0].classList.remove("activedate");

      setrangepic(false);
      setmonthpic(false);
      setmonthyearpic(false);
      setyearpic(false);
      setDatetypepic(true);
    },
  }));

  const defaultFrom = null;
  const defaultTo = null;
  const defaultValue = {
    from: defaultFrom,
    to: defaultTo,
  };

  const curdate = new Date();
  const todaydate = format(curdate, "MM-dd-yyyy");
  curdate.setDate(curdate.getDate() - 1);
  const yestdate = format(curdate, "MM-dd-yyyy");
  curdate.setDate(curdate.getDate() - 1);
  const mlist = [
    "All",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const getthree = format(curdate, "MM-dd-yyyy");
  var threefro = getthree.split("-");
  var threeto = todaydate.split("-");
  const threedate =
    mlist[threefro[0].replace(/^0+/, "")] +
    "-" +
    threefro[1] +
    " - " +
    mlist[threeto[0].replace(/^0+/, "")] +
    "-" +
    threeto[1];
  curdate.setDate(curdate.getDate() - 4);
  const get7 = format(curdate, "MM-dd-yyyy");
  var sevenfro = get7.split("-");
  var sevento = todaydate.split("-");
  const sevendate =
    mlist[sevenfro[0].replace(/^0+/, "")] +
    "-" +
    sevenfro[1] +
    " - " +
    mlist[sevento[0].replace(/^0+/, "")] +
    "-" +
    sevento[1];
  //   15 Days
  curdate.setDate(curdate.getDate() - 8);
  const get15 = format(curdate, "MM-dd-yyyy");
  var fiftenfro = get15.split("-");
  var fiftento = todaydate.split("-");
  const fifteendate =
    mlist[fiftenfro[0].replace(/^0+/, "")] +
    "-" +
    fiftenfro[1] +
    " - " +
    mlist[fiftento[0].replace(/^0+/, "")] +
    "-" +
    fiftento[1];
  // 30 Days
  curdate.setDate(curdate.getDate() - 15);
  const get30 = format(curdate, "MM-dd-yyyy");
  var thirtyfro = get30.split("-");
  var thirtyto = todaydate.split("-");
  const thirtydate =
    mlist[thirtyfro[0].replace(/^0+/, "")] +
    "-" +
    thirtyfro[1] +
    " - " +
    mlist[thirtyto[0].replace(/^0+/, "")] +
    "-" +
    thirtyto[1];
  //   Last Week
  const curlastweek = new Date();
  const prevfistdate = new Date(
    curlastweek.getFullYear(),
    curlastweek.getMonth() - 1,
    1,
  );
  const prevenddate = new Date(
    curlastweek.getFullYear(),
    curlastweek.getMonth() - 1 + 1,
    0,
  );
  var first = curlastweek.getDate() - curlastweek.getDay();
  var last = first + 6;
  var firstday = new Date(curlastweek.setDate(first));
  firstday.setDate(firstday.getDate() - 1);
  const getlastdate = format(new Date(firstday), "MM-dd-yyyy");
  firstday.setDate(firstday.getDate() - 6);
  const getlastdate2 = format(new Date(firstday), "MM-dd-yyyy");
  var lastweekfro = getlastdate2.split("-");
  var lastweekto = getlastdate.split("-");
  const lastweek =
    mlist[lastweekfro[0].replace(/^0+/, "")] +
    "-" +
    lastweekfro[1] +
    " - " +
    mlist[lastweekto[0].replace(/^0+/, "")] +
    "-" +
    lastweekto[1];
  // Last month
  const getfistmonth = format(new Date(prevfistdate), "MM-dd-yyyy");
  const getendmonth = format(new Date(prevenddate), "MM-dd-yyyy");
  var lastmonthfro = getfistmonth.split("-");
  var lastmonthto = getendmonth.split("-");
  const lastmonth =
    mlist[lastmonthfro[0].replace(/^0+/, "")] +
    "-" +
    lastmonthfro[1] +
    " - " +
    mlist[lastmonthto[0].replace(/^0+/, "")] +
    "-" +
    lastmonthto[1];

  // Last Year
  const currentDate = new Date();

  const lastYearStart = new Date(
    currentDate.getFullYear() - 1,
    currentDate.getMonth(),
    1,
  );
  const lastYearEnd = new Date(
    currentDate.getFullYear() - 1 + 1,
    currentDate.getMonth(),
    0,
  );
  const getLastYearStart = format(new Date(lastYearStart), "MM-dd-yyyy");
  const getLastYearEnd = format(new Date(lastYearEnd), "MM-dd-yyyy");

  var lastmonthfro = getLastYearStart.split("-");
  var lastmonthto = getLastYearEnd.split("-");
  const lastyear =
    mlist[lastmonthfro[0].replace(/^0+/, "")] +
    "-" +
    lastmonthfro[1] +
    " - " +
    mlist[lastmonthto[0].replace(/^0+/, "")] +
    "-" +
    lastmonthto[1];

  // Future Date
  const furdate = new Date();
  const nextfistdate = new Date(
    furdate.getFullYear(),
    furdate.getMonth() + 1,
    1,
  );
  const nextenddate = new Date(
    furdate.getFullYear(),
    furdate.getMonth() + 1 + 1,
    0,
  );
  furdate.setDate(furdate.getDate() + 1);
  const tomrwdate = format(new Date(furdate), "MM-dd-yyyy");
  furdate.setDate(furdate.getDate() + 1);
  const nextgetthree = format(new Date(furdate), "MM-dd-yyyy");
  var nextthreefro = todaydate.split("-");
  var nextthreeto = nextgetthree.split("-");
  const nextthreedate =
    mlist[nextthreefro[0].replace(/^0+/, "")] +
    "-" +
    nextthreefro[1] +
    " - " +
    mlist[nextthreeto[0].replace(/^0+/, "")] +
    "-" +
    nextthreeto[1];
  furdate.setDate(furdate.getDate() + 4);
  const nextget7 = format(new Date(furdate), "MM-dd-yyyy");
  var nextsevenfro = todaydate.split("-");
  var nextsevento = nextget7.split("-");
  const nextsevendate =
    mlist[nextsevenfro[0].replace(/^0+/, "")] +
    "-" +
    nextsevenfro[1] +
    " - " +
    mlist[nextsevento[0].replace(/^0+/, "")] +
    "-" +
    nextsevento[1];
  // Next Week
  var lastday = new Date(curlastweek.setDate(last));
  lastday.setDate(lastday.getDate() + 1);
  const nextgetlastdate = format(new Date(lastday), "MM-dd-yyyy");
  lastday.setDate(lastday.getDate() + 6);
  const nextgetlastdate2 = format(new Date(lastday), "MM-dd-yyyy");
  var nextweekfro = nextgetlastdate.split("-");
  var nextweekto = nextgetlastdate2.split("-");
  const nextweek =
    mlist[nextweekfro[0].replace(/^0+/, "")] +
    "-" +
    nextweekfro[1] +
    " - " +
    mlist[nextweekto[0].replace(/^0+/, "")] +
    "-" +
    nextweekto[1];
  // Next 15 Days
  furdate.setDate(furdate.getDate() + 8);
  const nextget15 = format(new Date(furdate), "MM-dd-yyyy");
  var nextfiftenfro = todaydate.split("-");
  var nextfiftento = nextget15.split("-");
  const nextfifteendate =
    mlist[nextfiftenfro[0].replace(/^0+/, "")] +
    "-" +
    nextfiftenfro[1] +
    " - " +
    mlist[nextfiftento[0].replace(/^0+/, "")] +
    "-" +
    nextfiftento[1];
  //Next 30 Days
  furdate.setDate(furdate.getDate() + 15);
  const nextget30 = format(new Date(furdate), "MM-dd-yyyy");
  var nextthirtyfro = todaydate.split("-");
  var nextthirtyto = nextget30.split("-");
  const nextthirtydate =
    mlist[nextthirtyfro[0].replace(/^0+/, "")] +
    "-" +
    nextthirtyfro[1] +
    " - " +
    mlist[nextthirtyto[0].replace(/^0+/, "")] +
    "-" +
    nextthirtyto[1];
  // Next month
  const nextgetfistmonth = format(new Date(nextfistdate), "MM-dd-yyyy");
  const nextgetendmonth = format(new Date(nextenddate), "MM-dd-yyyy");
  var nextmonthfro = nextgetfistmonth.split("-");
  var nextmonthto = nextgetendmonth.split("-");
  const nextmonth =
    mlist[nextmonthfro[0].replace(/^0+/, "")] +
    "-" +
    nextmonthfro[1] +
    " - " +
    mlist[nextmonthto[0].replace(/^0+/, "")] +
    "-" +
    nextmonthto[1];

  const [selectedDayRange, setSelectedDayRange] = useState(defaultValue);
  const [Fromdate, setFromdate] = useState("");
  const [Todate, setTodate] = useState("");
  const [rangeFrom, setrangeFrom] = useState("");
  const [rangeto, setrangeto] = useState("");
  const [rangetype, setrangetype] = useState("");
  const [rangepic, setrangepic] = useState(false);
  const [monthpic, setmonthpic] = useState(false);
  const [monthyearpic, setmonthyearpic] = useState(false);
  const [yearpic, setyearpic] = useState(false);
  const [Datetypepic, setDatetypepic] = useState(true);
  const curmonyr = new Date();
  const [yearmon, setyearmon] = useState(curmonyr);
  const [yearval, setyearval] = useState(curmonyr);
  const [futuredate, setfuturedate] = useState(false);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [dateRangeModal, setDateRangeModal] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function Selectpic(e) {
    if (e === "today") {
      let range = document.getElementsByClassName("Datemrg activedate");
      range?.length > 0 && range[0].classList.remove("activedate");
      let range2 = document.getElementsByClassName("Datemrg today");
      range2?.length > 0 && range2[0].classList.add("activedate");
        
      let rangeicon = document.getElementsByClassName("icondate activedate");
      rangeicon?.length > 0 && rangeicon[0].classList.remove("activedate");
      let rangeicon2 = document.getElementsByClassName("icondate today");
      rangeicon2?.length > 0 && rangeicon2[0].classList.add("activedate");

      const curdate2 = new Date();
      const todaydate2 = format(curdate2, "MM-dd-yyyy");
      let today = todaydate2.split("-");

      props.SendDateVal(today[0] + "-" + today[1] + "-" + today[2], today[0] + "-" + today[1] + "-" + today[2], "DATE");
    }
    if (e === "yestar") {
      for (let i = 0; i < 8; i++) {
        let range = document.getElementsByClassName("Datemrg")[i];
        range.classList.remove("activedate");
        let range2 = document.getElementsByClassName("Datemrg")[1];
        range2.classList.add("activedate");
        let rangeicon = document.getElementsByClassName("icondate")[i];
        rangeicon.classList.remove("activedate");
        let rangeicon2 = document.getElementsByClassName("icondate")[1];
        rangeicon2.classList.add("activedate");
      }
      let yestday = yestdate.split("-");
      props.SendDateVal(
        yestday[0] + "-" + yestday[1] + "-" + yestday[2],
        "",
        "DATE",
      );
    }
    if (e === "tomrw") {
      for (let i = 0; i < 8; i++) {
        let range = document.getElementsByClassName("Datemrg")[i];
        range.classList.remove("activedate");
        let range2 = document.getElementsByClassName("Datemrg")[1];
        range2.classList.add("activedate");
        let rangeicon = document.getElementsByClassName("icondate")[i];
        rangeicon.classList.remove("activedate");
        let rangeicon2 = document.getElementsByClassName("icondate")[1];
        rangeicon2.classList.add("activedate");
      }
      let tomrwday = tomrwdate.split("-");
      props.SendDateVal(
        tomrwday[0] + "-" + tomrwday[1] + "-" + tomrwday[2],
        "",
        "DATE",
      );
    }
    if (e === "3day") {
      for (let i = 0; i < 8; i++) {
        let range = document.getElementsByClassName("Datemrg")[i];
        range.classList.remove("activedate");
        let range2 = document.getElementsByClassName("Datemrg")[2];
        range2.classList.add("activedate");
        let rangeicon = document.getElementsByClassName("icondate")[i];
        rangeicon.classList.remove("activedate");
        let rangeicon2 = document.getElementsByClassName("icondate")[2];
        rangeicon2.classList.add("activedate");
      }
      let three = getthree.split("-");
      let today = todaydate.split("-");
      props.SendDateVal(
        three[0] + "-" + three[1] + "-" + three[2],
        today[0] + "-" + today[1] + "-" + today[2],
        "BETWEEN",
      );
    }
    if (e === "next3day") {
      for (let i = 0; i < 8; i++) {
        let range = document.getElementsByClassName("Datemrg")[i];
        range.classList.remove("activedate");
        let range2 = document.getElementsByClassName("Datemrg")[2];
        range2.classList.add("activedate");
        let rangeicon = document.getElementsByClassName("icondate")[i];
        rangeicon.classList.remove("activedate");
        let rangeicon2 = document.getElementsByClassName("icondate")[2];
        rangeicon2.classList.add("activedate");
      }
      let three = nextgetthree.split("-");
      let today = todaydate.split("-");
      props.SendDateVal(
        today[0] + "-" + today[1] + "-" + today[2],
        three[0] + "-" + three[1] + "-" + three[2],
        "BETWEEN",
      );
    }
    if (e === "7day") {
      for (let i = 0; i < 8; i++) {
        let range = document.getElementsByClassName("Datemrg")[i];
        range.classList.remove("activedate");
        let range2 = document.getElementsByClassName("Datemrg")[3];
        range2.classList.add("activedate");
        let rangeicon = document.getElementsByClassName("icondate")[i];
        rangeicon.classList.remove("activedate");
        let rangeicon2 = document.getElementsByClassName("icondate")[3];
        rangeicon2.classList.add("activedate");
      }
      let seven = get7.split("-");
      let today = todaydate.split("-");
      props.SendDateVal(
        seven[0] + "-" + seven[1] + "-" + seven[2],
        today[0] + "-" + today[1] + "-" + today[2],
        "BETWEEN",
      );
    }
    if (e === "next7day") {
      for (let i = 0; i < 8; i++) {
        let range = document.getElementsByClassName("Datemrg")[i];
        range.classList.remove("activedate");
        let range2 = document.getElementsByClassName("Datemrg")[3];
        range2.classList.add("activedate");
        let rangeicon = document.getElementsByClassName("icondate")[i];
        rangeicon.classList.remove("activedate");
        let rangeicon2 = document.getElementsByClassName("icondate")[3];
        rangeicon2.classList.add("activedate");
      }
      let seven = nextget7.split("-");
      let today = todaydate.split("-");
      props.SendDateVal(
        today[0] + "-" + today[1] + "-" + today[2],
        seven[0] + "-" + seven[1] + "-" + seven[2],
        "BETWEEN",
      );
    }
    if (e === "15day") {
      for (let i = 0; i < 8; i++) {
        let range = document.getElementsByClassName("Datemrg")[i];
        range.classList.remove("activedate");
        let range2 = document.getElementsByClassName("Datemrg")[4];
        range2.classList.add("activedate");
        let rangeicon = document.getElementsByClassName("icondate")[i];
        rangeicon.classList.remove("activedate");
        let rangeicon2 = document.getElementsByClassName("icondate")[4];
        rangeicon2.classList.add("activedate");
      }
      let fifteen = get15.split("-");
      let today = todaydate.split("-");
      props.SendDateVal(
        fifteen[0] + "-" + fifteen[1] + "-" + fifteen[2],
        today[0] + "-" + today[1] + "-" + today[2],
        "BETWEEN",
      );
    }
    if (e === "next15day") {
      for (let i = 0; i < 8; i++) {
        let range = document.getElementsByClassName("Datemrg")[i];
        range.classList.remove("activedate");
        let range2 = document.getElementsByClassName("Datemrg")[4];
        range2.classList.add("activedate");
        let rangeicon = document.getElementsByClassName("icondate")[i];
        rangeicon.classList.remove("activedate");
        let rangeicon2 = document.getElementsByClassName("icondate")[4];
        rangeicon2.classList.add("activedate");
      }
      let fifteen = nextget15.split("-");
      let today = todaydate.split("-");
      props.SendDateVal(
        today[0] + "-" + today[1] + "-" + today[2],
        fifteen[0] + "-" + fifteen[1] + "-" + fifteen[2],
        "BETWEEN",
      );
    }
    if (e === "lastweek") {
      let range = document.getElementsByClassName("Datemrg activedate");    
      range?.length > 0 && range[0].classList.remove("activedate");
      let range2 = document.getElementsByClassName("Datemrg lastweek");
      range2?.length > 0 && range2[0].classList.add("activedate");
        
      let rangeicon = document.getElementsByClassName("icondate activedate");
      rangeicon?.length > 0 && rangeicon[0].classList.remove("activedate");
      let rangeicon2 = document.getElementsByClassName("icondate lastweek");
      rangeicon2?.length > 0 && rangeicon2[0].classList.add("activedate");

      let weekfrom = getlastdate2.split("-");
      let weekto = getlastdate.split("-");
      props.SendDateVal(
        weekfrom[0] + "-" + weekfrom[1] + "-" + weekfrom[2],
        weekto[0] + "-" + weekto[1] + "-" + weekto[2],
        "BETWEEN",
      );
    }
    if (e === "nextweek") {
      for (let i = 0; i < 8; i++) {
        let range = document.getElementsByClassName("Datemrg")[i];
        range.classList.remove("activedate");
        let range2 = document.getElementsByClassName("Datemrg")[5];
        range2.classList.add("activedate");
        let rangeicon = document.getElementsByClassName("icondate")[i];
        rangeicon.classList.remove("activedate");
        let rangeicon2 = document.getElementsByClassName("icondate")[5];
        rangeicon2.classList.add("activedate");
      }
      let weekfrom = nextgetlastdate2.split("-");
      let weekto = nextgetlastdate.split("-");
      props.SendDateVal(
        weekto[0] + "-" + weekto[1] + "-" + weekto[2],
        weekfrom[0] + "-" + weekfrom[1] + "-" + weekfrom[2],
        "BETWEEN",
      );
    }
    if (e === "30day") {
      for (let i = 0; i < 8; i++) {
        let range = document.getElementsByClassName("Datemrg")[i];
        range.classList.remove("activedate");
        let range2 = document.getElementsByClassName("Datemrg")[6];
        range2.classList.add("activedate");
        let rangeicon = document.getElementsByClassName("icondate")[i];
        rangeicon.classList.remove("activedate");
        let rangeicon2 = document.getElementsByClassName("icondate")[6];
        rangeicon2.classList.add("activedate");
      }
      let thirty = get30.split("-");
      let today = todaydate.split("-");
      props.SendDateVal(
        thirty[0] + "-" + thirty[1] + "-" + thirty[2],
        today[0] + "-" + today[1] + "-" + today[2],
        "BETWEEN",
      );
    }
    if (e === "next30day") {
      for (let i = 0; i < 8; i++) {
        let range = document.getElementsByClassName("Datemrg")[i];
        range.classList.remove("activedate");
        let range2 = document.getElementsByClassName("Datemrg")[6];
        range2.classList.add("activedate");
        let rangeicon = document.getElementsByClassName("icondate")[i];
        rangeicon.classList.remove("activedate");
        let rangeicon2 = document.getElementsByClassName("icondate")[6];
        rangeicon2.classList.add("activedate");
      }
      let thirty = nextget30.split("-");
      let today = todaydate.split("-");
      props.SendDateVal(
        today[0] + "-" + today[1] + "-" + today[2],
        thirty[0] + "-" + thirty[1] + "-" + thirty[2],
        "BETWEEN",
      );
    }
    if (e === "lastmonth") {
      let range = document.getElementsByClassName("Datemrg activedate");    
      range?.length > 0 && range[0].classList.remove("activedate");
      let range2 = document.getElementsByClassName("Datemrg lastmonth");
      range2?.length > 0 && range2[0].classList.add("activedate");
        
      let rangeicon = document.getElementsByClassName("icondate activedate");
      rangeicon?.length > 0 && rangeicon[0].classList.remove("activedate");
      let rangeicon2 = document.getElementsByClassName("icondate lastmonth");
      rangeicon2?.length > 0 && rangeicon2[0].classList.add("activedate");

      const prevfistdate2 = new Date(
        curlastweek.getFullYear(),
        curlastweek.getMonth() - 1,
        1,
      );
      const prevenddate2 = new Date(
        curlastweek.getFullYear(),
        curlastweek.getMonth() - 1 + 1,
        0,
      );

      const getfistmonth2 = format(new Date(prevfistdate2), "MM-dd-yyyy");
      let from = getfistmonth2.split("-");

      const getendmonth2 = format(new Date(prevenddate2), "MM-dd-yyyy");
      let premonthto = getendmonth2.split("-");

      props.SendDateVal(
        from[0] + "-" + from[1] + "-" + from[2],
        premonthto[0] + "-" + premonthto[1] + "-" + premonthto[2],
        "BETWEEN",
      );
    }
    if (e === "nextmonth") {
      for (let i = 0; i < 8; i++) {
        let range = document.getElementsByClassName("Datemrg")[i];
        range.classList.remove("activedate");
        let range2 = document.getElementsByClassName("Datemrg")[7];
        range2.classList.add("activedate");
        let rangeicon = document.getElementsByClassName("icondate")[i];
        rangeicon.classList.remove("activedate");
        let rangeicon2 = document.getElementsByClassName("icondate")[7];
        rangeicon2.classList.add("activedate");
      }
      let from = nextgetfistmonth.split("-");
      let premonthto = nextgetendmonth.split("-");
      props.SendDateVal(
        from[0] + "-" + from[1] + "-" + from[2],
        premonthto[0] + "-" + premonthto[1] + "-" + premonthto[2],
        "BETWEEN",
      );
    }
    if (e === "lastyear") {
      let range = document.getElementsByClassName("Datemrg activedate");
      range?.length > 0 && range[0].classList.remove("activedate");
      let range2 = document.getElementsByClassName("Datemrg lastyear");
      range2?.length > 0 && range2[0].classList.add("activedate");
        
      let rangeicon = document.getElementsByClassName("icondate activedate");
      rangeicon?.length > 0 && rangeicon[0] .classList.remove("activedate");
      let rangeicon2 = document.getElementsByClassName("icondate lastyear");
      rangeicon2?.length > 0 && rangeicon2[0].classList.add("activedate");

      let fromStart = getLastYearStart.split("-");
      let toEnd = getLastYearEnd.split("-");
      props.SendDateVal(
        fromStart[0] + "-" + fromStart[1] + "-" + fromStart[2],
        toEnd[0] + "-" + toEnd[1] + "-" + toEnd[2],
        "BETWEEN",
      );
    }

    if (e === "range") {
      setrangepic(true);
      setmonthpic(false);
      setyearpic(false);
      setDatetypepic(false);
    }
    if (e === "month") {
    setrangepic(false);
      setmonthpic(true);
      setmonthyearpic(false);
      setyearpic(false);
      setDatetypepic(false);
      let yrmon =
        yearmon.getFullYear() +
        "-" +
        (yearmon.getMonth() + 1) +
        "-" +
        yearmon.getDate();
      setrangeFrom(yrmon);
      setrangeto(null);
      setrangetype("MONTH");
    }
    if (e === "monthyear") {
      setrangepic(false);
      setmonthpic(false);
      setmonthyearpic(true);
      setyearpic(false);
      setDatetypepic(false);
      let yrmon =
        yearmon.getFullYear() +
        "-" +
        (yearmon.getMonth() + 1) +
        "-" +
        yearmon.getDate();
      setrangeFrom(yrmon);
      setrangeto(null);
      setrangetype("MONTHYEAR");
    }
    if (e === "year") {
      setrangepic(false);
      setmonthpic(false);
      setmonthyearpic(false);
      setyearpic(true);
      setDatetypepic(false);
      let yrmon =
        yearval.getFullYear() +
        "-" +
        (yearval.getMonth() + 1) +
        "-" +
        yearval.getDate();
      setrangeFrom(yrmon);
      setrangeto(null);
      setrangetype("YEAR");
    }
    if (e === "Datetype") {
      setrangepic(false);
      setmonthpic(false);
      setmonthyearpic(false);
      setyearpic(false);
      setDatetypepic(true);
    }
  }

  function DayRange(e) {
    setSelectedDayRange(e);
    if (e.from) {
      let from = e.from.year + "-" + e.from.month + "-" + e.from.day;
      let from2 = e.from.day + "-" + e.from.month + "-" + e.from.year;

      setFromdate(format(new Date(from), "MM-dd-yyyy"));
      setrangeFrom(format(new Date(from), "MM-dd-yyyy"));
      setrangetype("DATE");
    }
    if (e.to) {
      let to = e.to.year + "-" + e.to.month + "-" + e.to.day;
      let to2 = e.to.day + "-" + e.to.month + "-" + e.to.year;
      setTodate(format(new Date(to), "MM-dd-yyyy"));
      setrangeto(format(new Date(to), "MM-dd-yyyy"));
      setrangetype("BETWEEN");
    } else {
      setTodate(null);
      setrangeto(null);
      setrangetype("DATE");
    }
  }

  function monthyearchange(e) {
    let datefor = format(new Date(e), "MM-dd-yyyy");
    if (monthpic === true) {
      setyearmon(datefor);
      setrangeFrom(datefor);
      setrangetype("MONTH");
    }
    if (monthyearpic === true) {
      setyearmon(datefor);
      setrangeFrom(datefor);
      setrangetype("MONTHYEAR");
    }
  }
  function onyearchange(e) {
    let datefor = format(new Date(e), "MM-dd-yyyy");
    setyearval(datefor);
    setrangeFrom(datefor);
    setrangetype("YEAR");
  }

  function swapdate() {
    if (futuredate === false) {
      setfuturedate(true);
    } else {
      setfuturedate(false);
    }
  }

  return (
    <>
      <div className="columnHeader">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div className="displayFlex width70">
          {props.keyboardback && (
            <KeyboardBackspaceIcon
              className="columnHeaderIcon"
              style={{ cursor: "pointer" }}
              onClick={props.keyboardback}
            />
          )}
            <p className="columnHeaderp">{props.title}</p>
          </div>
          <div>
            {!Datetypepic && (
              <>
                <button
                  className="btnDate"
                  onClick={() => Selectpic("Datetype")}
                >
                  Back
                </button>

                <button
                  className="btnDate"
                  onClick={() =>
                    props.SendDateVal(rangeFrom, rangeto, rangetype)
                  }
                >
                  Go
                </button>
              </>
            )}
            {/* {Datetypepic && (
              <SwapHoriz className="iconswap" onClick={() => swapdate()} />
            )} */}
          </div>
        </div>
      </div>

      <div className="FilterParentDiv">
        <div data-list-scroll-container="true">
          {Datetypepic ? (
            <GridContainer
              style={{
                width: "calc(100% + 0px)",
                margin: "0px",
                overflowY: "scroll",
                maxHeight: "300px",
              }}
            >
              <GridItem
                xs={12}
                sm={12}
                md={4}
                className="GridDate"
                onClick={() => {
                  Selectpic("range");
                  setDateRangeModal(true);
                }}
              >
                <EventNoteIcon className="icondate" />
                <div className="btnDate Datemrg">Range</div>
              </GridItem>

              <GridItem
                xs={12}
                sm={12}
                md={4}
                className="GridDate"
                onClick={() => Selectpic("today")}
              >
                <EventAvailableIcon className="icondate today" />
                <div className="btnDate Datemrg today">Today</div>
                <div className="datelabel">{todaydate}</div>
              </GridItem>
              {/* {futuredate ? (
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  className="GridDate"
                  onClick={() => Selectpic("tomrw")}
                >
                  <TodayIcon className="icondate" />
                  <div className="btnDate Datemrg">Tommorow</div>
                  <div className="datelabel">{tomrwdate}</div>
                </GridItem>
              ) : (
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  className="GridDate"
                  onClick={() => Selectpic("yestar")}
                >
                  <TodayIcon className="icondate" />
                  <div className="btnDate Datemrg">Yesterday</div>
                  <div className="datelabel">{yestdate}</div>
                </GridItem>
              )} */}
              {/* {futuredate ? (
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  className="GridDate"
                  onClick={() => Selectpic("next3day")}
                >
                  <Filter3RoundedIcon className="icondate" />
                  <div className="btnDate Datemrg">Next 3 Days</div>
                  <div className="datelabel">{nextthreedate}</div>
                </GridItem>
              ) : (
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  className="GridDate"
                  onClick={() => Selectpic("3day")}
                >
                  <Filter3RoundedIcon className="icondate" />
                  <div className="btnDate Datemrg">Last 3 Days</div>
                  <div className="datelabel">{threedate}</div>
                </GridItem>
              )} */}
              {/* {futuredate ? (
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  className="GridDate"
                  onClick={() => Selectpic("next7day")}
                >
                  <Filter7RoundedIcon className="icondate" />
                  <div className="btnDate Datemrg">Next 7 Days</div>
                  <div className="datelabel">{nextsevendate}</div>
                </GridItem>
              ) : (
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  className="GridDate"
                  onClick={() => Selectpic("7day")}
                >
                  <Filter7RoundedIcon className="icondate" />
                  <div className="btnDate Datemrg">Last 7 Days</div>
                  <div className="datelabel">{sevendate}</div>
                </GridItem>
              )} */}
              {futuredate ? (
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  className="GridDate"
                  onClick={() => Selectpic("nextweek")}
                >
                  <DateRangeIcon className="icondate" />
                  <div className="btnDate Datemrg">Next Week</div>
                  <div className="datelabel">{nextweek}</div>
                </GridItem>
              ) : (
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  className="GridDate"
                  onClick={() => Selectpic("lastweek")}
                >
                  <DateRangeIcon className="icondate lastweek" />
                  <div className="btnDate Datemrg lastweek">Last Week</div>
                  <div className="datelabel">{lastweek}</div>
                </GridItem>
              )}
              {/* {futuredate ? (
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  className="GridDate"
                  onClick={() => Selectpic("next15day")}
                >
                  <DateIcon className="icondate" />
                  <div className="btnDate Datemrg">Next 15 Days</div>
                  <div className="datelabel">{nextfifteendate}</div>
                </GridItem>
              ) : (
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  className="GridDate"
                  onClick={() => Selectpic("15day")}
                >
                  <DateIcon className="icondate" />
                  <div className="btnDate Datemrg">Last 15 Days</div>
                  <div className="datelabel">{fifteendate}</div>
                </GridItem>
              )} */}
              {/* {futuredate ? (
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  className="GridDate"
                  onClick={() => Selectpic("next30day")}
                >
                  <DateIcon className="icondate" />
                  <div className="btnDate Datemrg">Next 30 Days</div>
                  <div className="datelabel">{nextthirtydate}</div>
                </GridItem>
              ) : (
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  className="GridDate"
                  onClick={() => Selectpic("30day")}
                >
                  <DateIcon className="icondate" />
                  <div className="btnDate Datemrg">Last 30 Days</div>
                  <div className="datelabel">{thirtydate}</div>
                </GridItem>
              )} */}
              {futuredate ? (
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  className="GridDate"
                  onClick={() => Selectpic("nextmonth")}
                >
                  <DateIcon className="icondate" />
                  <div className="btnDate Datemrg">Next Month</div>
                  <div className="datelabel">{nextmonth}</div>
                </GridItem>
              ) : (
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  className="GridDate"
                  onClick={() => Selectpic("lastmonth")}
                >
                  <DateIcon className="icondate lastmonth " />
                  <div className="btnDate Datemrg lastmonth">Last Month</div>
                  <div className="datelabel">{lastmonth}</div>
                </GridItem>
              )}

                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  className="GridDate"
                  onClick={() => Selectpic("lastyear")}
                >
                  <DateIcon className="icondate lastyear" />
                  <div className="btnDate Datemrg lastyear">Last Year</div>
                  <div className="datelabel">{lastyear}</div>
                </GridItem>
              
              {/* <GridItem
                xs={12}
                sm={12}
                md={4}
                className="GridDate"
                onClick={() => Selectpic("month")}
              >
                <AppsRoundedIcon className="icondate" />
                <div className="btnDate Datemrg">Month</div>
              </GridItem> */}
             
              {/* <GridItem
                xs={12}
                sm={12}
                md={4}
                className="GridDate"
                onClick={() => {
                  Selectpic("monthyear");
                }}
              >
                <DateIcon className="icondate" />
                <div className="btnDate Datemrg">Month&year</div>
              </GridItem> */}
              {/* <GridItem
                xs={12}
                sm={12}
                md={4}
                className="GridDate"
                onClick={() => Selectpic("year")}
              >
                <DateIcon className="icondate" />
                <div className="btnDate Datemrg">Year</div>
              </GridItem> */}
            </GridContainer>
          ) : null}

          <div style={screenWidth < 960 ? { padding: "15px" } : {}}>
            {rangepic ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    padding: screenWidth > 960 ? "15px" : "",
                  }}
                >
                  <span>From Date: {Fromdate}</span>
                  {toDate ? <span>To Date: {Todate}</span> : null}
                </div>
                <div style={{ padding: screenWidth > 960 ? "15px" : "" }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => {
                      setDateRangeModal(true);
                    }}
                  >
                    {screenWidth < 960 ? (
                      <i className="fa fa-calendar" aria-hidden="true"></i>
                    ) : (
                      " Select Date "
                    )}
                  </Button>
                </div>

                <Dialog
                  fullWidth
                  open={dateRangeModal}
                  maxWidth={"sm"}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Calendar
                    value={selectedDayRange}
                    onChange={DayRange}
                    colorPrimary="#0fbcf9" // added this
                    colorPrimaryLight="rgba(75, 207, 250, 0.4)" // and this
                    shouldHighlightWeekends
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px",
                      justifyContent: "flex-end",
                      flexWrap: "wrap",
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginRight: "5px" }}
                      onClick={() => {
                        setDateRangeModal(false);
                      }}
                    >
                      Select
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        setDateRangeModal(false);
                        setFromdate("");
                        setTodate("");
                        setSelectedDayRange(defaultValue);
                        setTodate("");
                        setrangeto("");
                        setrangetype("");
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Dialog>
              </div>
            ) : null}
            {monthyearpic ? (
              <MatDatePicker
                value={yearmon}
                label={"Month & Year"}
                change={(val) => monthyearchange(val)}
                maxDate={new Date("2030-12-01")}
                minDate={new Date("2000-01-01")}
                view={["year", "month"]}
                format={"MM-yyyy"}
                variant={screenWidth < 960 ? "dialog" : "static"}
              />
            ) : null}
            {monthpic ? (
              <MatDatePicker
                value={yearmon}
                label={"Month"}
                change={(val) => monthyearchange(val)}
                view={["month"]}
                format={"MM"}
                variant={screenWidth < 960 ? "dialog" : "static"}
              />
            ) : null}
            {yearpic ? (
              <MatDatePicker
                value={yearval}
                label={"Year"}
                change={(val) => onyearchange(val)}
                maxDate={new Date("2030-12-01")}
                minDate={new Date("2000-01-01")}
                view={["year"]}
                format={"yyyy"}
                variant={screenWidth < 960 ? "dialog" : "static"}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
});

export default Datecomponent;

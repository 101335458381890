import "react-infinite-calendar/styles.css";

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useContext,
} from "react";

import {  
  clickFilterHandler
} from "../../../components/PageFilters/helpers/filterHelper";

import FilterSection from "./Filters/FilterSection";
import TableView from "../../../components/TableView/TableView";

import { DiagnosisCodesContext } from "../context/diagnosisCodesContextProvider";

import {
  getDiagnosisCodesList,
  searchDiagnosisCodes,
} from "../actions/viewListDiagnosisCodesAction";

import { initialState } from "../helpers/mutationDiagnosisCodeHelpers";

import {
  statusesData,
  defaultFilters
} from "./Filters/helpers/filtersDataHelper";

import {
  VisibleItemSelect,
  tableColumnsList,
} from "../helpers/tableDiagnosisCodesHelpers";

const DiagnosisCodesTable = forwardRef((props, ref) => {
  const context = useContext(DiagnosisCodesContext);
  const { commonReactState, commonReactDispatch } = context;

  const [diagnosisCodesList, setDiagnosisCodesList] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [pageFilters, setPageFilters] = useState(defaultFilters);

  const [pagination, setPagination] = useState({
    pageNo: 1,
    getPerPage: 10,
    itemsTotalNumber: 0,
    pagesTotalNumber: 0
  });

  const [TableVisibleData, setTableVisibleData] = useState([]);
  const [TableVisibleItem, setTableVisibleItem] = useState([]);
  const [TableBodyItem, setTableBodyItem] = useState([]);

  const stateName = 'diagnosisCodes';
  const filterSectionId = `${stateName}_FilterDiv`;
  const filterButtonId = `${stateName}_FilterDiv`;
  const tableId = `${stateName}_TableDiv`;

  useEffect(() => {
    if (!isFiltersOpen) {
      setPageFilters(defaultFilters);
      context.refreshTable(defaultFilters);
    }    
  }, [isFiltersOpen]);

  useEffect(() => {
    VisibleItemSelect(
      tableColumnsList,
      setTableVisibleData,
      setTableVisibleItem,
      setTableBodyItem
    );
  }, [
    JSON.stringify(
      tableColumnsList
    ),
  ]);

  useImperativeHandle(ref, () => ({
    getCurrentModalItem: (itemId) => {
      let item

      diagnosisCodesList.map((value) => {
        if (itemId === value.id) {
          item = value
        }
      });
      return item;
    },
    applySectionFilters: (sectionFilters) => {
      setPageFilters(sectionFilters);
    },
    refreshTable: (currentDiagnosisCodesFilters) => {
      const diagnosisCodesFilters = currentDiagnosisCodesFilters ? currentDiagnosisCodesFilters : pageFilters;

      getDiagnosisCodesList(
        setDiagnosisCodesList,
        context,
        {
          diagnosisCodesFilters
        },
        {
          pageNo: pagination.pageNo,
          getPerPage: pagination.getPerPage,
          pagination,
          setPagination
        }        
      );
    },
  }));

  const searchHandler = (e) => {
    setSearchInput(e);
    if (e.length == "0") {
      getDiagnosisCodesList(
        setDiagnosisCodesList,
        context,
        {
          diagnosisCodesFilters: pageFilters
        },
        {
          pageNo: pagination.pageNo,
          getPerPage: pagination.getPerPage,
          pagination,
          setPagination
        }
      );
    } else {
      searchDiagnosisCodes(
        e,        
        setDiagnosisCodesList,
        context,
        commonReactDispatch,
        {
          pageNo: 1,
          getPerPage: 10
        },
        pagination,
        setPagination
      );
    }    
  }

  const closeSearchHandler = () => {
    getDiagnosisCodesList(
      setDiagnosisCodesList,
      context,
      {
        diagnosisCodesFilters: pageFilters
      },
      {
        pageNo: pagination.pageNo,
        getPerPage: pagination.getPerPage,
        pagination,
        setPagination
      }
    );
    setSearchInput("");
  }

  const onClickAdd = () => context.openModalMutation()

  const tableViewHeaderParams = {
    tableName: 'Diagnosis Codes',
    itemsCountOnPage: diagnosisCodesList.length,//todo delete
    itemsTotalNumber: pagination.itemsTotalNumber,
    searchInput,
    searchHandler,
    closeSearchHandler,
    onClickAdd
  }

  const onClickFilterHandler = () => {
    clickFilterHandler(isFiltersOpen, setIsFiltersOpen, tableId, filterButtonId, filterSectionId);
  }

  const tableContainerParams = {
    accordionDetailsStyles: {
      className: "tableCardL1"
    },// for dx
  }

  const refreshHandler = () => {
    setSearchInput("");
    getDiagnosisCodesList(
      setDiagnosisCodesList,
      context,
      {
        diagnosisCodesFilters: pageFilters
      },
      {
        pageNo: pagination.pageNo,
        getPerPage: pagination.getPerPage,
        pagination,
        setPagination
      }
    );
  }

  const tableToolbarParams = {
    tableHeaderStyles: {}, 
    refreshHandler,
    filterButton: {
      onClickFilterHandler,
      filterButtonId
    }
    // tableHeaderStyles: {
    //   width: "100%",
    //   padding: "10px",
    //   display: "flex",
    //   justifyContent: "space-between",
    //   flexWrap: "wrap",
    // }, // for dx,
    
    // tableToolbarOptions,
    // page,
    // handleChangePage,
    // handleChangeRowsPerPage,
  }

  // const customPagination = (
  //   <>
  //     <MUIPagination
  //       count={TableTotal}
  //       color="primary"
  //       page={GridPagination.current}
  //       onChange={(e, val) => {
  //         setGridPagination({
  //           current: val,
  //           pageSize: GridPagination.pageSize,
  //         });

  //         var cardcls =
  //           document.querySelectorAll(".CardViewMain");
  //         for (let i = 0; i < cardcls.length; i++) {
  //           cardcls[i].style.background = "#FFF";
  //         }
  //         if (CommonSearchValue.length > 0) {
  //           searchDiagnosisCodes(
  //             CommonSearchValue,
  //             { current: val, pageSize: GridPagination.pageSize },
  //             context,
  //             commonReactDispatch,
  //             setTableTotal,
  //             setTableTotalCount,
  //             setDiagnosisCodesList,
  //             setGridPagination,
  //           );
  //         } else {
  //           getDiagnosisCodesList(
  //             { current: val, pageSize: GridPagination.pageSize },
  //             JsonWithValue,
  //             "DxID",
  //             context,
  //             commonReactDispatch,
  //             setTableTotalCount,
  //             setTableTotal,
  //             setGridPagination,
  //             setDiagnosisCodesList,
  //           );
  //         }
  //       }}
  //     />
  //     <MUISelect
  //       value={GridPagination.pageSize}
  //       onChange={(val) => {
  //         setGridPagination({
  //           current: 1,
  //           pageSize: val.target.value,
  //         });

  //         if (CommonSearchValue.length > 0) {
  //           searchDiagnosisCodes(
  //             CommonSearchValue,
  //             { current: 1, pageSize: val.target.value },
  //             context,
  //             commonReactDispatch,
  //             setTableTotal,
  //             setTableTotalCount,
  //             setDiagnosisCodesList,
  //             setGridPagination,
  //           );
  //         } else {
  //           getDiagnosisCodesList(
  //             { current: 1, pageSize: val.target.value },
  //             JsonWithValue,
  //             "DxID",
  //             context,
  //             commonReactDispatch,
  //             setTableTotalCount,
  //             setTableTotal,
  //             setGridPagination,
  //             setDiagnosisCodesList,
  //           );
  //         }
  //       }}
  //       input={<BootstrapInput />}
  //     >
  //       <MenuItem value={10}>10</MenuItem>
  //       <MenuItem value={20}>20</MenuItem>
  //       <MenuItem value={50}>50</MenuItem>
  //       <MenuItem value={100}>100</MenuItem>
  //     </MUISelect>
  //     {ExpandView && (
  //       <p className="per-page">
  //         / per page of ({TableTotalCount}){" "}
  //       </p>
  //     )}
  //   </>
  // );

  const onChangePageHandler = (nextPageNo, nextGetPerPage, tablePaginationContainerParams) => {
    getDiagnosisCodesList(
      tablePaginationContainerParams.setItemsList,
      tablePaginationContainerParams.context,
      {
        diagnosisCodesFilters: pageFilters
      },
      {
        pageNo: nextPageNo,
        getPerPage: nextGetPerPage,
        pagination: tablePaginationContainerParams.pagination,
        setPagination: tablePaginationContainerParams.setPagination
      }
    );
  }

  const tablePaginationContainerParams = {
    setItemsList: setDiagnosisCodesList,
    context,
    commonReactDispatch,
    pagination,
    setPagination,
    onChangePageHandler
  }

  const tableBodyParams = {
    tableId: 'DxRegistryTable',
    TableVisibleItem,
    bodyData: diagnosisCodesList,
    TableBodyItem,

    isActionsVisible: true,
    isViewVisible: true,
    viewHandler: (row) => context.openModalView(row.id),
    isEditVisible: true,
    editHandler: (row) => context.openModalMutation(row.id),
    isRemoveVisible: true,
    removeHandler: (row) => context.removeAlertModal(row.id),
  }

  return (
    <div
      className="displayFlex padding0"
      style={{ overflow: "auto" }}
    >
      {isFiltersOpen && (
        <FilterSection 
          stateName={stateName}
        />
      )}

      <div
        id={tableId}
        style={{ width: "100%", position: "relative" }}
      >
        <TableView 
          tableViewHeaderParams = {tableViewHeaderParams}
          tableContainerParams = {tableContainerParams}
          tableToolbarParams = {tableToolbarParams}
          tablePaginationContainerParams = {tablePaginationContainerParams}
          tableBodyParams = {tableBodyParams}
        />
      </div>
    </div>
  );
});

export default DiagnosisCodesTable; 

const ModalHeaderView = (props) => {
  return (
    <div className="padding2 displayFlex backgroundLight borderRadiusTop width100">
      <div className="displayFlex EntryViewHeader">
        <p className="ListP">{props.modalHeaderParams.modalItemName}</p>
      </div>
    </div>   
  )
}

export default ModalHeaderView;
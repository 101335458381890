import Tooltip from "@material-ui/core/Tooltip";
import MenuIcon from "@material-ui/icons/Menu";

// import Button from "../../../../../../components/CustomButtons/Button";
import Button from "../../../../../../components/CustomButtons/ButtonRoot";
import { toggleHeaderWidth } from "../../helpers/headerHelpers";
import {
  toggleSidebarWidth
} from "../../../Sidebar/components/SideMenu/helpers/sideMenuHelpers";

const SidebarMenuSwitcher = (props) => {
  return (
    <Tooltip placement="right" title="Menu">
      <Button
        style={{ color:" #999",
          marginTop: "12px" }}
        justIcon
        round
        color="white"
        className="listButton"
        onClick={() => {
          toggleHeaderWidth(
            !props.isSideMenuHasMinWidth
          );

          toggleSidebarWidth(
            false,
            !props.isSideMenuHasMinWidth
          );

          props.setIsSideMenuHasMinWidth(!props.isSideMenuHasMinWidth);
          props.setIsSideMenuActive(false);
        }}
      >
        <MenuIcon className="sidebarMiniIcon" />
      </Button>      
    </Tooltip>
  );
}

export default SidebarMenuSwitcher;
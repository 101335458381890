import AccordionDetails from "@material-ui/core/AccordionDetails";

import TableContainer from "./TableContainer/TableContainer";

const TableViewBody = (props) => {
  return (
    <AccordionDetails className="accordionDetails"> {/*AccDetails*/ }
      <TableContainer 
        tableContainerParams = {props.tableContainerParams}        
        tableToolbarParams = {props.tableToolbarParams}
        tablePaginationContainerParams = {props.tablePaginationContainerParams}            
        tableBodyParams = {props.tableBodyParams}        
      />
    </AccordionDetails>   
  )
}

export default TableViewBody;
import React from "react";
import ReactDOM from "react-dom";

import {
  makeTabActive,
  removeTab,
} from "../../../../MainContent/components/TabsBar/helpers/tabsBarHelpers";

import TabLink from '../TabLink';
import TabPage from '../TabPage';


const deactivateAllTabs = () => {
  if (document.querySelector(".homeActive")) {
    document.querySelector(".homeActive").classList.remove("homeActive");
  }

  if (document.querySelector(".tabActive")) {
    document.querySelector(".tabActive").classList.remove("tabActive");
  }
  if (document.querySelector(".activeDiv")) {
    document.querySelector(".activeDiv").className = "hideDiv";
  }
}

const appendNewTab = (menuItem, newTabLinkComponent, newTabPageComponent) => {
  const tabsBarContainer = document.querySelector("#tabs");
  const mainContentContainer = document.querySelector("#main");
  
  const newTabLink = document.createElement("li");
  const newTabContainer = document.createElement("div");
  
  const newTabLinkId = "tab" + menuItem.MenuID;
  const newTabContainerId = "div" + menuItem.MenuID;

  newTabLink.textContent = menuItem.MenuDisplayName;
  newTabLink.setAttribute("id", newTabLinkId);
  newTabLink.setAttribute("class", "liTabs tabActive");

  newTabContainer.setAttribute("id", newTabContainerId);
  newTabContainer.setAttribute("class", "activeDiv");

  tabsBarContainer.appendChild(newTabLink);
  mainContentContainer.appendChild(newTabContainer);

  ReactDOM.render(newTabLinkComponent, document.getElementById(newTabLinkId));
  ReactDOM.render(newTabPageComponent, document.getElementById(newTabContainerId));
}

const openNewTab = (
  menuItem,
  setMessage,
  openedTabsList,
  setOpenedTabsList,
  setLoading,
  setConfirmationAlert,
  store,
  persistor,
  closeTabHandler
) => {
  const isTabOpened = openedTabsList.find((val) => val == menuItem.MenuID);
  if (isTabOpened) {
    makeTabActive(menuItem.MenuID);

    return;
  }

  const newOpenedTabsList = [...openedTabsList, menuItem.MenuID];

  setOpenedTabsList(newOpenedTabsList);

  deactivateAllTabs();    

  const newTabLinkComponent = (
    <TabLink 
      menuItem={menuItem}
      closeTabHandler={closeTabHandler}
    />      
  );

  const newTabPageComponent = (
    <TabPage 
      menuItem={menuItem}
      store={store}
      persistor={persistor}
      setLoading={setLoading}
      setMessage={setMessage}
      setConfirmationAlert={setConfirmationAlert}
    />
  );
        
  appendNewTab(menuItem, newTabLinkComponent, newTabPageComponent);    

  if (openedTabsList.length > 6) {
    removeTab(
      openedTabsList[0],
      openedTabsList,
      setOpenedTabsList,
    );
  } 
};

function openNav() {
  document.getElementById("SideBarView").style.width = "94%";
  document.getElementById("SideBarView").style.height = "100vh";
}

const messagePageUnderDevelopment = (id, level, setMessage) => {
  if (level == "submenu1") {
    let elem = document.querySelector(".subActive");
    if (elem) {
      elem.classList.remove("subActive");
    }
    document.querySelector("#" + id + " div").classList.add("subActive");
  } else if (level == "submenu2") {
    let elem = document.querySelector(".subActive2");
    if (elem) {
      elem.classList.remove("subActive2");
    }
    document.querySelector("#" + id + " div").classList.add("subActive3");
  } else if (level == "submenu3") {
    let elem = document.querySelector(".subActive3");
    if (elem) {
      elem.classList.remove("subActive3");
    }
    document.querySelector("#" + id + " div").classList.add("subActive3");
  } else if (level == "Main") {
    let elem = document.querySelector(".active");
    if (elem) {
      elem.classList.remove("active");
    }
    document.querySelector("#" + id + " div").classList.add("active");
  }
  setMessage({
    open: true,
    color: "error",
    message: [["Page Under Development"]],
  });
};

function stopTabFunction(e) {
  if (e.keyCode === 9) {
    e.preventDefault();
  }
}

const handleChangeExpanedSubMenuItem = (menuId, setSubMenuItemIdExpanded) => (e, isExp) => {
  setSubMenuItemIdExpanded(isExp ? menuId : false);
};

const listMenuActive = (
  currentMenuItemId,
  openedMenuId,
  UserMenuDetails
) => {
  UserMenuDetails
    .filter((item) => item.MenuOrder == "1")
    .map((menu) => {
      document
        .getElementById(menu.MenuID)
        .classList.remove("tgactive");
      document
        .getElementById(menu.MenuID)
        .classList.remove("tgactiveSmall");
    });

  if (openedMenuId) {
    var reopenul = document.getElementById("ul" + openedMenuId);
    if (reopenul?.style) {
      reopenul.style.transform = "";
      reopenul.style.transition = "";
    }
  }

  var getul = document.getElementById("ul" + currentMenuItemId);
  getul.style.transform = "translateX(0)";
  getul.style.transition = "all 0.5s ease";
  getul.style.position = "fixed";

  document.getElementById(currentMenuItemId).classList.add("tgactive");
  document.getElementById(currentMenuItemId).classList.add("tgactiveSmall");  
};

const toggleSidebarWidth = (
  isSideMenuActive,
  isSideMenuHasMinWidth
) => {
  if (isSideMenuActive) {
    document.getElementsByClassName("sidebar")[0].style.width = "18%";
    document.getElementById("CloseMenu").style.width = "82%";
    document.getElementsByClassName("sidebar-nav")[0].style.height = "90%";    
  } else {
    document.getElementsByClassName("sidebar")[0].style.width = isSideMenuHasMinWidth ? "9.5%" : "5%";
    document.getElementById("CloseMenu").style.width = isSideMenuHasMinWidth ? "90.5%" : "95%";
  }

  document.getElementById("CloseMenu").style.transform = "translateX(0)";
  document.getElementById("CloseMenu").style.transition = "all 0.5s ease";
}

const MenuClickAway = (
  openedMenuId,
  stopTabFunction,
  UserMenuDetails,
) => {
  document.removeEventListener("keydown", stopTabFunction);

  if (openedMenuId) {
    var getul = document.getElementById("ul" + openedMenuId);
    getul.style.transform = "";
    getul.style.transition = "";
    document.getElementById(openedMenuId).classList.remove("tgactiveSmall");

    UserMenuDetails
      .filter((item) => item.MenuOrder == "1")
      .map(
        (menu) => {
          document
            .getElementById(menu.MenuID)
            .classList.remove("Divbtnlvl");
          document.getElementById(menu.MenuID).classList.add("btnDiv");

          document
            .getElementById(menu.MenuID)
            .classList.remove("rippleSmall");
          document.getElementById(menu.MenuID).classList.add("ripple");

          var elems = document.querySelectorAll(".labeltxt");
          [].forEach.call(elems, function (el) {
            el.style.display = "block";
          });
        },
      );
  }
};

const listClassDivBtn = (e, UserMenuDetails) => {
  e.preventDefault();

  UserMenuDetails
    .filter((item) => item.MenuOrder == "1")
    .map((menu, index) => {
      document.getElementById(menu.MenuID).classList.remove("btnDiv");
      document.getElementById(menu.MenuID).classList.add("Divbtnlvl");

      document.getElementById(menu.MenuID).classList.remove("ripple");
      document
        .getElementById(menu.MenuID)
        .classList.add("rippleSmall");

      var elems = document.querySelectorAll(".labeltxt");
      [].forEach.call(elems, function (el) {
        el.style.display = "none";
      });
    });
};

const subMenuActiveSwitcher = (UserMenuDetails, currentMenuItem) => {
  UserMenuDetails
    .filter((item) => item.MenuOrder == "1")
    .map(
      (subMenuItem) => {
        document
          .getElementById(subMenuItem.MenuID)
          .classList.remove("tgactive");
      },
    );

  document
    .getElementById(currentMenuItem.MenuID)
    .classList.add("tgactive");
}

export {
  openNewTab,
  openNav,
  stopTabFunction,
  handleChangeExpanedSubMenuItem,
  listMenuActive,
  MenuClickAway,
  listClassDivBtn,
  subMenuActiveSwitcher,
  messagePageUnderDevelopment,
  toggleSidebarWidth
};

const timeZonesDataInit = [
  {
    id: "America/New_York",
    label: "UTC-05:00 - Eastern Standard Time (EST)",
    value: "UTC-05:00 - Eastern Standard Time",
  },//America/New_York US/Eastern
  {
    id: "America/Chicago",
    label: "UTC-06:00 - Central Standard Time (CST)",
    value: "UTC-06:00 - Central Standard Time",
  },//America/Chicago US/Central
  {
    id: "America/Denver",
    label: "UTC-07:00 - Mountain Standard Time (MST)",
    value: "UTC-07:00 - Mountain Standard Time",
  },//America/Phoenix  US/Mountain
  {
    id: "America/Los_Angeles",
    label: "UTC-08:00 - Pacific Standard Time (PST)",
    value: "UTC-08:00 - Pacific Standard Time",
  },//America/Los_Angeles US/Pacific
];

export { timeZonesDataInit };

import React from "react";
import Button from "../_helperComponents/Button.js";
import Add from "@material-ui/icons/Add";

export default function AddButton(props) {
  return (
    <Button
      formctrl_id={props.formctrl_id}
      className={props.className + " boxShadowNone"}
      name={"Add"}
      title="Add"
      disabled={props.disabled}
      onClick={props.onClick}
      startIcon={<Add className="AddBtnIcon" />}
    />
  );
}

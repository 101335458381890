import React, {
  forwardRef,
} from "react";

// import GridContainer from "../../../components/Grid/GridContainer";
import GridContainer from "../../../components/Grid/GridContainerRoot";
// import GridItem from "../../../components/Grid/GridItem";
import GridItem from "../../../components/Grid/GridItemRoot";
import Checkbox from "../../../components/_helperComponents/Checkbox";

const UserFormBodyView = forwardRef((props, ref) => {
  return (
    <div key={props.modalBodyParams.item.id}>
      <div index={props.modalBodyParams.item.id} className="padding paddingLeft width100">
        <GridContainer style={{ borderBottom: "1px solid #dad1d1" }}>
          <GridItem
            sm={12}
            md={12}
            lg={12}
            xs={12}
            className="viewModal"
          >
            <GridContainer>
              <GridItem sm={12} md={12} lg={12} xs={12}>
                <GridContainer>
                  <GridItem
                    sm={5}
                    md={5}
                    lg={5}
                    xs={5}
                    className="viewgridpad"
                  >
                    <p className="ViewHeadpTag">{"User Name"} </p>
                  </GridItem>
                  <GridItem
                    sm={7}
                    md={7}
                    lg={7}
                    xs={7}
                    className="viewgridpad"
                  >
                    <span className="ViewBodypTag ViewHightLight">:</span>{" "}
                    <span className="ViewBodypTag ViewHightLight">
                      {" "}
                      {props.modalBodyParams.item.username}
                    </span>
                  </GridItem>
                </GridContainer>
              
                <GridContainer>
                  <GridItem
                    sm={5}
                    md={5}
                    lg={5}
                    xs={5}
                    className="viewgridpad"
                  >
                    <p className="ViewHeadpTag">{"First Name"} </p>
                  </GridItem>
                  <GridItem
                    sm={7}
                    md={7}
                    lg={7}
                    xs={7}
                    className="viewgridpad"
                  >
                    <span className="ViewBodypTag ViewHightLight">:</span>{" "}
                    <span className="ViewBodypTag ViewHightLight">
                      {" "}
                      {props.modalBodyParams.item.firstName}
                    </span>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem
                    sm={5}
                    md={5}
                    lg={5}
                    xs={5}
                    className="viewgridpad"
                  >
                    <p className="ViewHeadpTag">{"Last Name"} </p>
                  </GridItem>
                  <GridItem
                    sm={7}
                    md={7}
                    lg={7}
                    xs={7}
                    className="viewgridpad"
                  >
                    <span className="ViewBodypTag ViewHightLight">:</span>{" "}
                    <span className="ViewBodypTag ViewHightLight">
                      {" "}
                      {props.modalBodyParams.item.lastName}
                    </span>
                  </GridItem>
                </GridContainer> 
                 
                <GridContainer>
                 <GridItem
                    sm={5}
                    md={5}
                    lg={5}
                    xs={5}
                    className="viewgridpad"
                  >
                    <p className="ViewHeadpTag">{"Middle Name"} </p>
                  </GridItem>                         
                  <GridItem
                    sm={7}
                    md={7}
                    lg={7}
                    xs={7}
                    className="viewgridpad"
                  >
                    <span className="ViewBodypTag ViewHightLight">:</span>{" "}
                    <span className="ViewBodypTag ViewHightLight">
                      {" "}
                      {props.modalBodyParams.item.middleName}
                    </span>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem
                    sm={5}
                    md={5}
                    lg={5}
                    xs={5}
                    className="viewgridpad"
                  >
                    <p className="ViewHeadpTag">{"Gender"} </p>
                  </GridItem>
                  <GridItem
                    sm={7}
                    md={7}
                    lg={7}
                    xs={7}
                    className="viewgridpad"
                  >
                    <span className="ViewBodypTag ViewHightLight">:</span>{" "}
                    <span className="ViewBodypTag ViewHightLight">
                      {" "}
                      {props.modalBodyParams.item.gender}
                    </span>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem
                    sm={5}
                    md={5}
                    lg={5}
                    xs={5}
                    className="viewgridpad"
                  >
                    <p className="ViewHeadpTag">{"User Role"} </p>
                  </GridItem>
                  <GridItem
                    sm={7}
                    md={7}
                    lg={7}
                    xs={7}
                    className="viewgridpad"
                  >
                    <span className="ViewBodypTag ViewHightLight">:</span>{" "}
                    <span className="ViewBodypTag ViewHightLight">
                      {" "}
                      {props.modalBodyParams.item.userRole}
                    </span>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem
                    sm={5}
                    md={5}
                    lg={5}
                    xs={5}
                    className="viewgridpad"
                  >
                    <p className="ViewHeadpTag">{"Designation"} </p>
                  </GridItem>
                  <GridItem
                    sm={7}
                    md={7}
                    lg={7}
                    xs={7}
                    className="viewgridpad"
                  >
                    <span className="ViewBodypTag ViewHightLight">:</span>{" "}
                    <span className="ViewBodypTag ViewHightLight">
                      {" "}
                      {props.modalBodyParams.item.designation}
                    </span>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem
                    sm={5}
                    md={5}
                    lg={5}
                    xs={5}
                    className="viewgridpad"
                  >
                    <p className="ViewHeadpTag">{"Speciality"} </p>
                  </GridItem>
                  <GridItem
                    sm={7}
                    md={7}
                    lg={7}
                    xs={7}
                    className="viewgridpad"
                  >
                    <span className="ViewBodypTag ViewHightLight">:</span>{" "}
                    <span className="ViewBodypTag ViewHightLight">
                      {" "}
                      {props.modalBodyParams.item.speciality}
                    </span>
                  </GridItem>
                </GridContainer>
                
                <GridContainer>
                  <GridItem
                    sm={5}
                    md={5}
                    lg={5}
                    xs={5}
                    className="viewgridpad"
                  >
                    <p className="ViewHeadpTag">{"Date of Birth"} </p>
                  </GridItem>
                  <GridItem
                    sm={7}
                    md={7}
                    lg={7}
                    xs={7}
                    className="viewgridpad"
                  >
                    <span className="ViewBodypTag ViewHightLight">:</span>{" "}
                    <span className="ViewBodypTag ViewHightLight">
                      {" "}
                      {props.modalBodyParams.item.birthDate}
                    </span>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem
                    sm={5}
                    md={5}
                    lg={5}
                    xs={5}
                    className="viewgridpad"
                  >
                    <p className="ViewHeadpTag">{"Phone No"} </p>
                  </GridItem>
                  <GridItem
                    sm={7}
                    md={7}
                    lg={7}
                    xs={7}
                    className="viewgridpad"
                  >
                    <span className="ViewBodypTag ViewHightLight">:</span>{" "}
                    <span className="ViewBodypTag ViewHightLight">
                      {" "}
                      {props.modalBodyParams.item.phoneNo}
                    </span>
                  </GridItem>
                </GridContainer>
         
                <GridContainer>               
                  <GridItem
                    sm={5}
                    md={5}
                    lg={5}
                    xs={5}
                    className="viewgridpad"
                  >
                    <p className="ViewHeadpTag">{"Email"} </p>
                  </GridItem>
                  <GridItem
                    sm={7}
                    md={7}
                    lg={7}
                    xs={7}
                    className="viewgridpad"
                  >
                    <span className="ViewBodypTag ViewHightLight">:</span>{" "}
                    <span className="ViewBodypTag ViewHightLight">
                      {" "}
                      {props.modalBodyParams.item.email}
                    </span>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem
                    sm={5}
                    md={5}
                    lg={5}
                    xs={5}
                    className="viewgridpad"
                  >
                    <p className="ViewHeadpTag">{"Time Zone"} </p>
                  </GridItem>
                  <GridItem
                    sm={7}
                    md={7}
                    lg={7}
                    xs={7}
                    className="viewgridpad"
                  >
                    <span className="ViewBodypTag ViewHightLight">:</span>{" "}
                    <span className="ViewBodypTag ViewHightLight">
                      {" "}
                      {props.modalBodyParams.item.timeZone}
                    </span>
                  </GridItem>
                </GridContainer>

              </GridItem>
            </GridContainer>

            {
              props.modalBodyParams.item.userRole !== "Call_Center" &&
                <GridContainer>
                  <GridItem sm={9} md={9} lg={9} xs={9}>
                    <Checkbox
                      label={"Phone number is verified"}
                      checked={props.modalBodyParams.item.isPhoneNoVerified ? true : false}
                      onClick={() => {}}
                      disabled={true}
                    />
                  </GridItem>
                </GridContainer>
            }

            {
              props.modalBodyParams.item.userRole !== "Call_Center" &&
                <GridContainer>
                  <GridItem sm={9} md={9} lg={9} xs={9}>
                    <Checkbox
                      label={"User stopped notifications"}
                      checked={props.modalBodyParams.item.isUserStopsNotifs ? true : false}
                      onClick={() => {}}
                      disabled={true}
                    />
                  </GridItem>
                </GridContainer>
            }
            
            <GridContainer>
              <GridItem sm={3} md={3} lg={3} xs={3}>
                <Checkbox
                  label={"Active"}
                  checked={props.modalBodyParams.item.isActive ? true : false}
                  onClick={() => {}}
                  disabled={true}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
});

export default UserFormBodyView;

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useReducer,
  useContext,
} from "react";

import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

import UserFormBodyMutation from "./UserFormBodyMutation";

import ModalWindowMutation from "../../../components/ModalWindow/ModalWindowMutation";
import ModalHeaderMutation from "../../../components/ModalWindow/ModalHeaderMutation";
import ModalFooterMutation from "../../../components/ModalWindow/ModalFooterMutation";

import mutationReactReducer from "../../../reactReducers/mutationReactReducer";

import { UsersContext } from "../context/usersContextProvider";
import { isValidMutationInput } from "../validations/mutationUserValidator";

import {
  saveSubmit,
  removeSubmit
} from "../actions/mutationUserAction";

import { sendVerificationAction } from "../../Auth/components/Login/actions/sendVerificationAction";

import {
  initialStateMutationUser,
} from "../helpers/mutationUserHelper";

const UserMutation = forwardRef((props, ref) => {
  const authStoreRedux = useSelector((state) => state.auth);
  
  const context = useContext(UsersContext);
  const { commonReactState, commonReactDispatch } = context;

  const [state, dispatch] = useReducer(mutationReactReducer, initialStateMutationUser);

  const [isModalOpen, setIsModalOpen] = useState(false); 

  useEffect(() => {
    if (commonReactState.currentModalItem) {
      dispatch({
        type: "set_entire_state",
        value: commonReactState.currentModalItem,
      });
    }
  }, [commonReactState.currentModalItem]);

  useImperativeHandle(ref, () => ({
    openModal: (item) => {
      const itemState = item ? item : initialStateMutationUser;
      
      dispatch({
        type: "set_entire_state",
        value: itemState,
      });

      setIsModalOpen(true);
    },
    closeModal: () => {
      setIsModalOpen(false);
    },
    removeAlertModal: (itemId) => {
      removeAlert(itemId);
    }
  }));

  const saveAlert = () => {
    context.ConfirmAlert(
      <SweetAlert
        warning
        Size="sm"
        style={{ display: "block", marginTop: "100px" }}
        title={"Are you sure?"}
        onConfirm={() => {
          saveSubmit(
            context,
            state,
            setIsModalOpen,
            dispatch,
            initialStateMutationUser
          );
        }}
        onCancel={() => {
            context.ConfirmAlert(null);
            setIsModalOpen(false);
            dispatch({
              type: "set_entire_state",
              value: initialStateMutationUser,
            });
          }
        }
        confirmBtnCssClass={"sweetAlertBtn sweetAlertBtnSuccess"} 
        cancelBtnCssClass={"sweetAlertBtn sweetAlertBtnCancel"}
        confirmBtnText={"Yes"}
        cancelBtnText={"Cancel"}
        showCancel
      >
        {"Do you want to Save?"}
      </SweetAlert>,
    );
  };

  const removeAlert = (itemId) => {
    context.ConfirmAlert(
      <SweetAlert
        warning
        Size="sm"
        style={{ display: "block", marginTop: "100px" }}
        title={"Are you sure?"}
        onConfirm={() => {
          removeSubmit(
            context,
            itemId,
            setIsModalOpen
          );
        }}
        onCancel={() => {
            context.ConfirmAlert(null);
            setIsModalOpen(false);
          }
        }
        confirmBtnCssClass={"sweetAlertBtn sweetAlertBtnSuccess"} 
        cancelBtnCssClass={"sweetAlertBtn sweetAlertBtnCancel"}
        confirmBtnText={"Yes"}
        cancelBtnText={"Cancel"}
        showCancel
      >
        {"Are you sure you want to delete this item?"}
      </SweetAlert>,
    );
  };

  const onClickClose = () => {
    dispatch({
      type: "set_entire_state",
      value: initialStateMutationUser,
    });
    setIsModalOpen(false);
  }

  const modalWindowParams = {
    isModalOpen, 
    setIsModalOpen,
    onClickClose
  } 

  const modalHeaderParams = {
    modalItemName: "User",
    modalItemId: state.id
  };

  const sendVerificationActionAlert = () => {   
    context.ConfirmAlert(
      <SweetAlert
        warning
        Size="sm"
        style={{ display: "block", marginTop: "100px" }}
        title={"Are you sure?"}
        onConfirm={() => {
          context.ConfirmAlert(null);  

          sendVerificationAction(state.id);
        }}
        onCancel={() => context.ConfirmAlert(null)}
        confirmBtnCssClass={"sweetAlertBtn sweetAlertBtnSuccess"} 
        cancelBtnCssClass={"sweetAlertBtn sweetAlertBtnCancel"}
        confirmBtnText={"Yes"}
        cancelBtnText={"Cancel"}
        showCancel
      >
        {"Do you want to Send Verification Message Again?"}
      </SweetAlert>,
    );
  }

  const modalBodyParams = {
    item: state,
    dispatch,
    designationsData: JSON.parse(authStoreRedux.designationsData),
    specialitiesData: JSON.parse(authStoreRedux.specialitiesData),
    gendersData: JSON.parse(authStoreRedux.gendersData),
    userRolesData: JSON.parse(authStoreRedux.userRolesData),
    timeZonesData: JSON.parse(authStoreRedux.timeZonesData),
    sendVerificationActionAlert: sendVerificationActionAlert
  };

  const onClickSave = () => {
    if (!isValidMutationInput(state, context)) {
      return;
    } 
    
    saveAlert();
  }
 
  const modalFooterParams = {
    state,
    dispatch,
    onClickSave,
    onClickCancel: onClickClose    
  };   

  return (
    <ModalWindowMutation 
      modalWindowParams = {modalWindowParams}
  
      modalHeader = {ModalHeaderMutation}
      modalHeaderParams = {modalHeaderParams}

      modalBody = {UserFormBodyMutation}
      modalBodyParams = {modalBodyParams}

      modalFooter = {ModalFooterMutation}
      modalFooterParams = {modalFooterParams}
    />      
  );
});

export default UserMutation;

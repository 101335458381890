import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import MatDatePicker from "./Mat_DatePicker";
import L2TextField from "./L2TextFieldTable";
import SearchIcon from "@material-ui/icons/Search";
import Checkbox from "./Checkboxtable";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CloseIcon from "@material-ui/icons/Close";
import { Button } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    height: "61vh",
    boxShadow: "none",
    border: "1px solid #e3e4e9",
  },
  height65: {
    height: "62vh",
  },
  height40: {
    height: "30vh",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
    color: "#fff",
  },

  listColor: {
    backgroundColor: "#fff !important",
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: "#fff !important",
    padding: "5px 4px !important",
    fontSize: "0.75rem !important",
  },
  body: {
    fontSize: "0.688rem !important",
    padding: "7px !important",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#f2f2f2",
    },
  },
}))(TableRow);

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    headCells,

    TableSearchParams,
  } = props;
  // console.log('EnhancedTableHead+++++++++++ to many renders!',props)
  const [FilterTable, setFilterTable] = useState({});

  const [FilterTableValue, setFilterTableValue] = useState({});

  useEffect(() => {
    FilterTableFunc();
  }, [TableSearchParams]);

  function FilterTableFunc() {
    if (!TableSearchParams) {
      return;
    }

    Object.keys(TableSearchParams).map((e) => {
      setFilterTable({
        ...FilterTable,
        [e]: TableSearchParams[e] ? true : false,
      });
    });
    setFilterTableValue(TableSearchParams);
    document.getElementById("CloseMenu").onclick = function () {
      escapefil();
    };
  }

  const handleChange = (e, id, name) => {
    e.stopPropagation();
    setFilterTable({ ...FilterTable, [id]: !FilterTable[id] });
    setTimeout(function () {
      var textid = document.getElementById(name + "headcell");
      if (textid) {
        textid.focus();
        document.getElementById(name + "headcell").onclick = function (event) {
          event.stopPropagation();
        };
      }
    }, 100);
  };

  function escapefil(e) {
    setFilterTable({
      CountryCode: "",
      CountryName: "",
      CurrencyName: "",
      CurrencySymbol: "",
      CurrencyCurVal: "",
    });
  }

  const handleChangeValue = (e, id) => {
    if (e.length == 0) {
      props.value({ ...FilterTableValue, [id]: "" });
    }
    setFilterTableValue({ ...FilterTableValue, [id]: e });
  };

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <StyledTableRow>
        {
          !props.isActionsVisible ? null : (
            <StyledTableCell style={{ minWidth: "80px" }}>
              <span className="trAction">Action</span>
            </StyledTableCell>
        )}

        {
          !props.isReasonForReassignVisible ? null : (
            <StyledTableCell style={{ minWidth: "8px" }}>
              <span className="trAction">R</span>
            </StyledTableCell>
        )}

        {
          !props.isCheckinVisible ? null : (
            <StyledTableCell style={{ minWidth: "80px" }}>
              <span className="trAction">Check In</span>
            </StyledTableCell>
        )}

        {headCells.map((headCell) => (
          <StyledTableCell
            className={classes.listColor}
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              minWidth: "180px",
              textAlign: headCell.id.slice(0, 2) == "Is" ? "center" : "left",
            }}
          >
            {!FilterTable[headCell.id] ? (
              !FilterTableValue[headCell.id] ? (
                <TableSortLabel
                  filter-title="Sort"
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  <span>{headCell.name}</span>
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : headCell.type == "Date" ? (
                <MatDatePicker
                  value={FilterTableValue[headCell.id]}
                  Label={headCell.name}
                  onChange={(e) => {
                    handleChangeValue(e, headCell.id);
                    props.value({
                      ...FilterTableValue,
                      [headCell.id]: e,
                      [headCell.id + "Type"]: "Date",
                    });
                  }}
                />
              ) : (
                <L2TextField
                  id={headCell.name + "headcell"}
                  className="TableheaderSearchInput"
                  type={headCell.type == "Number" ? "number" : "text"}
                  labelname={headCell.name}
                  onChange={(e) => {
                    handleChangeValue(e, headCell.id, headCell.type);
                  }}
                  disabled={false}
                  value={
                    FilterTableValue[headCell.id].indexOf("%") === -1
                      ? FilterTableValue[headCell.id]
                      : FilterTableValue[headCell.id].replace(/[%]/g, "")
                  }
                  clear={(e, s) => {
                    if (e) {
                      handleChangeValue("", headCell.id);
                      handleChange(s, headCell.id);
                      props.value({ ...FilterTableValue, [headCell.id]: "" });
                    }
                  }}
                  mandatory={false}
                  keyPress={true}
                  onKeyPress={(e) => {
                    props.value({
                      ...FilterTableValue,
                      [headCell.id]: headCell.type == "Number" ? e : e,
                    });
                  }}
                />
              )
            ) : headCell.id.includes("Date") ||
              headCell.id.includes("date") ||
              headCell.id.includes("Dt") ||
              headCell.id.includes("dt") ? (
              <MatDatePicker
                value={FilterTableValue[headCell.id]}
                label={headCell.name}
                onChange={(e) => {
                  handleChangeValue(e, headCell.id);
                  props.value({
                    ...FilterTableValue,
                    [headCell.id]: e,
                    [headCell.id + "Type"]: "Date",
                  });
                }}
              />
            ) : (
              <L2TextField
                id={headCell.name + "headcell"}
                className="TableheaderSearchInput"
                type={headCell.type == "Number" ? "number" : "text"}
                labelname={headCell.name}
                onChange={(e) => {
                  handleChangeValue(e, headCell.id, headCell.type);
                }}
                disabled={false}
                value={
                  FilterTableValue[headCell.id].indexOf("%") === -1
                    ? FilterTableValue[headCell.id]
                    : FilterTableValue[headCell.id].replace(/[%]/g, "")
                }
                clear={(e, s) => {
                  if (e) {
                    handleChangeValue("", headCell.id);
                    handleChange(s, headCell.id);
                    props.value({ ...FilterTableValue, [headCell.id]: "" });
                  }
                }}
                mandatory={false}
                keyPress={true}
                onKeyPress={(e) => {
                  props.value({
                    ...FilterTableValue,
                    [headCell.id]: headCell.type == "Number" ? e : e,
                  });
                }}
              />
            )}
            {/* {!FilterTable[headCell.id] &&
              headCell.id.substring(0, 2) !== "Is" &&
              !FilterTableValue[headCell.id] && (
                <SearchIcon
                  className="tableHeadersearch"
                  onClick={(e) => handleChange(e, headCell.id, headCell.name)}
                />
              )} */}
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
}

export default function MuiTable(props) {
  const TableClass = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const userRole = localStorage.getItem("userRole");
  // console.log('MuiTable+++++++++++ to many renders!',props)
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function TableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function TableCellValue(val, pass) {
    if (pass.toLowerCase().indexOf("password") > -1) {
      var password = "";
      for (let i = 0; i < val.length; i++) {
        password += "*";
      }
      return password;
    } else {
      return val;
    }
  }
  const timeZone = localStorage.getItem("timeZone");

  const getRowColor = (val) => {
      if (val.status == "Cancelled") {
        return "#f2f2f2"; //light grey 
      }

      if (val.status == "Not Started" && val.waitingTime < 30) {
        return "#f2f2f2"; //light grey
      }

      if (val.status == "Not Started" && (val.waitingTime >= 30 && val.waitingTime < 60)) {
        return "#ffffa1"; //yellow
      }

      if (val.status == "Not Started" && val.waitingTime > 60) {
        return '#ff9d9d'  // red
      }     

      if (val.status == "Started") {
        return "#C3F8FF"; //light blue
      }

      if (val.status == "Completed") {
        return "#90EE90"; //light green
      }
  };

  return (
    <TableContainer
      component={Paper}
      className={props.classheight ? "" : TableClass.container + " scrollY"}
    >
      <Table stickyHeader id={props.id}>
        <EnhancedTableHead
          classes={TableClass}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headCells={props.headerdata}
          value={props.searchvalue}
          isActionsVisible={props.isActionsVisible}
          isReassignVisible={props.isReassignVisible}
          isReasonForReassignVisible={props.isReasonForReassignVisible}
          isCheckinVisible={props.isCheckinVisible}
          TableSearchParams={props.tablesearchparams}
        />

        <TableBody>
          {
          TableSort(props.bodydata, getComparator(order, orderBy)).map(
            (row, i) => (
              <StyledTableRow
                key={i}
                onDoubleClick={(e) => {
                  props.editHandler && props.editHandler(row);
                }}
                style={{
                  background: props.isRowColorCalculated ? getRowColor(row) : "transparent",
                }}
                draggable={false}
                // onDragStart={(e) => props.ondrag(e, row)}
                id={"drag" + i}
              >  

                {
                  props.isActionsVisible && (
                    <StyledTableCell style={{ display: "flex" }}>
                        {
                          props.isViewVisible && (
                            <div style={{ flex: "1", marginRight: "5px" }}>
                              <Button
                                disabled={false}
                                variant="contained"
                                color="primary"
                                startIcon={<VisibilityIcon />}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  props.viewHandler(row);
                                }}
                              >
                                View
                              </Button>
                            </div>
                        )}

                        {
                          props.isEditVisible && (
                            <div style={{ flex: "1", marginRight: "5px" }}>
                              <Button
                                disabled={(row?.status == "Not Started" || !row?.status && userRole === "Administration") ? false : true}
                                variant="contained"
                                color="primary"
                                startIcon={<EditIcon />}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  props.editHandler(row);
                                }}
                              >
                                Edit
                              </Button>
                            </div>
                        )}

                        {
                          props.isRemoveVisible && (
                            <div style={{ flex: "1", marginRight: "5px" }}>
                              <Button
                                disabled={(row?.status == "Not Started" || !row?.status && userRole === "Administration") ? false : true}
                                variant="contained"
                                color="secondary"
                                startIcon={<CloseIcon />}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  props.removeHandler(row);
                                }}
                              >
                                { row?.status ? "Cancel" : "Delete" }
                              </Button>
                            </div>
                        )}

                        {
                          props.isReassignVisible && (
                            <div style={{ flex: "1", marginRight: "5px" }}>
                              <Button
                                disabled={row.status == "Not Started" ? false : true}
                                variant="contained"
                                color="primary"
                                startIcon={<AddToPhotosIcon />}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  props.reassignHandler(row);
                                }}
                              >
                                Reassign
                              </Button>
                            </div>
                        )}

                    </StyledTableCell>
                )}

                {
                  props.isReasonForReassignVisible && (
                    <StyledTableCell>
                      <div style={{ flex: "1", marginRight: "5px" }}>
                        <div class="tooltip2">R
                          <span class="tooltiptext">{row.reasonForReassign ? row.reasonForReassign : "Consult was not reassigned yet."}</span>
                        </div> 
                      </div>
                    </StyledTableCell>
                )}

                {
                  props.isCheckinVisible && (
                    <StyledTableCell>
                      <Switch
                        disabled={row.status == "Not Started" ? false : true}
                        variant="contained"
                        checked={row.checkedIn}
                        inputProps={{ "aria-label": "primary checkbox" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          props.checkinHandler(row);
                        }}
                      />
                    </StyledTableCell>
                )}

                {
                  props.bodyitem.map((val, j) => {
                    let type = props.visibleitem.filter((e) => e.id == val);
                    if (val.slice(0, 2) == "is") {
                      return (
                        <StyledTableCell key={j} style={{ textAlign: "center" }}>
                          <Checkbox
                            checked={row[val]}
                            disabled={true}
                          />
                        </StyledTableCell>
                      );
                    } else {
                      if (type.length > 0) {
                        return (
                          <StyledTableCell
                            key={j}
                            style={{
                              textAlign:
                                type[0].type == "numeric" ||
                                type[0].type == "smallint" ||
                                type[0].type == "int"
                                  ? "right"
                                  : "left",
                            }}
                          >
                            {TableCellValue(row[val], val)}
                          </StyledTableCell>
                        );
                      } else {
                        return (
                          <StyledTableCell key={j}>
                            {TableCellValue(row[val], val)}
                          </StyledTableCell>
                        );
                      }
                    }
                  }
                )}
              </StyledTableRow>
            ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import { 
  forwardRef,
  useContext ,
  useState,
  useImperativeHandle,
  tableRef,
  useEffect,
  useLayoutEffect
} from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";

import { MeetingsContext } from "../../Meetings/context/meetingsContextProvider";

import {
  getProvidersList
} from "../../Users/actions/viewProvidersListAction";
import {
  refreshProviderStatistics
} from "../actions/statisticsMeetingsAction";

import {
  getMeetingsFiltersDefault,
  pendingMeetingsFilters,
  totalMeetingsFilters,
  cancelledMeetingsFilters,
  completedMeetingsFilters
} from "../../Meetings/helpers/meetingsQueueTableHelper";

import 
MeetingsStatisticsTable
 from "./MeetingsStatisticsTable";

// import GridContainer from "../../../components/Grid/GridContainer";
import GridContainer from "../../../components/Grid/GridContainerRoot";
// import GridItem from "../../../components/Grid/GridItem";
import GridItem from "../../../components/Grid/GridItemRoot";
import DropDown from "../../../components/_helperComponents/DropDown";

const MeetingsStatistics = forwardRef((props, ref) => {
  const authStoreRedux = useSelector((state) => state.auth);

  const context = useContext(MeetingsContext);
  const { commonReactState, commonReactDispatch } = context;

  const meetingsFiltersDefault = getMeetingsFiltersDefault();
  
  const [activeCell, setActiveCell] = useState("");

  const [totalMeetingsCounter, setTotalMeetingsCounter] = useState(0);
  const [cancelledMeetingsCounter, setCancelledMeetingsCounter] = useState(0);
  const [completedMeetingsCounter, setCompletedMeetingsCounter] = useState(0);
  const [pendingMeetingsCounter, setPendingMeetingsCounter] = useState(0);
  
  // because context not ready with stuff
  const [statisticsProviderId, setStatisticsProviderId] = useState('');
  const [statisticsProvider, setStatisticsProvider] = useState({});
  const [providersList, setProvidersList] = useState([]);
  const [providersPagination, setProvidersPagination] = useState({
    pageNo: 1,
    getPerPage: 100,
    itemsTotalNumber: 0,
    pagesTotalNumber: 0
  });

  const userRole = localStorage.getItem("userRole");
  const userId = localStorage.getItem("userId");

  const defaultFilters = {
    statuses: {
      'Active': "true",
      'Inactive': "false"
    }
  }
  
  useImperativeHandle(ref, () => ({
    refreshProviderStatistics: (providerId) => {

      setStatisticsProviderId(providerId);
      
      refreshProviderStatistics(
        providerId,
        setTotalMeetingsCounter,
        setCancelledMeetingsCounter,
        setCompletedMeetingsCounter,
        setPendingMeetingsCounter,
      );
    },
    refreshActiveCellStatistics: (meetingsType) => {
      setActiveCell(meetingsType);
    },
    clearProviderCounters: () => {  
      tableRef.current.clearTable();

      setTotalMeetingsCounter(0);
      setCancelledMeetingsCounter(0);
      setCompletedMeetingsCounter(0);
      setPendingMeetingsCounter(0);
    },
  }));

  useLayoutEffect(() => {
    getProvidersList(
      setProvidersList,
      {
        loading: (val) => {},
        alert: (val) =>  {}
      },
      {
        usersFilters: defaultFilters
      },
      {
        pageNo: 1,
        getPerPage: 100,
        pagination: providersPagination,
        setPagination: setProvidersPagination
      }
    );
  }, []);

  useEffect(() => {
    providersList.map((value) => {
      if (statisticsProviderId === value.id) {
        setStatisticsProvider(value);
      }
    });
  }, [statisticsProviderId, providersList]);

  useEffect(() => {

    if (commonReactState.currentProvider) {
      setStatisticsProvider(commonReactState.currentProvider);
    }
  }, [commonReactState.currentProvider]);

  useEffect(() => {

    refreshProviderStatistics(
      commonReactState.currentProvider.id,
      setTotalMeetingsCounter,
      setCancelledMeetingsCounter,
      setCompletedMeetingsCounter,
      setPendingMeetingsCounter,
    );
  }, []);

  const dateRangeFromToday = format(new Date(), "MM-dd-yyyy");
  const dateRangeToToday = format(new Date(), "MM-dd-yyyy");

  const tableCellsParams = [
    {
      cellName: "Total Consults",
      cellHandler: () => {
        setActiveCell('Total');
        // const providerId = userRole == "Provider" ? userId : commonReactState.currentProvider.id;
        const providerId = commonReactState.currentProvider.id;

        context.refreshTable(
          providerId,
          // commonReactState.currentProvider?.id,
          // statisticsProviderId,
          
          {
            dateRangeFrom: dateRangeFromToday,
            dateRangeTo: dateRangeToToday,
            ...totalMeetingsFilters
            // statuses: {
            //   'Not Started': "true",
            //   'Started': "true",
            //   'Cancelled': "true",
            //   'Completed': "true"
            // }
          }
        );
      },
      cellCounter: totalMeetingsCounter,
      styleColor: "#7B68EE",
      cellBgColor: activeCell == 'Total' ? '#f2f2f2' : ''
    },
    {
      cellName: "Canceled Consults",
      cellHandler: () => {
        setActiveCell('Cancelled');
        // const providerId = userRole == "Provider" ? userId : commonReactState.currentProvider.id;
        const providerId = commonReactState.currentProvider.id;

        context.refreshTable(
          providerId,
          // commonReactState.currentProvider.id,
          // statisticsProviderId,
          {
              dateRangeFrom: dateRangeFromToday,
              dateRangeTo: dateRangeToToday,
              ...cancelledMeetingsFilters
          //   statuses: {
          //     'Cancelled': "true"
          //   }
          }
        )
      },
      cellCounter: cancelledMeetingsCounter,
      styleColor: "#FA8072",
      cellBgColor: activeCell == 'Cancelled' ? '#f2f2f2' : ''
    },
    {
      cellName: "Completed Consults",
      cellHandler: () => {
        setActiveCell('Completed');
        // const providerId = userRole == "Provider" ? userId : commonReactState.currentProvider.id;
        const providerId = commonReactState.currentProvider.id;

        context.refreshTable(
          providerId,
          // commonReactState.currentProvider.id,
          {
            dateRangeFrom: dateRangeFromToday,
            dateRangeTo: dateRangeToToday,
            ...completedMeetingsFilters
          //   statuses: {
          //     'Completed': "true"
          //   }
          }
        )
      },
      cellCounter: completedMeetingsCounter,
      styleColor: "#32CD32",
      cellBgColor: activeCell == 'Completed' ? '#f2f2f2' : ''
    },
    {
      cellName: "Pending Consults",
      cellHandler: () => {        
        setActiveCell('Pending');
        // const providerId = userRole == "Provider" ? userId : commonReactState.currentProvider.id;
        const providerId = commonReactState.currentProvider.id;

        context.refreshTable(
          providerId,
          // commonReactState.currentProvider.id,
          pendingMeetingsFilters
        )
      },
      cellCounter: pendingMeetingsCounter,
      styleColor: "#704a2f",
      cellBgColor: activeCell == 'Pending' ? '#f2f2f2' : ''
    }
  ]

  return (
    <GridContainer
      spacing={3}
      style={{ margin: "0px", width: "100%" }}
    >    
      <GridItem xs={12} sm={12} md={2}>
        <h4 className="AccHtext">Provider’s Consults Statistics</h4>
        <h4 className="AccHtext">
          {format(new Date(), "MMMM") +
            "-" +
            format(new Date(), "dd") +
            "-" +
            format(new Date(), "yy")}
        </h4>
      </GridItem>     
        
      <GridItem xs={12} sm={12} md={3}>
        { 
          //userRole != "Provider" && (
            <DropDown
              placeholder="Provider"
              onChange={(providerId) => {
                const pickedProviderId = providerId === "All" ? null : providerId;
                
                context.refreshActiveCellStatistics("Pending");
                context.getCurrentProvider(pickedProviderId);

                context.refreshTable(
                  pickedProviderId,
                  pendingMeetingsFilters
                )

                context.refreshProviderStatistics(pickedProviderId)              
              }}
              data={[
                { 
                  id: "All",
                  value: "All",
                  label: "All"
                },
                ...context.providersList, 
              ]}
              value={commonReactState.currentProvider.id ? commonReactState.currentProvider.id : "All"}
              mandatory={true}
            />   
        //)
        }      
      </GridItem>      
          
      <MeetingsStatisticsTable
        provider={statisticsProvider} 
        tableCellsParams={tableCellsParams} 
      />
    </GridContainer>
  );
});
  
export default MeetingsStatistics; 

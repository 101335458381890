import { withStyles } from "@material-ui/styles";
import InputBase from "@material-ui/core/InputBase";

import { format, differenceInMinutes } from "date-fns";

function VisibleItemSelect(
  val,
  setTableVisibleItem,
  setTableBodyItem
) {
  const data = val;
  let arr = [];
  let bodyArr = [];
  let ItemArr = [];
  data.map((val) => {
    if (val.IsVisible == "1") {
      arr.push({
        id: val.FieldName,
        name: val.FieldDescription,
        type: val.FieldDataType,
      });

      bodyArr.push(val.FieldName);
      ItemArr.push(val.FieldDescription);
    }
  });
  setTableVisibleItem(arr);
  setTableBodyItem(bodyArr);
}


const Refresh = (
  type,
  date,
  GridPagination,
  setConsultInQue,
  setSelectedMenu,
  setAppointmentType,
  setSearchParams,
  searchData,
  context,
  setTableTotal,
  setTableTotalCount,
  cdispatch,
  setAppointmentRegistryData,
  setAppointmentData,
  setGridPagination,
  CurrentDate,
  AppointmentType,
) => {
  if (type === "PendingConsults" && date) {
    setConsultInQue(true);
  } else {
    setConsultInQue(false);
  }

  setSelectedMenu(type);
  setAppointmentType(type);
  setSearchParams("");

  searchData(
    GridPagination,
    EmptyJson,
    type,
    date,
    context,
    setTableTotal,
    setTableTotalCount,
    cdispatch,
    setAppointmentRegistryData,
    setAppointmentData,
    setGridPagination,
    CurrentDate,
    AppointmentType,
  );
};

const TotalTime = (startt, end) => {
  if (new Date(startt) == "Invalid Date") {
    startt = startt.replace(/-/g, "/");
  }

  var time = "";
  let start = format(new Date(startt), "yyyy-MM-dd hh:mm a");
  let endTime = format(new Date(end), "yyyy-MM-dd hh:mm a");
  time = differenceInMinutes(new Date(endTime), new Date(start));
  time = time ? time : 1;
  return time;
  // let day = '';
  // let hour = '';
  // let mins = '';

  // if(Math.floor(time/24/60)){
  //     day = `${Math.floor(time/24/60)} Days`;
  // }

  // if(Math.floor(time/60%24)){
  //     hour = `${Math.floor(time/60%24)} Hours`;
  // }

  // if(Math.floor(time%60)){
  //     mins = `${Math.floor(time%60)} Mins`;
  // }

  // return `${day} ${hour} ${mins}`;
};

const AppointmentDiff = (starttime, endtime) => {
  let start = format(new Date(starttime), "yyyy-MM-dd hh:mm a");
  let current = format(new Date(endtime), "yyyy-MM-dd hh:mm a");

  let time = differenceInMinutes(new Date(current), new Date(start));

  let day = "";
  let hour = "";
  let mins = "";

  if (Math.floor(time / 24 / 60)) {
    day = `${Math.floor(time / 24 / 60)} days`;
  }

  if (Math.floor((time / 60) % 24)) {
    hour = `${Math.floor((time / 60) % 24)} hours`;
  }

  if (Math.floor(time % 60)) {
    mins = `${Math.floor(time % 60)} Mins`;
  }
  // console.log('q',`${day} ${hour} ${mins} in Queue` )
  return `${day} ${hour} ${mins} in Queue`;
};

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

const UserGridViewData2 = {};

const TableVisibleItem = [
  { id: "SNo", name: "S.No", type: "varchar" },
  { id: "FacilityName", name: "Facility", type: "varchar" },
  { id: "EmpFirstName", name: "Provider", type: "varchar" },
  { id: "CreatedDateTime", name: "Appointment Date Time", type: "varchar" },
  { id: "AppointmentWaiting", name: "Appointment Waiting", type: "varchar" },
  { id: "PatFirstName", name: "Patient First Name", type: "varchar" },
  { id: "PatLastName", name: "Patient Last Name", type: "varchar" },
  { id: "CalDiff", name: "Consult Status", type: "varchar" },
  { id: "ConsultTypeName", name: "Consult Type", type: "varchar" },
  { id: "ConStartTime", name: "Consult Start Time", type: "varchar" },
  { id: "ConEndTime", name: "Consult End Time", type: "varchar" },
  { id: "ConTotalTime", name: "Consult Duration", type: "varchar" },
  { id: "PatSiteCBNumber", name: "Site Call Back Number", type: "varchar" },
  { id: "PatSiteName", name: "Site Contact Name", type: "varchar" },
  { id: "CPTCode", name: "CPT Code", type: "varchar" },
  { id: "DxCode", name: "Dx Code", type: "varchar" },
  { id: "Description", name: "Description", type: "varchar" },
  { id: "Disposition", name: "Disposition", type: "varchar" },
  { id: "DispositionText", name: "Disposition Text", type: "varchar" },
  { id: "Actual AP Time", name: "ActualApTime", type: "varchar" },
];

const TableBodyItem = [
  "SNo",
  "FacilityName",
  "EmpFirstName",
  "CreatedDateTime",
  "AppointmentWaiting",
  "PatFirstName",
  "PatLastName",
  "CalDiff",
  "ConsultTypeName",
  "ConStartTime",
  "ConEndTime",
  "ConTotalTime",
  "PatSiteCBNumber",
  "PatSiteName",
  "CPTCode",
  "DxCode",
  "Description",
  "Disposition",
  "DispositionText",
  "ActualApTime",
];

const BootstrapInput = withStyles((theme) => ({
  root: {
    bottom: "15%",
    "label + &": {
      marginTop: 3,
    },
  },
  input: {
    borderRadius: 2,
    position: "relative",
    border: "1px solid #ced4da",
    fontSize: 12,
    padding: "5px 0px",
    "&:focus": {
      borderRadius: 2,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0 rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

let EmptyJson = {
  AppointmentIDPK: "",
  AppointmentCode: "",
  AppointmentDateTime: "",
  AppointmentDateTimeType: "",
  AppointmentDateTimeBetween: "",
  Description: "",
  PatFirstName: "",
  PatMiddleName: "",
  PatLastName: "",
  PatDateofBirth: "",
  PatDateofBirthType: "",
  PatDateofBirthBetween: "",
  PatMrnNumber: "",
  PatCartNumber: "",
  PatSiteCBNumber: "",
  PatSiteName: "",
  ConStartTime: "",
  ConEndTime: "",
  ConTotalTime: "",
  EmployeeKey: "",

  ConsultTypeID: "",
  CPTID: "",
  DxID: "",
  EmployeeID: "",
  FacilityID: "",
  SpecialityID: "",
  IsActive: "",
  DeleStat: "",
  CreatedUserID: "",
  CreatedTtm: "",
  CreatedTtmType: "",
  CreatedTtmBetween: "",
  UpdatedTtm: "",
  UserCode: "",
  UserEmail: "",
  UserMobile: "",
  UserName: "",
  UserPassword: "",
  ConsultTypeCode: "",
  ConsultTypeName: "",
  CPTCode: "",
  DxCode: "",
  DesignationID: "",
  DesignationCode: "",
  DesignationName: "",
  SpecialityCode: "",
  SpecialityName: "",
  Title: "",
  PhoneNo1: "",
  PhoneNo2: "",
  EmpEmail: "",
  PersonalEmail: "",
  EmpFirstName: "",
  EmpLastName: "",
  Gender: "",
  CityID: "",
  CityCode: "",
  CityName: "",
  RegionID: "",
  RegionCode: "",
  RegionName: "",
  CountryID: "",
  CountryCode: "",
  CountryName: "",
  Address1: "",
  BranchCode: "",
  ContactPerson: "",
  ContactPersonEmail: "",
  ContactPersonPhoneNo: "",
  Email1: "",
  Email2: "",
  FacilityCode: "",
  FacilityName: "",
  FacilityType: "",
  ZipCode: "",
  PageIndex: "",
  PageSize: "",
  Type: "",
  OrderByType: "",
  SortOrder: "",
};

const tableSearchParamsInit = {
  CalDiff: "",
  SNo: "",
  AppointmentCode: "",
  AppointmentDateTime: "",
  AppointmentWaiting: "",
  PatFirstName: "",
  PatMiddleName: "",
  PatLastName: "",
  PatDateofBirth: "",
  PatMrnNumber: "",
  PatCartNumber: "",
  PatSiteCBNumber: "",
  PatSiteName: "",
  ConStartTime: "",
  ConEndTime: "",
  ConTotalTime: "",
  EmployeeKey: "",
  ConsultTypeName: "",
  CPTCode: "",
  DxCode: "",
  Description: "",
  Disposition: "",
  DispositionText: "",
  ActualApTime: "",
  EmpFirstName: "",
  FacilityName: "",
  SpecialityName: "",
};

let tableHeaderColumnIndex = [
  "S.no",
  "Facility",
  "Provider",
  "Appointment Date Time",
  "Appointment Waiting in Minutes",
  "Pat First Name",
  "Pat Last Name",
  "MRN Number",
  "Pat Date of Birth",
  "Consult Status",
  "Consult Type",
  "Consult Start Time",
  "Consult End Time",
  "Consult Duration in Minutes",
  "Site Call Back Number",
  "Site Contact Name",
  "CPT Code",
  "Dx Code",
  "Description",
  "Disposition",
  "Disposition Text",
  "Remarks",
  "Actual AP Time",
];

const tableRow = [
  "SNo",
  "FacilityName",
  "EmpFirstName",
  "CreatedDateTime",
  "AppointmentWaiting",
  "PatFirstName",
  "PatLastName",
  "PatMrnNumber",
  "PatDateofBirth",
  "CalDiff",
  "ConsultTypeName",
  "ConStartTime",
  "ConEndTime",
  "ConTotalTime",
  "PatSiteCBNumber",
  "PatSiteName",
  "CPTCode",
  "DxCode",
  "Description",
  "Disposition",
  "DispositionText",
  "Remarks",
  "ActualApTime",
];

const tableColumnsList = [
  {
    FieldName: "id",
    FieldDescription: "Consult No",
    IsVisible: true
  },
  {
    FieldName: "facility",
    FieldDescription: "Facility",
    IsVisible: true
  },
  {
    FieldName: "provider",
    FieldDescription: "Provider",
    IsVisible: true
  },
  {
    FieldName: "createdAt",
    FieldDescription: "Consult Created",
    IsVisible: true
  },
  {
    FieldName: "patientName",
    FieldDescription: "Patient Name",
    IsVisible: true
  },
  {
    FieldName: "accountNumber",
    FieldDescription: "Account Number",
    IsVisible: true
  },{
    FieldName: "consultType",
    FieldDescription: "Consult Type",
    IsVisible: true
  },
  {
    FieldName: "status",
    FieldDescription: "Consult Status",
    IsVisible: true
  },
  {
    FieldName: "waitingTime",
    FieldDescription: "Consult Waiting Time",
    IsVisible: true
  },
  {
    FieldName: "startTime",
    FieldDescription: "Consult Start Time",
    IsVisible: true
  },
  {
    FieldName: "endTime",
    FieldDescription: "Consult End Time",
    IsVisible: true
  },
  {
    FieldName: "duration",
    FieldDescription: "Consult Duration",
    IsVisible: true
  },
  {
    FieldName: "disposition",
    FieldDescription: "Disposition",
    IsVisible: true
  },
  {
    FieldName: "siteCallbackNumber",
    FieldDescription: "Site Call Back Number",
    IsVisible: true
  },
  {
    FieldName: "siteContactName",
    FieldDescription: "Site Contact Name",
    IsVisible: true
  },
  {
    FieldName: "currentProceduralTerminologyCode",
    FieldDescription: "CPT Code",
    IsVisible: true
  },
  {
    FieldName: "diagnosisCode",
    FieldDescription: "Diagnosis Code",
    IsVisible: true
  },
  {
    FieldName: "description",
    FieldDescription: "Diagnosis Description",
    IsVisible: true
  }
];

const excelColumnsList = [
  ...tableColumnsList,
  {
    FieldName: "siteCallbackNumberSecondary",
    FieldDescription: "Site Call Back Number Secondary",
    IsVisible: true
  },
  {
    FieldName: "siteContactNameSecondary",
    FieldDescription: "Site Contact Name Secondary",
    IsVisible: true
  },
  {
    FieldName: "cancelReason",
    FieldDescription: "Cancel Reason",
    IsVisible: true
  },
  {
    FieldName: "cancelRemarks",
    FieldDescription: "Cancel Remarks",
    IsVisible: true
  },
  { 
    FieldName: "reasonForMeeting",
    FieldDescription: "Reason For Meeting",
    IsVisible: true
  },
  {
    FieldName: "reasonForReassign",
    FieldDescription: "Reason For Reassign",
    IsVisible: true
  }
]

export {
  excelColumnsList,
  VisibleItemSelect,
  TotalTime,
  AppointmentDiff,
  isValidDate,
  Refresh,
  UserGridViewData2,
  TableBodyItem,
  TableVisibleItem,
  BootstrapInput,
  EmptyJson,
  tableSearchParamsInit,
  tableHeaderColumnIndex,
  tableRow,
  tableColumnsList,
};

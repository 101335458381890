import React, { useEffect, useReducer,  useRef } from "react";

import { commonReactReducer, initialStateGlobal } from "../../reactReducers/commonReactReducer";

import UsersTable from "./components/UsersTable";
import UserView from "./components/UserView";
import UserMutation from "./components/UserMutation";
import { UsersContextProvider } from "./context/usersContextProvider";

const Users = (props) => {
  const mutationRef = useRef();
  const viewRef = useRef();
  const tableRef = useRef();

  const [commonReactState, commonReactDispatch] = useReducer(commonReactReducer, initialStateGlobal);
  
  useEffect(() => {
    props.loading(true);
    document.getElementById("EmployeeRegistry-MainTable").style.width = "100%";
    document
      .getElementById("EmployeeRegistry-MainDiv")
      .classList.add("displayFlex");
    document
      .getElementById("EmployeeRegistry-MainDiv")
      .classList.remove("displayGrid");
  }, []);

  const providerValue = {
    commonReactState, 
    commonReactDispatch,
    openModalMutation: (itemId) => {      
      const item = tableRef.current.getCurrentModalItem(itemId);
      mutationRef.current.openModal(item);
    },
    removeAlertModal: (itemId) => {
      mutationRef.current.removeAlertModal(itemId);
    },
    openModalView: (tableItem) => {
      const item = tableRef.current.getCurrentModalItem(tableItem);
      viewRef.current.openModal(item);
    },
    refreshTable: (filters) => tableRef.current.refreshTable(filters),
    applySectionFilters: (sectionFilters) => tableRef.current.applySectionFilters(sectionFilters),
    loading: (val) => props.loading(val),
    alert: (val) => props.alertmsg(val),
    ConfirmAlert: (val) => props.ConfirmAlert(val),
  };

  return (
    <>
      <UsersContextProvider value={providerValue}>
        <>
          <div
            id="EmployeeRegistry-MainDiv"
            className="MainDivStyles displayFlex"
          >
            <div id="EmployeeRegistry-MainTable" className="Maincontent">
              <UsersTable ref={tableRef} />  
            </div>            
          </div>
          <UserView ref={viewRef} />
          <UserMutation ref={mutationRef} />
        </>  
      </UsersContextProvider>
    </>
  );
};

export default Users;

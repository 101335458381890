import { format } from "date-fns";
import { 
  getLocalizedDateFrom,
  getLocalizedDateCurrent,
  getUtcDateCurrent,
  getUtcDateFrom
} from "../../../helpers/utils";

function VisibleItemSelect(
  val,
  setTableVisibleItem,
  setTableBodyItem
) {
  const data = val;
  let arr = [];
  let bodyArr = [];
  let ItemArr = [];
  data.map((val) => {
    if (val.IsVisible == "1") {
      arr.push({
        id: val.FieldName,
        name: val.FieldDescription,
        type: val.FieldDataType,
      });

      bodyArr.push(val.FieldName);
      ItemArr.push(val.FieldDescription);
    }
  });

  setTableVisibleItem(arr);
  setTableBodyItem(bodyArr);
}

const tableColumnsList = [
  {
    FieldName: "consultNo",
    FieldDescription: "Consult No",
    IsVisible: true
  },
  {
    FieldName: "facility",
    FieldDescription: "Facility",
    IsVisible: true
  },
  {
    FieldName: "createdAt",
    FieldDescription: "Consult Created",
    IsVisible: true
  },
  {
    FieldName: "patientName",
    FieldDescription: "Patient Name",
    IsVisible: true
  },
  {
    FieldName: "accountNo",
    FieldDescription: "Account Number",
    IsVisible: true
  },{
    FieldName: "consultType",
    FieldDescription: "Consult Type",
    IsVisible: true
  },
  {
    FieldName: "status",
    FieldDescription: "Consult Status",
    IsVisible: true
  },
  {
    FieldName: "waitingTime",
    FieldDescription: "Consult Waiting Time",
    IsVisible: true
  },
  {
    FieldName: "startTime",
    FieldDescription: "Consult Start Time",
    IsVisible: true
  },
  {
    FieldName: "endTime",
    FieldDescription: "Consult End Time",
    IsVisible: true
  },
  {
    FieldName: "duration",
    FieldDescription: "Consult Duration",
    IsVisible: true
  },
  {
    FieldName: "disposition",
    FieldDescription: "Disposition",
    IsVisible: true
  },
  {
    FieldName: "siteCallbackNumber",
    FieldDescription: "Site Call Back Number",
    IsVisible: true
  },
  {
    FieldName: "siteContactName",
    FieldDescription: "Site Contact Name",
    IsVisible: true
  },
];

const zones = {
  "UTC-05:00 - Eastern Standard Time": "-05:00",
  "UTC-06:00 - Central Standard Time": "-06:00",
  "UTC-07:00 - Mountain Standard Time": "-07:00",
  "UTC-08:00 - Pacific Standard Time": "-08:00",
};

const initialStateMeeting = {
  userId: "",
  provider: "",
  facilityId: "",
  facility: "",
  consultType: "",
  communicationMethod: "",
  cartNumber: "",
  siteCallbackNumber: "",
  siteContactName: "",
  siteCallbackNumberSecondary: "",
  siteContactNameSecondary: "",
  firstName: "",
  lastName: "",
  birthDate: "",
  medicalRecordNumber: "",
};

const getMeetingsFiltersDefault = () => {
  const dateRangeFrom = format(new Date('1970-01-01'), "MM-dd-yyyy");
  const dateRangeTo = format(new Date(), "MM-dd-yyyy");
  
  const meetingsFiltersDefault = {
    dateRangeFrom,
    dateRangeTo,
    statuses: {
      'Not Started': "true",
      'Started': "true"
    }
  }

  return meetingsFiltersDefault;
}

const totalMeetingsFilters = {
  statuses: {
    'Cancelled': "true",
    'Completed': "true",
    'Not Started': "true",
    'Started': "true"
  }
}

const cancelledMeetingsFilters = {
  statuses: {
    'Cancelled': "true"
  }
}

const completedMeetingsFilters = {
  statuses: {
    'Completed': "true"
  }
}

const pendingMeetingsFilters = {
  statuses: {
    'Not Started': "true",
    'Started': "true"
  }
}

export {
  VisibleItemSelect,
  tableColumnsList,
  zones,
  initialStateMeeting,
  getMeetingsFiltersDefault,
  pendingMeetingsFilters,
  totalMeetingsFilters,
  cancelledMeetingsFilters,
  completedMeetingsFilters
}
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import SaveBtn from "../../../../components/Buttons/SaveButton";
import ClearBtn from "../../../../components/Buttons/ClearButton";
import GridContainer from "../../../../components/Grid/GridContainerRoot";
import GridItem from "../../../../components/Grid/GridItemRoot";
import L2TextField from "../../../../components/_helperComponents/L2TextFieldTable";

import { changePasswordAction } from "./actions/changePasswordAction";

const ChangePassword = (props) => {
  const dispatch = useDispatch();

  const [newPasswordObject, setNewPasswordObject] = useState({
    Old: "",
    New: "",
    Confirm: "",
  });

  const handleClose = () => {
    props.setOpenChangePassword(false);
    setNewPasswordObject({ Old: "", New: "", Confirm: "" });
  };

  return (
    <Dialog
      open={props.openChangePassword}
      keepMounted={props.keepMounted}
      disableEscapeKeyDown={props.disableEscapeKeyDown}
      onClose={handleClose}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <DialogContent
        style={{ minWidth: "500px" }}
        id="classic-modal-slide-description"
        className={props.classes.modalBody}
      >
        <p id="welcome">Change Password</p>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <div class="change-password tooltip-bottom"  style={{ paddingTop: "23px" }}>Rules
              <span class="tooltiptext tooltiptext-bottom">
                <p> {"- minimum length – 8 characters"}</p>
                <p> {"- maximum length – 64 characters"}</p>
                <p> {"- at least 1 upper-case character (A-Z)"}</p>
                <p> {"- at least 1 lower-case character (a-z)"}</p>
                <p> {"- at least 1 numeric character (0-9)"}</p>
                <p> {"- at least 1 specific character (@#$%_^&)"}</p>
              </span>
            </div> 

            <L2TextField
              marginTop="4%"
              id="Old_password"
              type={"password"}
              labelname={"Old Password"}
              value={newPasswordObject.Old}
              Iscurr={false}
              mandatory={true}
              disabled={false}
              onChange={(e) => {
                setNewPasswordObject({ ...newPasswordObject, Old: e });
              }}
              clear={(e) => {
                if (e) {
                  setNewPasswordObject({ ...newPasswordObject, Old: "" });
                }
              }}
            />
            <L2TextField
              marginTop="6%"
              id="New_password"
              type={"password"}
              labelname={"New Password"}
              value={newPasswordObject.New}
              Iscurr={false}
              mandatory={true}
              disabled={false}
              onChange={(e) => {
                setNewPasswordObject({ ...newPasswordObject, New: e });
              }}
              clear={(e) => {
                if (e) {
                  setNewPasswordObject({ ...newPasswordObject, New: "" });
                }
              }}
            />            
            <L2TextField
              marginTop="6%"
              id="Confirm_password"
              type={"password"}
              labelname={"Confirm Password"}
              value={newPasswordObject.Confirm}
              Iscurr={false}
              mandatory={true}
              disabled={false}
              onChange={(e) => {
                setNewPasswordObject({ ...newPasswordObject, Confirm: e });
              }}
              clear={(e) => {
                if (e) {
                  setNewPasswordObject({ ...newPasswordObject, Confirm: "" });
                }
              }}
            />
          </GridItem>
        </GridContainer>
      </DialogContent>
      
      <DialogActions>
        <ClearBtn
          disabled={false}
          onClick={() => {
            handleClose();
          }}
        />
        <SaveBtn
          name={"SAVE"}
          disabled={false}
          onClick={() => {
            changePasswordAction(
              newPasswordObject,
              props.setLoading,
              props.setOpenProfile,
              props.history,
              props.setMessage,
              dispatch,
            );
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ChangePassword;

import React, {
  forwardRef,
} from "react";
import { format } from "date-fns";

import Typography from "@material-ui/core/Typography";

import GridItem from "../../../components/Grid/GridItemRoot";
import Card from "../../../components/Card/CardRoot";
import CardBody from "../../../components/Card/CardBodyRoot";

const PatientFormBodyView = forwardRef((props, ref) => {
  return (
    <GridItem  xs={12} sm={12} md={4}>
                  <h4 className="patientDetails">Patient Details</h4>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    style={{ width: "100%", padding: "0 15px" }}
                  >
                    <Card
                      style={{
                        backgroundColor: "rgb(220 220 220 / 32%)",
                        margin: "0",
                        borderRadius: "6px"
                      }}
                    >
                      <CardBody style={{ padding: "5px 20px" }}>
                        <h2 className="Register">Registered:</h2>
                        <Typography className="patientKey1" gutterBottom>
                          Patient Name:
                          <span className="patientValue">
                            {` ${props.modalBodyParams.item.firstName} ${props.modalBodyParams.item.lastName}`}
                          </span>
                        </Typography>
                        <Typography gutterBottom className="patientKey">
                          Birth Date:
                          <span className="patientValue">
                            {props.modalBodyParams.item.birthDate ? format(new Date(props.modalBodyParams.item.birthDate), "MM-dd-yyyy") : ""}
                          </span>
                        </Typography>
                        <Typography gutterBottom className="patientKey">
                          Medical Record Number:
                          <span className="patientValue">
                            {props.modalBodyParams.item.medicalRecordNumber}
                          </span>
                        </Typography>
                        <Typography gutterBottom className="patientKey">
                          Account Number:
                          <span className="patientValue">
                            {props.modalBodyParams.item.accountNo}
                          </span>
                        </Typography>
                        <Typography gutterBottom className="patientKey">
                          Consult Status:
                          <span className="patientValue">
                            {props.modalBodyParams.item.status}
                          </span>
                        </Typography>
                        <Typography gutterBottom className="patientKey">
                          Cart Name/Number:
                          <span className="patientValue">
                            {props.modalBodyParams.item.cartNumber}
                          </span>
                        </Typography>
                        <Typography gutterBottom className="patientKey">
                          Consult Type:
                          <span className="patientValue">
                            {props.modalBodyParams.item.consultType}
                          </span>
                        </Typography>
                        <Typography gutterBottom className="patientKey">
                          Facility:
                          <span className="patientValue">
                            {props.modalBodyParams.item.facility}
                          </span>
                        </Typography>
                        <Typography gutterBottom className="patientKey">
                          Site Call Back Number:
                          <span className="patientValue">
                            {props.modalBodyParams.item.siteCallbackNumber}
                          </span>
                        </Typography>
                        <Typography gutterBottom className="patientKey">
                          Site Contact Name:
                          <span className="patientValue">
                            {props.modalBodyParams.item.siteContactName}
                          </span>
                        </Typography>
                        <Typography gutterBottom className="patientKey">
                          Site Call Back Number Secondary:
                          <span className="patientValue">
                            {props.modalBodyParams.item.siteCallbackNumberSecondary}
                          </span>
                        </Typography>
                        <Typography gutterBottom className="patientKey">
                          Site Contact Name Secondary:
                          <span className="patientValue">
                            {props.modalBodyParams.item.siteContactNameSecondary}
                          </span>
                        </Typography>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridItem>
  );
});

export default PatientFormBodyView;

import RefreshButton from "../../_helperComponents/RefreshButton";
import ExcelExportButton from "../../_helperComponents/ExcelExportButton";
import FilterButton from "../../_helperComponents/FilterButton";
import TablePaginationContainer from "./TablePaginationContainer";

const TableToolbar = (props) => {
  return (
    <>
      <div 
        id="tableHeader" 
        className="displayFlex padding" 
        style={props.tableToolbarParams.tableHeaderStyles}
      >
        <div className="tableHeaderItems displayFlex">
        
          { 
            props.tableToolbarParams.filterButton && 
            <FilterButton 
              filterButton={props.tableToolbarParams.filterButton}
            />
          }

          { 
            props.tableToolbarParams.excelExportButton && 
            <ExcelExportButton 
              title={props.tableToolbarParams.excelExportButton.title}
              // dataSet={dataSet}
              excelData={props.tableToolbarParams.excelExportButton.excelData}
              excelColumnsList={props.tableToolbarParams.excelExportButton.excelColumnsList}
              triggerApi={() => {
                props.tableToolbarParams.excelExportButton.getExcelExportDataHandler();
              }}
            />
          }

          <RefreshButton
            Refresh={props.tableToolbarParams.refreshHandler}
          />
          <TablePaginationContainer
            tablePaginationContainerParams = {props.tablePaginationContainerParams}    
          />
        </div>
      </div>
      <div 
        id="tableBody" 
        className="displayFlex padding0"              
      >
        {props.tableToolbarParams.tableToolbarOptions}
      </div>
    </>    
  )
}

export default TableToolbar;
import "react-infinite-calendar/styles.css";

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useContext,
} from "react";

import {  
  clickFilterHandler
} from "../../../components/PageFilters/helpers/filterHelper";

import FilterSection from "./Filters/FilterSection";
import TableView from "../../../components/TableView/TableView";

import { initialStateMutationFacility } from "../helpers/mutationFacilityHelpers";
import { FacilitiesContext } from "../context/facilitiesContextProvider";
import {
  getFacilitiesList,
  searchFacilities,
} from "../actions/viewListFacilitiesAction";
import {
  VisibleItemSelect,
  handleChangeRowsPerPage,
  tableColumnsList,
} from "../helpers/tableFacilitiesHelpers";

import {
  statusesData,
  defaultFilters
} from "./Filters/helpers/filtersDataHelper";

const FacilitiesTable = forwardRef((props, ref) => {
  const context = useContext(FacilitiesContext);
  const { commonReactState, commonReactDispatch } = context;

  const [facilitiesList, setFacilitiesList] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [pageFilters, setPageFilters] = useState(defaultFilters);

  const [pagination, setPagination] = useState({
    pageNo: 1,
    getPerPage: 10,
    itemsTotalNumber: 0,
    pagesTotalNumber: 0
  });

  const [TableVisibleData, setTableVisibleData] = useState([]);
  const [TableVisibleItem, setTableVisibleItem] = useState([]);
  const [TableBodyItem, setTableBodyItem] = useState([]);

  const stateName = 'facilities';
  const filterSectionId = `${stateName}_FilterDiv`;
  const filterButtonId = `${stateName}_FilterDiv`;
  const tableId = `${stateName}_TableDiv`;
  
  useEffect(() => {
    if (!isFiltersOpen) {
      setPageFilters(defaultFilters);
      context.refreshTable(defaultFilters);
    }    
  }, [isFiltersOpen]);

  useEffect(() => {
    VisibleItemSelect(
      tableColumnsList,
      setTableVisibleData,
      setTableVisibleItem,
      setTableBodyItem
    );
  }, [
    JSON.stringify(
      tableColumnsList,
    ),
  ]);
  
  useImperativeHandle(ref, () => ({
    getCurrentModalItem: (itemId) => {
      let item

      facilitiesList.map((value) => {
        if (itemId === value.id) {
          item = value
        }
      });
      return item;
    },
    resetCurrentModalItem: () => {
      commonReactDispatch({
        type: "currentModalItem",
        payload: initialStateMutationFacility,
      });
    },
    applySectionFilters: (sectionFilters) => {
      setPageFilters(sectionFilters);
    },
    refreshTable: (currentFacilitiesFilters = null) => {
      refreshTable(currentFacilitiesFilters);
    },
  }));

  const refreshTable = (currentFacilitiesFilters = null) => {
    const facilitiesFilters = currentFacilitiesFilters ? currentFacilitiesFilters : pageFilters;

    getFacilitiesList(
      setFacilitiesList,
      context,
      {
        facilitiesFilters
      },
      {
        pageNo: pagination.pageNo,
        getPerPage: pagination.getPerPage,
        pagination,
        setPagination
      }
    );
  };

  const searchHandler = (e) => {
    setSearchInput(e);
    if (e.length == "0") {
      getFacilitiesList(
        setFacilitiesList,
        context,
        {
          facilitiesFilters: pageFilters
        },
        {
          pageNo: pagination.pageNo,
          getPerPage: pagination.getPerPage,
          pagination,
          setPagination
        }
      );
      return false;
    } else {
      searchFacilities(
        e,
        setFacilitiesList,
        context,
        commonReactDispatch,
        {
          pageNo: 1,
          getPerPage: 10
        },
        pagination,
        setPagination
      );
    }
  }

  const closeSearchHandler = () => {
    refreshTable();
    setSearchInput("");
  }

  const onClickAdd = () => context.openModalMutation()

  const tableViewHeaderParams = {
    tableName: 'Facilities',
    itemsCountOnPage: facilitiesList.length,//todo delete
    itemsTotalNumber: pagination.itemsTotalNumber,
    searchInput,
    searchHandler,
    closeSearchHandler,
    onClickAdd
  }

  const onClickFilterHandler = () => {
    clickFilterHandler(isFiltersOpen, setIsFiltersOpen, tableId, filterButtonId, filterSectionId);
  }

  const tableContainerParams = {}

  const refreshHandler = () => {
    setSearchInput("");
    getFacilitiesList(
      setFacilitiesList,
      context,
      {
        facilitiesFilters: pageFilters
      },
      {
        pageNo,
        getPerPage,
        pagination,
        setPagination
      }
    );
  }

  const tableToolbarParams = {
    tableHeaderStyles: {}, 
    refreshHandler,
    filterButton: {
      onClickFilterHandler,
      filterButtonId
    }
  }

  const onChangePageHandler = (nextPageNo, nextGetPerPage, tablePaginationContainerParams) => {
    getFacilitiesList(
      tablePaginationContainerParams.setItemsList,
      tablePaginationContainerParams.context,
      {
        facilitiesFilters: pageFilters
      },
      {
        pageNo: nextPageNo,
        getPerPage: nextGetPerPage,
        pagination: tablePaginationContainerParams.pagination,
        setPagination: tablePaginationContainerParams.setPagination
      }
    );
  }

  const tablePaginationContainerParams = {
    setItemsList: setFacilitiesList,
    context,
    commonReactDispatch,
    pagination,
    setPagination,
    onChangePageHandler
    // TableTotalCount,
    // page,
    // handleChangeRowsPerPage,
  }

  const tableBodyParams = {
    tableId: 'FacilityRegistryTable',
    TableVisibleItem,
    bodyData: facilitiesList,
    TableBodyItem,

    isActionsVisible: true,
    isViewVisible: true,
    viewHandler: (row) => context.openModalView(row.id),
    isEditVisible: true,
    editHandler: (row) => context.openModalMutation(row.id),
    isRemoveVisible: true,
    removeHandler: (row) => context.removeAlertModal(row.id),
  }

  return (
    <div
      className="displayFlex padding0"
      style={{ overflow: "auto" }}
    >
      {isFiltersOpen && (
        <FilterSection 
          stateName={stateName}
        />
      )}

      <div
        id={tableId}
        style={{ width: "100%", position: "relative" }}
      >
        <TableView 
          tableViewHeaderParams = {tableViewHeaderParams}
          tableContainerParams = {tableContainerParams}
          tableToolbarParams = {tableToolbarParams}
          tablePaginationContainerParams = {tablePaginationContainerParams}
          tableBodyParams = {tableBodyParams}
        />
      </div>
    </div>
  );
});

export default FacilitiesTable; 

const gendersDataInit = [
  {
    id: "Male",
    label: "Male",
    value: "Male",
  },
  {
    id: "Female",
    label: "Female",
    value: "Female",
  },
];

export { gendersDataInit };

import  {

  useState,

} from "react";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckboxNew from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import SaveBtn from "../Buttons/SaveButton";
import ClearBtn from "../Buttons/ClearButton";
import CloseBtn from "../Buttons/CloseButton";

const ModalFooterMutation = (props) => {
  const [isActive, setIsActive] = useState(props.modalFooterParams.state.isActive);

  return (
    <div 
      className="displayFlex backgroundLight borderRadiusTop width100"
      style={{ paddingRight: "40px", paddingTop: "10px" }}  
    >
        
          <div className="displayFlex EntryHeaderBtn">
            { 
            !props.modalFooterParams.skipActiveButton && (
              <div className="Entry-Checkbox">
                <FormControlLabel
                  style={{ color: "#337ab7" }}
                  control={
                    <CheckboxNew
                      style={{ color: "#rgb(52, 179, 68)", padding: "6px" }}
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={
                        <CheckBoxIcon
                          fontSize="small"
                          style={{ color: "rgb(52, 179, 68)" }}
                        />
                      }
                      checked={isActive}
                      onChange={(event) => {
                        setIsActive(!isActive)
                        props.modalFooterParams.dispatch({
                          type: "boolean",
                          name: "isActive",
                          boolean: !isActive
                        });
                      }}
                    />
                  }
                  label="Active"
                />
              </div>
            )
            }

              <SaveBtn
                name={props.modalFooterParams.actionButtonName ? props.modalFooterParams.actionButtonName : props.modalFooterParams.state.id ? "Update" : "Save"}
                disabled={props.modalFooterParams.isActionButtonDisabled ? props.modalFooterParams.isActionButtonDisabled : false}
                onClick={props.modalFooterParams.onClickSave}
              />
            
              <ClearBtn
                name={props.modalFooterParams.cancelButtonName ? props.modalFooterParams.cancelButtonName : false}
                disabled={props.modalFooterParams.isCancelButtonDisabled ? props.modalFooterParams.isCancelButtonDisabled : false}
                onClick={props.modalFooterParams.onClickCancel}
              />                         
            
              {
                props.modalFooterParams.onClickClose && (
                  <CloseBtn
                    disabled={false}
                    onClick={props.modalFooterParams.onClickClose}
                  />
                )
              } 
          </div>        
      </div>
  )
}

export default ModalFooterMutation;

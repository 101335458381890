import React from "react";
import { PersistGate } from "redux-persist/lib/integration/react";
import { Provider } from "react-redux";

import Users from "../../../../../Users/Users";
import DiagnosisCodes from "../../../../../DiagnosisCodes/DiagnosisCodes"; 
import Facilities from "../../../../../Facilities/Facilities";
import CallCenter from "../../../../../CallCenterOperations/CallCenter"; // customise form bilal
import ProviderServices from "../../../../../ProviderServices/ProviderServices";

import {
  openNav,
  messagePageUnderDevelopment
} from "./helpers/sideMenuHelpers";

const TabPage = (props) => {
  let NewTabPageComponent; 

  switch(props.menuItem.MenuID) {
    case "users": 
      NewTabPageComponent = Users;
      break;
    case "diagnosis_codes": 
      NewTabPageComponent = DiagnosisCodes;
      break;
    case "facilities":
      NewTabPageComponent = Facilities;
      break;
    case "call_center": 
      NewTabPageComponent = CallCenter;
      break;
    case "provider_services": 
      NewTabPageComponent = ProviderServices;
      break;
    default:
      return;
  }

  const newTabPage = (<NewTabPageComponent
    loading={(e) => props.setLoading(e)}
    alertmsg={(e) => props.setMessage({ open: e.open, color: e.color, message: [e.message] })}
    ConfirmAlert={(e) => props.setConfirmationAlert(e)}
    openNav={() => openNav()}
  />);
  
  if (!NewTabPageComponent || !newTabPage) {
    return messagePageUnderDevelopment(
      props.menuItem.MenuID,
      "submenu1",
      props.setMessage,
    );
  }

  return (
    <Provider store={props.store}>
      <PersistGate persistor={props.persistor}>
        <React.Suspense fallback={<div>Loading...</div>}>
          {newTabPage}
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
  
export default TabPage;

import React, {
  forwardRef,
} from "react";

import GridContainer from "../../../components/Grid/GridContainerRoot";

import 
  MeetingProviderFormBodyMutation
 from "./MeetingProviderFormBodyMutation";
import PatientFormBodyView
 from "./PatientFormBodyView";
import 
 MeetingTimeStats
 from "./MeetingTimeStats";

const MeetingMutationProviderModal = forwardRef((props, ref) => {
  return (
    <GridContainer>
      
      <MeetingTimeStats 
        modalBodyParams = {props.modalBodyParams}
      />

      <PatientFormBodyView 
        modalBodyParams = {props.modalBodyParams}
      />

      <MeetingProviderFormBodyMutation 
        modalBodyParams = {props.modalBodyParams}
      />
    </GridContainer>
  );
});

export default MeetingMutationProviderModal; 

import TabsBar from "./components/TabsBar/TabsBar";
import Dashboard from "./components/Dashboard/Dashboard";

const MainContent = (props) => {
  return (
    <div id="CloseMenu" className="expandNav">
      <div className="menuContent">
        <TabsBar
          setConfirmationAlert={props.setConfirmationAlert}
          classes={props.classes}
        />

        <Dashboard
          loading={(e) => {
            props.setLoading(e);
          }}
          classes={props.classes}
        />
      </div>
    </div>
  );
};

export default MainContent;

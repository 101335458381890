import {
  getFacilitiesAction,
  getListFacilitiesAction,
  searchFacilitiesAction,
  createFacilitiesAction,
  updateFacilitiesAction,
  deleteFacilitiesAction,
  api2FrontMapper,
  front2ApiMapper,
} from "./actions";

const removeSubmit = async (
  context,
  itemId,
  setIsModalOpen
) => {
  context.loading(true);
  context.ConfirmAlert(null);

  const item = {
    id: itemId,
  };
  const result = await deleteFacilitiesAction(item, context.alert, context.loading);

  if (!result) {
    return;
  }

  setIsModalOpen(false);

  context.refreshTable();
}

const saveSubmit = async (
  context,
  state,
  setIsModalOpen,
  dispatch,
  initialStateMutationFacility
) => {
  context.loading(true);
  context.ConfirmAlert(null);

  const item = front2ApiMapper(state);

  const result = !item.id
    ? await createFacilitiesAction(item, context.alert, context.loading)
    : await updateFacilitiesAction(
        { ...item },
        context.alert,
        context.loading,
      );

  // setIsModalOpen(false);  
  if (!result) {
    return;
  }

  setIsModalOpen(false);
  dispatch({
    type: "set_entire_state",
    value: initialStateMutationFacility,
  });

  // context.alert({ open: true, color: "success", message: ["success"] });
  // context.loading(false);
  context.refreshTable();
};

export {
  saveSubmit,
  removeSubmit,
};

const homePageStyles = {
  companyName: {
    fontWeight: 600,
    fontSize: 16,
    marginLeft: 15,
  },
  hideName: {
    display: "none",
  },
  smallSidenavUL: {
    backgroundColor: "#000",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    color: "#fff",
    width: 85,
    top: 0,
    bottom: 0,
    position: "fixed",
    zIndex: 10,
    marginBottom: 0,
    listStyleType: "none",
    marginBlockStart: 0,
    marginBlockEnd: 0,
    paddingInlineStart: 0,
    boxShadow:
      "0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    "&:before": {
      content: '""',
      zIndex: "-1",
      position: "fixed",
      top: 0,
      bottom: 0,
      height: "100%",
      width: "85px",
      background: "#2F2F2F",
      opacity: "0.8",
    },
  },
  sidenavLISmall: {
    fontSize: "0.85rem",
    fontWeight: "400",
    listStyleType: "none",
    textAlign: "center",
  },
  subli: {
    fontSize: "0.85rem",
    fontWeight: "400",
    listStyleType: "none",
    width: "250px",
    display: "inline-block !important ",
  },
  hideArrow: {
    display: "none",
  },

  miniSidenavSpan: {
    padding: "5px 0px 0px 15px",
    position: "absolute",
    fontSize: 15,
    display: "none",
  },
  sidenavHeader: {
    textAlign: "center",
    padding: "8px",
    margin: "0px 10px",
    borderBottom: "1px solid",
  },
  tabDiv: {
    zIndex: 10,
    display: "flex",
    width: "100%",
    marginTop: "-5px",
    marginBottom: "-2rem",
  },
  tabList: {
    padding: "7px",
    opacity: 0.5,
    borderRadius: "6px",
    cursor: "pointer",
    margin: "3px",
    listStyleType: "none",
    backgroundColor: "#fff",
    borderRight: "1px solid gainsboro",
    boxShadow:
      "0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12)",
  },
  tabIcons: {
    position: "relative",
    top: "7px",
  },
  pageContent: {
    display: "none",
  },
  contentMain: {
    // width: '100%',
    // //margin: 'auto',
    // padding: '15px',
    // background: '#fff'
  },
  profileImg: {
    width: "35px",
    height: "35px",
    marginRight: "0%",
    background: "#fff",
    borderRadius: "50%",
    marginTop: "3%",
    boxShadow: "0px 2px 3px rgba(4, 4, 7, 0.1)",
    border: "solid 1px #f1f1f1",
    padding: "4px",
    "&>img": {
      width: "25px",
      height: "25px",
      borderRadius: "50%",
    },
  },
  profileDiv: {
    display: "inline-flex",
  },
  menuImgSmall: {
    width: "50px",
    height: "35px",
  },
  dropdown: {
    borderRadius: "3px",
    border: "0",
    boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.26)",
    top: "100%",
    zIndex: "1000",
    minWidth: "160px",
    padding: "5px 0",
    margin: "2px 0 0",
    fontSize: "14px",
    textAlign: "left",
    listStyle: "none",
    backgroundColor: "#fff",
    backgroundClip: "padding-box",
  },

  dropdownItem: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "400",
    lineHeight: "1.5em",
    fontSize: "13px",
    padding: "10px 20px",
    margin: "0 5px",
    borderRadius: "2px",
    position: "relative",
    transition: "all 150ms linear",
    display: "block",
    clear: "both",
    height: "100%",
    color: "333",
    whiteSpace: "nowrap",
    minHeight: "unset",
  },
  infoHover: {
    "&:hover": {
      backgroundColor: "#f0f0f2",
      color: "#337ab7",
    },
  },
  homeActive: {
    color: "#337ab7",
  },
};

export { homePageStyles };

import dashboard20 from "../images/dashboard20.png";

const loginStyle = {
  contentBody: {
    marginTop: "0%",
    marginLeft: "3%",
  },
  footerImg: {
    textAlign: "center",
    marginTop: "0%",
  },
  imgFullsize: {
    "@media (min-width:768px) and (max-width:1200px)": {
      width: "300px",
      height: "60vh",
    },
    width: "100%",
    //  height: '85vh',
    marginBottom: "12px",
  },
  headerToplogo: {
    float: "right",
    marginRight: "15px",
    marginTop: "3px",
  },
  headerImgSize: {
    // '@media (min-width:768px) and (max-width:1200px)' :{
    //     width: "110px",
    //     height: "40px",
    //   },
    // width: "350px",
    height: "85px",
    margin: "6% 12px",
    borderRadius: "5px",
  },
  HighliteText: {
    "&:hover": {
      color: "#33234E",
    },
    fontSize: "12px",
    fontWeight: "600",
    color: "#33234E",
    userSelect: "none",
  },
  margin5: {
    marginTop: "5px",
    marginRight: "11%",
  },
  pageBackground: {
    // background:'#fff'
  },
  TextWeight500: {
    fontWeight: "500",
    userSelect: "none",
    fontSize: "25px",
    color: "#33234E",
    margin: "15px 0px 0px",
    lineHeight: "1",
  },
  Grdwid: {
    width: "calc(100% + 15px) !important",
  },
  Grdcontainerwid: {
    width: "calc(100% + 0px) !important",
    margin: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  TextWeight400: {
    fontWeight: "600",
    userSelect: "none",
    fontSize: "13px",
    color: "#33234E",
  },
  icondet: {
    fontWeight: "500",
    userSelect: "none",
    fontSize: "10px",
    color: "#000",
  },
  wheather: {
    fontWeight: "600",
    color: "#fff",
    float: "right",
    margin: "0",
    fontSize: "0.625rem",
  },
  Ops: {
    fontWeight: "600",
    color: "#fff",
    margin: "0",
    fontSize: "0.625rem",
    fontStyle: "italic",
  },
  headerstyle: {
    margin: "0px",
    borderRadius: "0px",
    opacity: "0.5",
    background: "#484444",
    padding: "5px 15px",
  },
  logincardbg: {
    // backgroundImage: `url(${loginbgimg})`,
  },
  subcardstyle: {
    "@media (max-width:950px)": {
      display: "none",
    },
    left: "10px",
    backgroundColor: "#edf1f4",
    paddingRight: "0",
  },
  imgicon: {
    position: "relative",
    height: "50px",
  },
  imgfooter: {
    position: "relative",
    height: "25px",
  },
  ftriconlabel: {
    color: "#337ab7",
    fontSize: "15px",
    fontStyle: "italic",
    position: "relative",
    bottom: "10px",
    left: "5px",
  },
  gridleft: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: "0",
  },
  cardstyle: {
    borderRadius: "8px",
    opacity: "0.8",
    margin: "0 0 8px",
    background: "transparent",

    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    // border: '1px solid #b7b8bb'

    // ${bgslide})
  },
  lianimation: {
    //     -webkit-animation: fadein 6s ease-in-out 4s infinite alternate; /* delay = duration * 66% */
    // -moz-animation: fadein 6s ease-in-out 4s infinite alternate;
    animation: "fadein 6s ease-in-out 4s infinite alternate",
  },
  footerlabel: {
    "@media (max-width:950px)": {
      paddingBottom: "2%",
    },
    fontStyle: "italic",
    fontSize: "12px",
  },
  carouselstyle: {
    margin: "5px",
  },
  bgm: {
    // backgroundColor: '##68bf71',
    // backgroundImage: `url(${'static/media/dashboard20.png'})`,
    backgroundImage: `url(${dashboard20})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100vh",
  },
};

export default loginStyle;

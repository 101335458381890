import React, { createContext } from "react";

const meetingsContextDefault = {};

export const MeetingsContext = createContext(meetingsContextDefault);

export const MeetingsContextProvider = (props) => {
  return (
    <MeetingsContext.Provider value={props.value}>
      {" "}
      {props.children}{" "}
    </MeetingsContext.Provider>
  );
};

import { format } from "date-fns";

import { config } from "../../../configs/config";
import { requestAxios } from "../../../services/axiosClient";
import { DateTime } from 'luxon';

import { updateConditionQuery } from "../../../helpers/utils";

const showErrorMessage = (responseError, showAlert, setLoading) => {
  const errorMessage = responseError?.message
    ? responseError?.message
    : "Bad Request";
  showAlert({ open: true, color: "error", message: [errorMessage] });
  setLoading(false);
};

const createMeetingAction = async (item, showAlert, setLoading) => {
  const url = "meetings";
  const params = {
    ...item,
  };
  const apiUrl = `${config.API_URL}/${url}`;
  const requestOptions = {
    requestType: "POST",
  };
  const [response, responseError] = await requestAxios(
    apiUrl,
    params,
    null,
    requestOptions,
  );

  if (responseError) {
    showErrorMessage(responseError, showAlert, setLoading);
    return;
  }

  showAlert({ open: true, color: "success", message: ["Consult was created"] });
  setLoading(false);

  return response;
};

const updateMeetingAction = async (
  item, 
  showAlert = () => {},
  setLoading = () => {}
) => {
  const url = "meetings";
  const params = {
    ...item,
  };

  const apiUrl = `${config.API_URL}/${url}/${item.id}`;
  const requestOptions = {
    requestType: "PUT",
  };
  const [response, responseError] = await requestAxios(
    apiUrl,
    params,
    null,
    requestOptions,
  );

  if (responseError) {
    showErrorMessage(responseError, showAlert, setLoading);
    return;
  }

  showAlert({ open: true, color: "success", message: ["Consult was updated"] });
  setLoading(false);

  return response;
};

const deleteMeetingAction = async (
  item,
  showAlert = () => {},
  setLoading = () => {},
) => {
  const url = "meetings/cancel";
  const params = {
    ...item,
  };

  let apiUrl;
  if (item.id) {
    apiUrl = `${config.API_URL}/${url}/${item.id}`;
  } else {
    showErrorMessage(null, showAlert, setLoading);
    return;
  }

  const requestOptions = {
    requestType: "PUT",
  };
  const [response, responseError] = await requestAxios(
    apiUrl,
    params,
    null,
    requestOptions,
  );

  if (responseError) {
    showErrorMessage(responseError, showAlert, setLoading);
    return;
  }

  showAlert({ open: true, color: "success", message: ["Consult was canceled"] });
  setLoading(false);

  return response;
};

const getProviderStatisticsAction = async (
  providerId = null,
  showAlert = () => {},
  setLoading = () => {},
) => {
  const url = "meetings";

  let conditionQuery = '';
  conditionQuery = providerId ? `provider_id=${providerId}` : "";
  const apiUrl = `${config.API_URL}/${url}/provider-statistics?${conditionQuery}`;

  const requestOptions = {
    requestType: "GET",
  };
  const [response, responseError] = await requestAxios(
    apiUrl,
    null,
    null,
    requestOptions,
  );

  if (responseError) {
    showErrorMessage(responseError, showAlert, setLoading);
    return;
  }

  setLoading(false);

  return response;
};

// const pageNo = index;
// const getPerPage = 10;
//const result = getListMeetingAction(pageNo, getPerPage, context.alert, context.loading);
const getMeetingsListAction = async (
  itemsFilters,
  currentPaginationParams,
  showAlert = () => {},
  setLoading = () => {},
) => {
  const url = "meetings";
  
  let conditionQuery = '';

  conditionQuery = itemsFilters?.meetingsFilters?.dateRangeFrom && itemsFilters?.meetingsFilters?.dateRangeFrom ? `&date_range_from=${itemsFilters?.meetingsFilters?.dateRangeFrom}&date_range_to=${itemsFilters?.meetingsFilters?.dateRangeTo}` : conditionQuery;
  
  conditionQuery = itemsFilters?.providerId ? `${conditionQuery}&provider_id=${itemsFilters?.providerId}` : conditionQuery;
  
  conditionQuery = updateConditionQuery(conditionQuery, "cpt_codes", itemsFilters?.meetingsFilters?.currentProceduralTerminologyCodes);
  conditionQuery = updateConditionQuery(conditionQuery, "consult_types", itemsFilters?.meetingsFilters?.consultTypes);
  conditionQuery = updateConditionQuery(conditionQuery, "statuses", itemsFilters?.meetingsFilters?.statuses);
  conditionQuery = updateConditionQuery(conditionQuery, "diagnosis_codes", itemsFilters?.meetingsFilters?.diagnosisCodes);
  conditionQuery = updateConditionQuery(conditionQuery, "waiting_times", itemsFilters?.meetingsFilters?.waitingTimes);
  
  const paginationQuery = `page_no=${currentPaginationParams.pageNo}&get_per_page=${currentPaginationParams.getPerPage}`;
  const apiUrl = `${config.API_URL}/${url}?${paginationQuery}${conditionQuery}`;
  const requestOptions = {
    requestType: "GET",
  };
  const [response, responseError] = await requestAxios(
    apiUrl,
    null,
    null,
    requestOptions,
  );

  if (responseError) {
    showErrorMessage(responseError, showAlert, setLoading);
    return;
  }

  setLoading(false);

  return response;
};
// const pageNo = index;
// const getPerPage = 10;
//const result = searchMeetingAction(searchQuery, context.alert, context.loading);
const searchMeetingsAction = async (
  searchQuery,
  showAlert = () => {},
  setLoading = () => {},
) => {
  const url = "meetings";

  const apiUrl = `${config.API_URL}/${url}/search/${searchQuery}`;
  const requestOptions = {
    requestType: "GET",
  };
  const [response, responseError] = await requestAxios(
    apiUrl,
    null,
    null,
    requestOptions,
  );

  if (responseError) {
    showErrorMessage(responseError, showAlert, setLoading);
    return;
  }

  setLoading(false);

  return response;
};

const api2FrontMapper = (item) => {
  return {
    DxRegistryDxID: item.id,
    DxIDPK: item.id,
    DxCode: item.meeting,
    Description: item.description,

    IsActive: item.isActive,
    DeleStat: item.deleStat,
    CreatedUserID: item.createdUserId,
    CreatedTtm: item.createdAt,
    UpdatedUserID: item.updatedUserId,
    UpdatedTtm: item.updatedAt,
  };
};

const front2ApiMapper = (item) => {

  return {
    id: !!item.id ? item.id : null,
    // meetingNo: `2 ${format(new Date(), "MM-dd-yyyy")}`,
    facilityId: item.facilityId,
    userId: item.userId,
    consultType: item.consultType,
    communicationMethod: item.communicationMethod,
    cartNumber: item.cartNumber,
    siteCallbackNumber: item.siteCallbackNumber,
    siteContactName: item.siteContactName,
    siteCallbackNumberSecondary: item.siteCallbackNumberSecondary,
    siteContactNameSecondary: item.siteContactNameSecondary,
    reasonForReassign: item.reasonForReassign,

    cancelReason: item.cancelReason,
    cancelRemarks: item.cancelRemarks,

    firstName: item.firstName,
    lastName: item.lastName,
    birthDate: item.birthDate,
    medicalRecordNumber: item.medicalRecordNumber,
    accountNo: item.accountNo,

    currentProceduralTerminologyCode: item.currentProceduralTerminologyCode,
    diagnosisId: item.diagnosisId,
    disposition: item.disposition,
    dispositionDetails: item.dispositionDetails,
    remarks: item.remarks,
    reasonForMeeting: item.reasonForMeeting,

    // isActive: item.isActive, // todo delete field
    
    // meetingDateTime: format(new Date(), "MM-dd-yyyy"),
    // meetingDateTime: new Date(DateTime.local().toUTC().toISO()),
    startTime: item.startTime,
    endTime: item.endTime,
    duration: item.duration,
    waitingTime: item.waitingTime,
    status: item.status,

    
  };
};

export {
  // getMeetingAction,
  getMeetingsListAction,
  searchMeetingsAction,
  createMeetingAction,
  updateMeetingAction,
  deleteMeetingAction,
  getProviderStatisticsAction,
  api2FrontMapper,
  front2ApiMapper,
};

import { makeStyles } from "@material-ui/styles";

// const getInitialFiltersOptions = (initialConfigFiltersOptions, authStoreRedux, diagnosisCodesList = false) => {
const getInitialFiltersOptions = (optionsDataByFilterNames) => {
  const stateFilters = {};


  Object.keys(optionsDataByFilterNames).length > 0 &&
    Object
      .keys(optionsDataByFilterNames)
      .map(optionFilterName => 
        Object.keys(optionsDataByFilterNames[optionFilterName]).length > 0 
          ? optionsDataByFilterNames[optionFilterName].map(optionsDataItem => {
              stateFilters[`${optionFilterName}_${optionsDataItem.value}`] = "false";
            })
          : []
      )

  // stateFilters.dateRangeFrom = format(new Date(), "MM-dd-yyyy");
  // stateFilters.dateRangeTo = format(new Date(), "MM-dd-yyyy");

  //  = {
  //   dateRangeFrom,
  //   dateRangeTo,
  //   providerId: "",
  //   providerName: "",
  // }
  // if (initialConfigFiltersOptions.currentProceduralTerminologyCodes) {
  //   const currentProceduralTerminologyCodesData = JSON.parse(authStoreRedux.currentProceduralTerminologyCodesData);
  //   currentProceduralTerminologyCodesData.map(item => {
  //     stateFilters[`currentProceduralTerminologyCodes_${item.value}`] = "false";
  //   });
  // }

  // if (initialConfigFiltersOptions.consultTypes) {
  //   const consultTypesData = JSON.parse(authStoreRedux.consultTypesData);
  //   consultTypesData.map(item => {
  //     stateFilters[`consultTypes_${item.value}`] = "false";
  //   });
  // }
  // // console.log("statuses",stateFilters.statuses)

  // if (initialConfigFiltersOptions.statuses) {
  //   const statuses = JSON.parse(statusesData);  
  //   statuses.map(item => {
  //     stateFilters[`statuses_${item.value}`] = "false";
  //   });
  // }

  // if (diagnosisCodesList) {
  //   const diagnosisCodesData = diagnosisCodesList?.length > 0 ? diagnosisCodesList : [];
  //   diagnosisCodesData.map(item => {
  //     stateFilters[`diagnosisCodes_${item.value}`] = "false";
  //   });
  // }
  // console.log("stateFiltersdiagnosisCodesList ",stateFilters)
  // console.log("waitingTime",stateFilters.waitingTime)

  // if (stateFilters.waitingTimes) {
  //   const waitingTimeData = JSON.parse(waitingTimeList);  
  //   waitingTimeData.map(item => {
  //     stateFilters[`waitingTimes_${item.value}`] = "false";
  //   });
  // }
  
  return stateFilters;
}

const aggregateStateByFilterNames = (state, optionsNamesConfig) => {
  const stateFilters = {};

  if (state?.dateRangeFrom) {
    stateFilters.dateRangeFrom = state?.dateRangeFrom;
  }

  if (state?.dateRangeTo) {
    stateFilters.dateRangeTo = state?.dateRangeTo;
  }

  Object
    .keys(optionsNamesConfig)
    .map(optionKeyPrefix => {
      stateFilters[`${optionKeyPrefix}`] = {}

      const currentFilterOptionKeys = Object.keys(state).filter(itemKey => itemKey.startsWith(`${optionKeyPrefix}_`));


      currentFilterOptionKeys.length > 0 && 
      currentFilterOptionKeys.map(optionKey => {

          const optionKeySuffix = optionKey.split(`${optionKeyPrefix}_`)[1];

          stateFilters[`${optionKeyPrefix}`][`${optionKeySuffix}`] = state[`${optionKey}`];
        })
    }
      // Object.keys(optionsDataByFilterNames[optionFilterName]).length > 0 
      //   ? optionsDataByFilterNames[optionTag].map(optionsDataItem => {
      //       stateFilters[`${optionFilterName}`][`${optionsDataItem}`] = state[`${optionFilterName}_${optionsDataItem}`];
      //     })
      //   : []
    )

  return stateFilters;
}

const useStyles = makeStyles({
  height65: {
    height: "50vh",
  },
  height40: {
    height: "30vh",
  },
});

const clickFilterHandler = (isFiltersOpen, setIsFiltersOpen, tableId, filterButtonId, filterSectionId) => {
  if (!isFiltersOpen) {
    // open filters
    document.getElementById(
      tableId,
    ).style.width = "75%";

    // open btn icon title      
    document.getElementById(
      filterButtonId,
    ).style.width = "90px";

    document
      .getElementById(filterButtonId)
      .getElementsByTagName(
        "svg",
      )[0].style.padding = "8%";
    
    setIsFiltersOpen(true);      
  } else {
    // close filters
    document.getElementById(
      filterSectionId,
    ).style.width = "0%";

    document.getElementById(
      filterSectionId,
    ).style.display = "none";

    document.getElementById(
      tableId,
    ).style.width = "100%";     

    // close btn icon title
    document.getElementById(
      filterButtonId,
    ).style.width = "35px";

    document
      .getElementById(filterButtonId)
      .getElementsByTagName(
        "svg",
      )[0].style.padding = "15%";
    
    setIsFiltersOpen(false);      
  }
}

export {
  useStyles,
  getInitialFiltersOptions,
  aggregateStateByFilterNames,
  clickFilterHandler
}
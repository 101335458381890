const isValidInputOtp = (otpValue, setMessage) => {
  if (!otpValue) {
    setMessage({
      open: true,
      color: "warning",
      message: ["Otp Value Should Not Be Empty"],
    });

    return;
  }

  return true;
};

export { isValidInputOtp };

import "react-infinite-calendar/styles.css";
import "react-modern-calendar-datepicker/lib/DatePicker.css";

import React, { 
  useReducer,
  useContext,
  useEffect,
  useRef
} from "react";
import { useSelector } from "react-redux";

import { format, differenceInMinutes, toDate } from "date-fns";
import { formateDatePickerDate, formateDateOnPick } from "../../../../helpers/utils";
import {
  initialStateUser
} from "../../../Users/helpers/mutationUserHelper";
import mutationReactReducer from "../../../../reactReducers/mutationReactReducer";
import { MeetingsContext } from "../../../Meetings/context/meetingsContextProvider";

import {
  statusesData,
} from "./helpers/filtersDataHelper";

import {
  getInitialFiltersOptions,
  aggregateStateByFilterNames
} from "../../../../components/PageFilters/helpers/filterHelper";

import FilterSectionBodyDatePicker from "../../../../components/PageFilters/FilterSectionBodyDatePicker";
import FilterOptions from "../../../../components/PageFilters/FilterOptions";
import FilterSelectProvider from "../../../../components/PageFilters/FilterSelectProvider";
import FilterSectionHeader from "../../../../components/PageFilters/FilterSectionHeader";

const FilterSection = (props) => {
  const datePickerRef = useRef();

  const authStoreRedux = useSelector((state) => state.auth);

  const context = useContext(MeetingsContext);
  const { commonReactState, commonReactDispatch } = context;

  const optionsDataByFilterNames = {
    currentProceduralTerminologyCodes: JSON.parse(authStoreRedux.currentProceduralTerminologyCodesData),
    consultTypes: JSON.parse(authStoreRedux.consultTypesData),
    statuses: JSON.parse(statusesData),
    diagnosisCodes: context.diagnosisCodesList?.length > 0 ? context.diagnosisCodesList : []
  };
  const initialFiltersOptionsState = getInitialFiltersOptions(optionsDataByFilterNames);
  const [state, dispatch] = useReducer(mutationReactReducer, initialFiltersOptionsState);

  // const initialOptionsFilters = getInitialFiltersOptions(props.initialOptionsFiltersConfig, authStoreRedux, context.diagnosisCodesList);
  // const [state, dispatch] = useReducer(mutationReactReducer, initialOptionsFilters);

  const filterSectionId = `${props.stateName}_FilterDiv`;

  const userRole = localStorage.getItem("userRole");
  const providerIdLocalStorage = localStorage.getItem("userId");
  
  useEffect(() => {
    document.getElementById(
      filterSectionId,
    ).style.width = "25%";
    
    document.getElementById(
      filterSectionId,
    ).style.display = "block";

    // dispatch({
    //   type: "set_entire_state",
    //   value: initialOptionsFilters,
    // });
  }, []);

  useEffect(() => {
    // const meetingsFilters = getNormalizedState(props.initialOptionsFiltersConfig, state, authStoreRedux, context.diagnosisCodesList);
    const optionsNamesConfig = {
      currentProceduralTerminologyCodes: [],
      consultTypes: [],
      statuses: [],
      diagnosisCodes: [],
    }
    const stateFilters = aggregateStateByFilterNames(state, optionsNamesConfig);
    // commonReactDispatch({ type: `${props.stateName}`, payload: meetingsFilters });
    // console.log("stateFilters commonReactState.currentProvider?.id",stateFilters, commonReactState.currentProvider?.id)
    
    // console.log("getCurrentProviderId!!!!!!!!!1",context.getCurrentProviderId())
    const providerId = context.getCurrentProviderId();

    context.applySectionFilters(stateFilters);
    context.refreshTable(
      providerId,
      stateFilters
    );
  }, [state]);

  const onSelectChange = (providerId) => {
    const pickedProviderId = providerId === "All" ? null : providerId;

    context.getCurrentProvider(pickedProviderId);

    if (pickedProviderId) {
      context.providersList.map((value) => {
        if (providerId === value.id) {
          dispatch({
            type: "text",
            name: "providerId",
            value: value.id,
          });
          dispatch({
            type: "text",
            name: "providerName",
            value: `${value.firstName} ${value.lastName}`,
          });
        }
      });
    } else {
      dispatch({
        type: "text",
        name: "providerId",
        value: "All",
      });
      dispatch({
        type: "text",
        name: "providerName",
        value: "All",
      });
    }
    
    
    context.refreshTable(
      pickedProviderId,
     // commonReactState.meetingsFilters
    );      
  }

  const onClickClear = () => {
    datePickerRef.current.closeDatePicker();

    const optionsDataByFilterNames = {
      currentProceduralTerminologyCodes: JSON.parse(authStoreRedux.currentProceduralTerminologyCodesData),
      consultTypes: JSON.parse(authStoreRedux.consultTypesData),
      statuses: JSON.parse(statusesData),
      diagnosisCodes: context.diagnosisCodesList?.length > 0 ? context.diagnosisCodesList : []
    };
    const initialFiltersOptionsState = getInitialFiltersOptions(optionsDataByFilterNames);

    // const initialOptionsFiltersCleared = getInitialFiltersOptions(props.initialOptionsFiltersConfig, authStoreRedux, context.diagnosisCodesList);
    dispatch({
      type: "set_entire_state",
      value: initialFiltersOptionsState,
    });

    userRole !== "Provider" && dispatch({
      type: "text",
      name: "providerId",
      value: '',
    });
    userRole !== "Provider" && dispatch({
      type: "text",
      name: "providerName",
      value: "",
    });
    // console.log("userRole",userRole)

    userRole !== "Provider" && commonReactDispatch({
      type: "currentProvider",
      payload: initialStateUser,
    });

    const providerId = userRole !== "Provider" ? null : providerIdLocalStorage;
    // console.log("providerIdLocalStorage",providerIdLocalStorage)
   
    context.refreshTable(
      providerId
    );
  }

  const onClickPickDate = (dateRangeFrom, dateRangeTo) => {
    dispatch({
      type: "date",
      name: "dateRangeFrom",
      date: formateDateOnPick(dateRangeFrom),
    });
    dispatch({
      type: "date",
      name: "dateRangeTo",
      date: formateDateOnPick(dateRangeTo),
    });
  }  

  return (
    <div
      id={filterSectionId}
      style={{
        width: "0%",
        display: "none",
        border: "1px solid #e3e4e9",
        marginRight: "1%",
      }}
    >
      <FilterSectionHeader 
        onClickClear={onClickClear}
      />    
    
      { userRole == "Administration" && 
        <FilterSelectProvider
          filterOptionsName={'Provider'}
          filterOptionsData={
            [
              { 
                id: "All",
                value: "All",
                label: "All"
              },
              ...context.providersList, 
            ]
          }
          filterOptionsTag={'provider'}
          onSelectChange={onSelectChange}
          currentProvider={commonReactState.currentProvider}
          state={state}
          dispatch={dispatch}
        />
      }

      { 
        <FilterSectionBodyDatePicker
          ref={datePickerRef}
          onClickPickDate={onClickPickDate}
        />
      }

      { //state.consultTypes && 
        <FilterOptions
          filterOptionsName={'Consult Type'}
          filterOptionsData={authStoreRedux.consultTypesData}
          filterOptionsTag={'consultTypes'}
          state={state}
          dispatch={dispatch}
        />
      }

      { //state.statuses && 
        <FilterOptions
          filterOptionsName={'Consult Status'}
          filterOptionsData={statusesData}
          filterOptionsTag={'statuses'}
          state={state}
          dispatch={dispatch}
        />
      }

      { //state.currentProceduralTerminologyCodes && 
        <FilterOptions
          filterOptionsName={'CPT Code'}
          filterOptionsData={authStoreRedux.currentProceduralTerminologyCodesData}
          filterOptionsTag={'currentProceduralTerminologyCodes'}
          state={state}
          dispatch={dispatch}
        />
      }
 
      {  
        <FilterOptions
          filterOptionsName={'Diagnosis Code'}
          filterOptionsData={context.diagnosisCodesList}
          filterOptionsTag={'diagnosisCodes'}
          state={state}
          dispatch={dispatch}
        />
      }

      { //props.stateName == "meetingsFiltersCallCenter" && 
        // <FilterOptions
        //   filterOptionsName={'Consult Waiting Time'}
        //   filterOptionsData={waitingTimeList}
        //   filterOptionsTag={'waitingTime'}
        //   state={state}
        //   dispatch={dispatch}
        // />
      }

    </div>     
  );
};

export default FilterSection;

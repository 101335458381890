const isValidMutationInput = (state, context) => {
  if (!state.facilityCode.trim()) {
    context.alert({
      open: true,
      color: "error",
      message: ["Please enter the Facility Code"],
    });
    return false;
  }
  
  if (!state.facilityName.trim()) {
    context.alert({
      open: true,
      color: "error",
      message: ["Please enter the Facility Name"],
    });
    return false;
  }

  if (!state.city || state.city == 0) {
    context.alert({
      open: true,
      color: "error",
      message: ["Please enter the City"],
    });
    return false;
  }

  if (!state.region || state.region == 0) {
    context.alert({
      open: true,
      color: "error",
      message: ["Please enter the Region"],
    });
    return false;
  }

  if (!state.country || state.country == 0) {
    context.alert({
      open: true,
      color: "error",
      message: ["Please enter the Country"],
    });
    return false;
  }

  let isContactPersonEmail =
    /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)$/.test(
      state.contactPersonEmail,
    );
  if (state.contactPersonEmail) {
    if (!isContactPersonEmail) {
      context.alert({
        open: true,
        color: "error",
        message: ["Invalid Contact Person Email"],
      });
      return false;
    }
  }

  return true;
};

export { isValidMutationInput };

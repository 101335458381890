import { useState } from "react";

import HomeIcon from "@material-ui/icons/Home";

import {
  TriggerFunc,
  makeTabActive,
  removeTab,
} from "./helpers/tabsBarHelpers";

const TabsBar = (props) => {
  const [ShowSlide, setShowSlide] = useState(false); //todo 4what
  const [selected, setSelected] = useState(false); //todo 4what

  return (
    <>
      <div className={props.classes.tabDiv} id="tabs">
        <li
          className={"liTabs tabActive"}
          onClick={() => makeTabActive("Home")}
          id="tabHome"
          style={{ marginLeft: "-1px", display: "none" }}
        >
          <span className={props.classes.tabIcons + " spanTab"}>
            <HomeIcon />
          </span>
          Home
        </li>
      </div>

      <input type="hidden" id="TriggerNewTab" onClick={TriggerFunc} />
    </>
  );
};

export default TabsBar;

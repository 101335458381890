import React from "react";

import Tooltip from "@material-ui/core/Tooltip";
import RefreshIcon from "@material-ui/icons/Refresh";

const RefreshButton = (props) => {
  return (
    <div>
      <div style={{ display: "flex" }}>
        {props.Refresh && (
          <div>
            <Tooltip title="Refresh" placement="top">
              <RefreshIcon
                className="tableFilterColumnIcon tablExpicon fontSize18"
                onClick={props.Refresh}
              />
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};

export default RefreshButton; 

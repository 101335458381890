const isValidMutationInput = (state, context) => {
  if (state.startTime && !state.currentProceduralTerminologyCode || state.currentProceduralTerminologyCode == 0) {
    context.alert({
      open: true,
      color: "error",
      message: ["Please Select the CPT Code"],
    });
    return false;
  }

  if (state.startTime && !state.diagnosisId || state.diagnosisId == 0) {
    context.alert({
      open: true,
      color: "error",
      message: ["Please Select the Diagnosis Code"],
    });
    return false;
  }

  if (state.startTime && !state.disposition || state.disposition == 0) {
    context.alert({
      open: true,
      color: "error",
      message: ["Please Select the Disposition"],
    });
    return false;
  }

  return true;
};

const isValidReassingInput = (state, context) => {
  if (!!!state.reasonForReassign.trim()) {
    context.alert({
      open: true,
      color: "error",
      message: ["Please type Reason For Reassign"],
    });
    return false;
  }

  return true;
}

export { isValidMutationInput, isValidReassingInput };

import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useReducer,
  useContext,
} from "react";

import SweetAlert from "react-bootstrap-sweetalert";

import mutationReactReducer from "../../../reactReducers/mutationReactReducer";

import { DiagnosisCodesContext } from "../context/diagnosisCodesContextProvider";

import {
  saveSubmit,
  removeSubmit,
} from "../actions/mutationDiagnosisCodeAction";

import {
  initialStateMutationDiagnosisCode,
} from "../helpers/mutationDiagnosisCodeHelpers";

import { isValidMutationInput } from "../validations/mutationDiagnosisCodeValidator";

import DiagnosisCodeFormBodyMutation from "./DiagnosisCodeFormBodyMutation";

import ModalWindowMutation from "../../../components/ModalWindow/ModalWindowMutation";
import ModalHeaderMutation from "../../../components/ModalWindow/ModalHeaderMutation";
import ModalFooterMutation from "../../../components/ModalWindow/ModalFooterMutation";

const DiagnosisCodeMutation = forwardRef((props, ref) => {
  const context = useContext(DiagnosisCodesContext);
  const { commonReactState, commonReactDispatch } = context;

  const [state, dispatch] = useReducer(mutationReactReducer, initialStateMutationDiagnosisCode);

  const [isModalOpen, setIsModalOpen] = useState(false); 

  useEffect(() => {
    if (commonReactState.currentModalItem) {
      dispatch({
        type: "set_entire_state",
        value: commonReactState.currentModalItem,
      });
    }
  }, [commonReactState.currentModalItem]);

  useImperativeHandle(ref, () => ({
    openModal: (item) => {
      const itemState = item ? item : initialStateMutationDiagnosisCode;
      
      dispatch({
        type: "set_entire_state",
        value: itemState,
      });

      setIsModalOpen(true);
    },
    closeModal: () => {
      setIsModalOpen(false);
    },
    removeAlertModal: (itemId) => {
      removeAlert(itemId);
    },
  }));

  

  const saveAlert = () => {
    context.ConfirmAlert(
      <SweetAlert
        warning
        Size="sm"
        style={{ display: "block", marginTop: "100px" }}
        title={"Are you sure?"}
        onConfirm={async () => {
          // context.loading(true);
          // context.ConfirmAlert(null);
          saveSubmit(
            context,
            state,
            setIsModalOpen,
            dispatch,
            initialStateMutationDiagnosisCode
          );
        }}
        confirmBtnCssClass={"sweetAlertBtn sweetAlertBtnSuccess"} 
        cancelBtnCssClass={"sweetAlertBtn sweetAlertBtnCancel"} 
        onCancel={() => {
            context.ConfirmAlert(null);
            setIsModalOpen(false);
            dispatch({
              type: "set_entire_state",
              value: initialStateMutationDiagnosisCode,
            });
          }
        }
        confirmBtnText={"Yes"}
        cancelBtnText={"Cancel"}
        showCancel
      >
        {"Do you want to Save?"}
      </SweetAlert>,
    );
  };

  const removeAlert = (itemId) => {
    context.ConfirmAlert(
      <SweetAlert
        warning
        Size="sm"
        style={{ display: "block", marginTop: "100px" }}
        title={"Are you sure?"}
        onConfirm={() => {
          context.loading(true);
          context.ConfirmAlert(null);
          removeSubmit(
            context,
            itemId,
            setIsModalOpen
          );
        }}
        onCancel={() => {
            context.ConfirmAlert(null);
            setIsModalOpen(false);
          }
        }
        confirmBtnCssClass={"sweetAlertBtn sweetAlertBtnSuccess"} 
        cancelBtnCssClass={"sweetAlertBtn sweetAlertBtnCancel"}
        confirmBtnText={"Yes"}
        cancelBtnText={"Cancel"}
        showCancel
      >
        {"Are you sure you want to delete this item?"}
      </SweetAlert>,
    );
  };

  const modalWindowParams = {
    isModalOpen, 
    setIsModalOpen,
  }

  const modalHeaderParams = {
    modalItemName: "Diagnosis Code",
    modalItemId: state.id
  };

  const modalBodyParams = {
    item: state,
    dispatch,
  };

  const onClickSave = () => {
    if (!isValidMutationInput(state, context)) {
      return;
    } 
    
    saveAlert();
  }

  const onClickCancel = () => {
    dispatch({
      type: "set_entire_state",
      value: initialStateMutationDiagnosisCode,
    });
    setIsModalOpen(false);
  }

  const modalFooterParams = {
    state,
    dispatch,
    onClickSave,
    onClickCancel
  };

  return (
    <ModalWindowMutation 
      modalWindowParams = {modalWindowParams}
  
      modalHeader = {ModalHeaderMutation}
      modalHeaderParams = {modalHeaderParams}

      modalBody = {DiagnosisCodeFormBodyMutation}
      modalBodyParams = {modalBodyParams}

      modalFooter = {ModalFooterMutation}
      modalFooterParams = {modalFooterParams}
    />      
  );
});

export default DiagnosisCodeMutation; 

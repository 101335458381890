import { useState, useEffect, useRef, useReducer } from "react";

import { commonReactReducer, initialStateGlobal } from "../../reactReducers/commonReactReducer";

// import Card from "../../components/Card/Card";
import Card from "../../components/Card/CardRoot";
// import CardBody from "../../components/Card/CardBody";
import CardBody from "../../components/Card/CardBodyRoot";
import MeetingsStatistics from "../Meetings/components/MeetingsStatistics";
import MeetingsQueueTable from "../Meetings/components/MeetingsQueueTable";
import MeetingMutation from "../Meetings/components/MeetingMutation";
import MeetingCancel from "../Meetings/components/MeetingCancel";

import {
  getProvidersList
} from "../Users/actions/viewProvidersListAction";

import { MeetingsContextProvider } from "../Meetings/context/meetingsContextProvider";

const CallCenter = (props) => {
  const mutationRef = useRef();
  const cancelRef = useRef();
  const statsRef = useRef();
  const queueRef = useRef();
  
  const [commonReactState, commonReactDispatch] = useReducer(commonReactReducer, initialStateGlobal);

  const [providersList, setProvidersList] = useState([]);
  const [providersPagination, setProvidersPagination] = useState({
    pageNo: 1,
    getPerPage: 100,
    itemsTotalNumber: 0,
    pagesTotalNumber: 0
  });

  const defaultFilters = {
    statuses: {
      'Active': "true",
      'Inactive': "false"
    }
  }

  useEffect(() => {
    getProvidersList(
      setProvidersList,
      {
        loading: (val) => props.loading(val),
        alert: (val) => props.alertmsg(val)
      },
      {
        usersFilters: defaultFilters
      },
      {
        pageNo: 1,
        getPerPage: 100,
        pagination: providersPagination,
        setPagination: setProvidersPagination
      }
    );
  }, []);

  const providerValue = {
    commonReactState, 
    commonReactDispatch,
    providersList,
    openModalMutation: (itemId) => {   
      let item = queueRef.current.getCurrentModalItem(itemId);
      mutationRef.current.openModal(item);
    },
    openModalMutationCancel: (itemId) => {   

      let item = queueRef.current.getCurrentModalItem(itemId);
      cancelRef.current.openModalCancel(item);
    },
    getCurrentProviderId: () => queueRef.current.getCurrentProviderId(),
    getCurrentProvider: (providerId = null) => queueRef.current.getCurrentProvider(providerId),
    refreshProviderStatistics: (providerId = null) => statsRef.current.refreshProviderStatistics(providerId),
    refreshActiveCellStatistics: (meetingsType) => statsRef.current.refreshActiveCellStatistics(meetingsType),
    refreshTable: (providerId = null, meetingsFilters = null) => queueRef.current.refreshTable(providerId, meetingsFilters),
    applySectionFilters: (sectionFilters) => queueRef.current.applySectionFilters(sectionFilters),    
    loading: (val) => props.loading(val),
    alert: (val) => props.alertmsg(val),
    ConfirmAlert: (val) => props.ConfirmAlert(val)
  }

  return (
    <>
      <MeetingsContextProvider value={providerValue}>
        <div id="ProviderQueue-MainDiv" className="MainDivStyles">        
          <div
            id="divAppointmentRegistry-Entry"
            style={{ width: "100%", position: "relative", marginTop: "6px" }}
          >
            <div id="SiteSurveyRegistry-MainEntry">
              <Card style={{ 
                background: "#FFF",
                boxShadow: "0 4px 20px 1px rgba(0,0,0,.06),0 1px 4px rgba(0,0,0,.08)",
                borderRadius: "6px" }} className="mar0">
                <CardBody className="acrcardpad">  
                  <MeetingsStatistics ref={statsRef} />
                  <MeetingsQueueTable ref={queueRef} />
                </CardBody>
              </Card>
            </div>
          </div>
          <MeetingMutation ref={mutationRef} />
          <MeetingCancel ref={cancelRef} />
        </div>
      </MeetingsContextProvider>
    </>
  );
};

export default CallCenter;

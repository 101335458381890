import DialogTitle from "@material-ui/core/DialogTitle";

const ModalHeader = (props) => {
  return (
    <div>
      <DialogTitle>
        <props.modalHeader
          modalHeaderParams={props.modalHeaderParams}
        />
      </DialogTitle>
    </div>      
  )
}

export default ModalHeader;
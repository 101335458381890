const currentProceduralTerminologyCodesDataInit = [
  {
    id: 'Non_Billable',
    label: "Non-Billable",
    value: "Non-Billable",
  },
  {
    id: 'Psychiatric_Diagnostic_Evaluation',
    label: "90792 (Psychiatric Diagnostic Evaluation with Medical Services)",
    value: "90792 (Psychiatric Diagnostic Evaluation with Medical Services)",
  },
];

export { currentProceduralTerminologyCodesDataInit };

import axios from "axios";

import { config } from "../configs/config";
import { refreshTokenAction } from "../pages/Auth/components/Login/actions/loginActions";

var axiosClient;

async function getAxiosClient() {
  if (!axiosClient) {
    const SERVER_URL = config.API_URL;

    if (!SERVER_URL) {
      throw new Error("No api url was found");
    }

    axiosClient = axios.create(
      {
        baseURL: SERVER_URL,
        // timeout: 30000,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      } /*, { withCredentials: true }*/,
    );

    // axiosClient.interceptors.request.use(function(config) {
    //   // change the url scheme from http to https
    //   console.log('ax config.url',config.url)
    //   config.url = config.url.replace('https://', 'http://')

    //   return config
    // })
  }

  return axiosClient;
}

async function requestAxios(
  requestApiUrl,
  requestBody = null,
  dispatchRedux = null,
  requestOptions = null,
) {
  const url = `${requestApiUrl}`;
  const data = requestBody ? JSON.stringify(requestBody) : {};

  const method = requestOptions?.requestType
    ? requestOptions.requestType
    : "POST";
  console.info("requestAxios method url", method, url);
  const apiToken = localStorage.getItem("actionToken");
  const headers = {
    "x-api-token": apiToken,
  };

  const params = requestOptions?.requestParams && requestOptions.requestParams;

  try {
    const client = await getAxiosClient();

    const response = await client.request({
      url,
      data,
      method,
      headers,
      params,
    });

    const apiResult = await response.data;

    return [apiResult, null];
  } catch (error) {
    const errorMessage = error?.response?.data?.message ? error?.response?.data?.message : 'Bad request';
    localStorage.setItem("error", errorMessage);
    dispatchRedux && dispatchRedux({ type: "error", error: errorMessage });

    console.error("requestAxios error", errorMessage);

    // refreshTokenAction();

    return [null, {message: errorMessage}];
  }
}

export { requestAxios };

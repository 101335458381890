import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useContext,
} from "react";

import { DiagnosisCodesContext } from "../context/diagnosisCodesContextProvider";

import ModalWindowView from "../../../components/ModalWindow/ModalWindowView";
import ModalHeaderView from "../../../components/ModalWindow/ModalHeaderView";
import ModalFooterView from "../../../components/ModalWindow/ModalFooterView";

import DiagnosisCodeFormBodyView from "./DiagnosisCodeFormBodyView";

const DiagnosisCodeView = forwardRef((props, ref) => {
  const context = useContext(DiagnosisCodesContext);
  const { commonReactState, commonReactDispatch } = context;

  const [diagnosisCodeItem, setDiagnosisCodeItem] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false); 

  useImperativeHandle(ref, () => ({
    openModal: (item) => {
      setDiagnosisCodeItem(item);
      setIsModalOpen(true);
    },
    closeModal: () => {
      setIsModalOpen(false);
    }
  }));

  const modalHeaderParams = {
    modalItemName: "Diagnosis Code"
  }

  const modalBodyParams = {
    item: diagnosisCodeItem
  }

  const onClickClose = () => {
    commonReactDispatch({
      type: "currentModalItem",
      payload: false,
    });
    setDiagnosisCodeItem({});
    setIsModalOpen(false);
  }
  
  const modalFooterParams ={
    onClickClose
  }

  const modalWindowParams = {
    isModalOpen, 
    setIsModalOpen
  }

  return (
    <ModalWindowView 
      modalWindowParams = {modalWindowParams}
  
      modalHeader = {ModalHeaderView}
      modalHeaderParams = {modalHeaderParams}

      modalBody = {DiagnosisCodeFormBodyView}
      modalBodyParams = {modalBodyParams}

      modalFooter = {ModalFooterView}
      modalFooterParams = {modalFooterParams}
    />      
  );
});

export default DiagnosisCodeView;

import "./TableView.css";

import Accordion from "@material-ui/core/Accordion";

import TableViewHeader from "./TableViewHeader";
import TableViewBody from "./TableViewBody";

const TableView = (props) => {
  return (
    <Accordion expanded={true}>
      <TableViewHeader 
        tableViewHeaderParams = {props.tableViewHeaderParams}
      /> {/** AccordionSummary */}
      <TableViewBody 
        tableContainerParams = {props.tableContainerParams}        
        tableToolbarParams = {props.tableToolbarParams}        
        tablePaginationContainerParams = {props.tablePaginationContainerParams}         
        tableBodyParams = {props.tableBodyParams}         
      />{/** AccordionDetails */}
    </Accordion>   
  )
}

export default TableView;
import { DateTime } from 'luxon';

function formateDatePickerDate(rawDate) { 
  if (!rawDate) {
    return new Date();
  }
  // const formatedDate = rawDate.split("-").join("/");
  return new Date(rawDate).toString();
}

const formateDateOnPick = (rawDate) => {
  if (!rawDate || rawDate == "Invalid Date") {
    return ;
  }
  // console.log("formateDateOnPick rawDate",rawDate);

  const formatedDate = rawDate.split("-");

  return `${formatedDate[2]}-${formatedDate[0]}-${formatedDate[1]}`;
}

const getLocalizedDateFrom = (rawDate, timeZone) => {
  if (!timeZone) {
    // console.log("No timeZone was provided");
    return "No timeZone was provided";
  }

  return rawDate
    ? DateTime
        .fromJSDate(new Date(rawDate), { zone: timeZone })
        .setLocale('en-US')
        .toFormat('MM-dd-yyyy hh:mm a')
    : "";
}

const getLocalizedDateCurrent = (timeZone) => {
  if (!timeZone) {
    // console.log("No timeZone was provided");
    return "No timeZone was provided";
  }

  return DateTime.local({ zone: timeZone })
    .setLocale('en-US')
    .toFormat('MM-dd-yyyy hh:mm a'); 
}

const getZuluFrom = (rawDate, timeZone) => {
  return DateTime.fromFormat(rawDate, 'MM-dd-yyyy hh:mm a', {zone: timeZone}).toUTC().toISO();
}

const getZuluCurrent = (timeZone) => {
  if (!timeZone) {
    // console.log("No timeZone was provided");
    return "No timeZone was provided";
  }

  return DateTime.local({ zone: timeZone }).toUTC().toISO();
}

const getUtcDateFrom = (rawDate) => {
  return DateTime.utc(new Date(rawDate)).toSQL()
}

const getUtcDateCurrent = (timeZone) => {
  if (!timeZone) {
    console.log("No timeZone was provided");
    return;
  }

  return DateTime.local({ zone: timeZone }).toSQL();
}

const getUtcDate = (rawDate) => {
  return DateTime.fromJSDate(rawDate).toUTC()//.toUTC().toISO();
}

const updateConditionQuery = (
  conditionQuery,
  paramName,
  filterOptionsRaw
) => {
  const filterOptions = [];
  for (let key in filterOptionsRaw) {
    if (filterOptionsRaw[key] == "true") {
      filterOptions.push(key)
    }
  }

  return filterOptions.length > 0 ? `${conditionQuery}&${paramName}=${filterOptions.join(',')}` : conditionQuery;
}

export { 
  formateDatePickerDate,
  formateDateOnPick,
  getLocalizedDateFrom,
  getLocalizedDateCurrent,
  getUtcDateCurrent,
  getUtcDateFrom,
  getUtcDate,
  updateConditionQuery,
  getZuluCurrent,
  getZuluFrom
};

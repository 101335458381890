import React, { useEffect, useRef, useReducer } from "react";

import FacilitiesTable from "./components/FacilitiesTable";
import FacilityView from "./components/FacilityView";
import FacilityMutation from "./components/FacilityMutation";

import { FacilitiesContextProvider } from "./context/facilitiesContextProvider";

import { commonReactReducer, initialStateGlobal } from "../../reactReducers/commonReactReducer";

function Facilities(props) {
  const tableRef = useRef();
  const mutationRef = useRef();
  const viewRef = useRef();

  const [commonReactState, commonReactDispatch] = useReducer(commonReactReducer, initialStateGlobal);

  useEffect(() => {
    props.loading(true);
    document.getElementById("FacilityRegistry-MainTable").style.width = "100%";
    document
      .getElementById("FacilityRegistry-MainDiv")
      .classList.add("displayFlex");
    document
      .getElementById("FacilityRegistry-MainDiv")
      .classList.remove("displayGrid");
  }, []);

  const providerValue = {
    commonReactState, 
    commonReactDispatch,
    openModalMutation: (itemId) => {      
      const item = tableRef.current.getCurrentModalItem(itemId);
      mutationRef.current.openModal(item);
    },
    removeAlertModal: (itemId) => {
      mutationRef.current.removeAlertModal(itemId);
    },
    openModalView: (tableItem) => {
      const item = tableRef.current.getCurrentModalItem(tableItem);
      viewRef.current.openModal(item);
    },
    refreshTable: (filters) => tableRef.current.refreshTable(filters),
    applySectionFilters: (sectionFilters) => tableRef.current.applySectionFilters(sectionFilters),
    loading: (val) => props.loading(val),
    alert: (val) => props.alertmsg(val),
    ConfirmAlert: (val) => props.ConfirmAlert(val),
  };

  return (
    <>
      <FacilitiesContextProvider value={providerValue}>
        <>
          <div
            id="FacilityRegistry-MainDiv"
            className="MainDivStyles displayFlex"
          >
            <div id="FacilityRegistry-MainTable" className="Maincontent">
              <FacilitiesTable ref={tableRef} />
            </div>
          </div>
          <FacilityView ref={viewRef} />
          <FacilityMutation ref={mutationRef} />
        </>
      </FacilitiesContextProvider>
    </>
  );
}

export default Facilities;

import Person from "../../../assets/img/person.png";

import { forwardRef } from "react";

import CardContent from "@material-ui/core/CardContent";

// import GridContainer from "../../../components/Grid/GridContainer";
import GridContainer from "../../../components/Grid/GridContainerRoot";
// import GridItem from "../../../components/Grid/GridItem";
import GridItem from "../../../components/Grid/GridItemRoot";
// import Card from "../../../components/Card/Card";
import Card from "../../../components/Card/CardRoot";

import 
  MeetingsStatisticsTableCell
 from "./MeetingsStatisticsTableCell";

const MeetingsStatisticsTable = forwardRef((props, ref) => {
  return (
    <GridItem xs={12} sm={12} md={7}>
      <Card style={{ margin: "0",
    background: "#FFF",
    boxShadow: "0 4px 20px 1px rgba(0,0,0,.06),0 1px 4px rgba(0,0,0,.08)",
    borderRadius: "6px" }}>
        <CardContent style={{ padding: "8px 16px" }}>
          <GridContainer style={{ width: "100%", margin: "0" }}>            
            <GridItem
              xs={12}
              sm={12}
              md={4}
              style={{
                borderRight: "0px solid #eee",
                padding: "0px",
              }}
            >
              <GridContainer style={{ width: "100%", margin: "0" }}>
                <GridItem md={5} style={{ padding: "0px 5px" }}>
                  <div className="entryimg">
                    <img
                      src={ Person }
                      width="71px"
                      height="54px"
                    />
                  </div>
                </GridItem>

                <GridItem
                  md={7}
                  className="entrydes"
                  style={{ padding: "0px" }}
                >
                  <span>{props.provider.label}</span>
                  <p className="entrycon">
                    {props.provider.designation}
                  </p>
                  <p className="entryphy">
                    {props.provider.speciality}
                  </p>
                </GridItem>
              </GridContainer>
            </GridItem>
             
            <GridItem
              xs={12}
              sm={12}
              md={8}
              style={{ padding: "0px" }}
            >
              <GridContainer>
                {                 
                  props.tableCellsParams.map(item => {
                    return (
                      <MeetingsStatisticsTableCell 
                        key={item.cellName}
                        item={item} 
                      />
                    )
                  })
                }                                     
              </GridContainer>
            </GridItem>
            
          </GridContainer>
        </CardContent>
      </Card>
    </GridItem>
  );
});
  
export default MeetingsStatisticsTable; 
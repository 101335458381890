import React, { useEffect, useState } from "react";

import ClearIcon from "@material-ui/icons/Clear";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import RefreshIcon from "@material-ui/icons/Refresh";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { json } from "./Json/EntryJson";

export default function TextArea(props) {
  const JSON = json;
  const [InputValue, setInputValue] = useState("");
  const [fieldType, setFieldType] = useState("");
  let fieldID = "";
  let type = "";
  let mandatory = "";
  let disabled = "";
  let maxLength = "";
  let labelname = "";

  if (JSON.Property[props.SPName]) {
    fieldID = JSON.Property[props.SPName][props.FieldName].id;
    type = JSON.Property[props.SPName][props.FieldName].type;
    mandatory = JSON.Property[props.SPName][props.FieldName].mandatory;
    disabled = JSON.Property[props.SPName][props.FieldName].disabled;
    maxLength = JSON.Property[props.SPName][props.FieldName].maxLength;
    labelname = JSON.Property[props.SPName][props.FieldName].labelname;
  }

  function OnChangeFunction(e) {
    if (mandatory) {
      if (!e.target.value) {
        e.target.classList.remove("successinput");
        e.target.classList.add("errorinput");
      } else {
        e.target.classList.add("successinput");
        e.target.classList.remove("errorinput");
      }
    } else if (!mandatory) {
      e.target.classList.add("successinput");
      e.target.classList.remove("errorinput");
    }
    if (labelname.includes("%")) {
      if (e.target.value <= 100) {
        setInputValue(e.target.value.replace(/["]/g, ""));
        props.onChange(e.target.value.replace(/["]/g, ""));
      }
    } else {
      setInputValue(e.target.value.replace(/["]/g, ""));
      props.onChange(e.target.value.replace(/["]/g, ""));
    }
  }

  useEffect(() => {
    setInputValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (type == "password") {
      setFieldType("password");
    } else {
      setFieldType("text");
    }
  }, [type]);

  return (
    <div>
      <div
        className={"floating-label " + props.className}
        style={{ marginTop: props.marginTop ? props.marginTop : "10px" }}
      >
        {props.Iscurr && (
          <span className="pretext">
            {/*localStorage.getItem('DefCurrenct')*/ "DefCurrenct"}
          </span>
        )}
        <textarea  
          rows="5" cols="50"
          className={
            (mandatory && !InputValue ? "errorinput " : "successinput ") +
            (type === "password"
              ? "floating-input floating-input-password"
              : "floating-input ") +
            (props.Iscurr ? "paddingcurr" : "")
          }
          placeholder=" "
          value={InputValue}
          id={fieldID}
          onChange={OnChangeFunction}
          onBlur={props.onBlur ? props.onBlur : null}
          type={type === "password" ? fieldType : type}
          disabled={disabled ? "disabled" : ""}
          maxLength={maxLength ? maxLength : ""}
          onKeyPress={(event) => {
            if (event.key === "Enter" && props.keyPress) {
              props.onKeyPress(event.target.value);
            }
          }}
        />
        <label
          className={
            props.Iscurr ? "float-label shrinklabel floatTure" : "float-label"
          }
        >
          {(!props.value ? "Enter " : " ") +
            labelname +
            (InputValue.length > 0 && maxLength && type !== "select"
              ? "(" + InputValue.length + "/" + maxLength + ")"
              : "")}
        </label>
        {type == "password" ? (
          fieldType == "password" ? (
            <VisibilityOffIcon
              className="passwordVisibleIcon"
              onClick={() => setFieldType("text")}
            />
          ) : (
            fieldType == "text" && (
              <VisibilityIcon
                className="passwordVisibleIcon"
                onClick={() => setFieldType("password")}
              />
            )
          )
        ) : null}
        <div className="L2DropDownInputClearDiv">
          {InputValue && !disabled ? (
            <ClearIcon
              title="clear"
              className="FilterClearIcon"
              onClick={() => {
                setInputValue("");
                props.onChange("");
                props.clear(true);
              }}
            />
          ) : (
            ""
          )}
        </div>
        <span>{props.helpertext && props.helpertext}</span>
      </div>
    </div>
  );
}

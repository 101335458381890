import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  Details: {
    padding: "0px 1px 1px 4.5% !important",
    display: "block",
    background: "aliceblue",
  },
  shadow: {
    borderBottom: "0px solid #ccc !important",
  },
}));

const initialStateMutationMeeting = {
  id: "",
  userId: "",
  provider: "",
  facilityId: "",
  facility: "",
  consultType: "",
  communicationMethod: "",
  cartNumber: "",
  siteCallbackNumber: "",
  siteContactName: "",
  siteCallbackNumberSecondary: "",
  siteContactNameSecondary: "",
  reasonForReassign: "", 
  firstName: "",
  lastName: "",
  birthDate: "",
  medicalRecordNumber: "",

  startTime: "",
  endTime: "",
  duration: "",
  waitingTime: "",
  
  status: "",
  cancelReason: "",
  cancelRemarks: "",
  checkedIn: "",
  currentProceduralTerminologyCode: "",
  diagnosisId: "",
  diagnosisCode: "",
  diagnosisDescription: "",
  disposition: "",
  dispositionDetails: "",
  remarks: "", 
  reasonForMeeting: "", 
}

const cancelReasonsData = [
  {
    id: "Duplicate Entry",
    label: "Duplicate Entry",
    value: "Duplicate Entry",
  },
  {
    id: "Patient AMA",
    label: "Patient AMA",
    value: "Patient AMA",
  },
  {
    id: "Patient Refused",
    label: "Patient Refused",
    value: "Patient Refused",
  },
  {
    id: "Patient Discharged",
    label: "Patient Discharged",
    value: "Patient Discharged",
  },
  {
    id: "Other",
    label: "Other",
    value: "Other",
  },
];

export {
  useStyles,
  initialStateMutationMeeting,
  cancelReasonsData
};

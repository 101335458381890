import * as menuDataInit from "../../../../../Redux/dataInit/menuDataInit";
import  {timeZonesDataInit} from "../../../../../Redux/dataInit/timeZonesDataInit";

const getTimeZoneInfoByValue = (timeZonesData, timeZoneValue) => {
  let tzResult = '';
 
  for (let tzItem of timeZonesData) {
    if (timeZoneValue == tzItem.value) {
      tzResult = tzItem;
      break;
    }
  }

  return tzResult
}

const setupDispatchReduxOnLogin = (responseData, dispatchRedux) => {
  dispatchRedux({ type: "userId", userId: responseData.userId });
  dispatchRedux({ type: "username", username: responseData.username });
  dispatchRedux({ type: "userRole", userRole: responseData.userRole });

  const timeZoneInfo = getTimeZoneInfoByValue(timeZonesDataInit, responseData.timeZone);
  dispatchRedux({ type: "timeZone", timeZone: timeZoneInfo.id });
  
  localStorage.setItem("timeZone", timeZoneInfo.id);
  localStorage.setItem("userRole", responseData.userRole);
  localStorage.setItem("userId", responseData.userId);

  dispatchRedux({
    type: "menuData",
    menuData: JSON.stringify(
      menuDataInit[responseData.userRole],
    ),
  });
};

const setupLocalStorageOnLogin = (responseData) => {
  const timeZoneInfo = getTimeZoneInfoByValue(timeZonesDataInit, responseData.timeZone);
  const authState = JSON.stringify({
    userId: responseData.userId,
    username: responseData.username,
    userRole: responseData.userRole,
    timeZone: timeZoneInfo.id,
    menuData: JSON.stringify(
      menuDataInit[responseData.userRole],
    )
  });

  localStorage.setItem("authState", authState);
}

export {
  setupDispatchReduxOnLogin,
  setupLocalStorageOnLogin
};

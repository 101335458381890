import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import { Steps } from "antd";

import L2TextField from "../../../../components/_helperComponents/L2TextFieldTable";
import Button from "../../../../components/CustomButtons/ButtonRoot";
import GridContainer from "../../../../components/Grid/GridContainerRoot";
import GridItem from "../../../../components/Grid/GridItemRoot";

import { forgetPasswordAction } from "./actions/forgetPasswordAction";
import { verifyOtpValueOnResetAction } from "./actions/verifyOtpValueOnResetAction";
import { resetPasswordAction } from "./actions/resetPasswordAction";

import { 
  isValidInputPhoneNo,
  isValidInputOtpValue,
  isValidInputResetPassword,
} from "./validations/forgetPasswordValidator";

function ForgetPassword(props) {
  const [currentStep, setCurrentStep] = useState(0);
  const [confirmAlert, setConfirmAlert] = useState(null);
  
  const [phoneNo, setPhoneNo] = useState("");
  const [otpValue, setOtpValue] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { Step } = Steps;
  const steps = [
    {
      title: "Verify Phone Number",
    },
    {
      title: "Verify OTP",
    },
    {
      title: "Reset Password",
    },
  ];

  const onClickGoNextStep = () => {
    props.loading(true);
    setConfirmAlert(null);

    const currentval = currentStep + 1;
    setCurrentStep(currentval);

    props.loading(false);
  }

  const onClickVerifyPhoneNo = async (phoneNo) => {
    if (!isValidInputPhoneNo(phoneNo, props.setMessage)) {
      return;
    } 

    const isForgetPasswordDone = await forgetPasswordAction(
      phoneNo,
      props.setMessage,
      props.loading,
    );

    if (!isForgetPasswordDone) {
      return;
    }

    setConfirmAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "100px", width: "40em" }}
        title="Message was sent."
        onConfirm={() => onClickGoNextStep()}
        closeOnClickOutside={false}
        confirmBtnCssClass={"sweetAlertBtnSuccessInfo"} 
      ></SweetAlert>,
    );
  }

  const onClickVerifyOtpValue = async (phoneNo, otpValue) => {
    if (!isValidInputOtpValue(otpValue, props.setMessage)) {
      return;
    } 

    const isVerifyOtpValueDone = await verifyOtpValueOnResetAction(
      phoneNo,
      otpValue,
      props.setMessage,
      props.loading,
    );

    if (!isVerifyOtpValueDone) {
      return;
    }

    setConfirmAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "100px", width: "40em" }}
        title="OTP was verified."
        onConfirm={() => onClickGoNextStep()}
        closeOnClickOutside={false}
        confirmBtnCssClass={"sweetAlertBtnSuccessInfo"} 
      ></SweetAlert>,
    );
  }

  const onClickResetPassword = async (phoneNo, otpValue, newPassword, confirmPassword) => {
    if (!isValidInputResetPassword(newPassword, confirmPassword, props.setMessage)) {
      return;
    } 

    const isResetPasswordDone = await resetPasswordAction(
      phoneNo,
      otpValue,
      newPassword,
      props.setMessage,
      props.loading,
    );

    if (!isResetPasswordDone) {
      return;
    }

    setConfirmAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "100px", width: "40em" }}
        title="Your Password was successfully reset."
        onConfirm={() => props.returnToLoginPage()}
        // onCancel={() => props.Returnlogin()}
        confirmBtnCssClass={"sweetAlertBtnSuccessInfo"} 
      ></SweetAlert>,
    );
  }

  return (
    <div style={{ marginTop: "2%", marginLeft: "50px", marginRight: "50px" }}>

      {confirmAlert}

      <Steps direction="vertical" current={currentStep}>
        {
          steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))
        }
      </Steps>

      <div className="stepInput">
        <p style={{ textAlign: "left" }}>
          Remember password ? Click here to{" "}
          <Link
            className={"TextWeight400"}
            onClick={() => props.returnToLoginPage()}
            to='/login'
          >
            Login
          </Link>
        </p>

        {
          currentStep === 0 && (
          <div>
            <GridContainer>
              <GridItem md={8} style={{ marginBottom: "0px" }}>
                <L2TextField
                  id="userPhoneNo"
                  type={"text"}
                  labelname={"Your Phone number"}
                  onChange={(e) => {
                    setPhoneNo(e);
                  }}
                  disabled={false}
                  value={phoneNo}
                  clear={(e) => {
                    if (e) {
                      setPhoneNo("");
                    }
                  }}
                  mandatory={false}
                  logincss="logininput"
                  marginTop="6%"
                />
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem md={8} align="right">
                <Button
                  style={{
                    fontWeight: "600",
                    background: "#33234E",
                    padding: "10px 30px",
                    marginTop: "5px",
                    color: "white"
                  }}
                  onClick={() => onClickVerifyPhoneNo(phoneNo)}
                >
                  Verify
                </Button> 
              </GridItem>
            </GridContainer>
          </div>
        )}

        {
          currentStep === 1 && (
          <div>
            <GridContainer>
              <GridItem md={5} style={{ marginBottom: "10px" }}>
                <L2TextField
                    id="userOtpValue"
                    type={"text"}
                    labelname={"Your OTP Value"}
                    onChange={(e) => {
                      setOtpValue(e);
                    }}
                    disabled={false}
                    value={phoneNo}
                    clear={(e) => {
                      if (e) {
                        setOtpValue("");
                      }
                    }}
                    mandatory={false}
                    logincss="logininput"
                    marginTop="6%"
                  />
                </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem md={5} align="right">
                <Button
                  style={{
                    width: "55%",
                    fontWeight: "600",
                    background: "#33234E",
                    padding: "10px 30px",
                    color: "white",
                  }}
                  name={"verify"}
                  onClick={() => onClickVerifyOtpValue(phoneNo, otpValue)}
                >
                  Verify
                </Button> 
              </GridItem>
            </GridContainer>
          </div>
        )}

        {
          currentStep === 2 && (
          <div style={{ textAlign: "start" }}>
            <GridContainer>
              <div class="change-password tooltip-bottom"  style={{ paddingTop: "23px" }}>Rules
                <span class="tooltiptext tooltiptext-bottom">
                  <p> {"- minimum length – 8 characters"}</p>
                  <p> {"- maximum length – 64 characters"}</p>
                  <p> {"- at least 1 upper-case character (A-Z)"}</p>
                  <p> {"- at least 1 lower-case character (a-z)"}</p>
                  <p> {"- at least 1 numeric character (0-9)"}</p>
                  <p> {"- at least 1 specific character (@#$%_^&)"}</p>
                </span>
              </div> 
            </GridContainer>

            <GridContainer>
              <GridItem md={5} style={{ marginBottom: "10px" }}>
                <div style={{ margin: "0%", textAlign: "start" }}>
                  <L2TextField
                    id="Password"
                    marginTop="6%"
                    type={"password"}
                    labelname={"New Password"}
                    onChange={(e) => {
                      setNewPassword(e);
                    }}
                    disabled={false}
                    value={newPassword}
                    clear={(e) => {
                      if (e) {
                        setNewPassword("");
                      }
                    }}
                    mandatory={false}
                    keyPress={false}
                    logincss="logininput"
                    onKeyPress={() => {}}
                  />
                </div>
              </GridItem>

              <GridItem md={5}>
                <div style={{ margin: "0%", textAlign: "start" }}>
                  <L2TextField
                    id="Password"
                    marginTop="6%"
                    type={"password"}
                    labelname={"Confirm Password"}
                    onChange={(e) => {
                      setConfirmPassword(e);
                    }}
                    disabled={false}
                    value={confirmPassword}
                    clear={(e) => {
                      if (e) {
                        setConfirmPassword("");
                      }
                    }}
                    mandatory={false}
                    keyPress={false}
                    logincss="logininput"
                    onKeyPress={() => {}}
                  />
                </div>
              </GridItem>
            </GridContainer>
            
            <GridContainer>
              <GridItem md={5}></GridItem>
              <GridItem md={5} align="right">
                <Button
                  style={{
                    fontWeight: "600",
                    background: "#33234E",
                    padding: "10px 30px",
                    color: "white",
                  }}
                  onClick={() => onClickResetPassword(phoneNo, otpValue, newPassword, confirmPassword)}
                >
                  Reset password
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        )}
      </div>
    </div>
  );
}

ForgetPassword.propTypes = {
  classes: PropTypes.object,
};

export default ForgetPassword; 

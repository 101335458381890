import React, { useState, useEffect } from "react";

import "./assets/css/homeMediaQuery.css";
import { dashboardStyles } from "./assets/css/dashboardStyles";

// import Card from "../../../../../../components/Card/Card";
import Card from "../../../../../../components/Card/CardRoot";
// import CardBody from "../../../../../../components/Card/CardBody";
import CardBody from "../../../../../../components/Card/CardBodyRoot";
// import GridContainer from "../../../../../../components/Grid/GridContainer";
import GridContainer from "../../../../../../components/Grid/GridContainerRoot";
// import GridItem from "../../../../../../components/Grid/GridItem";
import GridItem from "../../../../../../components/Grid/GridItemRoot";

import { getDashboardStatistics } from "./actions/dashboardAction";

const Dashboard = (props) => {  
  const [providersCounter, setProvidersCounter] = useState(0);
  const [facilitiesCounter, setFacilitiesCounter] = useState(0);
  const [totalMeetingsCounter, setTotalMeetingsCounter] = useState(0);
  const [completedMeetingsCounter, setCompletedMeetingsCounter] = useState(0);
  const [chartOptions, setChartOptions] = useState(dashboardStyles);

  const userRole = localStorage.getItem("userRole");
  
  useEffect(() => {
    if (userRole !== "Administration") {
      return
    }
    
    getDashboardStatistics(
      setProvidersCounter,
      setFacilitiesCounter, 
      setTotalMeetingsCounter,
      setCompletedMeetingsCounter,
      setChartOptions,
      props.loading
    );
  }, []);

  return (
    <div className={props.classes.contentMain} id="main">
      <div className="activeDiv" id="divHome">
        {
          // localStorage.getItem('UserGroupName') == 'Admin' ? // todo was superadmin
          userRole !== "Administration" 
          ? null
          : ( // todo was superadmin
            <div>
              <Card style={{ 
                marginTop: "3%",
                background: "#FFF",
                boxShadow: "0 4px 20px 1px rgba(0,0,0,.06),0 1px 4px rgba(0,0,0,.08)", 
                borderRadius: "6px"
              }}>
                <CardBody className="dashcardb">
                  <GridContainer className="dgridcont">
                    <GridItem xs={12} sm={12} md={6} style={{ padding: "1% 2% 1% 0%" }}>
                      <GridContainer
                        style={{ margin: "0", width: "100%", padding: "0 0 0 10%" }}
                      >
                        <GridItem x={12} sm={6} md={6} style={{ padding: "0% 1% 1%" }}>
                          <Card style={{ margin: "0",
                          marginTop: "5px",
                background: "#FFF",
                boxShadow: "0 4px 20px 1px rgba(0,0,0,.06),0 1px 4px rgba(0,0,0,.08)",
                borderRadius: "6px" }}>
                            <CardBody style={{ textAlign: "center" }}>
                              <i
                                className="fas fa-users iconStyle"
                                style={{
                                  color: "#4d8af0",
                                  backgroundColor: "rgba(77,138,240,.2)",
                                }}
                              ></i>
                              <h4 className="cntfont">{providersCounter}</h4>
                              <p style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
                                Total Providers
                              </p>
                            </CardBody>
                          </Card>
                        </GridItem>
                        <GridItem x={12} sm={6} md={6} style={{ padding: "0% 1% 1%" }}>
                          <Card style={{ margin: "0",
                          marginTop: "5px",
                          background: "#FFF",
                boxShadow: "0 4px 20px 1px rgba(0,0,0,.06),0 1px 4px rgba(0,0,0,.08)",
                borderRadius: "6px" }}>
                            <CardBody style={{ textAlign: "center" }}>
                              <i
                                className="fas fa-trophy iconStyle"
                                style={{
                                  color: "#ff5f66",
                                  backgroundColor: "rgba(255,95,102,.2)",
                                }}
                              ></i>
                              <h4 className="cntfont">{facilitiesCounter}</h4>
                              <p style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
                                Total Facility
                              </p>
                            </CardBody>
                          </Card>
                        </GridItem>
                      </GridContainer>
                      <GridContainer
                        style={{ margin: "0", width: "100%", padding: "0 0 0 10%" }}
                      >
                        <GridItem x={12} sm={6} md={6} style={{ padding: "1% 1% 0%" }}>
                          <Card style={{ margin: "0",
                          marginTop: "5px",
                          background: "#FFF",
                boxShadow: "0 4px 20px 1px rgba(0,0,0,.06),0 1px 4px rgba(0,0,0,.08)",
                borderRadius: "6px" }}>
                            <CardBody style={{ textAlign: "center" }}>
                              <i
                                className="fas fa-fire iconStyle"
                                style={{
                                  color: "#f0825f",
                                  backgroundColor: "rgba(240,130,95,.2)",
                                }}
                              ></i>
                              <h4 className="cntfont">{totalMeetingsCounter}</h4>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontFamily: "sans-serif",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Total Consults Booked
                              </p>
                            </CardBody>
                          </Card>
                        </GridItem>
                        <GridItem x={12} sm={6} md={6} style={{ padding: "1% 1% 0%" }}>
                          <Card style={{ margin: "0",
                          marginTop: "5px",
                          background: "#FFF",
                boxShadow: "0 4px 20px 1px rgba(0,0,0,.06),0 1px 4px rgba(0,0,0,.08)",
                borderRadius: "6px" }}>
                            <CardBody style={{ textAlign: "center" }}>
                              <i
                                className="far fa-heart iconStyle"
                                style={{
                                  color: "#00e093",
                                  backgroundColor: "rgba(0,224,147,.2)",
                                }}
                              ></i>
                              <h4 className="cntfont">{completedMeetingsCounter}</h4>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontFamily: "sans-serif",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Total Consults Closed
                              </p>
                            </CardBody>
                          </Card>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </div>
          )
      }
      </div>
    </div>
  );
};

export default Dashboard;

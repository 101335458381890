import "react-infinite-calendar/styles.css";
import "react-toastify/dist/ReactToastify.css";

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useContext,
  useRef
} from "react";
import { useSelector } from "react-redux";

import { toast, ToastContainer } from "react-toastify";

import {
  initialStateUser
} from "../../Users/helpers/mutationUserHelper";

import {  
  statusesData,
} from "./Filters/helpers/filtersDataHelper";

import {  
  getInitialFiltersOptions,
  aggregateStateByFilterNames
} from "../../../components/PageFilters/helpers/filterHelper";

import {
  pendingMeetingsFilters
} from "../../Meetings/helpers/meetingsQueueTableHelper";

import {
  getMeetingsList,
  
} from "../../Meetings/actions/meetingsQueueTableAction";

import {
  clickFilterHandler
} from "../../../components/PageFilters/helpers/filterHelper";

import TableView from "../../../components/TableView/TableView";

import getSocketIOClient from "../../../services/socketIOClient";
import { MeetingsContext } from "../../Meetings/context/meetingsContextProvider";

import FilterSection from "./Filters/FilterSection";
import ProviderServiceReassignModal from "./ProviderServiceReassignModal";

import {
  checkInAction
} from "../actions/viewListProviderServicesAction";

import {
  VisibleItemSelect,
  tableColumnsList,
  excelColumnsList
} from "../helpers/tableProviderServicesHelper";

const ProviderMeetingsTable = forwardRef((props, ref) => {
  const reassingRef = useRef();

  const authStoreRedux = useSelector((state) => state.auth);

  const context = useContext(MeetingsContext);
  const { commonReactState, commonReactDispatch } = context;

  const [meetingsList, setMeetingsList] = useState([]);

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [pageFilters, setPageFilters] = useState(pendingMeetingsFilters);
  
  const [pagination, setPagination] = useState({
    pageNo: 1,
    getPerPage: 10,
    itemsTotalNumber: 0,
    pagesTotalNumber: 0
  });
  
  const [excelData, setExcelData] = useState([]);
  const [TableVisibleItem, setTableVisibleItem] = useState([]);
  const [TableBodyItem, setTableBodyItem] = useState([]);
  
  const stateName = 'providerServices';
  const filterSectionId = `${stateName}_FilterDiv`;
  const filterButtonId = `${stateName}_FilterDiv`;
  const tableId = `${stateName}_TableDiv`;

  const timeZone = localStorage.getItem("timeZone");
  const userRole = localStorage.getItem("userRole");
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    const providerId = userRole !== "Provider" ? null : userId;

    if (isFiltersOpen) {

      const optionsDataByFilterNames = {
        currentProceduralTerminologyCodes: JSON.parse(authStoreRedux.currentProceduralTerminologyCodesData),
        consultTypes: JSON.parse(authStoreRedux.consultTypesData),
        statuses: JSON.parse(statusesData),
        diagnosisCodes: context.diagnosisCodesList?.length > 0 ? context.diagnosisCodesList : []
      };
      const initialFiltersOptionsState = getInitialFiltersOptions(optionsDataByFilterNames);
      // const initialOptionsFilters = getInitialFiltersOptions(providerInitialStateFiltersConfig, authStoreRedux, context.diagnosisCodesList);
      // const meetingsFilters = getNormalizedState(providerInitialStateFiltersConfig, initialOptionsFilters, authStoreRedux, context.diagnosisCodesList);
      
      const optionsNamesConfig = {// key should be the same as optionsDataByFilterNames
        currentProceduralTerminologyCodes: [],
        consultTypes: [],
        statuses: [],
        diagnosisCodes: [],
      }
      const meetingsFilters = aggregateStateByFilterNames(initialFiltersOptionsState, optionsNamesConfig);
      setPageFilters(meetingsFilters);

      context.refreshTable(
        providerId, //commonReactState.currentProvider?.id,
        meetingsFilters
      );
    } else {
      !providerId && context.getCurrentProvider();
      
      setPageFilters(pendingMeetingsFilters);
      context.refreshTable(
        providerId,
        pendingMeetingsFilters
      );
    }    
  }, [isFiltersOpen]);

  useEffect(() => {
    VisibleItemSelect(
      tableColumnsList,
      setTableVisibleItem,
      setTableBodyItem
    );
  }, [
    JSON.stringify(
      tableColumnsList,
    ),
  ]);


  useEffect(() => {
    // const providerId = userRole == "Provider" ? userId : '';
    const providerId = userRole !== "Provider" ? null : userId;

    getMeetingsList(
      setMeetingsList,
      context,
      commonReactDispatch,
      {
        providerId,
        timeZone,//: authStoreRedux.timeZone,
        meetingsFilters: pageFilters//isFiltersOpen ? commonReactState.meetingsFiltersProvider : meetingsFiltersDefault
      },
      {          
        pageNo: pagination.pageNo,
        getPerPage: pagination.getPerPage,
        pagination,
        setPagination
      }
    );

    // userRole == "Provider" && context.refreshProviderStatistics(providerId)

    if (providerId && providerId !== '') {
      context.providersList.map((value) => {
        if (providerId === value.id) {

          commonReactDispatch({
            type: "currentProvider",
            payload: value,
          });
        }
      });
    }
  }, []);

  useImperativeHandle(ref, () => ({
    getCurrentProvider: (itemId = null) => {
      if (!itemId) {
        commonReactDispatch({
          type: "currentProvider",
          payload: initialStateUser,
        });
        return;
      }

      context.providersList.map((value) => {
        if (itemId === value.id) {
          commonReactDispatch({
            type: "currentProvider",
            payload: value,
          });
        }
      });
    },
    getCurrentModalItem: getCurrentModalItem,
    applySectionFilters: (sectionFilters) => {
      setPageFilters(sectionFilters);
    },
    getCurrentProviderId:()=>{
      const userRole = localStorage.getItem("userRole");
      const userId = localStorage.getItem("userId");
      const providerId = userRole !== "Provider" ? commonReactState.currentProvider.id : userId;

      return providerId
    },
    refreshTable: (
      providerId = null,
      currentMeetingsFilters = null
    ) => {
      const meetingsFilters = currentMeetingsFilters ? currentMeetingsFilters : pageFilters//

      getMeetingsList(
        setMeetingsList,
        context,
        commonReactDispatch,
        {
          providerId,
          timeZone,//: authStoreRedux.timeZone,
          // meetingsFilters: isFiltersOpen ? meetingsFilters : meetingsFiltersDefault
          meetingsFilters
        },
        {          
          pageNo: pagination.pageNo,
          getPerPage: pagination.getPerPage,
          pagination,
          setPagination
        }
      );
    },
  }));

  const getCurrentModalItem = (itemId) => {
    let item

    meetingsList.map((value) => {
      if (itemId === value.id) {

        item = value
      }
    });

    return item;
  }

  useEffect(() => {
    const apiToken = localStorage.getItem("actionToken");

    const socketIOClient = getSocketIOClient(apiToken);
    socketIOClient.on("MEETINGS_UPDATES", (item) => {
      // console.log("GOT SOCKET MEETINGS_UPDATES consult created item",item)
      // refreshHandler();

      const timeZone = localStorage.getItem("timeZone");
      const pageProviderId2 =  context.getCurrentProviderId()
      // console.log("GOT SOCKET MEETINGS_UPDATES consult created  pageProviderId2",pageProviderId2)

      getMeetingsList(
        setMeetingsList,
        context,
        commonReactDispatch,
        {
          providerId: pageProviderId2,// commonReactState.currentProvider.id,
          timeZone,//: authStoreRedux.timeZone,
          meetingsFilters: pageFilters// isFiltersOpen ? commonReactState.meetingsFiltersCallCenter : meetingsFiltersDefault
        },
        {          
          pageNo: pagination.pageNo,
          getPerPage: pagination.getPerPage,
          pagination,
          setPagination
        }
      );
    });

    // socketIOClient.on("Sent_SMS", (item) => {
    //   console.log("GOT SOCKET Sent_SMS message:",item)
    // });
  
    // socketIOClient.on("CronJob_Sent_SMS", (item) => {
    //   console.log("GOT SOCKET CronJob_Sent_SMS message:",item)
    // });
  }, []);

  const onClickFilterHandler = () => {
    clickFilterHandler(isFiltersOpen, setIsFiltersOpen, tableId, filterButtonId, filterSectionId);
  }
 
  const openReassignMeetingModalHandler = (item) => {

    const meeting = getCurrentModalItem(item.id);
    // setReassignMeeting(meeting);
    reassingRef.current.openReassignModal(meeting);
  };
  
  const closeReassignMeetingModalHandler = () => {
    reassingRef.current.closeReassignModal();
    // setReassignMeeting(null);

    // if (authStoreRedux.userRole == "Provider") {
    //   context.refreshProviderStatistics(commonReactState.currentProvider.id)
    // }
  }; 
 
  const checkInHandler = async (item) => {
    await checkInAction(item, context, toast);
    // const providerId = authStoreRedux.userRole == "Provider" 
    //     ? authStoreRedux.userId : commonReactState.currentProvider.id 
    //       ? commonReactState.currentProvider.id : '';  
    const providerId = userRole == "Provider" 
      ? userId : commonReactState.currentProvider.id 
        ? commonReactState.currentProvider.id : '';  

    getMeetingsList(
      setMeetingsList,
      context,
      commonReactDispatch,
      {
        providerId: providerId,
        timeZone,//: authStoreRedux.timeZone,
        meetingsFilters: pageFilters//isFiltersOpen ? commonReactState.meetingsFiltersProvider : meetingsFiltersDefault
      },
      {          
        pageNo: 1,
        getPerPage: 10,
        pagination,
        setPagination
      }
    );
  }

  const onClickAdd = () => context.openModalMutation();

  const tableViewHeaderParams = {
    tableName: 'Consults in Queue',
    itemsCountOnPage: meetingsList.length,//todo delete
    itemsTotalNumber: pagination.itemsTotalNumber,
    onClickAdd,
    skipButtonAdd: true,
    skipButtonSearch: true
  }

  const tableContainerParams = {};

  const refreshHandler = (providerIdInput) => {
    // console.log("refreshHandler SONE")

    // const providerId = (providerIdInput && typeof providerIdInput === 'string') 
    //   ? providerIdInput : authStoreRedux.userRole == "Provider" 
    //     ? authStoreRedux.userId : commonReactState.currentProvider.id 
    //       ? commonReactState.currentProvider.id : '';    
    
    const providerId = (providerIdInput && typeof providerIdInput === 'string') 
      ? providerIdInput : userRole == "Provider" 
        ? userId : commonReactState.currentProvider.id 
          ? commonReactState.currentProvider.id : '';    
    
    getMeetingsList(
      setMeetingsList,
      context,
      commonReactDispatch,
      {
        providerId: providerId,
        timeZone,//: authStoreRedux.timeZone,
        meetingsFilters: pageFilters//isFiltersOpen ? commonReactState.meetingsFiltersProvider : meetingsFiltersDefault
      },
      {          
        pageNo: 1,
        getPerPage: 10,
        pagination,
        setPagination
      }
    );
  }

  const getExcelExportDataHandler = () => {
    const providerId = userRole == "Provider" 
        ? userId : commonReactState.currentProvider.id 
          ? commonReactState.currentProvider.id : '';  

    getMeetingsList(
      setExcelData,
      context,
      commonReactDispatch,
      {
        providerId: providerId,
        timeZone,//: authStoreRedux.timeZone,
        meetingsFilters: pageFilters//isFiltersOpen ? commonReactState.meetingsFiltersProvider : meetingsFiltersDefault,
      },
      {          
        pageNo: pagination.pageNo,
        getPerPage: 100000, // get all
        pagination,
        setPagination: ()=>{}
      }
    );
  }

  const tableToolbarParams = {
    tableHeaderStyles: {}, 
    refreshHandler,
    excelExportButton: {
      title:"Provider Consult",
      excelData,
      excelColumnsList,
      getExcelExportDataHandler              
    },
    filterButton: {
      onClickFilterHandler,
      filterButtonId
    }
  }

  const onChangePageHandler = (nextPageNo, nextGetPerPage, tablePaginationContainerParams) => {
    getMeetingsList(
      setMeetingsList,
      context,
      commonReactDispatch,
      {
        providerId: commonReactState.currentProvider.id,
        timeZone,//: authStoreRedux.timeZone,
        meetingsFilters: pageFilters//isFiltersOpen ? commonReactState.meetingsFiltersProvider : meetingsFiltersDefault
      },
      {          
        pageNo: nextPageNo,
        getPerPage: nextGetPerPage,
        pagination: tablePaginationContainerParams.pagination,
        setPagination: tablePaginationContainerParams.setPagination
      }
    );
  }

  const tablePaginationContainerParams = {
    setItemsList: setMeetingsList,
    context,
    commonReactDispatch,
    pagination,
    setPagination,
    onChangePageHandler
    // TableTotalCount,
    // page,
    // handleChangePage,
    // handleChangeRowsPerPage,
  }
  
  const tableBodyParams = {
    tableId: 'AppointmentRegistryTable',
    TableVisibleItem,
    bodyData: meetingsList,
    TableBodyItem,
    isRowColorCalculated: true,
    isActionsVisible: true,
    editHandler: (row) => context.openModalMutation(row.id),
    isReassignVisible: (userRole == "Administration" || userRole == "Provider") && true,
    isReasonForReassignVisible: (userRole == "Administration" || userRole == "Provider") && true,
    reassignHandler: openReassignMeetingModalHandler,
    isCheckinVisible: (userRole == "Administration" || userRole == "Provider") && true,
    checkinHandler: checkInHandler,
  }

  return (   
    <div className="tableCard">
      <div
        className="displayFlex padding0"
        style={{ overflow: "auto" }}
      >
        
        {isFiltersOpen && (
          <FilterSection             
            stateName={stateName}
          />
        )}

        <div
          id={tableId}
          style={{ width: "100%", position: "relative" }}
        >
          <TableView 
            tableViewHeaderParams = {tableViewHeaderParams}
            tableContainerParams = {tableContainerParams}
            tableToolbarParams = {tableToolbarParams}
            tablePaginationContainerParams = {tablePaginationContainerParams}
            tableBodyParams = {tableBodyParams}
          />
          
          <ProviderServiceReassignModal      
            ref={reassingRef} 
            closeReassignMeetingModalHandler={closeReassignMeetingModalHandler}
            refreshHandler={refreshHandler}
          />
        </div>
      </div>
    </div>
  );
});

export default ProviderMeetingsTable;

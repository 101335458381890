import "react-infinite-calendar/styles.css";
import "react-modern-calendar-datepicker/lib/DatePicker.css";

import React, { 
  useReducer,
  useEffect,
  useContext
} from "react";
import { useSelector } from "react-redux";

import mutationReactReducer from "../../../../reactReducers/mutationReactReducer";
import { DiagnosisCodesContext } from "../../context/diagnosisCodesContextProvider";

import {
  statusesData,
  defaultFilters
} from "./helpers/filtersDataHelper";

import {
  getInitialFiltersOptions,
  aggregateStateByFilterNames
} from "../../../../components/PageFilters/helpers/filterHelper";

import FilterOptions from "../../../../components/PageFilters/FilterOptions";
import FilterSectionHeader from "../../../../components/PageFilters/FilterSectionHeader";

const FilterSection = (props) => {
  const authStoreRedux = useSelector((state) => state.auth);

  const context = useContext(DiagnosisCodesContext);
  const optionsDataByFilterNames = {
    statuses: JSON.parse(statusesData),
  };
  const initialFiltersOptionsState = getInitialFiltersOptions(optionsDataByFilterNames);
  const [state, dispatch] = useReducer(mutationReactReducer, initialFiltersOptionsState);

  const filterSectionId = `${props.stateName}_FilterDiv`;
  
  useEffect(() => {
    document.getElementById(
      filterSectionId,
    ).style.width = "25%";
    
    document.getElementById(
      filterSectionId,
    ).style.display = "block";
  }, []);

  useEffect(() => {
    const optionsNamesConfig = { // key should be the same as optionsDataByFilterNames
      statuses: [],
    }
    const stateFilters = aggregateStateByFilterNames(state, optionsNamesConfig);

    context.applySectionFilters(stateFilters);
    context.refreshTable(stateFilters);
  }, [state]);

  const onClickClear = () => {
    const optionsDataByFilterNames = {
      statuses: JSON.parse(statusesData),
    };
    const initialFiltersOptionsState = getInitialFiltersOptions(optionsDataByFilterNames);

    dispatch({
      type: "set_entire_state",
      value: initialFiltersOptionsState,
    });
   
    context.refreshTable(defaultFilters);
  }

  return (
    <div
      id={filterSectionId}
      style={{
        width: "0%",
        display: "none",
        border: "1px solid #e3e4e9",
        marginRight: "1%",
      }}
    >
      <FilterSectionHeader 
        onClickClear={onClickClear}
      />    
    
      { 
        <FilterOptions
          filterOptionsName={'Status'}
          filterOptionsData={statusesData}
          filterOptionsTag={'statuses'}
          state={state}
          dispatch={dispatch}
        />
      }

    </div>     
  );
};

export default FilterSection;

import React, { useRef,
  useEffect, useState } from "react";

import Tooltip from "@material-ui/core/Tooltip";
import PresentToAllIcon from "@material-ui/icons/PresentToAll";

import ExcelDownloadButton from "./ExcelDownloadButton";

const ExcelExportButton = (props) => {
  const refExcelDownload = useRef();
  const [triggerApi, setTriggerApi] = useState(false);
  const [dataSet, setDataSet] = useState([]);
  // let dataSet
  useEffect(() => {
    if (triggerApi && props.excelData.length > 0) {
      setTriggerApi(false);

      const dataRaw = [...props.excelData]
      delete dataRaw.facilities
      delete dataRaw.patients
      delete dataRaw.patientsLinkFacilities

          // resultval.map((value, i) => {
          //   let keys = [];
          //   tableRow.forEach((x) => {
          //     keys.push(value[x]);
          //   });
          //   exportItem.push(keys);
          // });

          const data = [];
          const tableColumnsKeys = props.excelColumnsList.map(item => item.FieldName);
          const tableColumnsHeaders = props.excelColumnsList.map(item => item.FieldDescription);

          dataRaw.forEach(value => {
            const row = [];

            tableColumnsKeys.forEach((x) => {
              row.push(value[x]);
            });

            data.push(row); 
          })

      setDataSet([
        {
          columns: tableColumnsHeaders,
          data,
        },
      ]);
    }

  }, [props.excelData]);

  useEffect(() => {
    if ( dataSet.length > 0) {
      refExcelDownload.current.click();
    }
  }, [dataSet]);

  return (
    <div>
      <div style={{ display: "flex" }}>
        {
          // !props.disabledExport &&
          // props.exportData &&
          <div>
            <Tooltip title="Excel Export" placement="top">
              <PresentToAllIcon
                className="tableFilterColumnIcon tablExpicon fontSize18"
                onClick={() => {
                  props.triggerApi();  
                  setTriggerApi(true)
                }}
              />
            </Tooltip>
          </div>
        }
        {
          // props.exportData &&
          // dataSet &&
          <ExcelDownloadButton
            title={props.title}
            dataSet={dataSet}
            refExcelDownload={refExcelDownload}
          />
        }
      </div>
    </div>
  );
};

export default ExcelExportButton; 

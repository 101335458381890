const userRolesDataInit = [
    {
      id: "Administration",
      label: "Administration",
      value: "Administration",
    },
    {
      id: "Provider",
      label: "Provider",
      value: "Provider",
    },
    {
      id: "Call_Center",
      label: "Call center operator",
      value: "Call_Center",
    },
    {
      id: "Billing",
      label: "Billing",
      value: "Billing",
    },
];

export { userRolesDataInit };

const isValidMutationInputCancel = (state, context) => {
  if (!state.cancelReason || state.cancelReason == '') {
    context.alert({
      open: true,
      color: "error",
      message: ["Please select the Reason"],
    });
    return false;
  }

  if ((!state.cancelRemarks || state.cancelRemarks == '') && state.cancelReason == 'Other') {
    context.alert({
      open: true,
      color: "error",
      message: ["Please select the Remarks"],
    });
    return false;
  }

  return true;
}

const isValidMutationInput = (state, context) => {
  if (!state.userId || state.userId == 0) {
    context.alert({
      open: true,
      color: "error",
      message: ["Please select the Provider"],
    });
    return false;
  }

  if (!state.facilityId || state.facilityId == 0) {
    context.alert({
      open: true,
      color: "error",
      message: ["Please select the Facility"],
    });
    return false;
  }

  if (!state.consultType || state.consultType == 0) {
    context.alert({
      open: true,
      color: "error",
      message: ["Please select the Consult Type"],
    });
    return false;
  }

  if (!state.communicationMethod || state.communicationMethod == 0) {
    context.alert({
      open: true,
      color: "error",
      message: ["Please select the Communication Method"],
    });
    return false;
  }

  if (state.communicationMethod == "Cart" && (!state.cartNumber || !state.cartNumber.trim())) {
    context.alert({
      open: true,
      color: "error",
      message: ["Please enter Cart Name/Number"],
    });
    return false;
  }

  if (!state.siteCallbackNumber || !state.siteCallbackNumber.trim()) {
    context.alert({
      open: true,
      color: "error",
      message: ["Please enter the Site Callback Number"],
    });
    return false;
  }

  if (!state.siteContactName || !state.siteContactName.trim()) {
    context.alert({
      open: true,
      color: "error",
      message: ["Please enter the Site Contact Name"],
    });
    return false;
  }
  
  if (!state.firstName || !state.firstName.trim()) {
    context.alert({
      open: true,
      color: "error",
      message: ["Please enter the Patient First Name"],
    });
    return false;
  }

  if (!state.lastName || !state.lastName.trim()) {
    context.alert({
      open: true,
      color: "error",
      message: ["Please enter the Patient Last Name"],
    });
    return false;
  }

  if (!state.birthDate || !state.birthDate.trim() || state.birthDate == 0 || state.birthDate == "Invalid Date") {
    context.alert({
      open: true,
      color: "error",
      message: ["Please enter the Patient Birth Date"],
    });
    return false;
  }

  if (!state.medicalRecordNumber || !state.medicalRecordNumber.trim()) {
    context.alert({
      open: true,
      color: "error",
      message: ["Please enter the Medical Record Number"],
    });
    return false;
  }

  return true;
};

export { isValidMutationInput, isValidMutationInputCancel };

const isValidInputPassword = (
  newPasswordObject,
  setMessage,
) => {
  if (!newPasswordObject.Old) {
    setMessage({
      open: true,
      color: "error",
      message: [["Old Password is Required"]],
    });
    return false;
  }

  if (!newPasswordObject.New) {
    setMessage({
      open: true,
      color: "error",
      message: [["New Password is Required"]],
    });
    return false;
  }

  if (!newPasswordObject.Confirm) {
    setMessage({
      open: true,
      color: "error",
      message: [["Confirm Password is Required"]],
    });
    return false;
  }

  if (newPasswordObject.New != newPasswordObject.Confirm) {
    setMessage({
      open: true,
      color: "error",
      message: [["Passwords did not match"]],
    });
    return false;
  }

  if (newPasswordObject.New == newPasswordObject.Old) {
    setMessage({
      open: true,
      color: "error",
      message: [["Current password can not be used as a new one"]],
    });
    return false;
  }

  return true;
};

export { isValidInputPassword };

const initialStateMutationUser = {
  id: "",
  firstName: "",
  lastName: "",
  middleName: "",
  username: "",
  password: "",
  userRole: "",
  designation: "",
  speciality: "",
  gender: "",
  birthDate: "",
  phoneNo: "",
  email: "",
  timeZone: "",
  isActive: true
}

const initialStateUser = {
  id: "",
  firstName: "",
  lastName: "",
  middleName: "",
  username: "",
  designation: "",
  speciality: "",
  gender: "",
  birthDate: "",
  phoneNo: "",
  email: "",
  timeZone: "",
}

export { initialStateMutationUser, initialStateUser };

import { 
  setupDispatchReduxOnLogin,
  setupLocalStorageOnLogin
} from "../../../helpers/loginHelpers";
import { config } from "../../../../../../../configs/config";
import { requestAxios } from "../../../../../../../services/axiosClient";

const otpAction = async (
  otpValue,
  username,
  password,
  setLoading,
  setMessage,
  history,
  dispatchRedux,
) => {
  setLoading(true);

  const url = "verify-otp";

  const params = {
    otpValue,
    username,
    password,
  };
  const apiUrl = `${config.API_URL}/${url}`;

  const [response, responseError] = await requestAxios(
    apiUrl,
    params,
    dispatchRedux,
  );
  if (!response || responseError) {
    const errorMessage = responseError?.message
      ? responseError?.message
      : "Bad Request";

    setMessage({ open: true, color: "error", message: [errorMessage] });
    setLoading(false);

    return;
  }

  localStorage.setItem("actionToken", response.actionToken);
  localStorage.setItem("refreshToken", response.refreshToken);
  
  setupLocalStorageOnLogin(response);
  setupDispatchReduxOnLogin(response, dispatchRedux);
  setMessage({ open: true, color: "success", message: ["Login success"] });

  setLoading(false);
  history.push("/Home");
};

export { otpAction };

import { format, differenceInMinutes } from "date-fns";


import { 
  formateDatePickerDate,
  formateDateOnPick,
  getLocalizedDateFrom,
  getLocalizedDateCurrent,
  getUtcDateCurrent,
  getUtcDateFrom,
  getUtcDate,
  getZuluCurrent
} from "../../../helpers/utils";

import {
  zones
} from "../helpers/meetingsQueueTableHelper";

import {
  getMeetingAction,
  getMeetingsListAction,
  searchMeetingsAction,
  createMeetingAction,
  updateMeetingAction,
  deleteMeetingAction,
  getProviderStatisticsAction,
  api2FrontMapper,
  front2ApiMapper,
} from "./actions";

// const searchData = (
//   param,
//   val,
//   type,
//   date,
//   timeZone,
//   empId,
//   context,
//   setTableTotal,
//   setTableTotalCount,
//   cdispatch,
//   setAppointmentRegistryData,
//   setAppointmentData,
//   setGridPagination,
//   cstate,
//   props,
//   config,
// ) => {
// }

const getPagesTotalNumber = (responseTotalCount, getPerPage) => {
  const totalRow = (responseTotalCount > 0) 
    ? parseFloat(responseTotalCount, 10) / 10
    : 0;
    
  let pagesTotalNumber = 0;
  if (getPerPage == 10) {
    pagesTotalNumber = Math.ceil(totalRow);
  } else if (getPerPage == 20) {
    pagesTotalNumber = Math.ceil(totalRow / 2);
  } else if (getPerPage == 50) {
    pagesTotalNumber = Math.ceil(totalRow / 5);
  } else if (getPerPage == 100) {
    pagesTotalNumber = Math.ceil(totalRow / 10);
  }

  return pagesTotalNumber;
}

const isValidTimeZone = (rawDate, fieldName, context, timeZone) => {
  if (!rawDate) {
    return false;
  }
  const itemDate = rawDate.replace("Z", zones[timeZone]);

  if (new Date(itemDate) == "Invalid Date") {
    // console.log(`${fieldName} Invalid TimeZone`)
    // context.alert({
    //   open: true,
    //   color: "error",
    //   message: [`${fieldName} Invalid TimeZone`],
    // });

    return false;
  }

  return true;
}

//searchData
const getMeetingsList = async (
  setMeetingsList,
  context,
  commonReactDispatch,
  itemsFilters,
  currentPaginationParams,
) => {
  const response = await getMeetingsListAction(
    itemsFilters,
    currentPaginationParams,
    context.alert,
    context.loading,
  );

  // const result = response.nodes.map((item) => api2FrontMapper(item));
  const items = response?.nodes ? response?.nodes : [];

  if (!response || !items || items?.length <= 0) {
    currentPaginationParams.setPagination({ 
      pageNo: response?.pageInfo.pageNo ? response?.pageInfo.pageNo : 1, 
      getPerPage: response?.pageInfo.getPerPage ? response?.pageInfo.getPerPage : 10,
      itemsTotalNumber: 0, 
      pagesTotalNumber: 0 
    });
    setMeetingsList([]);

    return;
  }

  const pagesTotalNumber = getPagesTotalNumber(response.totalCount, currentPaginationParams.getPerPage);
    
  currentPaginationParams.setPagination({ 
    pageNo: response.pageInfo.pageNo, 
    getPerPage: response.pageInfo.getPerPage, 
    itemsTotalNumber: response.totalCount, 
    pagesTotalNumber 
  });
  // commonReactDispatch({ type: "count", payload: items.length }); 

  const result = items.map(function (item) {
    // if (isValidTimeZone(item.createdAt, 'createdAt', context, itemsFilters.timeZone)) {
      // return false;
      // console.log('inValidTimeZone createdAt')
    // }           
//ConStartTime
    // if (isValidTimeZone(item.startTime, 'startTime', context, itemsFilters.timeZone)) {
      // return false;
      // console.log('inValidTimeZone startTime')

    // }
//ConEndTime
    // if (isValidTimeZone(item.endTime, 'endTime', context, itemsFilters.timeZone)) {
      // return false;
      // console.log('inValidTimeZone endTime')
    // }

    // const o = Object.assign({}, item);
    item.consultNo = item.id//item.SNo + 1 + " " + item.meetingDateTime;
    // o.EmpRealName = item.EmpFirstName;
    // o.EmpFirstName = item.EmpFirstName + " " + item.EmpLastName;
      
    const startTimeLocalized = getLocalizedDateFrom(item.startTime, itemsFilters.timeZone); 
    const endTimeLocalized = getLocalizedDateFrom(item.endTime, itemsFilters.timeZone); 
    const createdAtLocalized = getLocalizedDateFrom(item.createdAt, itemsFilters.timeZone); 

     const zuluCurrent = getZuluCurrent(itemsFilters.timeZone);

    //  console.log("getMeets createdAt ",item.createdAt)

    //  console.log("getMeets zuluCurrent",zuluCurrent)
    //  console.log("getMeets new Date(zuluCurrent",new Date(zuluCurrent))

    //  console.log("getMeets new Date( item.createdAt)",new Date( item.createdAt))

     const waitingTimeCalculated = (item.status !== "Not Started" && item.waitingTime && item.waitingTime > 0) 
      ? item.waitingTime 
      : differenceInMinutes(new Date(zuluCurrent), new Date( item.createdAt));

    //  console.log("getMeets waitingTime CALC format",waitingTimeCalculated)

    return {
      ...item,
      startTime: startTimeLocalized,
      endTime: endTimeLocalized,
      createdAt: createdAtLocalized,
      waitingTime: waitingTimeCalculated
    };
  });

  setMeetingsList(result);
};  
         
async function searchMeetings(
  searchInputParam,
  setMeetingsList,
  context,
  commonReactDispatch,
  currentPaginationParams,
  pagination,
  setPagination
) {
  const response = await searchMeetingsAction(
    searchInputParam,
    context.alert,
    context.loading,
  );

  const items = response//.map((item) => api2FrontMapper(item));

  if (!items || items?.length <= 0) {
    // setPagination({ 
    //   pageNo: response.pageInfo.pageNo, 
    //   getPerPage: response.pageInfo.getPerPage, 
    //   itemsTotalNumber: 0, 
    //   pagesTotalNumber: 0 
    // });
    setMeetingsList([]);

    return;
  }
  
  // commonReactDispatch({ type: "count", payload: items.length });

  setMeetingsList(items);
}

export {
  getMeetingsList,
  searchMeetings,
};

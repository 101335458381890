

import {
  openNewTab,
  listMenuActive,
  listClassDivBtn,
  subMenuActiveSwitcher,
  toggleSidebarWidth
} from "./helpers/sideMenuHelpers";

import RootMenuItem from "./RootMenuItem";
import NextLevelMenuList from "./NextLevelMenuList";

const RootMenuList = (props) => {
  return props.UserMenuDetails.map((currentMenuItem) => {
    const isRootMenuItemArray = currentMenuItem?.Sub1.length > 0;

    const clickHandler = isRootMenuItemArray
      ? (e) => {
          listClassDivBtn(e, props.UserMenuDetails);

          listMenuActive(
            currentMenuItem.MenuID,
            props.openedMenuId,
            props.UserMenuDetails
          );

          toggleSidebarWidth(
            true,
            props.isSideMenuHasMinWidth
          );

          props.setOpenedMenuId(currentMenuItem.MenuID);          
        }
      : () => {
        openNewTab(
          currentMenuItem,
          props.setMessage,
          props.openedTabsList,
          props.setOpenedTabsList,
          props.setLoading,
          props.setConfirmationAlert,
          props.store,
          props.persistor,
          props.closeTabHandler
        );

        subMenuActiveSwitcher(props.UserMenuDetails, currentMenuItem);
      };

    return (
      <div key={currentMenuItem.MenuID}>        
        <RootMenuItem 
          clickHandler={clickHandler}
          isSideMenuHasMinWidth={props.isSideMenuHasMinWidth}
          rootMenuItem={currentMenuItem}
        />          
        {
          isRootMenuItemArray 
            ? <NextLevelMenuList
                rootMenuItem={currentMenuItem}
                isCollapseButtonOpen={props.isCollapseButtonOpen}
                subMenuItemIdExpanded={props.subMenuItemIdExpanded}
                setSubMenuItemIdExpanded={props.setSubMenuItemIdExpanded}
                collabseAllOnClick={props.collabseAllOnClick}
                setMessage={props.setMessage}
                openedTabsList={props.openedTabsList}
                setOpenedTabsList={props.setOpenedTabsList}
                setLoading={props.setLoading}
                setConfirmationAlert={props.setConfirmationAlert}
                dispatch={props.dispatch}
                store={props.store}
                persistor={props.persistor}
                closeTabHandler={props.closeTabHandler}
              />
            : null
        }
      </div>
    )
  })
}

export default RootMenuList;

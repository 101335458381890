import {
  updateMeetingAction,
  front2ApiMapper
} from "../../Meetings/actions/actions";

const reassignAction = async (state, toast, context) => {
  const item = front2ApiMapper(state);

  const result = await updateMeetingAction(
    { 
      ...item,
      checkedIn: false
    },
    context.alert,
    context.loading,
  );

  if (result) {
    toast.success("Reassigned successfully")
  } else {
    toast.error('toast err');
  }
}

export { 
  reassignAction 
};

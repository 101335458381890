import {
  getUserAction,
  getListUserAction,
  searchUserAction,
  createUserAction,
  updateUserAction,
  deleteUserAction,
  api2FrontMapper,
  front2ApiMapper,
} from "./actions";

const getPagesTotalNumber = (responseTotalCount, getPerPage) => {
  const totalRow = (responseTotalCount > 0) 
    ? parseFloat(responseTotalCount, 10) / 10
    : 0;
    
  let pagesTotalNumber = 0;
  if (getPerPage == 10) {
    pagesTotalNumber = Math.ceil(totalRow);
  } else if (getPerPage == 20) {
    pagesTotalNumber = Math.ceil(totalRow / 2);
  } else if (getPerPage == 50) {
    pagesTotalNumber = Math.ceil(totalRow / 5);
  } else if (getPerPage == 100) {
    pagesTotalNumber = Math.ceil(totalRow / 10);
  }

  return pagesTotalNumber;
}
  
const getUsersList = async (
  setUsersList,
  context,
  itemsFilters,
  currentPaginationParams,
) => {
  const response = await getListUserAction(
    itemsFilters,
    currentPaginationParams,
    context.alert,
    context.loading,
  );
  const items = response?.nodes

  if (!response || !items || items?.length <= 0) {
    currentPaginationParams.setPagination({ 
      pageNo: response?.pageInfo.pageNo ? response?.pageInfo.pageNo : 1, 
      getPerPage: response?.pageInfo.getPerPage ? response?.pageInfo.getPerPage : 10,
      itemsTotalNumber: 0, 
      pagesTotalNumber: 0 
    });
    setUsersList([]);

    return;
  }

  const pagesTotalNumber = getPagesTotalNumber(response.totalCount, currentPaginationParams.pagination.getPerPage);
    
  currentPaginationParams.setPagination({ 
    pageNo: response.pageInfo.pageNo, 
    getPerPage: response.pageInfo.getPerPage, 
    itemsTotalNumber: response.totalCount, 
    pagesTotalNumber 
  });

  setUsersList(items);
};

async function searchUsers(
  searchInputParam,
  setUsersList,
  context,
  commonReactDispatch,
  currentPaginationParams,
  pagination,
  setPagination
) {
  const response = await searchUserAction(
    searchInputParam,
    context.alert,
    context.loading,
  );

  const items = response//.map((item) => api2FrontMapper(item));

  if (!items || items?.length <= 0) {
    // setPagination({ 
    //   pageNo: response.pageInfo.pageNo, 
    //   getPerPage: response.pageInfo.getPerPage, 
    //   itemsTotalNumber: 0, 
    //   pagesTotalNumber: 0 
    // });
    setUsersList([]);

    return;
  }
  
  setUsersList(items);
}

export { getUsersList, searchUsers };

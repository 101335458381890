const initialStateMutationFacility = {
  id: "",
  facilityCode: "",
  facilityName: "",
  contactPerson: "",
  contactPersonPhoneNo: "",
  contactPersonEmail: "",
  address: "",
  zipCode: "",
  city: "",
  region: "",
  country: "",
  isActive: true,
}

export {
  initialStateMutationFacility,
};

import React, {
  forwardRef,
  useState,
} from "react";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextArea from "../../../components/_helperComponents/TextArea";

// import GridContainer from "../../../components/Grid/GridContainer";
import GridContainer from "../../../components/Grid/GridContainerRoot";
// import GridItem from "../../../components/Grid/GridItem";
import GridItem from "../../../components/Grid/GridItemRoot";

import {
  refreshProviderStatistics
} from "../../Meetings/actions/statisticsMeetingsAction";

const MeetingReassignFormBodyMutation = forwardRef((props, ref) => {
  const [isSelectProviderModalOpen, setIsSelectProviderModalOpen] = useState(false); 
  const [currentProvider, setCurrentProvider] = useState(null);
  const [queuedMeetingsCounter, setQueuedMeetingsCounter] = useState(0);
  const [reasonForReassign, setReasonForReassign] = useState("");

  return (
    <div>
      <GridContainer
          spacing={2}
          alignItems="center"
          style={{
            width: "100%",
            margin: "0",
            padding: "1rem",
            justifyContent: "center",
          }}
        >
          <GridItem xs={12} sm={12} md={8} lg={8}>
            <Autocomplete
              options={props.modalBodyParams.providersList}
              onOpen={() => {
                setIsSelectProviderModalOpen(true);
              }}
              onClose={() => {
                setIsSelectProviderModalOpen(false);
              }}
              onChange={(e, value) => {
                props.modalBodyParams.dispatch({
                  type: "text",
                  name: "userId",
                  value: value?.id ? value.id : '',
                });
                props.modalBodyParams.dispatch({
                  type: "text",
                  name: "provider",
                  value: value?.firstName ? `${value.firstName} ${value.lastName}` : '',
                });

                const providerIdCalculated = value?.id ? value.id : null
                refreshProviderStatistics(
                  providerIdCalculated,
                  ()=>{},
                  ()=>{},
                  ()=>{},
                  ()=>{},
                  setQueuedMeetingsCounter
                );

                setCurrentProvider(value);
              }}
              getOptionLabel={(options) =>
                `${options.firstName} ${options.lastName}`
              }
              open={isSelectProviderModalOpen}
              id="Reassign-search"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Provider"
                  margin="none"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </GridItem>
        </GridContainer>
        {currentProvider ? (
          <GridContainer
            spacing={2}
            alignItems="center"
            style={{
              width: "100%",
              margin: "0",
              padding: "1rem",
              background: "#C0EEF2",
              justifyContent: "center",
            }}
          >
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <p>
                Name:{" "}
                <span style={{ fontWeight: "bold", marginLeft: "10px" }}>
                  {" "}
                  {`${currentProvider?.firstName} ${currentProvider?.lastName}`}
                </span>
              </p>
            </GridItem>
            <GridItem xs={12} sm={12} md={3} lg={3}>
              <p>
                Consult in Queue:{" "}
                <span style={{ fontWeight: "bold", marginLeft: "10px" }}>
                  {queuedMeetingsCounter}
                </span>
              </p>
            </GridItem>
            <GridItem xs={12} md={12} lg={12} sm={12}>
              <TextArea
                SPName="MeetingsDictionary"
                FieldName="reasonForReassign"
                onChange={(e) => {
                  props.modalBodyParams.dispatch({
                    type: "text",
                    name: "reasonForReassign",
                    value: e,
                  });
                  setReasonForReassign(e)
                }}
                clear={(e) => {
                  if (e) {
                    props.modalBodyParams.dispatch({
                      type: "text",
                      name: "reasonForReassign",
                      value: "",
                    });
                    setReasonForReassign("")
                  }
                }}
                value={reasonForReassign}
                disabled={false}
              />
            </GridItem>
          </GridContainer>
        ) : (
          ""
        )}
    </div>
  );
});

export default MeetingReassignFormBodyMutation;
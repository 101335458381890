import React, { useEffect, useReducer, useState,
  useImperativeHandle,
  useContext,
  forwardRef
} from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import ModalWindowMutation from "../../../components/ModalWindow/ModalWindowMutation";
import ModalHeaderMutation from "../../../components/ModalWindow/ModalHeaderMutation";
import ModalFooterMutation from "../../../components/ModalWindow/ModalFooterMutation";

import {
  getProvidersList
} from "../../Users/actions/viewProvidersListAction";

import {
  initialStateMutationMeeting,
} from "../../Meetings/helpers/mutationMeetingHelpers";
import { isValidReassingInput } from "../validations/mutationMeetingValidator";

import mutationReactReducer from "../../../reactReducers/mutationReactReducer";
import { MeetingsContext } from "../../Meetings/context/meetingsContextProvider";
import MeetingReassignFormBodyMutation from "./MeetingReassignFormBodyMutation";

import {
  reassignAction,
} from "../actions/reassignModalProviderServiceAction";

const MeetingReassignModalMutation = forwardRef((props, ref) => {

  const [state, dispatch] = useReducer(mutationReactReducer, initialStateMutationMeeting);

  const [isModalOpen, setIsModalOpen] = useState(false); 

  const authStoreRedux = useSelector((state) => state.auth);
  const localStorageUserId = localStorage.getItem("userId");
  const localStorageUserRole = localStorage.getItem("userRole");

  const context = useContext(MeetingsContext);
  const { commonReactState, commonReactDispatch } = context;

  const [providersList, setProvidersList] = useState([]);
  const [providersPagination, setProvidersPagination] = useState({
    pageNo: 1,
    getPerPage: 100,
    itemsTotalNumber: 0,
    pagesTotalNumber: 0
  });

  const defaultFilters = {
    statuses: {
      'Active': "true",
      'Inactive': "false"
    }
  }

  useImperativeHandle(ref, () => ({
    openReassignModal: (meeting) => {
//todo
      // setReassignMeeting(item)
      dispatch({
        type: "set_entire_state",
        value: meeting,
      });
      dispatch({
        type: "text",
        name: "reasonForReassign",
        value: "",
      });
      setIsModalOpen(true);
    },
    closeReassignModal: () => {
      setIsModalOpen(false);
    }
  }));

  useEffect(() => {
    getProvidersList(
      setProvidersList,
      {
        loading: (val) => context.loading(val),
        alert: (val) => context.alert(val)
      },
      {
        usersFilters: defaultFilters
      },
      {
        pageNo: 1,
        getPerPage: 100,
        pagination: providersPagination,
        setPagination: setProvidersPagination
      }
    );
  }, []);

  // useEffect(() => {
  //   if (commonReactState.currentModalItem) {
  //     dispatch({
  //       type: "set_entire_state",
  //       value: commonReactState.currentModalItem,
  //     });
  //   }
  // }, [commonReactState.currentModalItem]);

  const modalWindowParams = {
    isModalOpen, 
    setIsModalOpen,
  }

  const modalHeaderParams = {
    modalItemName: "Reassign",
    modalItemNameActionIsSkipped: true,
    modalItemId: state.id,
  };

  const modalBodyParams = {
    // item: state,
    dispatch,
    providersList: localStorageUserRole == 'Provider' ? providersList.filter(item => item.id != localStorageUserId ) : providersList,
  };

  const onClickSave = () => {
    if (!isValidReassingInput(state, context)) {
      return;
    } 

    reassignAction(state, toast, context);    
    props.closeReassignMeetingModalHandler();
    
    const providerId = localStorageUserRole == "Provider" ? localStorageUserId : commonReactState.currentProvider.id ? commonReactState.currentProvider.id : '';    
    (localStorageUserRole !== "Provider" && !!providerId) && context.refreshProviderStatistics(providerId);
    (localStorageUserRole !== "Provider" && !!providerId) && context.refreshActiveCellStatistics("Pending");
    props.refreshHandler(providerId);

    dispatch({
      type: "set_entire_state",
      value: initialStateMutationMeeting,
    });
  }

  const onClickCancel = () => {
    props.closeReassignMeetingModalHandler();
  }

  const modalFooterParams = {
    state,
    dispatch,
    onClickSave,
    onClickCancel,
    skipActiveButton: true,
    actionButtonName: 'Confirm'
  };
  
  return (
    <ModalWindowMutation 
      modalWindowParams = {modalWindowParams}
  
      modalHeader = {ModalHeaderMutation}
      modalHeaderParams = {modalHeaderParams}

      modalBody = {MeetingReassignFormBodyMutation}
      modalBodyParams = {modalBodyParams}

      modalFooter = {ModalFooterMutation}
      modalFooterParams = {modalFooterParams}
    />      
  );
});

export default MeetingReassignModalMutation;

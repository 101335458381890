import { forwardRef } from "react";

// import GridContainer from "../../../components/Grid/GridContainer";
import GridContainer from "../../../components/Grid/GridContainerRoot";
// import GridItem from "../../../components/Grid/GridItem";
import GridItem from "../../../components/Grid/GridItemRoot";
import DropDown from "../../../components/_helperComponents/DropDown";
import L2TextField from "../../../components/_helperComponents/L2TextField";

const MeetingFormBodyCancel = forwardRef((props, ref) => {
  return (
    <GridItem xs={12}>
      <GridContainer>

        <GridItem xs={3} md={3} lg={3} sm={3}>
          <DropDown
            placeholder="Cancel Reason"
            onChange={(e) => {
              props.modalBodyParams.dispatch({
                type: "select",
                name: "cancelReason",
                value: e,
              });
            }}
            data={props.modalBodyParams.cancelReasonsData}
            value={props.modalBodyParams.item.cancelReason ? props.modalBodyParams.item.cancelReason : ""}
            mandatory={true}
          />          
        </GridItem>  

        <GridItem xs={3} md={3} lg={3} sm={3}>
          <L2TextField
            SPName="MeetingsDictionary"
            FieldName="cancelRemarks"
            onChange={(e) => {
              props.modalBodyParams.dispatch({
                type: "text",
                name: "cancelRemarks",
                value: e,
              });
            }}
            clear={(e) => {
              if (e) {
                props.modalBodyParams.dispatch({
                  type: "text",
                  name: "cancelRemarks",
                  value: "",
                });
              }
            }}
            value={props.modalBodyParams.item.cancelRemarks ? props.modalBodyParams.item.cancelRemarks : ""}
          />
        </GridItem>
         
      </GridContainer>
    </GridItem>
  );
});

export default MeetingFormBodyCancel; 
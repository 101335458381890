import { config } from "../../../../../configs/config";
import { requestAxios } from "../../../../../services/axiosClient";

const sendVerificationAction = async (userId) => {
  const url = "users/send-phone-no-verification";
  const params = {
    userId
  };
  const apiUrl = `${config.API_URL}/${url}`;

  const [response, responseError] = await requestAxios(
    apiUrl,
    params,
  );
  if (!response || responseError) {
    const errorMessage = responseError?.message
      ? responseError?.message
      : "Bad Request";

    return;
  }

  return true;
};

export { 
  sendVerificationAction,
};

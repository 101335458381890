import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useContext,
} from "react";

import {
  UsersContextProvider,
  UsersContext,
} from "../context/usersContextProvider";

import UserFormBodyView from "./UserFormBodyView";

import ModalWindowView from "../../../components/ModalWindow/ModalWindowView";
import ModalHeaderView from "../../../components/ModalWindow/ModalHeaderView";
import ModalFooterView from "../../../components/ModalWindow/ModalFooterView";

const UserView = forwardRef((props, ref) => {
  const context = useContext(UsersContext);
  const { commonReactState, commonReactDispatch } = context;

  const [userItem, setUserItem] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    openModal: (item) => {     
      setUserItem(item);
      setIsModalOpen(true);
    },
    closeModal: () => onClickClose()
  }));

  const modalHeaderParams = {
    modalItemName: "User"
  }

  const modalBodyParams = {
    item: userItem
  }

  const onClickClose = () => {
    commonReactDispatch({
      type: "currentModalItem",
      payload: false,
    });
    setUserItem({});
    setIsModalOpen(false);
  }

  const modalFooterParams ={
    onClickClose
  }

  const modalWindowParams = {
    isModalOpen, 
    setIsModalOpen
  }

  return (
    <ModalWindowView 
      modalWindowParams = {modalWindowParams}
  
      modalHeader = {ModalHeaderView}
      modalHeaderParams = {modalHeaderParams}

      modalBody = {UserFormBodyView}
      modalBodyParams = {modalBodyParams}

      modalFooter = {ModalFooterView}
      modalFooterParams = {modalFooterParams}
    />      
  );
});

export default UserView;

import React, { createContext } from "react";

const diagnosisCodesContextDefault = {};

const DiagnosisCodesContext = createContext(diagnosisCodesContextDefault);

const DiagnosisCodesContextProvider = (props) => {
  return (
    <DiagnosisCodesContext.Provider value={props.value}>
      {" "}
      {props.children}{" "}
    </DiagnosisCodesContext.Provider>
  );
};

export {
  DiagnosisCodesContext,
  DiagnosisCodesContextProvider
}

function VisibleItemSelect(
  val,
  setTableVisibleData,
  setTableVisibleItem,
  setTableBodyItem
) {
  const data = val;
  let arr = [];
  let bodyArr = [];
  let ItemArr = [];
  data.map((val) => {
    if (val.IsVisible == "1") {
      arr.push({
        id: val.FieldName,
        name: val.FieldDescription,
        type: val.FieldDataType,
      });

      bodyArr.push(val.FieldName);
      ItemArr.push(val.FieldDescription);
    }
  });
  setTableVisibleData(data);
  setTableVisibleItem(arr);
  setTableBodyItem(bodyArr);
}

// todo add more params where it's called
const handleChangePage = (
  event,
  setPage,
  getUsersList,
  newPage,
  GridPagination,
  context,
  setTableTotal,
  setTableTotalCount,
  cdispatch,
  setUsersList,
  setGridPagination,
) => {
  setPage(newPage);
  getUsersList(
    { current: newPage + 1, pageSize: GridPagination.pageSize },
    context,
    cdispatch,
    setTableTotalCount,
    setTableTotal,
    setGridPagination,
    setUsersList
  );
};

const handleChangeRowsPerPage = (event, setPage) => {
  setPage(0);
};

const tableColumnsList = [
  {
    FieldName: "userRole",
    FieldDescription: "User Role",
    IsVisible: true
  },
  {
    FieldName: "firstName",
    FieldDescription: "First Name",
    IsVisible: true
  },
  {
    FieldName: "lastName",
    FieldDescription: "Last Name",
    IsVisible: true
  },
];

export {
  VisibleItemSelect,
  handleChangePage,
  handleChangeRowsPerPage,
  tableColumnsList,
};

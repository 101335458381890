import React, {
  forwardRef,
} from "react";

// import GridContainer from "../../../components/Grid/GridContainer";
import GridContainer from "../../../components/Grid/GridContainerRoot";
// import GridItem from "../../../components/Grid/GridItem";
import GridItem from "../../../components/Grid/GridItemRoot";
import Checkbox from "../../../components/_helperComponents/Checkbox";

const DiagnosisCodeFormBodyView = forwardRef((props, ref) => {
  return (
    <div key={props.modalBodyParams.item.id}>
      <div index={props.modalBodyParams.item.id} className="padding paddingLeft width100">
        <GridContainer style={{ borderBottom: "1px solid #dad1d1" }}>
          <GridItem
            sm={12}
            md={12}
            lg={12}
            xs={12}
            className="viewModal"
          >
            <GridContainer>
              <GridItem sm={12} md={12} lg={12} xs={12}>
                <GridContainer>
                  <GridItem
                    sm={5}
                    md={5}
                    lg={5}
                    xs={5}
                    className="viewgridpad"
                  >
                    <p className="ViewHeadpTag">{"Diagnosis Code"} </p>
                  </GridItem>
                  <GridItem
                    sm={7}
                    md={7}
                    lg={7}
                    xs={7}
                    className="viewgridpad"
                  >
                    <span className="ViewBodypTag ViewHightLight">:</span>{" "}
                    <span className="ViewBodypTag ViewHightLight">
                      {" "}
                      {props.modalBodyParams.item.diagnosisCode}
                    </span>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem
                    sm={5}
                    md={5}
                    lg={5}
                    xs={5}
                    className="viewgridpad"
                  >
                    <p className="ViewHeadpTag">{"Description"} </p>
                  </GridItem>
                  <GridItem
                    sm={7}
                    md={7}
                    lg={7}
                    xs={7}
                    className="viewgridpad"
                  >
                    <span className="ViewBodypTag ViewHightLight">:</span>{" "}
                    <span className="ViewBodypTag ViewHightLight">
                      {" "}
                      {props.modalBodyParams.item.description}
                    </span>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem sm={3} md={3} lg={3} xs={3}>
                <Checkbox
                  label={"Active"}
                  checked={props.modalBodyParams.item.isActive ? true : false}
                  onClick={() => {}}
                  disabled={true}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    </div>    
  );
});

export default DiagnosisCodeFormBodyView;

import React, { createContext } from "react";

const facilitiesContextDefault = {};

const FacilitiesContext = createContext(
  facilitiesContextDefault,
);

const FacilitiesContextProvider = (props) => {
  return (
    <FacilitiesContext.Provider value={props.value}>
      {" "}
      {props.children}{" "}
    </FacilitiesContext.Provider>
  );
};

export {
  FacilitiesContext,
  FacilitiesContextProvider
}

import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useContext,
} from "react";

import { FacilitiesContext } from "../context/facilitiesContextProvider";

import ModalWindowView from "../../../components/ModalWindow/ModalWindowView";
import ModalHeaderView from "../../../components/ModalWindow/ModalHeaderView";
import ModalFooterView from "../../../components/ModalWindow/ModalFooterView";
import FacilityFormBodyView from "./FacilityFormBodyView";

const FacilityView = forwardRef((props, ref) => {
  const context = useContext(FacilitiesContext);
  const { commonReactState, commonReactDispatch } = context;

  const [fasilityItem, setFasilityItem] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false); 

  useImperativeHandle(ref, () => ({
    openModal: (item) => {
      setFasilityItem(item);
      setIsModalOpen(true);
    },
    closeModal: () => {
      setIsModalOpen(false);
    },
  }));

  const modalHeaderParams = {
    modalItemName: "Facility"
  }

  const modalBodyParams = {
    item: fasilityItem
  }

  const onClickClose = () => {
    commonReactDispatch({
      type: "currentModalItem",
      payload: false,
    });
    setFasilityItem({});
    setIsModalOpen(false);
  }

  const modalFooterParams = {
    onClickClose
  }

  const modalWindowParams = {
    isModalOpen, 
    setIsModalOpen
  }

  return (
    <ModalWindowView 
      modalWindowParams = {modalWindowParams}
  
      modalHeader = {ModalHeaderView}
      modalHeaderParams = {modalHeaderParams}

      modalBody = {FacilityFormBodyView}
      modalBodyParams = {modalBodyParams}

      modalFooter = {ModalFooterView}
      modalFooterParams = {modalFooterParams}
    />      
  );
});

export default FacilityView;

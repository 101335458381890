import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

import DateFnsUtils from "@date-io/date-fns"; 
import { DateTime } from 'luxon';

const theme1 = createTheme({
  overrides: {
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: "2px solid red",
        },
        "&:after": {
          borderBottom: "2px solid #34ace0",
        },
      },
    },
  },
});
const theme2 = createTheme({
  overrides: {
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: "2px solid #34ace0",
        },
        "&:after": {
          borderBottom: "2px solid #34ace0",
        },
      },
    },
  },
});

function BasicDatePicker(props) {

  const handleChange = (val) => {
    if (val == "Invalid Date" || val == null) {
      // console.log("BasicDatePicker Invalid Date val",val)
      const datePickerDivs = document.getElementsByClassName("datePickerItem");
      datePickerDivs[0].classList.add("datePickerItemError");

      props.onChange(val);
    } else {
      // console.log("BasicDatePicker val",val)

      const normilizedDate = new Date(val);
      // console.log("before BasicDatePicker normilizedDate",normilizedDate)

      const shiftHoursPlus = 20*60*60*1000;
      normilizedDate.setTime(normilizedDate.getTime() + shiftHoursPlus);
      // console.log("after BasicDatePicker normilizedDate",normilizedDate)
      
      const timeZone = localStorage.getItem("timeZone");
      const promisible = DateTime.fromJSDate(normilizedDate, {zone: timeZone}).toUTC().toFormat('MM-dd-yyyy')// .toISO() 
      const promisibleOld = DateTime.fromJSDate(val, {zone: timeZone}).toUTC().toFormat('MM-dd-yyyy')// .toISO() 
      // console.log("BasicDatePicker promisible",promisible)
      // console.log("BasicDatePicker promisibleOld",promisibleOld)

      // if (val.getDay() === normilizedDate.getDay()) {
      //   console.log("BasicDatePicker val.getDay === normilizedDate.getDay()",val.getDay(), normilizedDate.getDay())
      // } else {
      //   console.log("BasicDatePicker val.getDay !!!== normilizedDate.getDay()",val.getDay(), normilizedDate.getDay())
      // }

      const datePickerDivs = document.getElementsByClassName("datePickerItem");
      datePickerDivs[0].classList.remove("datePickerItemError");

      props.onChange(promisible);
    }
  };

  return (
    <MuiThemeProvider theme={!props.value ? theme1 : theme2}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          id={props.id}
          autoOk
          label={props.label}
          fullWidth={!props.fullWidth ? true : false}
          value={props.value ? props.value : null} //should be YYYY-MM-dd format
          variant={props.variant ? props.variant : "inline"}
          InputAdornmentProps={{ position: "end" }}
          // onChange={date => fromDatecalc(date)}
          format={props.format ? props.format : "dd-MM-yyyy"}
          maxDate={props.maxDate && new Date(props.maxDate)} //should be YYYY-MM-dd format eg : new Date('2020-03-31')
          minDate={props.minDate && new Date(props.minDate)}
          onChange={props.change ? props.change : handleChange}
          // color={!props.value ? 'primary' : 'secondary'}
          disabled={props.disabled ? props.disabled : false}
          className={(props?.mandatory && !props.value ? "errorinput " : "")}
          // inputProps={{ ...props.inputProps, readOnly: true }}
        />
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
}

export default BasicDatePicker;

const isValidInputPhoneNo = (phoneNo, setMessage) => {
  if (!phoneNo) {
    setMessage({
      open: true,
      color: "warning",
      message: ["Please enter your Phone Number"],
    });

    return false;
  }

  return true;
};

const isValidInputOtpValue = (otpValue, setMessage) => {
  if (!otpValue) {
    setMessage({
      open: true,
      color: "warning",
      message: ["Please enter your OTP code"],
    });

    return false;
  }

  return true;
};

const isValidInputResetPassword = (newPassword, confirmPassword, setMessage) => {
  if (!newPassword) {
    setMessage({
      open: true,
      color: "warning",
      message: ["Password Should Not Be Empty"],
    });
    return false;
  }

  if (newPassword.length < 6) {
    setMessage({
      open: true,
      color: "warning",
      message: ["Password should be minimum of six characters"],
    });
    return false;
  }

  if (!confirmPassword) {
    setMessage({
      open: true,
      color: "warning",
      message: ["Please enter the confirm password"],
    });
    return false;
  }

  if (confirmPassword.length < 6) {
    setMessage({
      open: true,
      color: "warning",
      message: ["Password should be minimum of six characters"],
    });
    return false;
  }

  if (newPassword !== confirmPassword) {
    setMessage({
      open: true,
      color: "warning",
      message: ["Passwords are not same"],
    });
    return false;
  }

  return true;
};

export { 
  isValidInputPhoneNo,
  isValidInputOtpValue,
  isValidInputResetPassword,
};


import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// import GridItem from "../../../../components/Grid/GridItem";
import GridItem from "../../../../components/Grid/GridItemRoot";
// import GridContainer from "../../../../components/Grid/GridContainer";
import GridContainer from "../../../../components/Grid/GridContainerRoot";

import ProfileMenu from "./components/ProfileMenu/ProfileMenu";
import SidebarMenuSwitcher from "./components/HeaderOptions/SidebarMenuSwitcher";
import HomeButton from "./components/HeaderOptions/HomeButton";
import ChangePassword from "../../../Auth/components/ChangePassword/ChangePassword";
import TimeZone from "../../../Users/components/TimeZone/TimeZone";

const Header = (props) => {
  const authStoreRedux = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const userRole = localStorage.getItem("userRole");

  const [openProfile, setOpenProfile] = useState(false);
  const [openModalTimeZone, setOpenModalTimeZone] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);

  return (
    <div className="horizontalNav" id="horizontalNav">
      <GridContainer  style={{ marginBottom: "10px" }}>
        <GridItem xs={2} sm={2} md={3} lg={2} style={{ paddingRight: "0px" }}>
          <GridContainer>
            <GridItem xs={7} sm={7} md={7} lg={7} style={{ padding: "0px" }}>
            </GridItem>
            <GridItem xs={2} sm={2} md={2} lg={2} style={{ padding: "0px" }}>
              <SidebarMenuSwitcher 
                isSideMenuActive={props.isSideMenuActive}
                setIsSideMenuActive={props.setIsSideMenuActive}
                isSideMenuHasMinWidth={props.isSideMenuHasMinWidth}
                setIsSideMenuHasMinWidth={props.setIsSideMenuHasMinWidth}
              />              
            </GridItem>
            <GridItem xs={2} sm={2} md={2} lg={2} style={{ padding: "0px" }}>
              <HomeButton />              
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem
          xs={8}
          sm={8}
          md={7}
          lg={8}
          align="left"
          style={{ paddingLeft: "0px" }}
        ></GridItem>
        <GridItem xs={2} sm={2} md={2} lg={2}>
          <ProfileMenu 
            openProfile={openProfile}
            setOpenProfile={setOpenProfile}
            setOpenChangePassword={setOpenChangePassword}
            setOpenModalTimeZone={setOpenModalTimeZone}
            classes={props.classes}    
            history={props.history}  
            setMessage={props.setMessage}         
          />
        </GridItem>
      </GridContainer>

      {
        userRole !== "Call_Center" && (
          <ChangePassword
            setOpenProfile={setOpenProfile}
            openChangePassword={openChangePassword}
            setOpenChangePassword={setOpenChangePassword}
            setLoading={props.setLoading}
            setMessage={props.setMessage}
            userID={authStoreRedux.userId}
            keepMounted
            disableEscapeKeyDown
            classes={props.classes}
            history={props.history}
          />
        )
      }
      <TimeZone
        setOpenProfile={setOpenProfile}
        openModalTimeZone={openModalTimeZone}
        setOpenModalTimeZone={setOpenModalTimeZone}
        setLoading={props.setLoading}
        setMessage={props.setMessage}
        userId={authStoreRedux.userId}
        keepMounted
        disableEscapeKeyDown
        classes={props.classes}
        dispatch={dispatch}
      />
    </div>
  );
};

export default Header;

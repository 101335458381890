import { config } from "../../../../../configs/config";
import { requestAxios } from "../../../../../services/axiosClient";

const resetPasswordAction = async (
  phoneNo, 
  otpValue,
  newPassword, 
  setMessage,
  loading
) => {
  loading(true);

  const url = "reset-password";
  const params = {
    phoneNo,
    otp: otpValue,
    password: newPassword,
  };
  const apiUrl = `${config.API_URL}/${url}`;

  const [response, responseError] = await requestAxios(apiUrl, params);
  if (responseError) {
    const errorMessage = responseError?.message
      ? responseError?.message
      : "Bad Request";

    setMessage({
      open: true,
      color: "warning",
      message: [errorMessage],
    });
    loading(false);

    return;
  }

  loading(false);

  return true;
};

export { resetPasswordAction };

import React from "react";
import ReactExport from "react-export-excel";

import Buttons from "../CustomButtons/ButtonRoot";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ExcelDownloadButton = (props) => {

  return (
    <ExcelFile
      filename={props.title}
      element={
        <Buttons style={{ display: "none" }} ref={props.refExcelDownload}>
          Excel Download
        </Buttons>
      }
    >
      <ExcelSheet dataSet={props.dataSet} name={props.title} />
    </ExcelFile>
  );
};

export default ExcelDownloadButton; 

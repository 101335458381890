import "../../assets/css/select.css";
import "./assets/css/homePage.css";
import { homePageStyles } from "./assets/css/homeStyles";

import React, { useState } from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";

import Loading from "../../components/_helperComponents/Loading";
import Notification from "../../components/_helperComponents/Notification";

import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import MainContent from "./components/MainContent/MainContent";

const HomePage = React.memo((props) => {
  const [loading, setLoading] = useState(false);
  const [Message, setMessage] = useState({
    open: false,
    color: "",
    message: [],
  });
  const [confirmationAlert, setConfirmationAlert] = useState(null);
  const [isSideMenuHasMinWidth, setIsSideMenuHasMinWidth] = useState(false);
  const [isSideMenuActive, setIsSideMenuActive] = useState(false);

  window.onhashchange = function () {
    const isUserAuth = localStorage.getItem('actionToken');
    if (!!isUserAuth) {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = function (event) {
        window.history.go(1);
      };
    }
  };

  return (
    <div>
      {confirmationAlert}
      <Notification
        open={Message.open}
        color={Message.color}
        message={Message.message}
        onClose={() =>
          setMessage({ open: false, color: Message.color, message: [] })
        }
      />
      <Loading loading={loading} />

      <Header
        loading={loading}
        setLoading={setLoading}
        Message={Message}
        setMessage={setMessage}
        classes={props.classes}
        isSideMenuActive={isSideMenuActive}
        setIsSideMenuActive={setIsSideMenuActive}
        isSideMenuHasMinWidth={isSideMenuHasMinWidth}
        setIsSideMenuHasMinWidth={setIsSideMenuHasMinWidth}
        history={props.history}
      />
      <Sidebar
        loading={loading}
        setLoading={setLoading}
        Message={Message}
        setMessage={setMessage}
        isSideMenuHasMinWidth={isSideMenuHasMinWidth}
        isSideMenuActive={isSideMenuActive}
        setIsSideMenuActive={setIsSideMenuActive}
        setConfirmationAlert={setConfirmationAlert}
      />
      <MainContent
        loading={loading}
        setLoading={setLoading}
        Message={Message}
        setMessage={setMessage}
        classes={props.classes}
        setConfirmationAlert={setConfirmationAlert}
      />
    </div>
  );
});

HomePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(homePageStyles)(HomePage);

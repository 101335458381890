const isValidMutationInput = (state, context) => {
  if (!state.diagnosisCode.trim()) {
    context.alert({
      open: true,
      color: "error",
      message: ["Please enter the Diagnosis Code"],
    });
    return false;
  }

  return true;
};

export { isValidMutationInput };

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useReducer,
  useContext,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

import mutationReactReducer from "../../../reactReducers/mutationReactReducer";

import { FacilitiesContext } from "../context/facilitiesContextProvider";
import {
  saveSubmit,
  removeSubmit,
} from "../actions/mutationFacilityAction";
import { isValidMutationInput } from "../validations/mutationFacilityValidator";
import { initialStateMutationFacility } from "../helpers/mutationFacilityHelpers";

import FacilityFormBodyMutation from "./FacilityFormBodyMutation";

import ModalWindowMutation from "../../../components/ModalWindow/ModalWindowMutation";
import ModalHeaderMutation from "../../../components/ModalWindow/ModalHeaderMutation";
import ModalFooterMutation from "../../../components/ModalWindow/ModalFooterMutation";

const FacilityMutation = forwardRef((props, ref) => {
  const authStoreRedux = useSelector((state) => state.auth);

  const context = useContext(FacilitiesContext);
  const { commonReactState, commonReactDispatch } = context;

  const [state, dispatch] = useReducer(mutationReactReducer, initialStateMutationFacility);
  
  const [isModalOpen, setIsModalOpen] = useState(false); 
  
  useEffect(() => {
    if (commonReactState.currentModalItem) {
      dispatch({
        type: "set_entire_state",
        value: commonReactState.currentModalItem,
      });
    }
  }, [commonReactState.currentModalItem]);

  useImperativeHandle(ref, () => ({
    openModal: (item) => {
      const itemState = item ? item : initialStateMutationFacility;
      
      dispatch({
        type: "set_entire_state",
        value: itemState,
      });

      setIsModalOpen(true);
    },
    closeModal: () => {
      setIsModalOpen(false);
    },
    removeAlertModal: (itemId) => {
      removeAlert(itemId);
    },
  }));

  const saveAlert = () => {
    context.ConfirmAlert(
      <SweetAlert
        warning
        Size="sm"
        style={{ display: "block", marginTop: "100px" }}
        title={"Are you sure?"}
        onConfirm={() => {
          // context.loading(true);
          // context.ConfirmAlert(null);
          saveSubmit(
            context,
            state,
            setIsModalOpen,
            dispatch,
            initialStateMutationFacility
          );
        }}
        onCancel={() => {
            context.ConfirmAlert(null);
            setIsModalOpen(false);
            dispatch({
              type: "set_entire_state",
              value: initialStateMutationFacility,
            });
          }
        }
        confirmBtnCssClass={"sweetAlertBtn sweetAlertBtnSuccess"} 
        cancelBtnCssClass={"sweetAlertBtn sweetAlertBtnCancel"}
        //{props.classes.button + " " + props.classes.success}
        // cancelBtnCssClass={props.classes.button + " " + props.classes.danger}
        confirmBtnText={"Yes"}
        cancelBtnText={"Cancel"}
        showCancel
      >
        {"Do you want to Save?"}
      </SweetAlert>,
    );
  };

  const removeAlert = (itemId) => {
    context.ConfirmAlert(
      <SweetAlert
        warning
        Size="sm"
        style={{ display: "block", marginTop: "100px" }}
        title={"Are you sure?"}
        onConfirm={() => {
          removeSubmit(
            context,
            itemId,
            setIsModalOpen
          );
        }}
        onCancel={() => {
            context.ConfirmAlert(null);
            setIsModalOpen(false);
          }
        }
        confirmBtnCssClass={"sweetAlertBtn sweetAlertBtnSuccess"} 
        cancelBtnCssClass={"sweetAlertBtn sweetAlertBtnCancel"}
        confirmBtnText={"Yes"}
        cancelBtnText={"Cancel"}
        showCancel
      >
        {"Are you sure you want to delete this item?"}
      </SweetAlert>,
    );
  };

  const modalWindowParams = {
    isModalOpen, 
    setIsModalOpen,
  }

  const modalHeaderParams = {
    modalItemName: "Facility",
    modalItemId: state.id
  };

  const modalBodyParams = {
    item: state,
    dispatch,
    regionsData: JSON.parse(authStoreRedux.regionsData),
    countriesData: JSON.parse(authStoreRedux.countriesData),
    regionsData: JSON.parse(authStoreRedux.regionsData)
  }

  const onClickSave = () => {
    if (!isValidMutationInput(state, context)) {
      return;
    } 
    
    saveAlert();
  }

  const onClickCancel = () => {
    dispatch({
      type: "set_entire_state",
      value: initialStateMutationFacility,
    });
    setIsModalOpen(false);
  } 

  const modalFooterParams = {
    state,
    dispatch,
    onClickSave,
    onClickCancel    
  };

  return (
    <ModalWindowMutation 
      modalWindowParams = {modalWindowParams}
  
      modalHeader = {ModalHeaderMutation}
      modalHeaderParams = {modalHeaderParams}

      modalBody = {FacilityFormBodyMutation}
      modalBodyParams = {modalBodyParams}

      modalFooter = {ModalFooterMutation}
      modalFooterParams = {modalFooterParams}
    />      
  );
});

export default FacilityMutation; 

import { config } from "../../../../../configs/config";
import { requestAxios } from "../../../../../services/axiosClient";

const verifyOtpValueOnResetAction = async (
  phoneNo, 
  otpValue,
  setMessage,
  loading
) => {
  loading(true);

  const url = "verify-otp-reset-password";
  const params = {
    phoneNo,
    otp: otpValue
  };
  const apiUrl = `${config.API_URL}/${url}`;

  const [response, responseError] = await requestAxios(apiUrl, params);
  if (responseError) {
    const errorMessage = responseError?.message
      ? responseError?.message
      : "Bad Request";

    setMessage({
      open: true,
      color: "warning",
      message: ["Invalid OTP"],
    });
    loading(false);

    return;
  }

  loading(false);

  return true;
};

export { verifyOtpValueOnResetAction };

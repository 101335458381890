import { config } from "../../../../../configs/config";
import { requestAxios } from "../../../../../services/axiosClient";

const logoutAction = async (
  setOpenProfile,
  history,
  setMessage,
  // dispatchRedux,
) => {
  setOpenProfile(false);

  const url = "logout";

  const refreshToken = localStorage.getItem("refreshToken");
  const params = {
    refreshToken,
  };
  const apiUrl = `${config.API_URL}/${url}`;

  const [response, responseError] = await requestAxios(
    apiUrl,
    params,
    // dispatchRedux,
  );
  if (responseError) {
    const errorMessage = responseError?.message
      ? responseError?.message
      : "Problem In Logout";
    setMessage({ open: true, color: "error", message: [errorMessage] });
  }

  localStorage.clear();
  sessionStorage.clear();
  history.push('/Login')

  return;
};

export { logoutAction };

import { forwardRef, useState } from "react";

// import GridContainer from "../../../components/Grid/GridContainer";
import GridContainer from "../../../components/Grid/GridContainerRoot";
// import GridItem from "../../../components/Grid/GridItem";
import GridItem from "../../../components/Grid/GridItemRoot";
import DropDown from "../../../components/_helperComponents/DropDown";
import L2TextField from "../../../components/_helperComponents/L2TextField";
import TextArea from "../../../components/_helperComponents/TextArea";
import Mat_DatePicker from "../../../components/_helperComponents/Mat_DatePickerEdit";
import Card from "../../../components/Card/CardRoot";
import CardBody from "../../../components/Card/CardBodyRoot";

import { formateDatePickerDate, formateDateOnPick } from "../../../helpers/utils";

const MeetingFormBodyMutation = forwardRef((props, ref) => {
  const [reasonForReassign, setReasonForReassign] = useState("");

  return (
    <GridItem xs={12}>
      <h4 className="modalHeaderDetails">Consult Details</h4>

      <GridContainer>
        <GridItem xs={6} md={6} lg={6} sm={6}>
          <DropDown
            placeholder="Provider"
            onChange={(e) => {
              props.modalBodyParams.providersList.map((value) => {
                if (e === value.id) {
                  props.modalBodyParams.dispatch({
                    type: "text",
                    name: "userId",
                    value: value.id,
                  });
                  props.modalBodyParams.dispatch({
                    type: "text",
                    name: "provider",
                    value: `${value.firstName} ${value.lastName}`,
                  });
                }
              });  

              props.modalBodyParams.dispatch({
                type: "text",
                name: "reasonForReassign",
                value: "",
              });          
              setReasonForReassign("");  
            }}
            data={props.modalBodyParams.providersList}
            value={props.modalBodyParams.item.userId ? props.modalBodyParams.item.userId : ""}
            mandatory={true}
            disabled={["Cancelled", "Completed", "Started"].includes(props.modalBodyParams.item.status) ? true : false}
          />          
        </GridItem>

        <GridItem xs={6} md={6} lg={6} sm={6}>
          <DropDown
            placeholder="Facility"
            onChange={(e) => {
              props.modalBodyParams.facilitiesList.map((value) => {
                if (e === value.id) {
                  props.modalBodyParams.dispatch({
                    type: "text",
                    name: "facilityId",
                    value: value.id,
                  });
                  props.modalBodyParams.dispatch({
                    type: "text",
                    name: "facility",
                    value: value.facilityName,
                  });
                }
              });              
            }}
            data={props.modalBodyParams.facilitiesList}
            value={props.modalBodyParams.item.facilityId ? props.modalBodyParams.item.facilityId : ""}
            mandatory={true}
            disabled={["Cancelled", "Completed", "Started"].includes(props.modalBodyParams.item.status) ? true : false}
          />          
        </GridItem>
        
        <GridItem xs={6} md={6} lg={6} sm={6}>
          <DropDown
            placeholder="Consult Type"
            onChange={(e) => {
              props.modalBodyParams.dispatch({
                type: "select",
                name: "consultType",
                value: e,
              });
            }}
            data={props.modalBodyParams.consultTypesData}
            value={props.modalBodyParams.item.consultType ? props.modalBodyParams.item.consultType : ""}
            mandatory={true}
            disabled={["Cancelled", "Completed", "Started"].includes(props.modalBodyParams.item.status) ? true : false}
          />          
        </GridItem>

        <GridItem xs={6} md={6} lg={6} sm={6}>
          <DropDown
            placeholder="Communication Method"
            onChange={(e) => {
              props.modalBodyParams.dispatch({
                type: "select",
                name: "communicationMethod",
                value: e,
              });
            }}
            data={props.modalBodyParams.communicationMethodsData}
            value={props.modalBodyParams.item.communicationMethod ? props.modalBodyParams.item.communicationMethod : ""}
            mandatory={true}
            disabled={["Cancelled", "Completed", "Started"].includes(props.modalBodyParams.item.status) ? true : false}
          />          
        </GridItem>

        {
          props.modalBodyParams.item.communicationMethod === "Cart" && (
            <GridItem xs={6} md={6} lg={6} sm={6}>
              <L2TextField
                SPName="MeetingsDictionary"
                FieldName="cartNumber"
                onChange={(e) => {
                  props.modalBodyParams.dispatch({
                    type: "text",
                    name: "cartNumber",
                    value: e,
                  });
                }}
                clear={(e) => {
                  if (e) {
                    props.modalBodyParams.dispatch({
                      type: "text",
                      name: "cartNumber",
                      value: "",
                    });
                  }
                }}
                value={props.modalBodyParams.item.cartNumber ? props.modalBodyParams.item.cartNumber : ""}
                disabled={["Cancelled", "Completed", "Started"].includes(props.modalBodyParams.item.status) ? true : false}
                marginTop={"10px"}
              />
            </GridItem>
        )}

        <GridItem xs={6} md={6} lg={6} sm={6}>
          <L2TextField
            SPName="MeetingsDictionary"
            FieldName="siteCallbackNumber"
            onChange={(e) => {
              props.modalBodyParams.dispatch({
                type: "text",
                name: "siteCallbackNumber",
                value: e,
              });
            }}
            clear={(e) => {
              if (e) {
                props.modalBodyParams.dispatch({
                  type: "text",
                  name: "siteCallbackNumber",
                  value: "",
                });
              }
            }}
            value={props.modalBodyParams.item.siteCallbackNumber ? props.modalBodyParams.item.siteCallbackNumber : ""}
            disabled={["Cancelled", "Completed", "Started"].includes(props.modalBodyParams.item.status) ? true : false}
            marginTop={"10px"}
          />
        </GridItem>

        <GridItem xs={6} md={6} lg={6} sm={6}>
          <L2TextField
            SPName="MeetingsDictionary"
            FieldName="siteContactName"
            onChange={(e) => {
              props.modalBodyParams.dispatch({
                type: "text",
                name: "siteContactName",
                value: e,
              });
            }}
            clear={(e) => {
              if (e) {
                props.modalBodyParams.dispatch({
                  type: "text",
                  name: "siteContactName",
                  value: "",
                });
              }
            }}
            value={props.modalBodyParams.item.siteContactName ? props.modalBodyParams.item.siteContactName : ""}
            disabled={["Cancelled", "Completed", "Started"].includes(props.modalBodyParams.item.status) ? true : false}
            marginTop={"10px"}
          />
        </GridItem>

        <GridItem xs={6} md={6} lg={6} sm={6}>
          <L2TextField
            SPName="MeetingsDictionary"
            FieldName="siteCallbackNumberSecondary"
            onChange={(e) => {
              props.modalBodyParams.dispatch({
                type: "text",
                name: "siteCallbackNumberSecondary",
                value: e,
              });
            }}
            clear={(e) => {
              if (e) {
                props.modalBodyParams.dispatch({
                  type: "text",
                  name: "siteCallbackNumberSecondary",
                  value: "",
                });
              }
            }}
            value={props.modalBodyParams.item.siteCallbackNumberSecondary ? props.modalBodyParams.item.siteCallbackNumberSecondary : ""}
            disabled={["Cancelled", "Completed", "Started"].includes(props.modalBodyParams.item.status) ? true : false}
            marginTop={"10px"}
          />
        </GridItem>

        <GridItem xs={6} md={6} lg={6} sm={6}>
          <L2TextField
            SPName="MeetingsDictionary"
            FieldName="siteContactNameSecondary"
            onChange={(e) => {
              props.modalBodyParams.dispatch({
                type: "text",
                name: "siteContactNameSecondary",
                value: e,
              });
            }}
            clear={(e) => {
              if (e) {
                props.modalBodyParams.dispatch({
                  type: "text",
                  name: "siteContactNameSecondary",
                  value: "",
                });
              }
            }}
            value={props.modalBodyParams.item.siteContactNameSecondary ? props.modalBodyParams.item.siteContactNameSecondary : ""}
            disabled={["Cancelled", "Completed", "Started"].includes(props.modalBodyParams.item.status) ? true : false}
            marginTop={"10px"}
          />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} md={12} lg={12} sm={12}>
          <TextArea
            SPName="MeetingsDictionary"
            FieldName="reasonForMeeting"
            onChange={(e) => {
              props.modalBodyParams.dispatch({
                type: "text",
                name: "reasonForMeeting",
                value: e,
              });
            }}
            clear={(e) => {
              if (e) {
                props.modalBodyParams.dispatch({
                  type: "text",
                  name: "reasonForMeeting",
                  value: "",
                });
              }
            }}
            value={props.modalBodyParams.item.reasonForMeeting ? props.modalBodyParams.item.reasonForMeeting : ""}
            disabled={["Cancelled", "Completed", "Started"].includes(props.modalBodyParams.item.status) ? true : false}
            marginTop={"20px"}
          />
        </GridItem>          
      </GridContainer>

      { !!props.modalBodyParams.item.id &&
        <GridContainer>
          <GridItem xs={12} md={12} lg={12} sm={12} className="reasonForReassign_call_center">
            <TextArea
              SPName="MeetingsDictionary"
              FieldName="reasonForReassign"
              onChange={(e) => {
                props.modalBodyParams.dispatch({
                  type: "text",
                  name: "reasonForReassign",
                  value: e,
                });
                setReasonForReassign(e)
              }}
              clear={(e) => {
                if (e) {
                  props.modalBodyParams.dispatch({
                    type: "text",
                    name: "reasonForReassign",
                    value: "",
                  });
                  setReasonForReassign("")
                }
              }}
              value={reasonForReassign}
              disabled={false}
            />
          </GridItem>
        </GridContainer>
      }
      
      <Card
        style={{
          backgroundColor: "rgb(220 220 220 / 32%)",
          margin: "15px",
          borderRadius: "6px",
          paddingTop: "15px",
          paddingBottom: '20px'
        }}
      >
        <CardBody >
          <h4 className="modalHeaderDetails" style={{ marginTop: "20px !important" }}>Patient Details</h4>

          <GridContainer>
          
            <GridItem xs={6} md={6} lg={6} sm={6}>
              <L2TextField
                SPName="MeetingsDictionary"
                FieldName="firstName"
                onChange={(e) => {
                  props.modalBodyParams.dispatch({
                    type: "text",
                    name: "firstName",
                    value: e,
                  });
                }}
                clear={(e) => {
                  if (e) {
                    props.modalBodyParams.dispatch({
                      type: "text",
                      name: "firstName",
                      value: "",
                    });
                  }
                }}
                value={props.modalBodyParams.item.firstName ? props.modalBodyParams.item.firstName : ""}
                disabled={["Cancelled", "Completed", "Started"].includes(props.modalBodyParams.item.status) ? true : false}
                marginTop={"10px"}
              />
            </GridItem>

            <GridItem xs={6} md={6} lg={6} sm={6}>
              <L2TextField
                SPName="MeetingsDictionary"
                FieldName="lastName"
                onChange={(e) => {
                  props.modalBodyParams.dispatch({
                    type: "text",
                    name: "lastName",
                    value: e,
                  });
                }}
                clear={(e) => {
                  if (e) {
                    props.modalBodyParams.dispatch({
                      type: "text",
                      name: "lastName",
                      value: "",
                    });
                  }
                }}
                value={props.modalBodyParams.item.lastName ? props.modalBodyParams.item.lastName : ""}
                disabled={["Cancelled", "Completed", "Started"].includes(props.modalBodyParams.item.status) ? true : false}
                marginTop={"10px"}
              />
            </GridItem>

            <GridItem xs={6} md={6} lg={6} sm={6} className="flexEnd datePickerItem datePickerItemError">
              <Mat_DatePicker
                label={"Birth Date"}
                format="MM-dd-yyyy"
                onChange={(e) => {
                  props.modalBodyParams.dispatch({
                    type: "date",
                    name: "birthDate",
                    date: formateDateOnPick(e),
                  });
                }}
                value={props.modalBodyParams.item.birthDate ? formateDatePickerDate(props.modalBodyParams.item.birthDate) : ""}
                disabled={["Cancelled", "Completed", "Started"].includes(props.modalBodyParams.item.status) ? true : false}
                marginTop={"10px"}
              />
            </GridItem>        

            <GridItem xs={6} md={6} lg={6} sm={6}>
              <L2TextField
                SPName="MeetingsDictionary"
                FieldName="medicalRecordNumber"
                onChange={(e) => {
                  props.modalBodyParams.dispatch({
                    type: "text",
                    name: "medicalRecordNumber",
                    value: e,
                  });
                }}
                clear={(e) => {
                  if (e) {
                    props.modalBodyParams.dispatch({
                      type: "text",
                      name: "medicalRecordNumber",
                      value: "",
                    });
                  }
                }}
                value={props.modalBodyParams.item.medicalRecordNumber ? props.modalBodyParams.item.medicalRecordNumber : ""}
                disabled={["Cancelled", "Completed", "Started"].includes(props.modalBodyParams.item.status) ? true : false}
                marginTop={"10px"}
              />
            </GridItem>   

            <GridItem xs={6} md={6} lg={6} sm={6}>
              <L2TextField
                SPName="MeetingsDictionary"
                FieldName="accountNo"
                onChange={(e) => {
                  props.modalBodyParams.dispatch({
                    type: "text",
                    name: "accountNo",
                    value: e,
                  });
                }}
                clear={(e) => {
                  if (e) {
                    props.modalBodyParams.dispatch({
                      type: "text",
                      name: "accountNo",
                      value: "",
                    });
                  }
                }}
                value={props.modalBodyParams.item.accountNo ? props.modalBodyParams.item.accountNo : ""}
                disabled={["Cancelled", "Completed", "Started"].includes(props.modalBodyParams.item.status) ? true : false}
                marginTop={"10px"}
              />
            </GridItem>      
          </GridContainer>
        </CardBody>
      </Card>
      
    </GridItem>
  );
});

export default MeetingFormBodyMutation; 
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useReducer,
  useContext,
} from "react";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

import mutationReactReducer from "../../../reactReducers/mutationReactReducer";

import MeetingFormBodyMutation from "./MeetingFormBodyMutation";
import { MeetingsContext } from "../../Meetings/context/meetingsContextProvider";


import {
  initialStateMutationMeeting,
} from "../helpers/mutationMeetingHelpers";

import {
  saveSubmit,
} from "../actions/mutationMeetingAction";

import { isValidMutationInput } from "../validations/mutationMeetingValidator";

import {
  getFacilitiesList
} from "../../Facilities/actions/viewListFacilitiesAction";

import ModalWindowMutation from "../../../components/ModalWindow/ModalWindowMutation";
import ModalHeaderMutation from "../../../components/ModalWindow/ModalHeaderMutation";
import ModalFooterMutation from "../../../components/ModalWindow/ModalFooterMutation";

// AppointmentRegistry
const MeetingMutation = forwardRef((props, ref) => {
  const authStoreRedux = useSelector((state) => state.auth);

  const context = useContext(MeetingsContext);
  const { commonReactState, commonReactDispatch, providersList } = context;

  const [state, dispatch] = useReducer(mutationReactReducer, initialStateMutationMeeting);

  const [isModalOpen, setIsModalOpen] = useState(false); 

  const [facilitiesList, setFacilitiesList] = useState([]);
  const [facilitiesPagination, setFacilitiesPagination] = useState({
    pageNo: 1,
    getPerPage: 100,
    itemsTotalNumber: 0,
    pagesTotalNumber: 0
  }); 

  const defaultFilters = {
    statuses: {
      'Active': "true",
      'Inactive': "false"
    }
  }

  useImperativeHandle(ref, () => ({
    openModal: (item) => {
      const meeting = item ? item : initialStateMutationMeeting;
      
      dispatch({
        type: "set_entire_state",
        value: meeting,
      });
      setIsModalOpen(true);
    },
    closeModal: () => {
      setIsModalOpen(false);

      dispatch({
        type: "set_entire_state",
        value: initialStateMutationMeeting,
      });
    },
  }));

  useEffect(() => {
    getFacilitiesList(
      setFacilitiesList,
      context,
      {
        facilitiesFilters: defaultFilters
      },
      {
        pageNo: 1,
        getPerPage: 100,
        pagination: facilitiesPagination,
        setPagination: setFacilitiesPagination
      }
    );
  }, []); 
  
  const saveAlert = () => {
    context.ConfirmAlert(
      <SweetAlert
        warning
        Size="sm"
        style={{ display: "block", marginTop: "100px" }}
        title={"Are you sure?"}
        onConfirm={() => {
          saveSubmit(
            context,
            state,
            setIsModalOpen,
            dispatch,
            initialStateMutationMeeting
          );
        }}
        onCancel={() => {
            context.ConfirmAlert(null)
            setIsModalOpen(false);
            dispatch({
              type: "set_entire_state",
              value: initialStateMutationMeeting,
            });
          }
        }
        confirmBtnCssClass={"sweetAlertBtn sweetAlertBtnSuccess"} 
        cancelBtnCssClass={"sweetAlertBtn sweetAlertBtnCancel"}
        confirmBtnText={"Yes"}
        cancelBtnText={"Cancel"}
        showCancel
      >
        {"Do you want to Save?"}
      </SweetAlert>,
    );
  };

  const modalWindowParams = {
    isModalOpen, 
    setIsModalOpen,
  }

  const modalHeaderParams = {
    modalItemName: "Consult",
    modalItemId: state.id
  };
  
  const modalBodyParams = {
    item: state,
    dispatch,
    providersList,
    facilitiesList,
    consultTypesData: JSON.parse(authStoreRedux.consultTypesData),
    communicationMethodsData: JSON.parse(authStoreRedux.communicationMethodsData),
    consultTypesData: JSON.parse(authStoreRedux.consultTypesData),
  };
  
  const onClickSave = () => {
    if (!isValidMutationInput(state, context)) {
      return;
    } 

    saveAlert();
  }
  
  const onClickCancel = () => {
    dispatch({
      type: "set_entire_state",
      value: initialStateMutationMeeting,
    });
    setIsModalOpen(false);
  }

  const modalFooterParams = {
    state,
    dispatch,
    skipActiveButton: true,
    onClickSave,
    isActionButtonDisabled: ["Cancelled", "Completed", "Started"].includes(state.status) ? true : false,
    onClickCancel,
    cancelButtonName: ["Cancelled", "Completed", "Started"].includes(state.status) ? "Close" : "Cancel",
    isCancelButtonDisabled: false,
  };
  

  return (         
    <ModalWindowMutation 
      modalWindowParams = {modalWindowParams}

      modalHeader = {ModalHeaderMutation}
      modalHeaderParams = {modalHeaderParams}

      modalBody = {MeetingFormBodyMutation}
      modalBodyParams = {modalBodyParams}

      modalFooter = {ModalFooterMutation}
      modalFooterParams = {modalFooterParams}
    />
  );
});

export default MeetingMutation; 


// import GridContainer from "../../../components/Grid/GridContainer";
import GridContainer from "../../../components/Grid/GridContainerRoot";
// import GridItem from "../../../components/Grid/GridItem";
import GridItem from "../../../components/Grid/GridItemRoot";
import L2TextField from "../../../components/_helperComponents/L2TextField";
import DropDown from "../../../components/_helperComponents/DropDown";
import Mat_DatePicker from "../../../components/_helperComponents/Mat_DatePickerEdit";
import SaveBtn from "../../../components/Buttons/SaveButton";
import Checkbox from "../../../components/_helperComponents/Checkbox";

import { formateDatePickerDate, formateDateOnPick } from "../../..//helpers/utils";

const UserFormBodyMutation = (props) => {
  return (
    <GridContainer>
      <GridContainer>
        <GridItem xs={6} md={6} lg={6} sm={6}>
          <L2TextField
            SPName="UsersDictionary"
            FieldName="username"
            onChange={(e) => {
              props.modalBodyParams.dispatch({
                type: "text",
                name: "username",
                value: e,
              });
            }}
            clear={(e) => {
              if (e) {
                props.modalBodyParams.dispatch({
                  type: "text",
                  name: "username",
                  value: "",
                });
              }
            }}
            value={props.modalBodyParams.item.username ? props.modalBodyParams.item.username : ""}

          />
        </GridItem>  

        {!props.modalBodyParams.item.id && (
          <> 
            <GridItem xs={5} md={5} lg={5} sm={5}>
              <L2TextField
                SPName="UsersDictionary"
                FieldName="password"
                onChange={(e) => {
                  props.modalBodyParams.dispatch({
                    type: "text",
                    name: "password",
                    value: e,
                  });
                }}
                clear={(e) => {
                  if (e) {
                    props.modalBodyParams.dispatch({
                      type: "text",
                      name: "password",
                      value: "",
                    });
                  }
                }}
              value={props.modalBodyParams.item.password ? props.modalBodyParams.item.password : ""}
              />
            </GridItem>     

            <GridItem xs={1} md={1} lg={1} sm={1} className="tooltipPassRules">
              <div class="tooltip-bottom"  style={{ paddingTop: "23px" }}>Rules
                <span class="tooltiptext tooltiptext-bottom">
                  <p> {"- minimum length – 8 characters"}</p>
                  <p> {"- maximum length – 64 characters"}</p>
                  <p> {"- at least 1 upper-case character (A-Z)"}</p>
                  <p> {"- at least 1 lower-case character (a-z)"}</p>
                  <p> {"- at least 1 numeric character (0-9)"}</p>
                  <p> {"- at least 1 specific character (@#$%_^&)"}</p>
                </span>
              </div> 
            </GridItem>    
          </> 
        )}

        <GridItem xs={6} md={6} lg={6} sm={6}>
          <L2TextField
            SPName="UsersDictionary"
            FieldName="firstName"
            onChange={(e) => {
              props.modalBodyParams.dispatch({
                type: "text",
                name: "firstName",
                value: e,
              });
            }}
            clear={(e) => {
              if (e) {
                props.modalBodyParams.dispatch({
                  type: "text",
                  name: "firstName",
                  value: "",
                });
              }
            }}
            value={props.modalBodyParams.item.firstName ? props.modalBodyParams.item.firstName : ""}
          />
        </GridItem>

        <GridItem xs={6} md={6} lg={6} sm={6}>

          <L2TextField
            SPName="UsersDictionary"
            FieldName="lastName"
            onChange={(e) => {
              props.modalBodyParams.dispatch({
                type: "text",
                name: "lastName",
                value: e,
              });
            }}
            clear={(e) => {
              if (e) {
                props.modalBodyParams.dispatch({
                  type: "text",
                  name: "lastName",
                  value: "",
                });
              }
            }}
            value={props.modalBodyParams.item.lastName ? props.modalBodyParams.item.lastName : ""}

          />
        </GridItem>

        <GridItem xs={6} md={6} lg={6} sm={6}>
          <L2TextField
            SPName="UsersDictionary"
            FieldName="middleName"
            onChange={(e) => {
              props.modalBodyParams.dispatch({
                type: "text",
                name: "middleName",
                value: e,
              });
            }}
            clear={(e) => {
              if (e) {
                props.modalBodyParams.dispatch({
                  type: "text",
                  name: "middleName",
                  value: "",
                });
              }
            }}
            value={props.modalBodyParams.item.middleName ? props.modalBodyParams.item.middleName : ""}

          />
        </GridItem>

        <GridItem xs={6} md={6} lg={6} sm={6}>
          <DropDown
            placeholder="User Role"
            onChange={(e) => {
              props.modalBodyParams.dispatch({ type: "select", name: "userRole", value: e });
            }}
            data={props.modalBodyParams.userRolesData}
            value={props.modalBodyParams.item.userRole ? props.modalBodyParams.item.userRole : ""}
            mandatory={true}            
          />
        </GridItem>  

        {
          props.modalBodyParams.item.userRole === "Provider" && <GridItem xs={6} md={6} lg={6} sm={6}>
            <DropDown
              placeholder="Designation"
              onChange={(e) => {
                props.modalBodyParams.dispatch({
                  type: "select",
                  name: "designation",
                  value: e,
                });
              }}
              data={props.modalBodyParams.designationsData}
              value={props.modalBodyParams.item.designation ? props.modalBodyParams.item.designation : ""}
              mandatory={false}
            />
          </GridItem>
        }

        <GridItem xs={6} md={6} lg={6} sm={6}>
          <DropDown
            placeholder="Speciality"
            onChange={(e) => {
              props.modalBodyParams.dispatch({
                type: "select",
                name: "speciality",
                value: e,
              });
            }}
            data={props.modalBodyParams.specialitiesData}
            value={props.modalBodyParams.item.speciality ? props.modalBodyParams.item.speciality : ""}
            mandatory={false}            
          />
        </GridItem>

        <GridItem xs={6} md={6} lg={6} sm={6} className="flexEnd">
          <DropDown
            placeholder="Gender"
            onChange={(e) => {
              props.modalBodyParams.dispatch({
                type: "select",
                name: "gender",
                value: e,
              });
            }}
            data={props.modalBodyParams.gendersData}
            value={props.modalBodyParams.item.gender ? props.modalBodyParams.item.gender : ""}
            mandatory={true}            
          />
        </GridItem>

        <GridItem xs={6} md={6} lg={6} sm={6} className="flexEnd datePickerItem datePickerItemError">
          <Mat_DatePicker
            label={"Birth Date"}
            format="MM-dd-yyyy"
            onChange={(e) => {
              props.modalBodyParams.dispatch({
                type: "date",
                name: "birthDate",
                date: formateDateOnPick(e),
              });
            }}
            value={props.modalBodyParams.item.birthDate ? formateDatePickerDate(props.modalBodyParams.item.birthDate) : null}
          />
        </GridItem>

        <GridItem xs={6} md={6} lg={6} sm={6}>
          <L2TextField
            SPName="UsersDictionary"
            FieldName="phoneNo"
            onChange={(e) => {
              props.modalBodyParams.dispatch({
                type: "number",
                name: "phoneNo",
                number: e,
              });
            }}
            clear={(e) => {
              if (e) {
                props.modalBodyParams.dispatch({
                  type: "number",
                  name: "phoneNo",
                  number: "",
                });
              }
            }}
            value={props.modalBodyParams.item.phoneNo ? props.modalBodyParams.item.phoneNo : ""}
          />
        </GridItem>

        <GridItem xs={6} md={6} lg={6} sm={6}>
          <L2TextField
            SPName="UsersDictionary"
            FieldName="email"
            onChange={(e) => {
              props.modalBodyParams.dispatch({
                type: "text",
                name: "email",
                value: e,
              });
            }}
            clear={(e) => {
              if (e) {
                props.modalBodyParams.dispatch({
                  type: "text",
                  name: "email",
                  value: "",
                });
              }
            }}
            value={props.modalBodyParams.item.email ? props.modalBodyParams.item.email : ""}
          />
        </GridItem> 

        <GridItem xs={6} md={6} lg={6} sm={6} className="flexEnd">
          <DropDown
            placeholder="Time Zone"
            onChange={(e) => {
              props.modalBodyParams.dispatch({
                type: "select",
                name: "timeZone",
                value: e,
              });
            }}
            data={props.modalBodyParams.timeZonesData}
            value={props.modalBodyParams.item.timeZone ? props.modalBodyParams.item.timeZone : ""}
            mandatory={true}
          />
        </GridItem>
      </GridContainer>
      
      <GridContainer>
        { 
          props.modalBodyParams.item.id !== "" &&
          !!props.modalBodyParams.item.isActive &&
          props.modalBodyParams.item.userRole !== "Call_Center" && 
            <GridItem sm={6} md={6} lg={6} xs={6} className="disabledCheckboxBlue">
              <Checkbox
                label={"Phone number is verified"}
                checked={props.modalBodyParams.item.isPhoneNoVerified ? true : false}
                onClick={() => {}}
                disabled={true}
              />
            </GridItem>
        }

        { 
          props.modalBodyParams.item.id !== "" &&
          !!props.modalBodyParams.item.isActive &&
          props.modalBodyParams.item.userRole !== "Call_Center" && 
            <GridItem sm={6} md={6} lg={6} xs={6} className="disabledCheckboxBlue">
              <Checkbox
                label={"User stopped notifications"}
                checked={props.modalBodyParams.item.isUserStopsNotifs ? true : false}
                onClick={() => {}}
                disabled={true}
              />
            </GridItem>
        }
      </GridContainer>

      <GridContainer>
        {
          props.modalBodyParams.item.id !== "" &&
          !!props.modalBodyParams.item.isActive &&
          props.modalBodyParams.item.userRole !== "Call_Center" && 
          (!props.modalBodyParams.item.isPhoneNoVerified && !props.modalBodyParams.item.isUserStopsNotifs) &&
            <GridItem sm={3} md={3} lg={3} xs={3}>
              <SaveBtn
                name={"Verify again"}
                className={"VerifyBtn"}
                onClick={props.modalBodyParams.sendVerificationActionAlert}
                disabled={false}
              />
            </GridItem>
        }
      </GridContainer>

    </GridContainer>
  )
}

export default UserFormBodyMutation;
import Tooltip from "@material-ui/core/Tooltip";
import FilterListIcon from "@material-ui/icons/FilterList";

const FilterButton = (props) => {
  return (
    <div id={props.filterButton.filterButtonId} className="buttoncard">
      <Tooltip title="Filter Data" placement="top">
        <FilterListIcon
          className="tableFilterColumnIcon"
          onClick={props.filterButton.onClickFilterHandler}
        />
      </Tooltip>
      <span className="iconinfo">Filter Data</span>
    </div>
  );
};

export default FilterButton; 

import "react-infinite-calendar/styles.css";
import "react-modern-calendar-datepicker/lib/DatePicker.css";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckboxNew from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

// import GridContainer from "../Grid/GridContainer";
import GridContainer from "../Grid/GridContainerRoot";
// import GridItem from "../Grid/GridItem";
import GridItem from "../Grid/GridItemRoot";

import {
  useStyles
} from "./helpers/filterHelper";

const FilterOptions = (props) => {
  const TableClass = useStyles();

  const filterOptionsData = typeof props.filterOptionsData === 'string' ? JSON.parse(props.filterOptionsData) : props.filterOptionsData;
  const filterOptionsDataRemappedByValue = {}
  filterOptionsData.map(item => {
    filterOptionsDataRemappedByValue[`${item.value}`] = item
  })
  
  const optionsKeys = Object.keys(props.state).filter(item => item.startsWith(`${props.filterOptionsTag}_`));

  return (
    <div>
      <div className="columnHeader displayFlex">
        <div className="columnFilterHeaderTitle width100 displayFlex">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <p className="columnHeaderp">
                {props.filterOptionsName}
              </p>
            </GridItem>
          </GridContainer>
        </div>
      </div>

      <div className="FilterItems">      
        <br/>
        <div className="FilterParentDiv" style={{ paddingLeft: "10px" }}>
          <div data-list-scroll-container="true">
            <div className="displayFlex width100">
              <div className="width90">
                { optionsKeys?.length > 0 && optionsKeys.map(optionKey => {
                                const optionKeySuffix = optionKey.split(`${props.filterOptionsTag}_`)[1];

                    return  (                     
                      <div className="Entry-Checkbox">
                        <FormControlLabel
                          style={{ color: "#337ab7" }}
                          control={
                            <CheckboxNew
                              style={{ color: "#rgb(52, 179, 68)", padding: "6px" }}
                              icon={<CheckBoxOutlineBlankIcon />}
                              checkedIcon={
                                <CheckBoxIcon
                                  fontSize="small"
                                  style={{ color: "rgb(52, 179, 68)" }}
                                />
                              }
                              checked={props.state[`${optionKey}`] === "false" ? false : true }
                              onChange={(event) => {                   

                                props.dispatch({
                                  type: "text",
                                  name: `${props.filterOptionsTag}_${event.target.value}`,
                                  value: `${event.target.checked}`,
                                });
                              }}
                            />
                          }
                          label={filterOptionsDataRemappedByValue[optionKeySuffix].label}
                          value={filterOptionsDataRemappedByValue[optionKeySuffix].value}
                        />
                      </div>
                    )
                  })
                }                  
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default FilterOptions;

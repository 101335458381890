import AddBtn from "../Buttons/AddButton";
import EditBtn from "../Buttons/EditButton";
import CloseBtn from "../Buttons/CloseButton";

const ModalFooterView = (props) => {
  return (
    <div className="padding2 displayFlex backgroundLight borderRadiusTop width100">
      <div className="displayFlex EntryHeaderViewBtn">
        {/* <EditBtn
          onClick={props.modalFooterParams.EditHandleClick}
          disabled={false}
        /> */}

        <CloseBtn
          disabled={false}
          onClick={() => {
            props.modalFooterParams.onClickClose()
          }}
        />
      </div>
    </div>   
  )
}

export default ModalFooterView;

const isValidMutationInput = (
  state,
  context
) => {
  if (!state.firstName.trim()) {
    context.alert({
      open: true,
      color: "error",
      message: ["Please enter the First Name"],
    });
    return false;
  }
  
  if (!state.lastName.trim()) {
    context.alert({
      open: true,
      color: "error",
      message: ["Please enter the Last Name"],
    });
    return false;
  }

  if (!state.username.trim()) {
    context.alert({
      open: true,
      color: "error",
      message: ["Please enter the User Name"],
    });
    return false;
  }

  if (!state.id && !state.password.trim()) {
    context.alert({
      open: true,
      color: "error",
      message: ["Please enter the Password"],
    });
    return false;
  }

  if (!state.userRole.trim()) {
    context.alert({
      open: true,
      color: "error",
      message: ["Please enter the User Role"],
    });
    return false;
  }

  if (!state.gender.trim()) {
    context.alert({
      open: true,
      color: "error",
      message: ["Please enter Gender"],
    });
    return false;
  }

  if (!state.birthDate || !state.birthDate.trim() || state.birthDate == 0 || state.birthDate == "Invalid Date") {
    context.alert({
      open: true,
      color: "error",
      message: ["Invalid Date of Birth"],
    });
    return false;
  }

  if (!state.phoneNo || state.phoneNo == 0) {
    context.alert({
      open: true,
      color: "error",
      message: ["Please enter the Phone No"],
    });
    return false;
  }

  if (!state.email.trim()) {
    context.alert({
      open: true,
      color: "error",
      message: ["Please enter the Official Email"],
    });
    return false;
  }  

  let vEmail =
    /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)$/.test(
      state.email,
    );

  if (state.email) {
    if (!vEmail) {
      context.alert({
        open: true,
        color: "error",
        message: ["Invalid Email"],
      });
      return false;
    }
  }

  if (!state.timeZone.trim()) {
    context.alert({
      open: true,
      color: "error",
      message: ["Please enter Time Zone"],
    });
    return false;
  }

  return true;
};

export { isValidMutationInput };

function VisibleItemSelect(
  val,
  setTableVisibleData,
  setTableVisibleItem,
  setTableBodyItem
) {
  const data = val;
  let arr = [];
  let bodyArr = [];
  let ItemArr = [];
  data.map((val) => {
    if (val.IsVisible == "1") {
      arr.push({
        id: val.FieldName,
        name: val.FieldDescription,
        type: val.FieldDataType,
      });

      bodyArr.push(val.FieldName);
      ItemArr.push(val.FieldDescription);
    }
  });
  setTableVisibleData(data);
  setTableVisibleItem(arr);
  setTableBodyItem(bodyArr);
}

const tableColumnsList = [
  {
    FieldName: "diagnosisCode",
    FieldDescription: "Diagnosis Code",
    IsVisible: true,
  },
  {
    FieldName: "description",
    FieldDescription: "Description",
    IsVisible: true,
  },
  {
    FieldName: "isActive",
    FieldDescription: "Active",
    IsVisible: true,
  },
];

export { 
  VisibleItemSelect, 
  tableColumnsList,
};

import { config } from "../../../configs/config";
import { requestAxios } from "../../../services/axiosClient";
import { updateConditionQuery } from "../../../helpers/utils";

const showErrorMessage = (responseError, showAlert, setLoading) => {
  const errorMessage = responseError?.message
    ? responseError?.message
    : "Bad Request";
  showAlert({ open: true, color: "error", message: [errorMessage] });
  setLoading(false);
};

const createFacilitiesAction = async (item, showAlert, setLoading) => {
  const url = "facilities";
  const params = {
    ...item,
  };
  const apiUrl = `${config.API_URL}/${url}`;
  const requestOptions = {
    requestType: "POST",
  };
  const [response, responseError] = await requestAxios(
    apiUrl,
    params,
    null,
    requestOptions,
  );

  if (responseError) {
    showErrorMessage(responseError, showAlert, setLoading);
    return;
  }

  showAlert({ open: true, color: "success", message: ["Facility was created"] });
  setLoading(false);

  return response;
};

const updateFacilitiesAction = async (item, showAlert, setLoading) => {
  const url = "facilities";
  const params = {
    ...item,
  };
  const apiUrl = `${config.API_URL}/${url}/${item.id}`;
  const requestOptions = {
    requestType: "PUT",
  };
  const [response, responseError] = await requestAxios(
    apiUrl,
    params,
    null,
    requestOptions,
  );

  if (responseError) {
    showErrorMessage(responseError, showAlert, setLoading);
    return;
  }

  showAlert({ open: true, color: "success", message: ["Facility was updated"] });
  setLoading(false);

  return response;
};

const deleteFacilitiesAction = async (
  item,
  showAlert = () => {},
  setLoading = () => {},
) => {
  const url = "facilities";

  let apiUrl;
  if (item.id) {
    apiUrl = `${config.API_URL}/${url}/${item.id}`;
  } else {
    showErrorMessage(null, showAlert, setLoading);
    return;
  }

  const requestOptions = {
    requestType: "DELETE",
  };
  const [response, responseError] = await requestAxios(
    apiUrl,
    null,
    null,
    requestOptions,
  );

  if (responseError) {
    showErrorMessage(responseError, showAlert, setLoading);
    return;
  }

  showAlert({ open: true, color: "success", message: ["Facility was deleted"] });
  setLoading(false);

  return response;
};

const getFacilitiesAction = async (
  item,
  showAlert = () => {},
  setLoading = () => {},
) => {
  const url = "facilities";

  let apiUrl;
  if (item.id) {
    apiUrl = `${config.API_URL}/${url}/id/${item.id}`;
  } else if (item.name) {
    apiUrl = `${config.API_URL}/${url}/name/${item.name}`;
  } else {
    showErrorMessage(null, showAlert, setLoading);
    return;
  }

  const requestOptions = {
    requestType: "GET",
  };
  const [response, responseError] = await requestAxios(
    apiUrl,
    null,
    null,
    requestOptions,
  );

  if (responseError) {
    showErrorMessage(responseError, showAlert, setLoading);
    return;
  }

  setLoading(false);

  return response;
};

const getListFacilitiesAction = async (
  itemsFilters,
  currentPaginationParams,
  showAlert = () => {},
  setLoading = () => {},
) => {
  const url = "facilities";

  let conditionQuery = '';

  conditionQuery = updateConditionQuery(conditionQuery, "statuses", itemsFilters?.facilitiesFilters?.statuses);

  const paginationQuery = `page_no=${currentPaginationParams.pageNo}&get_per_page=${currentPaginationParams.getPerPage}`;
  const apiUrl = `${config.API_URL}/${url}?${paginationQuery}${conditionQuery}`;
  const requestOptions = {
    requestType: "GET",
  };
  const [response, responseError] = await requestAxios(
    apiUrl,
    null,
    null,
    requestOptions,
  );

  if (responseError) {
    showErrorMessage(responseError, showAlert, setLoading);
    return;
  }

  setLoading(false);

  return response;
};

const searchFacilitiesAction = async (
  searchQuery,
  showAlert = () => {},
  setLoading = () => {},
) => {
  const url = "facilities";

  const apiUrl = `${config.API_URL}/${url}/search/${searchQuery}`;
  const requestOptions = {
    requestType: "GET",
  };
  const [response, responseError] = await requestAxios(
    apiUrl,
    null,
    null,
    requestOptions,
  );

  if (responseError) {
    showErrorMessage(responseError, showAlert, setLoading);
    return;
  }

  setLoading(false);

  return response;
};

const api2FrontMapper = (params) => {
  return {
    FacilityIDPK: params.id,
    FacilityCode: params.facilityCode,
    FacilityType: params.facilityType,
    FacilityName: params.facilityName,
    Address1: params.address,
    ZipCode: params.zipCode,
    PhoneNo1: params.phoneNo1,
    PhoneNo2: params.phoneNo2,
    Email1: params.email1,
    Email2: params.email2,
    ContactPerson: params.contactPerson,
    ContactPersonPhoneNo: params.contactPersonPhoneNo,
    ContactPersonEmail: params.contactPersonEmail,
    CityID: params.cityId,
    CityCode: params.cityCode,
    CityName: params.city,
    RegionID: params.regionId,
    RegionCode: params.regionCode,
    RegionName: params.region,
    CountryID: params.countryID,
    CountryCode: params.countryCode,
    CountryName: params.country,
    BranchCode: params.branchCode,

    IsActive: params.isActive,
    DeleStat: params.isDeleted,
    CreatedUserID: params.createdUserId,
    CreatedTtm: params.createdAt,
    UpdatedUserID: params.updatedUserId,
    UpdatedTtm: params.updatedAt,
  };
};

const front2ApiMapper = (params) => {
  return {
    id: !!params.id ? params.id : null,
    facilityCode: params.facilityCode,
    facilityName: params.facilityName,
    contactPerson: params.contactPerson,
    contactPersonPhoneNo: params.contactPersonPhoneNo,
    contactPersonEmail: !!params.contactPersonEmail ? params.contactPersonEmail : null,
    address: params.address,
    city: params.city,
    region: params.region,
    country: params.country,
    zipCode: params.ZipzipCodeCode,

    isActive: params.isActive
  };
};

export {
  getFacilitiesAction,
  getListFacilitiesAction,
  searchFacilitiesAction,
  createFacilitiesAction,
  updateFacilitiesAction,
  deleteFacilitiesAction,
  api2FrontMapper,
  front2ApiMapper,
};

import { countriesDataInit } from "../dataInit/countriesDataInit";
import { regionsDataInit } from "../dataInit/regionsDataInit";
import { timeZonesDataInit } from "../dataInit/timeZonesDataInit";

import { currentProceduralTerminologyCodesDataInit } from "../dataInit/currentProceduralTerminologyCodesDataInit";
import { designationsDataInit } from "../dataInit/designationsDataInit";
import { dispositionsDataInit } from "../dataInit/dispositionsDataInit";

import { consultTypesDataInit } from "../dataInit/consultTypesDataInit";
import { communicationMethodsDataInit } from "../dataInit/communicationMethodsDataInit";
import { specialitiesDataInit } from "../dataInit/specialitiesDataInit";

import { userRolesDataInit } from "../dataInit/userRolesDataInit";
import { gendersDataInit } from "../dataInit/gendersDataInit";

const defaultState = {
  error: "",
  
  userId: "",
  username: "",
  userRole: "",
  timeZone: "",
  
  menuData: "",

  countriesData: JSON.stringify(countriesDataInit),
  regionsData: JSON.stringify(regionsDataInit),
  timeZonesData: JSON.stringify(timeZonesDataInit),

  currentProceduralTerminologyCodesData: JSON.stringify(currentProceduralTerminologyCodesDataInit),
  designationsData: JSON.stringify(designationsDataInit),
  dispositionsData: JSON.stringify(dispositionsDataInit),

  consultTypesData: JSON.stringify(consultTypesDataInit),
  communicationMethodsData: JSON.stringify(communicationMethodsDataInit),
  specialitiesData: JSON.stringify(specialitiesDataInit),
  
  userRolesData: JSON.stringify(userRolesDataInit),
  gendersData: JSON.stringify(gendersDataInit),
};

export default function (state = defaultState, action = {}) {  
  switch (action.type) {
    case "error":
      return {
        ...state,
        error: action.error,
      };    
    case "userId":
      return {
        ...state,
        userId: action.userId,
      };
    case "username":
      return {
        ...state,
        username: action.username,
      };
    case "userRole":
      return {
        ...state,
        userRole: action.userRole,
      };
    case "timeZone":
      return {
        ...state,
        timeZone: action.timeZone,
      };    
    case "menuData":
      return {
        ...state,
        menuData: action.menuData,
      };
    case "gendersData":
      return {
        ...state,
        gendersData: action.gendersData,
      };
    case "countriesData":
      return {
        ...state,
        countriesData: action.countriesData,
      };
    case "regionsData":
      return {
        ...state,
        regionsData: action.regionsData,
      };  
    case "userRolesData":
      return {
        ...state,
        userRolesData: action.userRolesData,
      };
    case "currentProceduralTerminologyCodesData":
      return {
        ...state,
        currentProceduralTerminologyCodesData: action.currentProceduralTerminologyCodesData,
      };
    case "designationsData":
      return {
        ...state,
        designationsData: action.designationsData,
      };
    case "dispositionsData":
      return {
        ...state,
        dispositionsData: action.dispositionsData,
      };
    case "consultTypesData":
      return {
        ...state,
        consultTypesData: action.consultTypesData,
      };
    case "specialitiesData":
      return {
        ...state,
        specialitiesData: action.specialitiesData,
      };
    case "persist/REHYDRATE":
      // it's a dirty hack because redux's persist/REHYDRATE doesn't work :D 
      // see setup localStorage here in function setupLocalStorageOnLogin()
      const authStateRaw = localStorage.getItem("authState");
      if (action.payload?.userId === "" && authStateRaw) {
        const authState = JSON.parse(authStateRaw);
     
        return {
          ...state,
          userId: authState.userId,
          username: authState.username,
          userRole: authState.userRole,
          timeZone: authState.timeZone,
          menuData: authState.menuData
        };
      }

      return state;
    default:
      return state;
  }
}

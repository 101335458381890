const statusesData = JSON.stringify([
  {
    id: 'Active',
    label: "Active",
    value: "Active",
  },
  {
    id: 'Inactive',
    label: "Inactive",
    value: "Inactive",
  }
]);

const defaultFilters = {
  statuses: {
    'Active': "false",
    'Inactive': "false"
  }
}

export {
  statusesData,
  defaultFilters
}
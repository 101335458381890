import { format } from "date-fns";

import {
  getMeetingsFiltersDefault
} from "../helpers/meetingsQueueTableHelper";

import {
  getMeetingAction,
  getListMeetingAction,
  searchMeetingAction,
  createMeetingAction,
  updateMeetingAction,
  deleteMeetingAction,
  getProviderStatisticsAction,
  api2FrontMapper,
  front2ApiMapper,
} from "./actions";

import {
  getUserAction,
  getListUserAction,
  searchUserAction,
  createUserAction,
  updateUserAction,
  deleteUserAction,

} from "../../Users/actions/actions";
import {
  getFacilitiesAction,
  getListFacilitiesAction,
  searchFacilitiesAction,
  createFacilitiesAction,
  updateFacilitiesAction,
  deleteFacilitiesAction,
  api2FrontMapper as api2FrontMapperFacilities,
} from "../../Facilities/actions/actions";

const removeSubmit = async (
  context,
  state,
  setIsModalOpen,
) => {
  context.loading(true);
  context.ConfirmAlert(null);

  const item = front2ApiMapper(state);

  const result = await deleteMeetingAction(
    item,
    context.alert,
    context.loading
  );

  if (!result) {
    return;
  }

  setIsModalOpen(false);

  context.getCurrentProvider(result.userId)
  context.refreshProviderStatistics(result.userId);
  
  // if added or updated conuslt we abolish filters and jump to pending consults for provider
  context.refreshActiveCellStatistics("Pending");
  const meetingsFiltersDefault = getMeetingsFiltersDefault();  
  context.refreshTable(
    item.userId,
    // commonReactState.meetingsFilters
    {
      ...meetingsFiltersDefault,
      statuses: {
        'Not Started': "true",
        'Started': "true"
      }
    }
  );
}

const saveSubmit = async (
  context,
  state,
  setIsModalOpen,
  dispatch,
  initialStateMutationMeeting
) => {
  context.loading(true);
  context.ConfirmAlert(null);

  // const url = "AppointmentRegistry_Save/";
  // const SubmitParam = {
  //   data: [
  //     {
        // AppointmentIDPK: content.AppointmentRegistryAppointmentID,
        // AppointmentCode: state.AppointmentCode,
        // AppointmentDateTime: format(new Date(), "MM-dd-yyyy"),

        // DeleStat: false,
        // ClockWait: "",
        // NewCreatedTtm: format(new Date(), "MM-dd-yyyy hh:mm a"),

  //     },
  //   ],
  // };
 
  const item = front2ApiMapper(state);

  const result = !item.id
    ? await createMeetingAction({...item, status: "Not Started"}, context.alert, context.loading)
    : await updateMeetingAction(
        { ...item },
        context.alert,
        context.loading,
      );

  if (!result) {
    return;
  }
  
  setIsModalOpen(false);
  dispatch({
    type: "set_entire_state",
    value: initialStateMutationMeeting,
  });

  context.getCurrentProvider(item.userId)
  context.refreshProviderStatistics(item.userId);

  // if added or updated conuslt we abolish filters and jump to pending consults for provider
  context.refreshActiveCellStatistics("Pending");
  context.refreshTable(
    item.userId,
    {
      statuses: {
        'Not Started': "true",
        'Started': "true"
      }
    }
  );
};

export {
  saveSubmit,
  removeSubmit
};

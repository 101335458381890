import withStyles from "@material-ui/core/styles/withStyles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";

const Accordion = withStyles({
  rounded: {
    borderBottom: "1px solid #fff",
  },
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  content: {
    padding: "0px",
  },
})(MuiAccordionSummary);

export { Accordion, AccordionSummary };

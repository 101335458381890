import TableToolbar from "./TableToolbar";
import TableBody from "./TableBody";

const TableContainer = (props) => {
  return (
    <div className="tableCard" style={props.tableContainerParams.accordionDetailsStyles}>        
      <TableToolbar
        tableToolbarParams = {props.tableToolbarParams}   
        tablePaginationContainerParams = {props.tablePaginationContainerParams}               
      />
      <TableBody 
        tableBodyParams = {props.tableBodyParams}        
      />
    </div>   
  )
}

export default TableContainer;
import DialogActions from "@material-ui/core/DialogActions";

const ModalFooter = (props) => {
  return (
    <div>
      <DialogActions>
        <props.modalFooter
          modalFooterParams={props.modalFooterParams}
        />         
      </DialogActions>
    </div>      
  )
}

export default ModalFooter;
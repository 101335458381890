import React, { useState } from "react";
// import Button from "../CustomButtons/Button";
import Button from "../CustomButtons/ButtonRoot";
import Tooltip from "@material-ui/core/Tooltip";

export default function Buttons(props) {
  const submit = (e) => {
    props.onClick(e);
  };

  return (
    <Tooltip placement="top" title={props.title ? props.title : ""}>
      <Button
        onClick={submit}
        startIcon={props.startIcon}
        className={props.className}
        disabled={props.disabled}
      >
        {props.name}
      </Button>
    </Tooltip>
  );
}

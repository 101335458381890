import React, {
  forwardRef,
  useState
} from "react";

import TextField from "@material-ui/core/TextField";

import GridContainer from "../../../components/Grid/GridContainerRoot";
import GridItem from "../../../components/Grid/GridItemRoot";
import DropDown from "../../../components/_helperComponents/DropDown";
import Card from "../../../components/Card/CardRoot";
import CardBody from "../../../components/Card/CardBodyRoot";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";

const MeetingProviderFormBodyMutation = forwardRef((props, ref) => {
  const [isSelectModalOpenDiagnosisCode, setIsSelectModalOpenDiagnosisCode] = useState(false);

  return (
    <>
      <GridItem xs={12} sm={12} md={8}>
          <p className="consultDetails">Consult Details</p>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <DropDown 
                placeholder="Select CPT Code"            
                onChange={(e) => {
                  props.modalBodyParams.dispatch({
                    type: "select",
                    name: "currentProceduralTerminologyCode",
                    value: e,
                  });
                }}       
                data={props.modalBodyParams.currentProceduralTerminologyCodesData}   
                value={props.modalBodyParams.item.currentProceduralTerminologyCode ? props.modalBodyParams.item.currentProceduralTerminologyCode : ""}
                disabled={["Cancelled", "Completed"].includes(props.modalBodyParams.item.status) ? true : false}
              />                     
            </GridItem>

            {
              !["Cancelled", "Completed"].includes(props.modalBodyParams.item.status) 
              ?            
                <GridItem xs={12} sm={12} md={8}>
                  <Autocomplete
                    options={props.modalBodyParams.diagnosisList}
                    onOpen={() => {
                      setIsSelectModalOpenDiagnosisCode(true);
                    }}
                    onClose={() => {
                      setIsSelectModalOpenDiagnosisCode(false);
                    }}
                    onChange={(e, value) => {
                      props.modalBodyParams.dispatch({
                        type: "text",
                        name: "diagnosisId",
                        value: value?.id ? value.id : "",
                      });
                      props.modalBodyParams.dispatch({
                        type: "text",
                        name: "diagnosisCode",
                        value: value?.diagnosisCode ? value.diagnosisCode : "",
                      });
                      props.modalBodyParams.dispatch({
                        type: "text",
                        name: "description",
                        value: value?.description ? value.description : "",
                      });
                    }}
                    getOptionLabel={(options) =>
                      `${options.diagnosisCode} - ${options.description}`
                    }
                    open={isSelectModalOpenDiagnosisCode}
                    disabled={["Cancelled", "Completed"].includes(props.modalBodyParams.item.status) ? true : false}
                    id="Diagnosis-Code-search"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search Diagnosis Code"
                        margin="none"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </GridItem>
              :
                <GridItem xs={12} sm={12} md={8}>
                  <TextField
                    id="standard-basic"
                    label="Diagnosis Code"
                    value={ props.modalBodyParams.item.diagnosisCode ? `${props.modalBodyParams.item.diagnosisCode} - ${props.modalBodyParams.item.description}` : ""}
                    style={{ width: "100%" }}
                    disabled={["Cancelled", "Completed"].includes(props.modalBodyParams.item.status) ? true : false}
                  />
                </GridItem>
            }

            <GridItem
              xs={12}
              sm={12}
              md={4}
              style={{ marginTop: "10px" }}
            >
              <DropDown 
                placeholder="Disposition"            
                onChange={(e) => {
                  props.modalBodyParams.dispatch({
                    type: "select",
                    name: "disposition",
                    value: e,
                  });
                }}       
                data={props.modalBodyParams.dispositionsData}   
                value={props.modalBodyParams.item.disposition ? props.modalBodyParams.item.disposition : ""}
                disabled={["Cancelled", "Completed"].includes(props.modalBodyParams.item.status) ? true : false}
              />           
            </GridItem>
            {
              props.modalBodyParams.item.disposition === "Other" && (
                <GridItem xs={12} sm={12} md={4} className="flexEnd">
                  <TextField
                    error={!props.modalBodyParams.item.dispositionDetails}
                    className="with-100"
                    style={{ marginTop: "20px" }}
                    id="standard-basic"
                    label="Enter your Disposition "
                    value={props.modalBodyParams.item.dispositionDetails ? props.modalBodyParams.item.dispositionDetails : ""}
                    onChange={(e) => {
                      props.modalBodyParams.dispatch({
                        type: "text",
                        name: "dispositionDetails",
                        value: e.target.value,
                      });
                    }}
                    disabled={["Cancelled", "Completed"].includes(props.modalBodyParams.item.status) ? true : false}
                  />
                </GridItem>
            )}

            <GridItem xs={12} sm={12} md={4} className="flexEnd">
              <TextField
                id="standard-basic"
                label="Remarks"
                value={props.modalBodyParams.item.remarks ? props.modalBodyParams.item.remarks : ""}
                style={{ width: "100%" }}
                onChange={(e) => {
                  props.modalBodyParams.dispatch({
                    type: "text",
                    name: "remarks",
                    value: e.target.value,
                  });
                }}
                disabled={["Cancelled", "Completed"].includes(props.modalBodyParams.item.status) ? true : false}
              />
            </GridItem>
          </GridContainer>
        </GridItem>   
         
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer style={{ marginTop: "5px" }}>
            <GridItem xs={12} sm={12} md={12}>
              <Card
                style={{
                  backgroundColor: "rgb(220 220 220 / 32%)",
                  margin: "0",
                  borderRadius: "6px"
                }}
              >
                <CardBody style={{ padding: "5px 20px" }}>
                  <h2 className="Register">Reason For Consult:</h2>
                  <Typography className="patientKey1" gutterBottom>
                    <span className="patientValue">
                      {props.modalBodyParams.item.reasonForMeeting}
                    </span>
                  </Typography>
                </CardBody>
              </Card>
            </GridItem>
        </GridContainer>    
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>
          <GridContainer style={{ marginTop: "5px" }}>
            <GridItem xs={12} sm={12} md={12}>
              <Card
                style={{
                  backgroundColor: "rgb(220 220 220 / 32%)",
                  margin: "0",
                  borderRadius: "6px"
                }}
              >
                <CardBody style={{ padding: "5px 20px" }}>
                  <h2 className="Register">Cancel Reason For Consult:</h2>
                  <Typography className="patientKey1" gutterBottom>
                    <span className="patientValue">
                      {props.modalBodyParams.item.cancelReason}
                    </span>
                  </Typography>
                </CardBody>
              </Card>
            </GridItem>
        </GridContainer>    
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>
          <GridContainer style={{ marginTop: "5px" }} >
            <GridItem xs={12} sm={12} md={12}>
              <Card
                style={{
                  backgroundColor: "rgb(220 220 220 / 32%)",
                  margin: "0",
                  borderRadius: "6px"
                }}
              >
                <CardBody style={{ padding: "5px 20px" }}>
                  <h2 className="Register">Cancel Reason Remarks:</h2>
                  <Typography className="patientKey1" gutterBottom>
                    <span className="patientValue">
                      {props.modalBodyParams.item.cancelRemarks}
                    </span>
                  </Typography>
                </CardBody>
              </Card>
            </GridItem>
        </GridContainer>    
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>
          <GridContainer style={{ marginTop: "5px" }}>
            <GridItem xs={12} sm={12} md={12}>
              <Card
                style={{
                  backgroundColor: "rgb(220 220 220 / 32%)",
                  margin: "0",
                  borderRadius: "6px"
                }}
              >
                <CardBody style={{ padding: "5px 20px" }}>
                  <h2 className="Register">Reason For Reassign:</h2>
                  <Typography className="patientKey1" gutterBottom>
                    <span className="patientValue">
                      {props.modalBodyParams.item.reasonForReassign}
                    </span>
                  </Typography>
                </CardBody>
              </Card>
            </GridItem>
        </GridContainer>    
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>
          <GridContainer style={{ marginTop: "5px" }}>
            <GridItem xs={12} sm={12} md={12}>
              <Card
                style={{
                  backgroundColor: "rgb(220 220 220 / 32%)",
                  margin: "0",
                  borderRadius: "6px"
                }}
              >
                <CardBody style={{ padding: "5px 20px" }}>
                  <h2 className="Register">Provider before latest Reassign:</h2>
                  <Typography className="patientKey1" gutterBottom>
                    <span className="patientValue">
                      {props.modalBodyParams.latestReassignUser}
                    </span>
                  </Typography>
                </CardBody>
              </Card>
            </GridItem>
        </GridContainer>    
      </GridItem>
    </>
  );
});

export default MeetingProviderFormBodyMutation; 
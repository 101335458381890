import {
  getUserAction,
  getListUserAction,
  searchUserAction,
  createUserAction,
  updateUserAction,
  deleteUserAction,
  api2FrontMapper,
  front2ApiMapper,
} from "./actions";

const removeSubmit = async (
  context,
  itemId,
  setIsModalOpen,
) => {
  context.loading(true);
  context.ConfirmAlert(null);

  const item = {
    id: itemId,
  };
  const result = await deleteUserAction(item, context.alert, context.loading);

  if (!result) {
    return;
  }

  setIsModalOpen(false);
  
  context.refreshTable();
}

const saveSubmit = async (
  context,
  state,
  setIsModalOpen,
  dispatch,
  initialStateMutationUser
) => {
  context.loading(true);
  context.ConfirmAlert(null);

  const item = front2ApiMapper(state);

  const result = !state.id
    ? await createUserAction(item, context.alert, context.loading)
    : await updateUserAction(
        { ...item },
        context.alert,
        context.loading,
      );

  if (!result) {
    return;
  }
  
  setIsModalOpen(false);
  dispatch({
    type: "set_entire_state",
    value: initialStateMutationUser,
  });

  context.refreshTable();
};

export {
  saveSubmit,
  removeSubmit
};

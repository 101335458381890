import {
  getUserAction,
  getListUserAction,
  searchUserAction,
  createUserAction,
  updateUserAction,
  deleteUserAction,
  api2FrontMapper,
  front2ApiMapper,
} from "../../../actions/actions";

const changeTimeZoneAction = async (
  timeZoneModalId,
  timeZoneModalValue,
  userId,
  dispatchRedux,
  setOpenModalTimeZone,
  setOpenProfile,
) => {
  const item = {
    id: userId,
  };
  const user = await getUserAction(item);
  if (!user) {
    return;
  }

  user.timeZone = timeZoneModalValue;
  const updatedUser = await updateUserAction(user);
  if (!updatedUser) {
    return;
  }
  dispatchRedux({ type: "timeZone", timeZone: timeZoneModalId });

  setOpenModalTimeZone(false);
  setOpenProfile(false);
};

export { changeTimeZoneAction };

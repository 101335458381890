import { format, differenceInMinutes } from "date-fns";
import { 
  getLocalizedDateFrom,
  getLocalizedDateCurrent,
  getUtcDateCurrent,
  getUtcDateFrom,
  getUtcDate,
  getZuluCurrent,
  getZuluFrom
} from "../../../helpers/utils";

import {
  getMeetingAction,
  getListMeetingAction,
  searchMeetingAction,
  createMeetingAction,
  updateMeetingAction,
  deleteMeetingAction,
  getProviderStatisticsAction,
  api2FrontMapper,
  front2ApiMapper,
} from "../../Meetings/actions/actions";

const saveSubmit = async (
  context,
  state,
  setIsModalOpen,
  dispatch,
  initialStateMutationMeeting,
  providerId,
  meetingsFilters,
) => {
  const timeZone = localStorage.getItem("timeZone");

  context.loading(true);
  context.ConfirmAlert(null);

  const meetingUpdate = {};
  let messageStatus = "updated";

  if (!["Started", "Not Started"].includes(state.status)) {
    setIsModalOpen(false);
    dispatch({
      type: "set_entire_state",
      value: initialStateMutationMeeting,
    });
    context.alert({ open: true, color: "error", message: ['Meeting has wrong status. Should be Started or Not Started'] });

    return;
  }

  if (state.status === "Not Started") {
    meetingUpdate.status = "Started";
    messageStatus = "Started";

      // const currentDate = getLocalizedDateCurrent(timeZone); // startTime but localized
      // console.log("Started timeZone",timeZone)
      // console.log("Started createdAt ",state.createdAt)
      // console.log("Started currentDate ",currentDate)

    // meetingUpdate.waitingTime = differenceInMinutes(new Date(currentDate, new Date(state.createdAt));    
      // console.log("Started new Date(createdAt) ",new Date(state.createdAt))      
      // console.log("Started new Date(currentDate) ",new Date(currentDate))
      // console.log("Started meetingUpdate.waitingTime",meetingUpdate.waitingTime)

      const zuluCurrent = getZuluCurrent(timeZone);
      const zuluCreatedAt = getZuluFrom(state.createdAt, timeZone)
      // console.log("Started zuluCurrent new Date(zuluCurrent)",zuluCurrent, new Date(zuluCurrent))
      // console.log("Started zuluCreatedAt new Date(zuluCreatedAt)",zuluCreatedAt, new Date(zuluCreatedAt))

      meetingUpdate.waitingTime =  differenceInMinutes(new Date(zuluCurrent), new Date(zuluCreatedAt));
      // console.log("Started waitingTimeCalculated CALC format",meetingUpdate.waitingTime)

      meetingUpdate.startTime = getUtcDateCurrent(timeZone);

      
  } else if (state.status === "Started") {
    meetingUpdate.status = "Completed";
    messageStatus = "Completed";
    
    const endTime = getLocalizedDateCurrent(timeZone);
    meetingUpdate.duration = differenceInMinutes(new Date(endTime), new Date(state.startTime))
    meetingUpdate.endTime = getUtcDateFrom(endTime);
  }

  // const currentDate = getLocalizedDateCurrent(timeZone);

  const item = front2ApiMapper({...state, ...meetingUpdate });

  if (!item.id) {
    setIsModalOpen(false);
    dispatch({
      type: "set_entire_state",
      value: initialStateMutationMeeting,
    });
    context.alert({ open: true, color: "error", message: ['Missed meeting id'] });

    return;
  }
  meetingUpdate.status == "Completed" && delete item.startTime;

  const result = await updateMeetingAction(
        { ...item },
        context.alert,
        context.loading,
      );

  if (!result) {
    return;
  }
  
  setIsModalOpen(false);
  dispatch({
    type: "set_entire_state",
    value: initialStateMutationMeeting,
  });
  context.alert({ open: true, color: "success", message: [`Consult was ${messageStatus}`] });
  
  // providerId && context.refreshProviderStatistics(providerId);
  // if added or updated conuslt we abolish filters and jump to pending consults for provider
  // providerId && context.refreshActiveCellStatistics("Pending");
  context.refreshTable(
    providerId,
    meetingsFilters
  );
};

export {
  saveSubmit,
}
const consultTypesDataInit = [
  {
    id: "Inpatient_Visit",
    label: "Inpatient Visit",
    value: "Inpatient Visit",
  },
  {
    id: "Emergency_Visit",
    label: "Emergency Visit",
    value: "Emergency Visit",
  },
];

export { consultTypesDataInit };

const designationsDataInit = [
  {
    id: "PHYSICIAN_ASSISTANT",
    label: "Physician Assistant",
    value: "Physician Assistant",
  },
  {
    id: "NURSE_PRACTITIONER",
    label: "Nurse Practitioner",
    value: "Nurse Practitioner",
  },
  {
    id: "MEDICAL_DOCTOR",
    label: "Medical Doctor",
    value: "Medical Doctor",
  },
];

export { designationsDataInit };

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useReducer,
  useContext,
} from "react";
import {  useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  getDiagnosisCodesList,
} from "../../DiagnosisCodes/actions/viewListDiagnosisCodesAction";

import mutationReactReducer from "../../../reactReducers/mutationReactReducer";

import { MeetingsContext } from "../../Meetings/context/meetingsContextProvider";

import {
  initialStateMutationMeeting,
} from "../../Meetings/helpers/mutationMeetingHelpers";

import {
  saveSubmit,
} from "../actions/mutationMeetingAction";

import { isValidMutationInput } from "../validations/mutationMeetingValidator";

import 
  MeetingMutationProviderModal
from "./MeetingMutationProviderModal";
 
import ModalWindowMutation from "../../../components/ModalWindow/ModalWindowMutation";
import ModalHeaderMutation from "../../../components/ModalWindow/ModalHeaderMutation";
import ModalFooterMutation from "../../../components/ModalWindow/ModalFooterMutation";

const MeetingMutationProvider = forwardRef((props, ref) => {
  const authStoreRedux = useSelector((state) => state.auth);

  const context = useContext(MeetingsContext);
  const { commonReactState, commonReactDispatch } = context;

  const [state, dispatch] = useReducer(mutationReactReducer, initialStateMutationMeeting);

  const [isModalOpen, setIsModalOpen] = useState(false); 
 
  const [diagnosisList, setDiagnosisList] = useState([]);
  const [diagnosisPagination, setDiagnosisPagination] = useState({
    pageNo: 1,
    getPerPage: 100,
    itemsTotalNumber: 0,
    pagesTotalNumber: 0
  });

  const userRole = localStorage.getItem("userRole");
  const userId = localStorage.getItem("userId");

  const defaultFilters = {
    statuses: {
      'Active': "true",
      'Inactive': "false"
    }
  }

  useImperativeHandle(ref, () => ({
    openModal: (item) => {
      const itemState = item ? item : initialStateMutationMeeting;
      
      dispatch({
        type: "set_entire_state",
        value: itemState,
      });

      setIsModalOpen(true);
    },
    closeModal: () => {
      setIsModalOpen(false);

      dispatch({
        type: "set_entire_state",
        value: initialStateMutationMeeting,
      });
    },
  }));

  useEffect(() => {
    if (commonReactState.currentModalItem) {
      dispatch({
        type: "set_entire_state",
        value: commonReactState.currentModalItem,
      });
    }
  }, [commonReactState.currentModalItem]);

  useEffect(() => {
    getDiagnosisCodesList(
      setDiagnosisList,
      context,
      {
        diagnosisCodesFilters: defaultFilters
      },
      {
        pageNo: 1,
        getPerPage: 1000,
        pagination: 1,
        setPagination: () => {}
      }
    );
  }, []); 

  const saveAlert = () => {
    context.ConfirmAlert(
      <SweetAlert
        warning
        Size="sm"
        style={{ display: "block", marginTop: "100px" }}
        title={"Are you sure?"}
        onConfirm={async () => {         
          const providerId = userRole !== "Provider" ? null : userId;
          const meetingsFilters = commonReactState.meetingsFilters ? commonReactState.meetingsFilters : {
            statuses: {
              'Not Started': "true",
              'Started': "true"
            }
          };

          saveSubmit(
            context,
            state,
            setIsModalOpen,
            dispatch,
            initialStateMutationMeeting,
            providerId,
            meetingsFilters
          );
        }}
        onCancel={() => {
            context.ConfirmAlert(null);
            setIsModalOpen(false);
            dispatch({
              type: "set_entire_state",
              value: initialStateMutationMeeting,
            });
          }
        }
        confirmBtnCssClass={"sweetAlertBtn sweetAlertBtnSuccess"} 
        cancelBtnCssClass={"sweetAlertBtn sweetAlertBtnCancel"}
        confirmBtnText={"Yes"}
        cancelBtnText={"Cancel"}
        showCancel
      >
        {"Do you want to Save?"}
      </SweetAlert>,
    );
  };

  const modalWindowParams = {
    isModalOpen, 
    setIsModalOpen,
    fullWidth: true,
    maxWidth: 'md'
  }

  const modalHeaderParams = {
    modalItemName: "Consult Page",
    modalItemId: state.id
  };

  let latestReassignUser = "";
  context.providersList.map((value) => {
    if (state?.latestReassignUserId === value.id) {
      latestReassignUser = `${value.firstName} ${value.lastName}`
    }
  });

  const modalBodyParams = {
    item: state,
    latestReassignUser,
    dispatch,
    diagnosisList,
    currentProceduralTerminologyCodesData: JSON.parse(authStoreRedux.currentProceduralTerminologyCodesData),
    dispositionsData: JSON.parse(authStoreRedux.dispositionsData),
  };

  const onClickSave = () => {
    if (!isValidMutationInput(state, context)) {
      return;
    } 
    
    saveAlert();
  }
  
  const onClickCancel = () => {
    if (!isValidMutationInput(state, context)) {
      return;
    } 
    
    saveAlert();

    // calcTime(
    //   dispatch,
    //   authStoreRedux.timeZone,
    //   submitConfirmation,
    //   state,
    //   context,
    //   saveAlert,
    //   config,
    // )
    // if (!state.ConEndTime) {
    //   context.Refresh("PendingConsults", "");
    // }
  }

  const onClickClose = () => {
    dispatch({
      type: "set_entire_state",
      value: initialStateMutationMeeting,
    });
    setIsModalOpen(false);
  } 

  const modalFooterParams = {
    state,
    dispatch,
    skipActiveButton: true,
    onClickSave,
    actionButtonName: 'Start Consult',
    isActionButtonDisabled: ["Cancelled", "Completed", "Started"].includes(state.status) ? true : false,
    onClickCancel,
    cancelButtonName: "End Consult",
    isCancelButtonDisabled: ["Cancelled", "Completed", "Not Started"].includes(state.status) ? true : false,
    onClickClose
  };

  return (
    <ModalWindowMutation 
      modalWindowParams = {modalWindowParams}

      modalHeader = {ModalHeaderMutation}
      modalHeaderParams = {modalHeaderParams}

      modalBody = {MeetingMutationProviderModal}
      modalBodyParams = {modalBodyParams}

      modalFooter = {ModalFooterMutation}
      modalFooterParams = {modalFooterParams}
    />
  );
});

export default MeetingMutationProvider;

import Icon from "@material-ui/core/Icon";
import DescriptionIcon from "@material-ui/icons/Description";
import CloseIcon from "@material-ui/icons/Close";

import {
  makeTabActive,
} from "../../../MainContent/components/TabsBar/helpers/tabsBarHelpers";

const TabLink = (props) => {
  return (
    <div className="tabcontain" onClick={() => makeTabActive(props.menuItem.MenuID)}>
      <span className={"tabIcons spanTab"}>
        {props.menuItem.MenuIconFont ? (
          <Icon className="SubMenuIcon">{props.menuItem.MenuIconFont}</Icon>
        ) : (
          <DescriptionIcon className="SubMenuIcon" />
        )}
      </span>
      <span className="tabcontent">{props.menuItem.MenuDisplayName}</span>
      <span className="tooltitle" style={{ float: "right" }}>
        <CloseIcon
          className={"closeBtn"}
          onClick={(e) => {
            props.closeTabHandler(e, props.menuItem.MenuID);
          }}
        />
        <span className="tooltiptext tooltip-right">Close</span>
      </span>
    </div>
  );
}
  
export default TabLink;

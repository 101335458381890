import React, {
  forwardRef,
} from "react";

// import GridContainer from "../../../components/Grid/GridContainer";
import GridContainer from "../../../components/Grid/GridContainerRoot";
// import GridItem from "../../../components/Grid/GridItem";
import GridItem from "../../../components/Grid/GridItemRoot";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const MeetingTimeStats = forwardRef((props, ref) => {
  return (
    <GridItem xs={12} sm={12} md={12}>
      <div className="displayFlex" style={{ alignItems: "center" }}>
              <GridContainer
                spacing={2}
                style={{ width: "100%", margin: "0", alignItems: "center" }}
              >
                <GridItem xs={6} sm={2} md={2}>
                  <h2 className="dialogTitle">Consult Page</h2>
                </GridItem>
                <GridItem xs={6} sm={1} md={1} className="display-sm-show">
                  <IconButton
                    aria-label="delete"
                    style={{ float: "right" }}
                    color="secondary"
                    onClick={() => {
                      // clear(
                      //   setAccordianDetailData,
                      //   cdispatch,
                      //   setDialogDisabled,
                      //   setButtonDisabled,
                      //   ReduxDispatch,
                      //   document,
                      //   setActive,
                      //   dispatch,
                      // );
                      // setOpen(false);
                      // if (!state.ConEndTime) {
                      //   context.Refresh("PendingConsults", "");
                      // }
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={3}
                  md={3}
                  style={{ borderRight: "1px solid #eee" }}
                >
                  <h4 className="consultim">
                    Consult Start Time : {props.modalBodyParams.item.startTime}{/*state.ConStartTime*/}
                  </h4>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={3}
                  md={3}
                  style={{ borderRight: "1px solid #eee" }}
                >
                  <h4 className="consultim">
                    Consult End Time : {props.modalBodyParams.item.endTime}{/*state.ConEndTime*/}
                  </h4>
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                  <h4 className="consultim">
                    Consult Duration : {props.modalBodyParams.item.duration}{/*state.ConTotalTime*/}{" "}
                  </h4>
                </GridItem>
                {/* <GridItem xs={1} sm={1} md={1} className="display-sm-hide">
                  <IconButton
                    aria-label="delete"
                    color="secondary"
                    onClick={() => {
                      // clear(
                      //   setAccordianDetailData,
                      //   cdispatch,
                      //   setDialogDisabled,
                      //   setButtonDisabled,
                      //   ReduxDispatch,
                      //   document,
                      //   setActive,
                      //   dispatch,
                      // );
                      // setOpen(false);
                      // if (!state.ConEndTime) {
                      //   context.Refresh("PendingConsults", "");
                      // }
                    }}
                  >
                  </IconButton>
                </GridItem> */}
              </GridContainer>
            </div>
    </GridItem>
  );
});

export default MeetingTimeStats;

import {
  format,
  differenceInMinutes,
  getYear,
  endOfMonth,
  startOfMonth,
} from "date-fns";

import {
  updateMeetingAction
} from "../../Meetings/actions/actions";

import {
  TotalTime,
  AppointmentDiff,
  tableRow,
  Refresh,
} from "../helpers/tableProviderServicesHelper";

import {
  getUserAction,
  getListUserAction,
  searchUserAction,
  createUserAction,
  updateUserAction,
  deleteUserAction,
  api2FrontMapper,
  front2ApiMapper,
} from "../../Users/actions/actions";

const CommonAPISelect = async()=>{}

const getExcelExportData = (
  val,
  AppointmentRegistryData,
  timeZone,
  context,
  setExportData,
  AppointmentType,
  CurrentDate,
  TableTotalCount,
  config,
  authStoreRedux,
) => {
  if (AppointmentRegistryData.length == 0) {
    return false;
  }
  context.loading(true);
  let url = "AppointmentRegistry_Select/";
  let params = {
    data: {
      AppointmentIDPK: val && val.AppointmentIDPK ? val.AppointmentIDPK : null,
      AppointmentCode: val && val.AppointmentCode ? val.AppointmentCode : null,
      AppointmentDateTime:
        (val && val.CreatedTtm) || AppointmentType === "PendingConsults"
          ? null
          : CurrentDate,
      AppointmentDateTimeType:
        val && val.AppointmentDateTimeType ? val.AppointmentDateTimeType : null,
      AppointmentDateTimeBetween:
        val && val.AppointmentDateTimeBetween
          ? val.AppointmentDateTimeBetween
          : null,
      Description: val && val.Description ? val.Description : null,
      PatFirstName: val && val.PatFirstName ? val.PatFirstName : null,
      PatMiddleName: val && val.PatMiddleName ? val.PatMiddleName : null,
      PatLastName: val && val.PatLastName ? val.PatLastName : null,
      PatDateofBirth: val && val.PatDateofBirth ? val.PatDateofBirth : null,
      PatDateofBirthType:
        val && val.PatDateofBirthType ? val.PatDateofBirthType : null,
      PatDateofBirthBetween:
        val && val.PatDateofBirthBetween ? val.PatDateofBirthBetween : null,
      PatMrnNumber: val && val.PatMrnNumber ? val.PatMrnNumber : null,
      PatCartNumber: val && val.PatCartNumber ? val.PatCartNumber : null,
      PatSiteCBNumber: val && val.PatSiteCBNumber ? val.PatSiteCBNumber : null,
      PatSiteName: val && val.PatSiteName ? val.PatSiteName : null,
      ConStartTime: val && val.ConStartTime ? val.ConStartTime : null,
      ConEndTime: val && val.ConEndTime ? val.ConEndTime : null,
      ConTotalTime: val && val.ConTotalTime ? val.ConTotalTime : null,
      EmployeeKey: val && val.EmployeeKey ? val.EmployeeKey : null,

      ConsultTypeID: val && val.ConsultTypeID ? val.ConsultTypeID : null,
      CPTID: val && val.CPTID ? val.CPTID : null,
      DxID: val && val.DxID ? val.DxID : null,
      EmployeeID:
        // localStorage.getItem("EmployeeID") == "undefined"
        authStoreRedux.EmployeeID == "undefined"
          ? null
          : authStoreRedux.EmployeeID, //localStorage.getItem("EmployeeID"),
      FacilityID: val && val.FacilityID ? val.FacilityID : null,
      SpecialityID: val && val.SpecialityID ? val.SpecialityID : null,
      IsActive: null,
      DeleStat: null,
      CreatedUserID: val && val.CreatedUserID ? val.CreatedUserID : null,
      CreatedTtm: val && val.CreatedTtm ? val.CreatedTtm : null,
      CreatedTtmType: val && val.CreatedTtmType ? val.CreatedTtmType : null,
      CreatedTtmBetween:
        val && val.CreatedTtmBetween ? val.CreatedTtmBetween : null,
      UpdatedTtm: null,
      UserCode: null,
      UserEmail: val && val.UserEmail ? val.UserEmail : null,
      UserMobile: val && val.UserMobile ? val.UserMobile : null,
      UserName: null,
      UserPassword: val && val.UserPassword ? val.UserPassword : null,
      ConsultTypeCode: val && val.ConsultTypeCode ? val.ConsultTypeCode : null,
      ConsultTypeName: val && val.ConsultTypeName ? val.ConsultTypeName : null,
      CPTCode: val && val.CPTCode ? val.CPTCode : null,
      DxCode: val && val.DxCode ? val.DxCode : null,
      DesignationID: val && val.DesignationID ? val.DesignationID : null,
      DesignationCode: val && val.DesignationCode ? val.DesignationCode : null,
      DesignationName: val && val.DesignationName ? val.DesignationName : null,
      SpecialityCode: val && val.SpecialityCode ? val.SpecialityCode : null,
      SpecialityName: val && val.SpecialityName ? val.SpecialityName : null,
      Title: val && val.Title ? val.Title : null,
      PhoneNo1: val && val.PhoneNo1 ? val.PhoneNo1 : null,
      PhoneNo2: val && val.PhoneNo2 ? val.PhoneNo2 : null,
      EmpEmail: val && val.EmpEmail ? val.EmpEmail : null,
      PersonalEmail: val && val.PersonalEmail ? val.PersonalEmail : null,
      EmpFirstName: val && val.EmpRealName ? val.EmpRealName : null,
      EmpLastName: val && val.EmpLastName ? val.EmpLastName : null,
      Gender: val && val.Gender ? val.Gender : null,
      CityID: val && val.CityID ? val.CityID : null,
      CityCode: val && val.CityCode ? val.CityCode : null,
      CityName: val && val.CityName ? val.CityName : null,
      RegionID: val && val.RegionID ? val.RegionID : null,
      RegionCode: val && val.RegionCode ? val.RegionCode : null,
      RegionName: val && val.RegionName ? val.RegionName : null,
      CountryID: val && val.CountryID ? val.CountryID : null,
      CountryCode: val && val.CountryCode ? val.CountryCode : null,
      CountryName: val && val.CountryName ? val.CountryName : null,
      Address1: val && val.Address1 ? val.Address1 : null,
      BranchCode: val && val.BranchCode ? val.BranchCode : null,
      ContactPerson: val && val.ContactPerson ? val.ContactPerson : null,
      ContactPersonEmail:
        val && val.ContactPersonEmail ? val.ContactPersonEmail : null,
      ContactPersonPhoneNo:
        val && val.ContactPersonPhoneNo ? val.ContactPersonPhoneNo : null,
      Email1: val && val.Email1 ? val.Email1 : null,
      Email2: val && val.Email2 ? val.Email2 : null,
      FacilityCode: val && val.FacilityCode ? val.FacilityCode : null,
      FacilityName: val && val.FacilityName ? val.FacilityName : null,
      FacilityType: val && val.FacilityType ? val.FacilityType : null,
      ZipCode: val && val.ZipCode ? val.ZipCode : null,
      PageIndex: 1,
      PageSize: TableTotalCount,
      Type: AppointmentType,
      OrderByType: null,
      SortOrder: null,
    },
  };

  const zones = {
    "UTC-05:00 - Eastern Standard Time": "-05:00",
    "UTC-06:00 - Central Standard Time": "-06:00",
    "UTC-07:00 - Mountain Standard Time": "-07:00",
    "UTC-08:00 - Pacific Standard Time": "-08:00",
  };
  CommonAPISelect(url, params).then((res) => {
    context.loading(false);
    if (res.Output.status.code == "200") {
      if (res.Output.data.length > 0) {
        let result = res.Output.data.length;
        if (result > 0) {
          var resultval = res.Output.data.map(function (el, i) {
            var CreatedTtm = el.CreatedTtm.replace(
              "Z",
              zones[authStoreRedux.timeZone],
            );
            if (new Date(CreatedTtm) == "Invalid Date") {
              context.alert({
                open: true,
                color: "error",
                message: ["Invalid TimeZone"],
              });
              return false;
            }

            if (el.ConStartTime) {
              var ConStartTime = el.ConStartTime.replace(
                "Z",
                zones[authStoreRedux.timeZone],
              );
              if (new Date(ConStartTime) == "Invalid Date") {
                context.alert({
                  open: true,
                  color: "error",
                  message: ["ConStartTime Invalid TimeZone"],
                });
                return false;
              }
            }
            if (el.ConEndTime) {
              var ConEndTime = el.ConEndTime.replace(
                "Z",
                zones[authStoreRedux.timeZone],
              );
              if (new Date(ConEndTime) == "Invalid Date") {
                context.alert({
                  open: true,
                  color: "error",
                  message: ["ConEndTime Invalid TimeZone"],
                });
                return false;
              }
            }

            var o = Object.assign({}, el);
            o.SNo = `${el.SNo} ${el.AppointmentDateTime}`;
            o.EmpRealName = el.EmpFirstName;
            o.EmpFirstName = el.EmpFirstName + " " + el.EmpLastName;
            o.ConStartTime = el.ConStartTime
              ? format(new Date(ConStartTime), "MM-dd-yyyy hh:mm aa")
              : "";
            o.ConEndTime = el.ConEndTime
              ? format(new Date(ConEndTime), "MM-dd-yyyy hh:mm aa")
              : "";
            o.ConTotalTime = el.ConEndTime
              ? TotalTime(el.ConStartTime, el.ConEndTime)
              : "";
            o.CreatedDateTime = el.CreatedTtm
              ? format(new Date(CreatedTtm), "MM-dd-yyyy hh:mm aa")
              : "";
            o.ActualApTime = el.NewCreatedTtm
              ? format(
                  new Date(el.NewCreatedTtm.replace(/-/g, "/")),
                  "MM-dd-yyyy hh:mm aa",
                )
              : "";
            o.AppointmentWaiting =
              el.CalDiff == "Closed"
                ? TotalTime(o.CreatedDateTime, el.ConStartTime)
                : el.AppointmentWaiting
                  ? AppointmentDiff(el.CreatedTtm, el.AppointmentWaiting)
                  : "No Data";

            return o;
          });

          let exportItem = [];
          resultval.map((value, i) => {
            let keys = [];
            tableRow.forEach((x) => {
              keys.push(value[x]);
            });
            exportItem.push(keys);
          });

          setExportData(exportItem);
        }
      }
    }
  });
};

const searchData = async (
  authStoreRedux,
  param,
  val,
  type,
  date,
  context,
  setTableTotal,
  setTableTotalCount,
  cdispatch,
  setAppointmentRegistryData,
  setAppointmentData,
  setGridPagination,
  CurrentDate,
  AppointmentType,
) => {
  context.loading(true);
  const url = "AppointmentRegistry_Select/";
  let params = {
    data: {
      AppointmentIDPK: val && val.AppointmentIDPK ? val.AppointmentIDPK : null,
      AppointmentCode: val && val.AppointmentCode ? val.AppointmentCode : null,
      AppointmentDateTime:
        date == ""
          ? null
          : // : localStorage.getItem("userid") == "60bce294098f7127b89d66a1" // todo
            // ? null
            CurrentDate,
      AppointmentDateTimeType:
        val && val.AppointmentDateTimeType ? val.AppointmentDateTimeType : null,
      AppointmentDateTimeBetween:
        val && val.AppointmentDateTimeBetween
          ? val.AppointmentDateTimeBetween
          : null,
      Description: val && val.Description ? val.Description : null,
      PatFirstName: val && val.PatFirstName ? val.PatFirstName : null,
      PatMiddleName: val && val.PatMiddleName ? val.PatMiddleName : null,
      PatLastName: val && val.PatLastName ? val.PatLastName : null,
      PatDateofBirth: val && val.PatDateofBirth ? val.PatDateofBirth : null,
      PatDateofBirthType:
        val && val.PatDateofBirthType ? val.PatDateofBirthType : null,
      PatDateofBirthBetween:
        val && val.PatDateofBirthBetween ? val.PatDateofBirthBetween : null,
      PatMrnNumber: val && val.PatMrnNumber ? val.PatMrnNumber : null,
      PatCartNumber: val && val.PatCartNumber ? val.PatCartNumber : null,
      PatSiteCBNumber: val && val.PatSiteCBNumber ? val.PatSiteCBNumber : null,
      PatSiteName: val && val.PatSiteName ? val.PatSiteName : null,
      ConStartTime: val && val.ConStartTime ? val.ConStartTime : null,
      ConEndTime: val && val.ConEndTime ? val.ConEndTime : null,
      ConTotalTime: val && val.ConTotalTime ? val.ConTotalTime : null,
      EmployeeKey: val && val.EmployeeKey ? val.EmployeeKey : null,

      ConsultTypeID: val && val.ConsultTypeID ? val.ConsultTypeID : null,
      CPTID: val && val.CPTID ? val.CPTID : null,
      DxID: val && val.DxID ? val.DxID : null,
      EmployeeID:
        authStoreRedux.EmployeeID == "undefined"
          ? // localStorage.getItem("EmployeeID") == "undefined"
            null
          : // : localStorage.getItem("EmployeeID"),
            authStoreRedux.EmployeeID,
      FacilityID: val && val.FacilityID ? val.FacilityID : null,
      SpecialityID: val && val.SpecialityID ? val.SpecialityID : null,
      IsActive: null,
      DeleStat: null,
      CreatedUserID: val && val.CreatedUserID ? val.CreatedUserID : null,
      CreatedTtm: val && val.CreatedTtm ? val.CreatedTtm : null,
      CreatedTtmType: val && val.CreatedTtmType ? val.CreatedTtmType : null,
      CreatedTtmBetween:
        val && val.CreatedTtmBetween ? val.CreatedTtmBetween : null,
      UpdatedTtm: null,
      UserCode: null,
      UserEmail: val && val.UserEmail ? val.UserEmail : null,
      UserMobile: val && val.UserMobile ? val.UserMobile : null,
      UserName: null,
      UserPassword: val && val.UserPassword ? val.UserPassword : null,
      ConsultTypeCode: val && val.ConsultTypeCode ? val.ConsultTypeCode : null,
      ConsultTypeName: val && val.ConsultTypeName ? val.ConsultTypeName : null,
      CPTCode: val && val.CPTCode ? val.CPTCode : null,
      DxCode: val && val.DxCode ? val.DxCode : null,
      Disposition: val && val.Disposition ? val.Disposition : null,
      DispositionText: val && val.DispositionText ? val.DispositionText : null,
      DesignationID: val && val.DesignationID ? val.DesignationID : null,
      DesignationCode: val && val.DesignationCode ? val.DesignationCode : null,
      DesignationName: val && val.DesignationName ? val.DesignationName : null,
      SpecialityCode: val && val.SpecialityCode ? val.SpecialityCode : null,
      SpecialityName: val && val.SpecialityName ? val.SpecialityName : null,
      Title: val && val.Title ? val.Title : null,
      PhoneNo1: val && val.PhoneNo1 ? val.PhoneNo1 : null,
      PhoneNo2: val && val.PhoneNo2 ? val.PhoneNo2 : null,
      EmpEmail: val && val.EmpEmail ? val.EmpEmail : null,
      PersonalEmail: val && val.PersonalEmail ? val.PersonalEmail : null,
      EmpFirstName: val && val.EmpRealName ? val.EmpRealName : null,
      EmpLastName: val && val.EmpLastName ? val.EmpLastName : null,
      Gender: val && val.Gender ? val.Gender : null,
      CityID: val && val.CityID ? val.CityID : null,
      CityCode: val && val.CityCode ? val.CityCode : null,
      CityName: val && val.CityName ? val.CityName : null,
      RegionID: val && val.RegionID ? val.RegionID : null,
      RegionCode: val && val.RegionCode ? val.RegionCode : null,
      RegionName: val && val.RegionName ? val.RegionName : null,
      CountryID: val && val.CountryID ? val.CountryID : null,
      CountryCode: val && val.CountryCode ? val.CountryCode : null,
      CountryName: val && val.CountryName ? val.CountryName : null,
      Address1: val && val.Address1 ? val.Address1 : null,
      BranchCode: val && val.BranchCode ? val.BranchCode : null,
      ContactPerson: val && val.ContactPerson ? val.ContactPerson : null,
      ContactPersonEmail:
        val && val.ContactPersonEmail ? val.ContactPersonEmail : null,
      ContactPersonPhoneNo:
        val && val.ContactPersonPhoneNo ? val.ContactPersonPhoneNo : null,
      Email1: val && val.Email1 ? val.Email1 : null,
      Email2: val && val.Email2 ? val.Email2 : null,
      FacilityCode: val && val.FacilityCode ? val.FacilityCode : null,
      FacilityName: val && val.FacilityName ? val.FacilityName : null,
      FacilityType: val && val.FacilityType ? val.FacilityType : null,
      ZipCode: val && val.ZipCode ? val.ZipCode : null,
      PageIndex: param.current,
      PageSize: param.pageSize,
      Type: type ? type : AppointmentType ? AppointmentType : "AppointmentID",
      OrderByType: null,
      SortOrder: null,
    },
  };

  await CommonAPISelect(url, params).then((res) => {
    context.loading(false);
    if (!res) {
      return false;
    }
    if (res.Output.status.code == "200") {
      if (res.Output.data.length > 0) {
        let result = res.Output.data.length;
        let totalRow = 0;
        if (result > 0) {
          totalRow = parseFloat(res.Output.data[0].TotalCount, 10) / 10;
        } else {
          totalRow = 0;
        }
        if (param.pageSize == 10) {
          setTableTotal(Math.ceil(totalRow));
        } else if (param.pageSize == 20) {
          setTableTotal(Math.ceil(totalRow / 2));
        } else if (param.pageSize == 50) {
          setTableTotal(Math.ceil(totalRow / 5));
        } else if (param.pageSize == 100) {
          setTableTotal(Math.ceil(totalRow / 10));
        }
        setTableTotalCount(res.Output.data.length);
        // cdispatch({ type: "count", payload: res.Output.data.length });
        const zones = {
          "UTC-05:00 - Eastern Standard Time": "-05:00",
          "UTC-06:00 - Central Standard Time": "-06:00",
          "UTC-07:00 - Mountain Standard Time": "-07:00",
          "UTC-08:00 - Pacific Standard Time": "-08:00",
        };
        var resultval = res.Output.data.map(function (el, i) {
          var CreatedTtm = el.CreatedTtm.replace(
            "Z",
            zones[authStoreRedux.timeZone],
          );
          if (new Date(CreatedTtm) == "Invalid Date") {
            context.alert({
              open: true,
              color: "error",
              message: ["Invalid TimeZone"],
            });
            return false;
          }
          if (el.ConStartTime) {
            var ConStartTime = el.ConStartTime.replace(
              "Z",
              zones[authStoreRedux.timeZone],
            );
            if (new Date(ConStartTime) == "Invalid Date") {
              context.alert({
                open: true,
                color: "error",
                message: ["ConStartTime Invalid TimeZone"],
              });
              return false;
            }
          }
          if (el.ConEndTime) {
            var ConEndTime = el.ConEndTime.replace(
              "Z",
              zones[authStoreRedux.timeZone],
            );
            if (new Date(ConEndTime) == "Invalid Date") {
              context.alert({
                open: true,
                color: "error",
                message: ["ConEndTime Invalid TimeZone"],
              });
              return false;
            }
          }

          var o = Object.assign({}, el);
          o.SNo = `${el.SNo} ${el.AppointmentDateTime}`;
          o.EmpRealName = el.EmpFirstName;
          o.EmpFirstName = el.EmpFirstName + " " + el.EmpLastName;
          o.ConStartTime = el.ConStartTime
            ? format(new Date(ConStartTime), "MM-dd-yyyy hh:mm aa")
            : "";
          o.ConEndTime = el.ConEndTime
            ? format(new Date(ConEndTime), "MM-dd-yyyy hh:mm aa")
            : "";
          o.ConTotalTime = el.ConEndTime
            ? TotalTime(el.ConStartTime, el.ConEndTime)
            : "";
          o.CreatedDateTime = el.CreatedTtm
            ? format(new Date(CreatedTtm), "MM-dd-yyyy hh:mm aa")
            : "";
          o.ActualApTime = el.NewCreatedTtm
            ? format(
                new Date(el.NewCreatedTtm.replace(/-/g, "/")),
                "MM-dd-yyyy hh:mm aa",
              )
            : "";

          o.AppointmentWaiting =
            el.CalDiff == "Closed"
              ? TotalTime(o.CreatedDateTime, el.ConStartTime)
              : el.AppointmentWaiting
                ? AppointmentDiff(el.CreatedTtm, el.AppointmentWaiting)
                : "No Data";
          return o;
        });

        setAppointmentRegistryData(resultval);
        setAppointmentData(resultval);
      } else {
        setAppointmentRegistryData([]);
        setAppointmentData([]);
        setGridPagination({ current: 1, pageSize: 10 });
        setTableTotal(0);
        setTableTotalCount(0);
        // cdispatch({ type: "count", payload: 0 });

        if (Object.values(val).some((e) => e)) {
          context.alert({
            open: true,
            color: "warning",
            message: ["There is no Record Found"],
          });
        }
      }
    } else {
      context.alert({
        open: true,
        color: "warning",
        message: [res.Output.status.message],
      });
    }
  });
};

const AppointmentCount = (
  localId,
  context,
  setTotal,
  setQueue,
  setClosed,
  setPending,
) => {
  context.loading(true);

  const url = "AppointmentRegistry_Count/";
  let params = {
    data: {
      EmployeeID: localId == "undefined" ? null : localId,
      AppointmentDateTime: format(new Date(), "MM-dd-yyyy"),
      PageIndex: 1,
      PageSize: 10,
      Type: "EmployeeID",
      OrderByType: null,
      SortOrder: null,
    },
  };

  CommonAPISelect(url, params).then((res) => {
    context.loading(false);
    if (res.Output.status.code == "200") {
      if (res.Output.data.length > 0) {
        if (res.Output.data) {
          let result = res.Output.data;

          setTotal(result[0].TotalApptCount);
          setQueue(result[0].TotalApptInQueue);
          setClosed(result[0].TotalApptCompletedCount);
          setPending(result[0].TotalApptNotCompletedCount);
        }
      }
    }
  });
};

const Employeelist = async (Id, context, setEmployeeData) => {
  context.loading(true);

  let data;
  let result;
  if (!!!Id) {
    const pageNo = 1;
    const getPerPage = 100;
    const resList = await getListUserAction(pageNo, getPerPage);
    result = resList?.nodes;
    if (!result) {
      return;
    }
    data = result.map((item) => api2FrontMapper(item));
    if (!result) {
      return;
    }
  } else {
    result = await getUserAction(Id);
    data = api2FrontMapper(result);
  }

  if (data) {
    setEmployeeData(data);
  }

  context.loading(false);
};

const checkInAction = async (item, context, toast) => {
  const result = await updateMeetingAction(
    { 
      ...item,
      checkedIn: !item.checkedIn
    },
    context.alert,
    context.loading,
  );

  if (result) {
    toast.success(
      !item.checkedIn
      ? "Checked in successfully"
      : "Unchecked in successfully",
    )
  } else {
    toast.error('toast err');
  }
}

export {
  searchData,
  AppointmentCount,
  checkInAction,
};

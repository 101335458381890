import menuLogo from "../assets/images/reviveMenuLogo.png";
import reviveTelehealth from "../../../../Auth/components/Login/assets/images/reviveTelehealth_03.png";

const Logo = (props) => {
  return (
    <>
      {!props.isSideMenuHasMinWidth ? (
        <div style={{ position: "fixed" }}>
          <img className="Logomenu" src={menuLogo} alt="Menulogo" />
        </div>
      ) : (
        <div style={{ position: "fixed" }}>
          <img className="LogomenuExp" src={reviveTelehealth} alt="logo" />
        </div>
      )}
    </>
  )
}

export default Logo;
   
import DialogContent from "@material-ui/core/DialogContent";

const ModalBody = (props) => {
  return (
    <>
      <DialogContent
        style={{ minWidth: "500px" }}
        id="classic-modal-slide-description"
        // className={props.classes.modalBody}
      >
        <props.modalBody
          modalBodyParams={props.modalBodyParams}
        />   
      </DialogContent>
    </>      
  )
}

export default ModalBody;
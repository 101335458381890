import {
  getProviderStatisticsAction
} from "./actions"

const refreshProviderStatistics = async (
  providerId,
  setTotalMeetingsCounter = ()=>{},
  setCancelledMeetingsCounter = ()=>{},
  setCompletedMeetingsCounter = ()=>{},
  setPendingMeetingsCounter = ()=>{},
  setQueuedMeetingsCounter = ()=>{}
) => {
  const result = await getProviderStatisticsAction(providerId);
  if (!result) {
    setTotalMeetingsCounter(0);
    setCancelledMeetingsCounter(0);
    setCompletedMeetingsCounter(0);
    setPendingMeetingsCounter(0);
    setQueuedMeetingsCounter(0);

    return
  }
  
  setTotalMeetingsCounter(result.totalMeetingsCounter);
  setCancelledMeetingsCounter(result.cancelledMeetingsCounter);
  setCompletedMeetingsCounter(result.completedMeetingsCounter);
  setPendingMeetingsCounter(result.pendingMeetingsCounter);
  setQueuedMeetingsCounter(result.pendingMeetingsCounter);
};

export {
  refreshProviderStatistics
};

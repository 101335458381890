const dispositionsDataInit = [
  {
    id: "Admit_to_Inpatient_Involuntary",
    label: "Admit to Inpatient - Involuntary",
    value: "Admit to Inpatient - Involuntary",
  },
  {
    id: "Admit_to_Inpatient_Voluntary",
    label: "Admit to Inpatient - Voluntary",
    value: "Admit to Inpatient - Voluntary",
  },
  {
    id: "Outpatient",
    label: "Discharge Home/Outpatient",
    value: "Discharge Home/Outpatient",
  },
  {
    id: "Other",
    label: "Other",
    value: "Other",
  },
];

export { dispositionsDataInit };

import React from "react";
import Button from "../_helperComponents/Button.js";
import CancelPresentationOutlinedIcon from "@material-ui/icons/Clear";

export default function SaveButton(props) {
  return (
    <Button
      formctrl_id={props.formctrl_id}
      className="CloseBtnCommon boxShadowNone"
      title={"Close"}
      // name={t("Close")}
      disabled={props.disabled}
      onClick={props.onClick}
      startIcon={<CancelPresentationOutlinedIcon className="CloseBtnIcon" />}
    />
  );
}

import Icon from "@material-ui/core/Icon";

import {
  openNewTab,
} from "./helpers/sideMenuHelpers";

const NextLevelMenuItem = (props) => {

  return (
    <a
      className="padd5 tagripple"
      onClick={() => {
        openNewTab(
          props.subMenuItem,
          props.setMessage,
          props.openedTabsList,
          props.setOpenedTabsList,                   
          props.setLoading,
          props.setConfirmationAlert,
          props.store,
          props.persistor,
          props.closeTabHandler
        )
      }}
    >
      <Icon className="iconsm">{props.subMenuItem.MenuIconFont}</Icon>
      <span className="subfontSty">
        {props.subMenuItem.MenuDisplayName}
      </span>
    </a>
  );
}

export default NextLevelMenuItem;

const communicationMethodsDataInit = [
  {
    id: "Video",
    label: "Video",
    value: "Video",
  },
  {
    id: "Cart",
    label: "Cart",
    value: "Cart",
  }
];

export { communicationMethodsDataInit };

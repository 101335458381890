import "../../../assets/css/App.css";

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Page404 = () => {
  const authStoreRedux = useSelector((state) => state.auth);

  const [message, setMessage] = useState("");
  const [descrition, setDescrition] = useState("");

  const storedError = authStoreRedux.error;

  useEffect(() => {
    setDescrition(storedError);
    setMessage("Internal Server Error");
  }, [storedError]);

  return (
    <div className="c">
      <div className="_404">Page Not Found</div>
      <div className="_1">{message}</div>
      <div className="_2">{descrition}</div>
      <a className="btn" href="#/Login">
        Back To Login Page
      </a>
    </div>
  );
};

export default Page404;

import { 
  setupDispatchReduxOnLogin,
  setupLocalStorageOnLogin
} from "../helpers/loginHelpers";
import { config } from "../../../../../configs/config";
import { requestAxios } from "../../../../../services/axiosClient";

const loginAction = async (
  username,
  password,
  setLoading,
  setMessage,
  setIsOtpPageActive,
  history,
  dispatchRedux,
) => {
  setLoading(true);

  const url = "login";
  const params = {
    username: username,
    password: password,
  };
  const apiUrl = `${config.API_URL}/${url}`;

  const [response, responseError] = await requestAxios(
    apiUrl,
    params,
    dispatchRedux,
  );
  if (!response || responseError) {
    const errorMessage = responseError?.message
      ? responseError?.message
      : "Bad Request";

    setMessage({ open: true, color: "error", message: [errorMessage] });
    setLoading(false);

    return;
  }

  // localStorage.setItem("actionToken", response.actionToken);
  // localStorage.setItem("refreshToken", response.refreshToken);
  
  // setupLocalStorageOnLogin(response);
  // setupDispatchReduxOnLogin(response, dispatchRedux);

  if (!response.actionToken) { 
    setMessage({ open: true, color: "success", message: ["Your OTP was sent"] });
    setLoading(false);
    setIsOtpPageActive(true);
  } else {
    localStorage.setItem("actionToken", response.actionToken);
    localStorage.setItem("refreshToken", response.refreshToken);
    
    setupLocalStorageOnLogin(response);
    setupDispatchReduxOnLogin(response, dispatchRedux);

    setMessage({ open: true, color: "success", message: ["Login success"] });
    setLoading(false);

    history.push("/Home");
  }
};

const refreshTokenAction = async () => {
  const refreshToken = localStorage.getItem("refreshToken", response.refreshToken);
  if (!refreshToken) {
    return;
  }
  
  const url = "refresh-token";
  const params = {
    refreshToken
  };
  const apiUrl = `${config.API_URL}/${url}`;

  const [response, responseError] = await requestAxios(
    apiUrl,
    params,
    dispatchRedux,
  );
  if (!response || responseError) {
    const errorMessage = responseError?.message
      ? responseError?.message
      : "Bad Request";
    console.error("refreshTokenAction error", errorMessage);

    return;
  }

  localStorage.setItem("actionToken", response.actionToken);
};

export { 
  loginAction,
  refreshTokenAction, 
};

import "react-infinite-calendar/styles.css";

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useContext,
} from "react";

import {  
  clickFilterHandler
} from "../../../components/PageFilters/helpers/filterHelper";

import FilterSection from "./Filters/FilterSection";
import TableView from "../../../components/TableView/TableView";

import { initialStateMutationUser } from "../helpers/mutationUserHelper";
import { UsersContext } from "../context/usersContextProvider";

import {
  getUsersList,
  searchUsers,
} from "../actions/viewListUsersAction";

import {
  VisibleItemSelect,
  tableColumnsList,
} from "../helpers/tableUsersHelper";

import {
  defaultFilters
} from "./Filters/helpers/filtersDataHelper";

const UsersTable = forwardRef((props, ref) => {
  const context = useContext(UsersContext);
  const { commonReactState, commonReactDispatch } = context;

  const [usersList, setUsersList] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [pageFilters, setPageFilters] = useState(defaultFilters);

  const [pagination, setPagination] = useState({
    pageNo: 1,
    getPerPage: 10,
    itemsTotalNumber: 0,
    pagesTotalNumber: 0
  });
  
  const [TableVisibleData, setTableVisibleData] = useState([]);
  const [TableVisibleItem, setTableVisibleItem] = useState([]);
  const [TableBodyItem, setTableBodyItem] = useState([]);

  const stateName = 'users';
  const filterSectionId = `${stateName}_FilterDiv`;
  const filterButtonId = `${stateName}_FilterDiv`;
  const tableId = `${stateName}_TableDiv`;


  useEffect(() => {
    if (!isFiltersOpen) {
      setPageFilters(defaultFilters);
      context.refreshTable(defaultFilters);
    }    
  }, [isFiltersOpen]);

  useEffect(() => {
    VisibleItemSelect(
      tableColumnsList,
      setTableVisibleData,
      setTableVisibleItem,
      setTableBodyItem,
    );
  }, [
    JSON.stringify(
      tableColumnsList
    ),
  ]);

  useImperativeHandle(ref, () => ({
    getCurrentModalItem: (itemId) => {
      let item

      usersList.map((value) => {
        if (itemId === value.id) {
          item = value
        }
      });
      return item;

    },
    resetCurrentModalItem: () => {
      commonReactDispatch({
        type: "currentModalItem",
        payload: initialStateMutationUser,
      });
    },
    applySectionFilters: (sectionFilters) => {
      setPageFilters(sectionFilters);
    },
    refreshTable: (currentUsersFilters = null) => {
      refreshTable(currentUsersFilters);
    },
    
  }));

  const refreshTable = (currentUsersFilters = null) => {
    const usersFilters = currentUsersFilters ? currentUsersFilters : pageFilters;

    getUsersList(
      setUsersList,
      context,
      {
        usersFilters
      },
      {
        pageNo: pagination.pageNo,
        getPerPage: pagination.getPerPage,
        pagination,
        setPagination
      }
    );
  };

  const searchHandler = (e) => {
    setSearchInput(e);
    if (e.length == "0") {
      getUsersList(
        setUsersList,
        context,
        {
          usersFilters: pageFilters
        },
        {
          pageNo: pagination.pageNo,
          getPerPage: pagination.getPerPage,
          pagination,
          setPagination
        }
      );
      return false;
    } else {
      searchUsers(
        e,
        setUsersList,
        context,
        commonReactDispatch,
        {
          pageNo: 1,
          getPerPage: 10
        },
        pagination,
        setPagination
      );
    }
  }

  const closeSearchHandler = () => {
    refreshTable();
    setSearchInput("");
  }
  const onClickAdd = () => context.openModalMutation()

  const tableViewHeaderParams = {
    tableName: 'Users',
    itemsCountOnPage: usersList.length,//todo delete
    itemsTotalNumber: pagination.itemsTotalNumber,
    searchInput,
    searchHandler,
    closeSearchHandler,
    onClickAdd
  }

  const tableContainerParams = {}

  const onClickFilterHandler = () => {
    clickFilterHandler(isFiltersOpen, setIsFiltersOpen, tableId, filterButtonId, filterSectionId);
  }

  const refreshHandler = () => {
    setSearchInput("");
    getUsersList(
      setUsersList,
      context,
      {
        usersFilters: pageFilters
      },
      {
        pageNo: 1,
        getPerPage: 10,
        pagination,
        setPagination
      }
    );
  }
  
  const tableToolbarParams = {
    tableHeaderStyles: {},
    refreshHandler,
    filterButton: {
      onClickFilterHandler,
      filterButtonId
    }
  }

  const onChangePageHandler = (nextPageNo, nextGetPerPage, tablePaginationContainerParams) => {
    getUsersList(
      tablePaginationContainerParams.setItemsList,
      tablePaginationContainerParams.context,
      {
        usersFilters: pageFilters
      },
      {
        pageNo: nextPageNo,
        getPerPage: nextGetPerPage,
        pagination: tablePaginationContainerParams.pagination,
        setPagination: tablePaginationContainerParams.setPagination
      }
    );
  }

  const tablePaginationContainerParams = {
    setItemsList: setUsersList,
    context,
    commonReactDispatch,
    pagination,
    setPagination,
    onChangePageHandler
    // TableTotalCount,
    // page,
    // handleChangePage,
    // handleChangeRowsPerPage,
  }

  const tableBodyParams = {
    tableId: 'EmployeeRegistryTable',
    TableVisibleItem,
    // TableSearchParams,
    // searchHandler: bodySearchHandler,
    bodyData: usersList,
    TableBodyItem,

    isActionsVisible: true,
    isViewVisible: true,
    viewHandler: (row) => context.openModalView(row.id),
    isEditVisible: true,
    editHandler: (row) => context.openModalMutation(row.id),
    isRemoveVisible: true,
    removeHandler: (row) => context.removeAlertModal(row.id),
  } 
  
  return (
    <div
      className="displayFlex padding0"
      style={{ overflow: "auto" }}
    >
      {isFiltersOpen && (
        <FilterSection 
          stateName={stateName}
        />
      )}

      <div
        id={tableId}
        style={{ width: "100%", position: "relative" }}
      >
        <TableView 
          tableViewHeaderParams = {tableViewHeaderParams}
          tableContainerParams = {tableContainerParams}
          tableToolbarParams = {tableToolbarParams}
          tablePaginationContainerParams = {tablePaginationContainerParams}
          tableBodyParams = {tableBodyParams}
        />
      </div>
    </div>    
  );
});

export default UsersTable;

import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";

const RootMenuItem = (props) => {
  return (
    <div
      className="btnDiv ripple"
      id={props.rootMenuItem.MenuID}
      key={props.rootMenuItem.MenuID}
      onClick={props.clickHandler}
    >
      <div>
        <Tooltip placement="right" title={props.rootMenuItem.MenuDisplayName}>
          <span className="iconspan">
            {" "}
            <Icon className="iconpad">{props.rootMenuItem.MenuIconFont}</Icon>{" "}
          </span>
        </Tooltip>
      </div>
      {!props.isSideMenuHasMinWidth && (
        <span className="labeltxt"> {props.rootMenuItem.MenuDisplayName}</span>
      )}
    </div> 
  );
}

export default RootMenuItem;
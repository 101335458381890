function VisibleItemSelect(
  val,
  setTableVisibleData,
  setTableVisibleItem,
  setTableBodyItem
) {
  const data = val;
  let arr = [];
  let bodyArr = [];
  let ItemArr = [];
  data.map((val) => {
    if (val.IsVisible == "1") {
      arr.push({
        id: val.FieldName,
        name: val.FieldDescription,
        type: val.FieldDataType,
      });

      bodyArr.push(val.FieldName);
      ItemArr.push(val.FieldDescription);
    }
  });
  setTableVisibleData(data);
  setTableVisibleItem(arr);
  setTableBodyItem(bodyArr);
}


// todo add params where it used
const handleChangeRowsPerPage = (event, setPage) => {
  setPage(0);
};

const tableColumnsList = [
  {
    FieldName: "facilityCode",
    FieldDescription: "Facility Code",
    IsVisible: true
  },
  {
    FieldName: "facilityName",
    FieldDescription: "Facility Name",
    IsVisible: true
  },
];

export {
  VisibleItemSelect,
  handleChangeRowsPerPage,
  tableColumnsList,
};

const statusesData = JSON.stringify([
  {
    id: 'Not Started',
    label: "Not Started",
    value: "Not Started",
  },
  {
    id: 'Started',
    label: "Started",
    value: "Started",
  },
  {
    id: 'Cancelled',
    label: "Cancelled",
    value: "Cancelled",
  },
  {
    id: 'Completed',
    label: "Completed",
    value: "Completed",
  }
]);

const getDiagnosisCodesData = (diagnosisCodes) => { 
  const diagnosisCodesData = diagnosisCodes?.length > 0 
    ? JSON.parse(diagnosisCodes.map(item => {
          return {
            id: item.id,
            label: item.diagnosisCode,
            value: item.id,
          }
        })
      )
    : [];  

  return diagnosisCodesData;
}

const defaultFilters = {
  statuses: {
    'Active': "true",
    'Inactive': "false"
  }
}

export {
  statusesData,
  getDiagnosisCodesData,
  defaultFilters
}
import {
  getDiagnosisCodeAction,
  getListDiagnosisCodeAction,
  searchDiagnosisCodeAction,
  createDiagnosisCodeAction,
  updateDiagnosisCodeAction,
  deleteDiagnosisCodeAction,
  api2FrontMapper,
  front2ApiMapper,
} from "./actions";

const getPagesTotalNumber = (responseTotalCount, getPerPage) => {
  const totalRow = (responseTotalCount > 0) 
    ? parseFloat(responseTotalCount, 10) / 10
    : 0;
    
  let pagesTotalNumber = 0;
  if (getPerPage == 10) {
    pagesTotalNumber = Math.ceil(totalRow);
  } else if (getPerPage == 20) {
    pagesTotalNumber = Math.ceil(totalRow / 2);
  } else if (getPerPage == 50) {
    pagesTotalNumber = Math.ceil(totalRow / 5);
  } else if (getPerPage == 100) {
    pagesTotalNumber = Math.ceil(totalRow / 10);
  }

  return pagesTotalNumber;
}

const getDiagnosisCodesList = async (
  setDiagnosisCodesList,
  context,
  itemsFilters,
  currentPaginationParams,
) => {
  const response = await getListDiagnosisCodeAction(
    itemsFilters,
    currentPaginationParams,
    context.alert,
    context.loading,
  );

  const itemsRaw = response?.nodes ? response.nodes : [];

  if (!response || !itemsRaw || itemsRaw?.length <= 0) {
    currentPaginationParams.setPagination({ 
      pageNo: response?.pageInfo.pageNo ? response?.pageInfo.pageNo : 1, 
      getPerPage: response?.pageInfo.getPerPage ? response?.pageInfo.getPerPage : 10,
      itemsTotalNumber: 0, 
      pagesTotalNumber: 0 
    });
    setDiagnosisCodesList([]);

    return;
  }

  const items = itemsRaw.map(item => {
    return {
      ...item,
      value: item.id,
      label: `${item.diagnosisCode}`,
    }
  })

  const pagesTotalNumber = getPagesTotalNumber(response.totalCount, currentPaginationParams.pagination.getPerPage);
    
  currentPaginationParams.setPagination({ 
    pageNo: response.pageInfo.pageNo, 
    getPerPage: response.pageInfo.getPerPage, 
    itemsTotalNumber: response.totalCount, 
    pagesTotalNumber 
  });

  setDiagnosisCodesList(items);
};  
         
async function searchDiagnosisCodes(
  searchInputParam,
  setDiagnosisCodesList,
  context,
  commonReactDispatch,
  currentPaginationParams,
  pagination,
  setPagination
) {
  const response = await searchDiagnosisCodeAction(
    searchInputParam,
    context.alert,
    context.loading,
  );

  const items = response//.map((item) => api2FrontMapper(item));

  if (!items || items?.length <= 0) {
    // setPagination({ 
    //   pageNo: response.pageInfo.pageNo, 
    //   getPerPage: response.pageInfo.getPerPage, 
    //   itemsTotalNumber: 0, 
    //   pagesTotalNumber: 0 
    // });
    setDiagnosisCodesList([]);

    return;
  }
  
  setDiagnosisCodesList(items);
}

export {
  getDiagnosisCodesList,
  searchDiagnosisCodes,
};

import { config } from "../../../../../configs/config";
import { requestAxios } from "../../../../../services/axiosClient";

import { logoutAction } from "../../Logout/actions/logoutAction";
import { isValidInputPassword } from "../validations/changePasswordValidator";

const changePasswordAction = async (
  newPasswordObject,
  setLoading,
  setOpenProfile,
  history,
  setMessage,
  // dispatchRedux,
) => {
  if (!isValidInputPassword(newPasswordObject, setMessage)) {
    return;
  }

  setLoading(true);

  const url = "change-password";
  const params = {
    password: newPasswordObject.Confirm,
    password_old: newPasswordObject.Old,
  };
  const apiUrl = `${config.API_URL}/${url}`;

  const [response, responseError] = await requestAxios(apiUrl, params);
  if (!response || responseError) {
    const errorMessage = responseError?.message
      ? responseError?.message
      : "Bad Request";

    setMessage({ open: true, color: "error", message: [errorMessage] });
    setLoading(false);

    return;
  }

  setMessage({
    open: true,
    color: "success",
    message: [["Password Changed Successfully"]],
  });

  logoutAction(setOpenProfile, history, setMessage/*, dispatchRedux*/);
};

export { changePasswordAction };

import "react-infinite-calendar/styles.css";
import "react-modern-calendar-datepicker/lib/DatePicker.css";

// import GridContainer from "../Grid/GridContainer";
import GridContainer from "../Grid/GridContainerRoot";
// import GridItem from "../Grid/GridItem";
import GridItem from "../Grid/GridItemRoot";
import DropDown from "../_helperComponents/DropDown";

import {
  useStyles
} from "./helpers/filterHelper";

const FilterSelectProvider = (props) => {
  const TableClass = useStyles();

  const filterOptionsData = typeof props.filterOptionsData === 'string' ? JSON.parse(props.filterOptionsData) : props.filterOptionsData;

  return (
    <div>
      <div className="FilterItems">      

        <div className="columnHeader displayFlex">
          <div className="columnFilterHeaderTitle width100 displayFlex">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <p className="columnHeaderp">
                  {props.filterOptionsName}
                </p>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        {/* providerId providerName */}
        <div 
          className="FilterParentDiv"
          style={{ paddingLeft: "10px" }}  
        >
          <div data-list-scroll-container="true">
            <div className="displayFlex width100">
              <div className="width90">
                <GridItem xs={12} sm={12} md={12}>
                  <DropDown
                    placeholder="Provider"
                    onChange={props.onSelectChange}
                    data={filterOptionsData}
                    value={props.state.providerId ? props.state.providerId : "All"}
                    mandatory={true}
                  />          
                </GridItem>                
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default FilterSelectProvider;

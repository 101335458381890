import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useReducer,
  useContext,
} from "react";

import SweetAlert from "react-bootstrap-sweetalert";

import mutationReactReducer from "../../../reactReducers/mutationReactReducer";

import MeetingFormBodyCancel from "./MeetingFormBodyCancel";
import { MeetingsContext } from "../../Meetings/context/meetingsContextProvider";

import {
  initialStateMutationMeeting,
  cancelReasonsData
} from "../helpers/mutationMeetingHelpers";

import {
  saveSubmit,
  removeSubmit,
  refreshProviderStatistics
} from "../actions/mutationMeetingAction";

import { isValidMutationInputCancel } from "../validations/mutationMeetingValidator";

import ModalWindowMutation from "../../../components/ModalWindow/ModalWindowMutation";
import ModalHeaderMutation from "../../../components/ModalWindow/ModalHeaderMutation";
import ModalFooterMutation from "../../../components/ModalWindow/ModalFooterMutation";

const MeetingCancel = forwardRef((props, ref) => {
  const context = useContext(MeetingsContext);
  const { commonReactState, commonReactDispatch } = context;

  const [state, dispatch] = useReducer(mutationReactReducer, initialStateMutationMeeting);

  const [isModalOpen, setIsModalOpen] = useState(false); 

  useImperativeHandle(ref, () => ({
    openModalCancel: (meeting) => {     
      dispatch({
        type: "set_entire_state",
        value: meeting,
      });
      setIsModalOpen(true);
    },
    closeModalCancel: () => {
      setIsModalOpen(false);
    }
  }));

  useEffect(() => {
    if (commonReactState.currentModalItem) {
      dispatch({
        type: "set_entire_state",
        value: commonReactState.currentModalItem,
      });
    }
  }, [commonReactState.currentModalItem]);
 
  const saveAlert = () => {
    context.ConfirmAlert(
      <SweetAlert
        warning
        Size="sm"
        style={{ display: "block", marginTop: "100px" }}
        title={"Are you sure?"}
        onConfirm={() => {
          removeSubmit(
            context,
            state,
            setIsModalOpen,
          );
        }}
        onCancel={() => {
            context.ConfirmAlert(null);
            setIsModalOpen(false);
          }
        }
        confirmBtnCssClass={"sweetAlertBtn sweetAlertBtnSuccess"} 
        cancelBtnCssClass={"sweetAlertBtn sweetAlertBtnCancel"}
        confirmBtnText={"Yes"}
        cancelBtnText={"Cancel"}
        showCancel
      >
        {"Do you want to Cancel?"}
      </SweetAlert>,
    );
  };

  const modalWindowParams = {
    isModalOpen, 
    setIsModalOpen,
  }

  const modalHeaderParams = {
    modalItemName: "Are you sure you want to cancel this consult? If so, please state the reason for canceling.",
    modalItemNameActionIsSkipped: true,
    modalItemId: state.id,
  };
  
  const modalBodyParams = {
    item: state,
    dispatch,
    cancelReasonsData,
  };
  
  const onClickSave = () => {
    if (!isValidMutationInputCancel(state, context)) {
      return;
    } 
    
    saveAlert();
  }
  
  const onClickCancel = () => {
    dispatch({
      type: "set_entire_state",
      value: initialStateMutationMeeting,
    });
    setIsModalOpen(false);
  }

  const modalFooterParams = {
    state,
    dispatch,
    onClickSave,
    onClickCancel,
    skipActiveButton: true,
  };  

  return (         
    <ModalWindowMutation 
      modalWindowParams = {modalWindowParams}

      modalHeader = {ModalHeaderMutation}
      modalHeaderParams = {modalHeaderParams}

      modalBody = {MeetingFormBodyCancel}
      modalBodyParams = {modalBodyParams}

      modalFooter = {ModalFooterMutation}
      modalFooterParams = {modalFooterParams}
    />
  );
});

export default MeetingCancel; 

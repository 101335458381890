const specialitiesDataInit = [
  {
    id: "ADMIN",
    label: "Administration",
    value: "Administration",
  },
  {
    id: "CALL_CENTER",
    label: "Call Center",
    value: "Call Center",
  },
  {
    id: "PSYCHIATRY",
    label: "Psychiatry",
    value: "Psychiatry",
  },
];

export { specialitiesDataInit };

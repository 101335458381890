const dashboardStyles = {
  animationEnabled: true,
  height: 190,
  toolTip: {
    shared: true,
  },

  axisX: {
    gridThickness: 0,
  },
  axisY: {
    gridThickness: 0,
  },
  data: [
    {
      indexLabel: "{y}",
      indexLabelPlacement: "outside",
      indexLabelFontColor: "red",
      indexLabelFontSize: 9,
      indexLabelFontWeight: 800,
      type: "column",
      name: "Total Consults Booked",
      legendText: "Total Consults Booked",
      showInLegend: true,
      indexLabelFontColor: "#5A5757",
      dataPoints: [],
    },
    {
      indexLabel: "{y}",
      indexLabelPlacement: "outside",
      indexLabelFontColor: "green",
      indexLabelFontSize: 9,
      indexLabelFontWeight: 800,
      type: "column",
      name: "Closed Consults Booked",
      legendText: "Closed Consults Booked",
      showInLegend: true,
      indexLabelFontColor: "#5A5757",
      dataPoints: [],
    },
  ],
};

export { dashboardStyles };

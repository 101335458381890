import "./assets/css/maintenance.css";

import reviveLogo from "./assets/images/revive_logo.png";
import reviveMobileLogo from "./assets/images/revive_logo_mobile.png";
import maintenanceLogo from "./assets/images/maintenance_logo.png";

import GridContainer from "../../../../components/Grid/GridContainerRoot";
import GridItem from "../../../../components/Grid/GridItemRoot";

const Maintenance = () => {
  return (
    <GridContainer className="maintenance_container">
      <GridContainer className="maintenance_body">
        <GridItem xs={12} sm={5} md={5} lg={5} style={{ display: "flex" }}>
          <GridContainer className="maintenance_body_title">
            <GridItem xs={12} sm={12} md={12} lg={12} className="app_logo">
              <img
                src={reviveLogo}
                alt="reviveLogo"
                className="logo_desc"
              />
              <img
                src={reviveMobileLogo}
                alt="reviveLogo"
                className="logo_mobile"
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={12} lg={12}>
              <h1>Website Under 
                <p>Maintenance</p> 
              </h1>
            </GridItem>

            <GridItem xs={12} sm={12} md={12} lg={12}>
              <h3>This website is currently undergoing scheduled maintenance. We should be back shortly.</h3>
            </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem xs={12} sm={7} md={7} lg={7} style={{ display: "flex" }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <img
                src={maintenanceLogo}
                alt="maintenanceLogo"
                className="maintenance_logo"
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={12} lg={12} className="maintenance_logo_link">
              <p>Image made by <a href="https://storyset.com/online">StorySet</a></p>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </GridContainer>
  );
};

export default Maintenance;

const initSideMenu = (
  setLoading,
  setUserMenuDetails,
  authStoreRedux,
) => {
  setLoading(true);
  const userMenu = JSON.parse(authStoreRedux.menuData);
  let newMenu = [];

  if (userMenu.length > 0) {
    userMenu.map((val) => {
      var Sub1 = [];
      if (val.MenuOrder == "1") {
        newMenu.push({
          MenuID: val.MenuID,
          MenuDisplayName: val.MenuDisplayName,
          MenuIconFont: val.MenuIconFont,
          MenuOrder: val.MenuOrder,
          ParentMenu: val.ParentMenu,
          Sub1: Sub1,
        });
        userMenu.map((val2) => {
          if (val.MenuID == val2.ParentMenu) {
            Sub1.push({
              MenuDisplayName: val2.MenuDisplayName,
              MenuID: val2.MenuID,
              MenuIconFont: val2.MenuIconFont,
              MenuOrder: val2.MenuOrder,
              ParentMenu: val2.ParentMenu,
              Sub2: userMenu.filter((item) => item.ParentMenu == val2.MenuID),
            });
          }
        });
      }
    });

    setUserMenuDetails(newMenu);
  }
  setLoading(false);
};

export { initSideMenu };

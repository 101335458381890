import "react-infinite-calendar/styles.css";

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useContext,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import getSocketIOClient from "../../../services/socketIOClient";

import {
  clickFilterHandler
} from "../../../components/PageFilters/helpers/filterHelper";

import { MeetingsContext } from "../../Meetings/context/meetingsContextProvider";

import {
  getMeetingsList,
  
} from "../actions/meetingsQueueTableAction";
import {
  getMeetingsFiltersDefault,
  pendingMeetingsFilters

} from "../helpers/meetingsQueueTableHelper";
import {
  VisibleItemSelect,
  tableColumnsList,
  initialStateMeeting
} from "../helpers/meetingsQueueTableHelper";

import TableView from "../../../components/TableView/TableView";


import {  
  getInitialFiltersOptions,
  aggregateStateByFilterNames
} from "../../../components/PageFilters/helpers/filterHelper";

import FilterSection from "./Filters/FilterSection";

import {
  
  statusesData,
  callCenterInitialStateFiltersConfig,
  waitingTimesData
} from "./Filters/helpers/filtersDataHelper";

import {
  initialStateUser,
} from "../../Users/helpers/mutationUserHelper";

//AppointmentRegistry
const MeetingsQueueTable = forwardRef((props, ref) => {
  const context = useContext(MeetingsContext);
  const { commonReactState, commonReactDispatch } = context;

  const authStoreRedux = useSelector((state) => state.auth);
  
  const [meetingsList, setMeetingsList] = useState([]);

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [pageFilters, setPageFilters] = useState(pendingMeetingsFilters);

  const [pagination, setPagination] = useState({
    pageNo: 1,
    getPerPage: 10,
    itemsTotalNumber: 0,
    pagesTotalNumber: 0
  });

  const [TableVisibleItem, setTableVisibleItem] = useState([]);
  const [TableBodyItem, setTableBodyItem] = useState([]);

  const stateName = 'callCenter';
  const filterSectionId = `${stateName}_FilterDiv`;
  const filterButtonId = `${stateName}_FilterDiv`;
  const tableId = `${stateName}_TableDiv`;
  
  const userRole = localStorage.getItem("userRole");

  useImperativeHandle(ref, () => ({
    getCurrentProvider: (itemId) => {
      if (!itemId) {
        commonReactDispatch({
          type: "currentProvider",
          payload: initialStateUser,
        });
        return;
      }

      context.providersList.map((value) => {
        if (itemId === value.id) {
          commonReactDispatch({
            type: "currentProvider",
            payload: value,
          });
        }
      });
    },
    getCurrentModalItem: (itemId) => {

      // if (!itemId) {
      //   commonReactDispatch({
      //     type: "currentModalItem",
      //     payload: initialStateMeeting
      //   });
      //   return;
      // }
      let item

      meetingsList.map((value) => {
        if (itemId === value.id) {

          // commonReactDispatch({
          //   type: "currentModalItem",
          //   payload: value,
          item = value
          // });
        }
      });
      return item;
    },
    applySectionFilters: (sectionFilters) => {
      setPageFilters(sectionFilters);
    },
    getCurrentProviderId:()=>{
      // return pageProviderId
      return commonReactState.currentProvider.id

    },
    refreshTable: (
      providerId = null,
      currentMeetingsFilters = null
    ) => {
      const timeZone = localStorage.getItem("timeZone");


      const meetingsFilters = currentMeetingsFilters ? currentMeetingsFilters : pageFilters//

      getMeetingsList(
        setMeetingsList,
        context,
        commonReactDispatch,
        {
          providerId,
          timeZone,//: authStoreRedux.timeZone,
          meetingsFilters
        },
        {          
          pageNo: pagination.pageNo,
          getPerPage: pagination.getPerPage,
          pagination,
          setPagination
        }
      );
    },
  }));

  useEffect(() => {
    if (isFiltersOpen) {

      context.refreshActiveCellStatistics("");

      const optionsDataByFilterNames = {
        consultTypes: JSON.parse(authStoreRedux.consultTypesData),
        statuses: JSON.parse(statusesData),
        waitingTimes: JSON.parse(waitingTimesData),
      };
      const initialFiltersOptionsState = getInitialFiltersOptions(optionsDataByFilterNames);
      
      // const initialOptionsFilters = getInitialFiltersOptions(callCenterInitialStateFiltersConfig, authStoreRedux, context.diagnosisCodesList);
      // const meetingsFilters = getNormalizedState(callCenterInitialStateFiltersConfig, initialOptionsFilters, authStoreRedux, context.diagnosisCodesList);
      
      const optionsNamesConfig = { // key should be the same as optionsDataByFilterNames
        consultTypes: [],
        statuses: [],
        waitingTimes: [],
      }
      const meetingsFilters = aggregateStateByFilterNames(initialFiltersOptionsState, optionsNamesConfig);
      setPageFilters(meetingsFilters);

      context.refreshTable(
        commonReactState.currentProvider?.id,
        meetingsFilters
      );
    } else {
      context.getCurrentProvider();
      context.refreshActiveCellStatistics("Pending");
      context.refreshProviderStatistics();

      setPageFilters(pendingMeetingsFilters);
      context.refreshTable(
        null,
        pendingMeetingsFilters
      );
    }    
  }, [isFiltersOpen]);

  useEffect(() => {
    VisibleItemSelect(
      tableColumnsList,
      setTableVisibleItem,
      setTableBodyItem
    );
  }, [
    JSON.stringify(
      tableColumnsList,
    ),
  ]);

  useEffect(() => {
    const timeZone = localStorage.getItem("timeZone");
    const pageProviderId2 =  context.getCurrentProviderId()
    getMeetingsList(
      setMeetingsList,
      context,
      commonReactDispatch,
      {
        providerId: pageProviderId2,//commonReactState.currentProvider.id,
        timeZone,//: authStoreRedux.timeZone,
        meetingsFilters: pageFilters// isFiltersOpen ? commonReactState.meetingsFiltersCallCenter : meetingsFiltersDefault
      },
      {          
        pageNo: pagination.pageNo,
        getPerPage: pagination.getPerPage,
        pagination,
        setPagination
      }
    );
  }, []);

  useEffect(() => {
    const apiToken = localStorage.getItem("actionToken");

    const socketIOClient = getSocketIOClient(apiToken);
    socketIOClient.on("MEETINGS_UPDATES", (item) => {
      // refreshHandler();
      const pageProviderId2 =  context.getCurrentProviderId()
      // console.log("GOT SOCKET MEETINGS_UPDATES consult created  pageProviderId2",pageProviderId2)

      // console.log("refreshHandler SONE commonReactState.currentProvider.id"
      // ,commonReactState.currentProvider.id
      // ,pageFilters);

      const timeZone = localStorage.getItem("timeZone");

    getMeetingsList(
      setMeetingsList,
      context,
      commonReactDispatch,
      {
        providerId: pageProviderId2,// commonReactState.currentProvider.id,
        timeZone,//: authStoreRedux.timeZone,
        meetingsFilters: pageFilters// isFiltersOpen ? commonReactState.meetingsFiltersCallCenter : meetingsFiltersDefault
      },
      {          
        pageNo: pagination.pageNo,
        getPerPage: pagination.getPerPage,
        pagination,
        setPagination
      }
    );

    // socketIOClient.on("consult-created", (id) => {
    //   getMeetingsList(
    //     setMeetingsList,
    //     context,
    //     commonReactDispatch,
    //     {
    //       providerId: commonReactState.currentProvider.id,
    //       timeZone: authStoreRedux.timeZone,
    //       meetingsFilters: isFiltersOpen ? commonReactState.meetingsFiltersCallCenter : meetingsFiltersDefault
    //     },
    //     {          
    //       pageNo: 1,
    //       getPerPage: 10,
    //       pagination,
    //       setPagination
    //     }
    //   );
    });
  }, []);

  const onClickFilterHandler = () => {
    clickFilterHandler(isFiltersOpen, setIsFiltersOpen, tableId, filterButtonId, filterSectionId);
  }

  const onClickAdd = () => context.openModalMutation();

  const tableViewHeaderParams = {
    tableName: 'Provider Queue',
    itemsCountOnPage: meetingsList.length,//todo delete
    itemsTotalNumber: pagination.itemsTotalNumber,
    onClickAdd,
    skipButtonAdd: false,
    skipButtonSearch: true
  }

  const tableContainerParams = {};

  const refreshHandler = () => { 
   

    const timeZone = localStorage.getItem("timeZone");

    getMeetingsList(
      setMeetingsList,
      context,
      commonReactDispatch,
      {
        providerId: commonReactState.currentProvider.id,
        timeZone,//: authStoreRedux.timeZone,
        meetingsFilters: pageFilters// isFiltersOpen ? commonReactState.meetingsFiltersCallCenter : meetingsFiltersDefault
      },
      {          
        pageNo: 1,
        getPerPage: 10,
        pagination,
        setPagination
      }
    );
  }

  const tableToolbarParams = {
    tableHeaderStyles: {}, 
    refreshHandler,
    filterButton: {
      onClickFilterHandler,
      filterButtonId
    }
  }

  const onChangePageHandler = (nextPageNo, nextGetPerPage, tablePaginationContainerParams) => {
    const timeZone = localStorage.getItem("timeZone");

    getMeetingsList(
      setMeetingsList,
      context,
      commonReactDispatch,
      {
        providerId: commonReactState.currentProvider.id,
        timeZone,//: authStoreRedux.timeZone,
        meetingsFilters: pageFilters// isFiltersOpen ? commonReactState.meetingsFiltersCallCenter : meetingsFiltersDefault
      },
      {          
        pageNo: nextPageNo,
        getPerPage: nextGetPerPage,
        pagination: tablePaginationContainerParams.pagination,
        setPagination: tablePaginationContainerParams.setPagination
      }
    );
  }

  const tablePaginationContainerParams = {
    setItemsList: setMeetingsList,
    context,
    commonReactDispatch,
    pagination,
    setPagination,
    onChangePageHandler
  }
  
  const tableBodyParams = {
    tableId: 'AppointmentRegistryTable',
    TableVisibleItem,
    bodyData: meetingsList,
    TableBodyItem,

    isRowColorCalculated: true,
    
    isActionsVisible: true,
    isEditVisible: (userRole == "Administration" || userRole == "Call_Center") && true,
    editHandler: (row) => context.openModalMutation(row.id),
    isRemoveVisible: (userRole == "Administration" || userRole == "Call_Center") && true,
    removeHandler: (row) => context.openModalMutationCancel(row.id),
  }

  return (
    <div className="tableCard">
      <div
        className="displayFlex padding0"
        style={{ overflow: "auto" }}
      >
        {isFiltersOpen && (
          <FilterSection 
            stateName={stateName}
          />
        )}

        <div
          id={tableId}
          style={{ width: "100%", position: "relative" }}
        >
          <TableView 
            tableViewHeaderParams = {tableViewHeaderParams}
            tableContainerParams = {tableContainerParams}
            tableToolbarParams = {tableToolbarParams}
            tablePaginationContainerParams = {tablePaginationContainerParams}
            tableBodyParams = {tableBodyParams}
          />
        </div>
      </div>
    </div>
  );
});

export default MeetingsQueueTable; 

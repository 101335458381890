import "./assets/css/App.css";

import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";

import { config } from "./configs/config";
import HomePage from "./pages/HomePage/HomePage";
import PrivateRoute from "./pages/Auth/PrivateRoute";
import Login from "./pages/Auth/components/Login/Login";
// import IdleLogout from "./pages/Auth/components/Logout/IdleLogout";
import Page404 from "./pages/Auth/components/Page404";
import Maintenance from "./pages/Auth/components/Maintenance/Maintenance";

import configureStore from "./Redux/store";
const { store, persistor } = configureStore();

const element = (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <HashRouter>
        {(!config.IS_MAINTENANCE_MODE_ACTIVE) ? (
          <Switch>
            <PrivateRoute path="/Home" component={HomePage} />
            <Route path="/home" component={HomePage} />
            <Route path="/Page404" component={Page404} />
            <Route path="/login" component={Login} />
            <Route path="/maintenance" component={Maintenance} />

            <Redirect from="/" to="/login" />
          </Switch> 
        ) : (
          <Switch>
            <Route path="/maintenance" component={Maintenance} />
            <Redirect from="/" to="/maintenance" />
          </Switch>
        )}
        {/* <IdleLogout /> */}
      </HashRouter>
    </PersistGate>
  </Provider>
);

ReactDOM.render(element, document.getElementById("root"));

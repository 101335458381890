import "react-infinite-calendar/styles.css";
import "react-modern-calendar-datepicker/lib/DatePicker.css";

import ClearIcon from "@material-ui/icons/Clear";
import FilterListIcon from "@material-ui/icons/FilterList";
import MatBtn from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

const FilterSectionHeader = (props) => {

  return (
    <div
      className="columnHeader"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}
    >
      <div className="columnHeaderTitle" style={{ width: "auto" }}>
        <FilterListIcon className="columnHeaderIcon" />
        <p className="columnHeaderp">Filter</p>
      </div>
      <div className="" align="right">
        <Tooltip title={"clear"} placement="top">
          <MatBtn
            className="ColumnApplyButton"
            startIcon={<ClearIcon className="ColumnApplyIcon" />}
            onClick={() => {
              props.onClickClear();
            }}
          >
            <span className="ColumnApplyTitle">Clear</span>
          </MatBtn>
        </Tooltip>
      </div>
    </div>
  );
}

export default FilterSectionHeader;

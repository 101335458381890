const getNormalizedState = (initialOptionsFiltersConfig, state, authStoreRedux, diagnosisCodesList = false) => {
  if (!state) return {}

  const normilizedState = {...initialOptionsFiltersConfig};

  normilizedState.dateRangeFrom = state.dateRangeFrom;
  normilizedState.dateRangeTo = state.dateRangeTo;

  if (normilizedState.currentProceduralTerminologyCode) {
    const currentProceduralTerminologyCodesData = JSON.parse(authStoreRedux.currentProceduralTerminologyCodesData)
    currentProceduralTerminologyCodesData.map(item => {
      normilizedState['currentProceduralTerminologyCodes'][item.value] = state[`currentProceduralTerminologyCodes_${item.value}`]
    });
  }
  
  if (normilizedState.consultTypes) {
    const consultTypesData = JSON.parse(authStoreRedux.consultTypesData);
    consultTypesData.map(item => {
      normilizedState['consultTypes'][item.value] = state[`consultTypes_${item.value}`]
    });
  }
  
  if (normilizedState.statuses) {
    const statuses = JSON.parse(statusesData);

    statuses.map(item => {
      normilizedState['statuses'][item.value] = state[`statuses_${item.value}`]
    });
  }
  
  if (normilizedState.diagnosisCodes) {
    const diagnosisCodesData = diagnosisCodesList?.length > 0 ? diagnosisCodesList : [];
    diagnosisCodesData.map(item => {
      normilizedState['diagnosisCodes'][item.value] = state[`diagnosisCodes_${item.value}`]
    });
  }

  // if (normilizedState.waitingTimes) {
  //   const waitingTimeData = JSON.parse(waitingTimeList);
  //   waitingTimeData.map(item => {
  //     normilizedState['waitingTimes'][item.value] = state[`waitingTimes_${item.value}`]
  //   });
  // }

  return normilizedState;
}

const waitingTimesData = JSON.stringify([
  {
    id: '1-30 mins',
    label: "1-30 mins",
    value: "1-30 mins",
  },
  {
    id: '31-60 mins',
    label: "31-60 mins",
    value: "31-60 mins",
  },
  {
    id: '60+ mins',
    label: "60+ mins",
    value: "60+ mins",
  }
]);

const statusesData = JSON.stringify([
  {
    id: 'Not Started',
    label: "Not Started",
    value: "Not Started",
  },
  {
    id: 'Started',
    label: "Started",
    value: "Started",
  },
  {
    id: 'Cancelled',
    label: "Cancelled",
    value: "Cancelled",
  },
  {
    id: 'Completed',
    label: "Completed",
    value: "Completed",
  }
]);

export {
  statusesData,
  waitingTimesData
}
import React, {
  forwardRef,
} from "react";

// import GridContainer from "../../../components/Grid/GridContainer";
import GridContainer from "../../../components/Grid/GridContainerRoot";
// import GridItem from "../../../components/Grid/GridItem";
import GridItem from "../../../components/Grid/GridItemRoot";

import L2TextField from "../../../components/_helperComponents/L2TextField";
import DropDown from "../../../components/_helperComponents/DropDown";

const FacilityFormBodyMutation = forwardRef((props, ref) => {
  return (
    <div>
      <GridContainer>
        <GridItem xs={6} md={6} lg={6} sm={6}>
          <L2TextField
            SPName="FacilitiesDictionary"
            FieldName="facilityCode"
            onChange={(e) => {
              props.modalBodyParams.dispatch({
                type: "text",
                name: "facilityCode",
                value: e,
              });
            }}
            clear={(e) => {
              if (e) {
                props.modalBodyParams.dispatch({
                  type: "text",
                  name: "facilityCode",
                  value: "",
                });
              }
            }}
            value={props.modalBodyParams.item.facilityCode ? props.modalBodyParams.item.facilityCode : ""}
          />
        </GridItem>

        <GridItem xs={6} md={6} lg={6} sm={6}>
          <L2TextField
            SPName="FacilitiesDictionary"
            FieldName="facilityName"
            onChange={(e) => {
              props.modalBodyParams.dispatch({
                type: "text",
                name: "facilityName",
                value: e,
              });
            }}
            clear={(e) => {
              if (e) {
                props.modalBodyParams.dispatch({
                  type: "text",
                  name: "facilityName",
                  value: "",
                });
              }
            }}
            value={props.modalBodyParams.item.facilityName ? props.modalBodyParams.item.facilityName : ""}
          />
        </GridItem>

        <GridItem xs={6} md={6} lg={6} sm={6}>
          <L2TextField
            SPName="FacilitiesDictionary"
            FieldName="contactPerson"
            onChange={(e) => {
              props.modalBodyParams.dispatch({
                type: "text",
                name: "contactPerson",
                value: e,
              });
            }}
            clear={(e) => {
              if (e) {
                props.modalBodyParams.dispatch({
                  type: "text",
                  name: "contactPerson",
                  value: "",
                });
              }
            }}
            value={props.modalBodyParams.item.contactPerson ? props.modalBodyParams.item.contactPerson : ""}
          />
        </GridItem>

        <GridItem xs={6} md={6} lg={6} sm={6}>
          <L2TextField
            SPName="FacilitiesDictionary"
            FieldName="contactPersonPhoneNo"
            onChange={(e) => {
              props.modalBodyParams.dispatch({
                type: "text",
                name: "contactPersonPhoneNo",
                value: e,
              });
            }}
            clear={(e) => {
              if (e) {
                props.modalBodyParams.dispatch({
                  type: "text",
                  name: "contactPersonPhoneNo",
                  value: "",
                });
              }
            }}
            value={props.modalBodyParams.item.contactPersonPhoneNo ? props.modalBodyParams.item.contactPersonPhoneNo : ""}
          />
        </GridItem>

        <GridItem xs={6} md={6} lg={6} sm={6}>
          <L2TextField
            SPName="FacilitiesDictionary"
            FieldName="contactPersonEmail"
            onChange={(e) => {
              props.modalBodyParams.dispatch({
                type: "text",
                name: "contactPersonEmail",
                value: e,
              });
            }}            
            clear={(e) => {
              if (e) {
                props.modalBodyParams.dispatch({
                  type: "text",
                  name: "contactPersonEmail",
                  value: "",
                });
              }
            }}
            value={props.modalBodyParams.item.contactPersonEmail ? props.modalBodyParams.item.contactPersonEmail : ""}
          />
        </GridItem>

        <GridItem xs={6} md={6} lg={6} sm={6}>
          <L2TextField
            SPName="FacilitiesDictionary"
            FieldName="address"
            onChange={(e) => {
              props.modalBodyParams.dispatch({
                type: "text",
                name: "address",
                value: e,
              });
            }}
            clear={(e) => {
              if (e) {
                props.modalBodyParams.dispatch({
                  type: "text",
                  name: "address",
                  value: "",
                });
              }
            }}
            value={props.modalBodyParams.item.address ? props.modalBodyParams.item.address : ""}
          />
        </GridItem>

        <GridItem xs={6} md={6} lg={6} sm={6}>
          <L2TextField
            SPName="FacilitiesDictionary"
            FieldName="zipCode"
            onChange={(e) => {
              props.modalBodyParams.dispatch({
                type: "text",
                name: "zipCode",
                value: e,
              });
            }}
            clear={(e) => {
              if (e) {
                props.modalBodyParams.dispatch({
                  type: "text",
                  name: "zipCode",
                  value: "",
                });
              }
            }}
            value={props.modalBodyParams.item.zipCode ? props.modalBodyParams.item.zipCode : ""}
          />
        </GridItem>

        <GridItem xs={6} md={6} lg={6} sm={6}>                       
          <L2TextField
              SPName="FacilitiesDictionary"
              FieldName="city"
              onChange={(e) => {
                props.modalBodyParams.dispatch({
                  type: "text",
                  name: "city",
                  value: e,
                });
              }}
              clear={(e) => {
                if (e) {
                  props.modalBodyParams.dispatch({
                    type: "text",
                    name: "city",
                    value: "",
                  });
                }
              }}
              value={props.modalBodyParams.item.city ? props.modalBodyParams.item.city : ""}
            />
        </GridItem>

        <GridItem xs={6} md={6} lg={6} sm={6}>
          <DropDown 
            placeholder="Region"            
            onChange={(e) => {
              props.modalBodyParams.dispatch({
                type: "select",
                name: "region",
                value: e,
              });
            }}       
            data={props.modalBodyParams.regionsData}   
            value={props.modalBodyParams.item.region}
            mandatory={true}           
          />
        </GridItem>

        <GridItem xs={6} md={6} lg={6} sm={6}>
          <DropDown 
            placeholder="Country"
            
            onChange={(e) => {
              props.modalBodyParams.dispatch({
                type: "select",
                name: "country",
                value: e,
              });
            }}       
            data={props.modalBodyParams.countriesData}  
            value={props.modalBodyParams.item.country}
            mandatory={true}            
          />                      
        </GridItem>       
      </GridContainer>
    </div>
  );
});

export default FacilityFormBodyMutation; 

import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

const tmpStyle = {
  background: "#FFF",
  boxShadow: "0 4px 20px 1px rgba(0,0,0,.06),0 1px 4px rgba(0,0,0,.08)",
}

function Card({ ...props }) {
  const {
    className,
    children,
    ...rest
  } = props;


  const cardClasses = classNames({
    [className]: className !== undefined,
  });
  return (
    <div className={cardClasses} {...rest}>
      {children}
    </div>
  );
}

Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default withStyles(tmpStyle)(Card);

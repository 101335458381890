import Person from "../../../../../../assets/img/person.png";

import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import VpnKeyIcon from "@material-ui/icons/VpnKey";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Divider from "@material-ui/core/Divider";
import MenuLists from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";

import { logoutAction } from "../../../../../Auth/components/Logout/actions/logoutAction";
import { handleClick } from "../../helpers/headerHelpers";

const ProfileMenu = (props) => {
  const anchorRef = useRef(null);

  const authStoreRedux = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const userRole = localStorage.getItem("userRole");

  const dropdownItemClassNames = classNames(
    props.classes.dropdownItem,
    // props.classes.infoHover,
    { [props.classes.dropdownItemRTL]: false },
  );

  return (
    <div className="profile">
      <div
        className={props.classes.profileDiv}
        color="transparent"
        aria-label="Person"
        aria-owns={props.openProfile ? "profile-menu-list" : null}
        aria-haspopup="true"
        onClick={() => handleClick(props.openProfile, props.setOpenProfile)}
        ref={anchorRef}
      >
        <div className={props.classes.profileImg}>
          <img src={Person} alt="Avatar" />
        </div>
        <div className="posAlign">
          <h6 className="Profile-Name" id="welcome">
            Welcome
          </h6>
          <h6 className="Profile-Name" id="user-name">
            {authStoreRedux.username}
          </h6>
        </div>
      </div>
      <Popper
        open={props.openProfile}
        anchorEl={anchorRef.current}
        placement="bottom"
        className={classNames({
          [props.classes.popperClose]: !props.openProfile,
          [props.classes.popperResponsive]: true,
          [props.classes.popperNav]: true,
        })}
        style={{ zIndex: "1000" }}
      >
        <ClickAwayListener onClickAway={() => props.setOpenProfile(false)}>
          <Paper className={props.classes.dropdown}>
            <MenuLists role="menu">
              {
                userRole !== "Call_Center" && (
                  <MenuItem
                    onClick={() => props.setOpenChangePassword(true)}
                    className={dropdownItemClassNames}
                  >
                    <VpnKeyIcon className="dropdownicon" />{" "}
                    <span>{"Change Password"}</span>
                  </MenuItem>
                )
              }
              <MenuItem
                onClick={() => {
                  props.setOpenModalTimeZone(true);
                }}
                className={dropdownItemClassNames}
              >
                <AccessTimeIcon className="dropdownicon" />{" "}
                <span>{"Change TimeZone"}</span>
              </MenuItem>
              <Divider light />
              <MenuItem
                onClick={() =>
                  logoutAction(
                    props.setOpenProfile,
                    props.history,
                    props.setMessage,
                    dispatch,
                  )
                }
                className={dropdownItemClassNames}
              >
                <PowerSettingsNewIcon className="dropdownicon" />
                <span>{"Log out"} </span>
              </MenuItem>
            </MenuLists>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
}

export default ProfileMenu;

import Dialog from "@material-ui/core/Dialog";

import ModalHeader from "./ModalHeader";
import ModalBody from "./ModalBody";
import ModalFooter from "./ModalFooter";

const ModalWindowView = (props) => {
  return (
    <div>
      <Dialog
        open={props.modalWindowParams.isModalOpen}
        // keepMounted={props.modalWindowParams.keepMounted}
        // disableEscapeKeyDown={props.modalWindowParams.disableEscapeKeyDown}
        onClose={
          (event, reason) => {
              if (reason && reason === "backdropClick") return
            props.modalWindowParams.onClickClose();
          }
        }
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <ModalHeader 
          modalHeader = {props.modalHeader}     
          modalHeaderParams = {props.modalHeaderParams}
        /> {/** AccordionSummary */}
        <ModalBody      
          modalBody = {props.modalBody}         
          modalBodyParams = {props.modalBodyParams}         
          />{/** AccordionDetails */}
        <ModalFooter
          modalFooter = {props.modalFooter}        
          modalFooterParams = {props.modalFooterParams}     
        />
      </Dialog>
    </div>      
  )
}

export default ModalWindowView;
const initialStateGlobal = {
  currentModalItem: {},
  currentProvider: {}
};

function commonReactReducer(state, action) {
  switch (action.type) {
    case "currentModalItem": {
      return { ...state, currentModalItem: action.payload };
    }
    case "currentProvider": {
      return { ...state, currentProvider: action.payload };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export {
  initialStateGlobal,
  commonReactReducer
}
import { useSelector } from "react-redux";

import Tooltip from "@material-ui/core/Tooltip";
import HomeIcon from "@material-ui/icons/Home";

import { makeTabActive } from "../../../MainContent/components/TabsBar/helpers/tabsBarHelpers";

const HomeButton = () => {
  const authStoreRedux = useSelector((state) => state.auth);

  return (
    <>
      {
        // localStorage.getItem('UserGroupName') == 'Admin' ? // todo was superadmin
        authStoreRedux.userRole == "Administration" ? ( // todo was superadmin
          <div className="homeButton">
            <Tooltip title="Home" placement="right">
              <HomeIcon
                id="home"
                className="homeActive homeicon"
                style={{ marginLeft: "20px" }}
                onClick={() => {
                  makeTabActive("Home");
                }}
              />
            </Tooltip>
          </div>
        ) : null
      }
    </>
  );
}

export default HomeButton;

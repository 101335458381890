import React, {
  forwardRef,
} from "react";

import L2TextField from "../../../components/_helperComponents/L2TextField";
// import GridContainer from "../../../components/Grid/GridContainer";
import GridContainer from "../../../components/Grid/GridContainerRoot";
// import GridItem from "../../../components/Grid/GridItem";
import GridItem from "../../../components/Grid/GridItemRoot";

const DiagnosisCodeFormBodyMutation = forwardRef((props, ref) => {
  return (
    <div>
      <GridContainer>
        <GridItem xs={6} md={6} lg={6} sm={6}>
          <L2TextField
            SPName="DiagnosisCodesDictionary"
            FieldName="diagnosisCode"
            onChange={(e) => {
              props.modalBodyParams.dispatch({
                type: "text",
                name: "diagnosisCode",
                value: e,
              });
            }}
            clear={(e) => {
              if (e) {
                props.modalBodyParams.dispatch({
                  type: "text",
                  name: "diagnosisCode",
                  value: "",
                });
              }
            }}
            value={props.modalBodyParams.item.diagnosisCode ? props.modalBodyParams.item.diagnosisCode : ""}
          />
        </GridItem>

        <GridItem xs={6} md={6} lg={6} sm={6}>
          <L2TextField
            SPName="DiagnosisCodesDictionary"
            FieldName="description"
            onChange={(e) => {
              props.modalBodyParams.dispatch({
                type: "text",
                name: "description",
                value: e,
              });
            }}
            clear={(e) => {
              if (e) {
                props.modalBodyParams.dispatch({
                  type: "text",
                  name: "description",
                  value: "",
                });
              }
            }}
            value={props.modalBodyParams.item.description ? props.modalBodyParams.item.description : ""}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
});

export default DiagnosisCodeFormBodyMutation; 

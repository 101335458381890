import React, { useRef, useReducer, useState, useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";

import MeetingsStatistics from "../Meetings/components/MeetingsStatistics";
import ProviderMeetingsTable from "./components/ProviderServicesTable";
import MeetingMutationProvider from "./components/MeetingMutationProvider";
import {
  getProvidersList
} from "../Users/actions/viewProvidersListAction";

import { getDiagnosisCodesList } from "../DiagnosisCodes/actions/viewListDiagnosisCodesAction";

import { commonReactReducer, initialStateGlobal } from "../../reactReducers/commonReactReducer";

import { MeetingsContextProvider } from "../Meetings/context/meetingsContextProvider";

const ProviderMeetings = (props) => {
  const mutationRef = useRef();
  // const statsRef = useRef();
  const queueRef = useRef();
  
  const authStoreRedux = useSelector((state) => state.auth);
  const [commonReactState, commonReactDispatch] = useReducer(commonReactReducer, initialStateGlobal);
  
  const [providersList, setProvidersList] = useState([]);
  const [providersPagination, setProvidersPagination] = useState({
    pageNo: 1,
    getPerPage: 100,
    itemsTotalNumber: 0,
    pagesTotalNumber: 0
  });

  const [diagnosisCodesList, setDiagnosisCodesList] = useState([]);
  const [diagnosisCodesPagination, setDiagnosisCodesPagination] = useState({
    pageNo: 1,
    getPerPage: 100,
    itemsTotalNumber: 0,
    pagesTotalNumber: 0
  });

  const userRole = localStorage.getItem("userRole");

  const defaultFilters = {
    statuses: {
      'Active': "true",
      'Inactive': "false"
    }
  }
  
  useLayoutEffect(() => {
    getProvidersList(
      setProvidersList,
      {
        loading: (val) => props.loading(val),
        alert: (val) => props.alertmsg(val)
      },
      {
        usersFilters: defaultFilters
      },
      {
        pageNo: 1,
        getPerPage: 100,
        pagination: providersPagination,
        setPagination: setProvidersPagination
      }
    );

    getDiagnosisCodesList(
      setDiagnosisCodesList,
      {
        loading: (val) => props.loading(val),
        alert: (val) => props.alertmsg(val)
      },
      {
        diagnosisCodesFilters: defaultFilters
      },
      {
        pageNo: 1,
        getPerPage: 1000,
        pagination: diagnosisCodesPagination,
        setPagination: setDiagnosisCodesPagination
      }  
    );    
  }, []);

  const providerValue = {
    commonReactState, 
    commonReactDispatch,
    providersList,
    diagnosisCodesList,
    openModalMutation: (itemId) => {   

      let item = queueRef.current.getCurrentModalItem(itemId);
      mutationRef.current.openModal(item);
      
      // queueRef.current.getCurrentModalItem(itemId);
      // mutationRef.current.openModal();
    },
    openModalReassign: (itemId) => {
      mutationRef.current.removeAlertModal(itemId);
    },
    // refreshProviderStatistics: (providerId) => statsRef.current.refreshProviderStatistics(providerId),
    // refreshActiveCellStatistics: (meetingsType) => statsRef.current.refreshActiveCellStatistics(meetingsType),
    getCurrentProviderId: () => queueRef.current.getCurrentProviderId(),
    getCurrentProvider: (providerId = null) => queueRef.current.getCurrentProvider(providerId),
    refreshTable: (providerId = null, meetingsFilters = null) => queueRef.current.refreshTable(providerId, meetingsFilters),
    applySectionFilters: (sectionFilters) => queueRef.current.applySectionFilters(sectionFilters),
    loading: (val) => props.loading(val),
    alert: (val) => props.alertmsg(val),
    ConfirmAlert: (val) => props.ConfirmAlert(val)
  }

  return (
    <>
      <MeetingsContextProvider value={providerValue}>
        <div id="Provider-MainDiv" className="MainDivStyles  height88">
          <div id="Provider-MainTable" className="Maincontent">
            <div className="displayFlex width100">
              <div
                id="divProvider-MainTable"
                style={{ width: "100%", position: "relative" }}
              >                
                {
                  // authStoreRedux.userRole == "Provider" && (
                  // userRole == "Provider" && (
                  //   <MeetingsStatistics/* ref={statsRef}*/ />
                  // )
                }
                <ProviderMeetingsTable ref={queueRef} />
              </div>
            </div>
          </div>
          {
            // (authStoreRedux.userRole == "Administration" || authStoreRedux.userRole == "Provider") && (
            (userRole == "Administration" || userRole == "Provider" || userRole == "Billing") && (
              <MeetingMutationProvider ref={mutationRef} />
            )
          }
        </div>
      </MeetingsContextProvider>
    </>
  );
};

export default ProviderMeetings;

import "./assets/css/menuNew.css";

import React, { forwardRef, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import configureStore from "../../../../../../Redux/store";

import { initSideMenu } from "./actions/sideMenuActions";
import {
  removeTab,
} from "../../../MainContent/components/TabsBar/helpers/tabsBarHelpers";
import {
  openNewTab,
  stopTabFunction
} from "./helpers/sideMenuHelpers";

import RootMenuList from './RootMenuList';

const SideMenu = forwardRef((props, ref) => {
  const authStoreRedux = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const { store, persistor } = configureStore();

  const [isCollapseButtonOpen, setIsCollapseButtonOpen] = useState(false);
  const [subMenuItemIdExpanded, setSubMenuItemIdExpanded] = useState(false);

  const initTab = (authStoreRedux.userRole == 'Call_Center'|| authStoreRedux.userRole == 'Provider')
   ? []
   : ["Home"]
  const [openedTabsList, setOpenedTabsList] = useState(initTab);
  
  const closeTabHandlerRef = useRef();

  useEffect(() => {
    initSideMenu(
      props.setLoading,
      props.setUserMenuDetails,
      authStoreRedux,
    );

    if( (authStoreRedux.userRole == 'Call_Center' || authStoreRedux.userRole == 'Provider') ){
      openNewTab(
        JSON.parse(authStoreRedux.menuData)[0],
        props.setMessage,
        openedTabsList,
        setOpenedTabsList,                   
        props.setLoading,
        props.setConfirmationAlert,
        store,
        persistor,
        closeTabHandler
      )
    }
  }, []);

  useEffect(() => {
    document.getElementById("sidebarwid").onclick = function () {
      document.addEventListener("keydown", stopTabFunction);
    };
  }, []);

  function CollapseAll() {
    if (isCollapseButtonOpen) {
      setIsCollapseButtonOpen(false);
    } else {
      setIsCollapseButtonOpen(true);
    }
  }

  const collabseAllOnClick = () => CollapseAll(isCollapseButtonOpen, setIsCollapseButtonOpen)

  closeTabHandlerRef.current = (e, tabId) => {
    e.stopPropagation();

    removeTab(
      tabId,
      openedTabsList,
      setOpenedTabsList,
    );
  }

  const closeTabHandler = (e, tabId) => {
    closeTabHandlerRef.current  (e, tabId)
  }

  return (    
    <ul>
      <RootMenuList 
        UserMenuDetails={props.UserMenuDetails}
        collabseAllOnClick={collabseAllOnClick}
        isCollapseButtonOpen={isCollapseButtonOpen}
        subMenuItemIdExpanded={subMenuItemIdExpanded}
        setSubMenuItemIdExpanded={setSubMenuItemIdExpanded}
        setMessage={props.setMessage}
        openedTabsList={openedTabsList}
        setOpenedTabsList={setOpenedTabsList}
        setLoading={props.setLoading}
        setConfirmationAlert={props.setConfirmationAlert}
        dispatch={dispatch}
        store={store}
        persistor={persistor}
        openedMenuId={props.openedMenuId}
        setOpenedMenuId={props.setOpenedMenuId}
        isSideMenuActive={props.isSideMenuActive}
        setIsSideMenuActive={props.setIsSideMenuActive}
        isSideMenuHasMinWidth={props.isSideMenuHasMinWidth}        
        closeTabHandler={closeTabHandler}
      />
    </ul>      
  );
});

export default SideMenu;

import React from "react";
import Button from "../_helperComponents/Button.js";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/DeleteOutline";

export default function SaveButton(props) {
  return (
    <Button
      formctrl_id={props.formctrl_id}
      className="ClearButton boxShadowNone"
      name={props.name ? props.name : "Cancel"}
      title={props.name ? props.name : "Cancel"}
      disabled={props.disabled}
      onClick={props.onClick}
      startIcon={
        props.name == "Cancel" 
        ? props.name ? (
              <DeleteIcon className="SubmitBtnIcon" />
            ) : (
              <ClearIcon className="SubmitBtnIcon" />
            )
        : ""
      }
    />
  );
}


import { useState } from "react";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import Logo from "./components/Logo";
import SideMenu from "./components/SideMenu/SideMenu";
import {
  stopTabFunction,
  MenuClickAway,
  toggleSidebarWidth
} from "./components/SideMenu/helpers/sideMenuHelpers";

const Sidebar = (props) => {
  const [openedMenuId, setOpenedMenuId] = useState('');
  const [UserMenuDetails, setUserMenuDetails] = useState([]);

  return (
    <div>
      <section className="app" style={{ display: "contents" }}>        
        <Logo
          isSideMenuHasMinWidth={props.isSideMenuHasMinWidth}
        />
        
        <ClickAwayListener
          onClickAway={(e) => {
              if (e.target.className.baseVal=="MuiSvgIcon-root sidebarMiniIcon") {
                // user was clicked on sidebar set min width button
                return;
              }

              MenuClickAway(
                openedMenuId,
                stopTabFunction,
                UserMenuDetails,
              );

              toggleSidebarWidth(
                false,
                props.isSideMenuHasMinWidth
              );

              props.setIsSideMenuActive(false);
            }
          }
        >
          <aside className="sidebar" id="sidebarwid">
            <div className="sidebar-nav">
              <SideMenu
                openedMenuId={openedMenuId}
                setOpenedMenuId={setOpenedMenuId}
                loading={props.loading}
                setLoading={props.setLoading}
                UserMenuDetails={UserMenuDetails}
                setUserMenuDetails={setUserMenuDetails}
                setConfirmationAlert={props.setConfirmationAlert}
                setMessage={props.setMessage}
                isSideMenuActive={props.isSideMenuActive}
                setIsSideMenuActive={props.setIsSideMenuActive}
                isSideMenuHasMinWidth={props.isSideMenuHasMinWidth}
              />
            </div>
          </aside>
        </ClickAwayListener>
      </section>
    </div>
  );
};

export default Sidebar;

export const json = { // todo formFieldsDictionaries
  EnterKeyValues: {    
  },

  Label: {  
  },

  LabelHeader: {   
  },

  Property: {
    
    MeetingsDictionary: {

      facility: {
        id: "facility",
        labelID: "facility",
        maxLength: "150",
        labelname: "Facility",
        type: "text",
        mandatory: true,
        disabled: false,
      },
      
      consultType: {
        id: "consultType",
        labelID: "consultType",
        maxLength: "150",
        labelname: "Consult Type",
        type: "text",
        mandatory: true,
        disabled: false,
      },

      communicationMethod: {
        id: "communicationMethod",
        labelID: "communicationMethod",
        maxLength: "150",
        labelname: "Communication Method",
        type: "text",
        mandatory: true,
        disabled: false,
      },      

      cartNumber: {
        id: "cartNumber",
        labelID: "cartNumber",
        maxLength: "150",
        labelname: "Cart Name/Number",
        type: "text",
        mandatory: true,
        disabled: false,
      },

      siteCallbackNumber: {
        id: "siteCallbackNumber",
        labelID: "siteCallbackNumber",
        maxLength: "150",
        labelname: "Site Callback Number",
        type: "text",
        mandatory: true,
        disabled: false,
      },

      siteContactName: {
        id: "siteContactName",
        labelID: "siteContactName",
        maxLength: "150",
        labelname: "Site Contact Name",
        type: "text",
        mandatory: true,
        disabled: false,
      },

      siteCallbackNumberSecondary: {
        id: "siteCallbackNumberSecondary",
        labelID: "siteCallbackNumberSecondary",
        maxLength: "150",
        labelname: "Secondary Site Callback Number",
        type: "text",
        mandatory: false,
        disabled: false,
      },

      siteContactNameSecondary: {
        id: "siteContactNameSecondary",
        labelID: "siteContactNameSecondary",
        maxLength: "150",
        labelname: "Secondary Site Contact Name",
        type: "text",
        mandatory: false,
        disabled: false,
      },

      firstName: {
        id: "firstName",
        labelID: "firstName",
        maxLength: "100",
        labelname: "First Name",
        type: "text",
        mandatory: true,
        disabled: false,
      },

      lastName: {
        id: "lastName",
        labelID: "lastName",
        maxLength: "100",
        labelname: "Last Name",
        type: "text",
        mandatory: true,
        disabled: false,
      },

      birthDate: {
        id: "birthDate",
        labelID: "birthDate",
        maxLength: "100",
        labelname: "birthDate",
        type: "text",
        mandatory: true,
        disabled: false,
      },

      medicalRecordNumber: {
        id: "medicalRecordNumber",
        labelID: "medicalRecordNumber",
        maxLength: "100",
        labelname: "Medical Record Number",
        type: "text",
        mandatory: true,
        disabled: false,
      },

      accountNo: {
        id: "accountNo",
        labelID: "accountNo",
        maxLength: "100",
        labelname: "Account No",
        type: "text",
        mandatory: false,
        disabled: false,
      },     

      cancelRemarks: {
        id: "cancelRemarks",
        labelID: "cancelRemarks",
        maxLength: "100",
        labelname: "Remarks",
        type: "text",
        mandatory: false,
        disabled: false,
      },

      reasonForMeeting: {
        id: "reasonForMeeting",
        labelID: "reasonForMeeting",
        maxLength: "500",
        labelname: "Reason For Consult",
        type: "text",
        mandatory: false,
        disabled: false,
      },

      reasonForReassign: {
        id: "reasonForReassign",
        labelID: "reasonForReassign",
        maxLength: "500",
        labelname: "Reason For Reassign",
        type: "text",
        mandatory: true,
        disabled: false,
      },
    },

    DiagnosisCodesDictionary: {
      diagnosisCode: {
        id: "diagnosisCode",
        labelID: "diagnosisCode",
        maxLength: "100",
        labelname: "Diagnosis Code",
        type: "text",
        mandatory: true,
        disabled: false,
      },

      description: {
        id: "description",
        labelID: "Description",
        maxLength: "150",
        labelname: "Description",
        type: "text",
        mandatory: false,
        disabled: false,
      },
    },

    UsersDictionary: {
      firstName: {
        id: "firstName",
        labelID: "firstName",
        maxLength: "100",
        labelname: "First Name",
        type: "text",
        mandatory: true,
        disabled: false,
      },

      lastName: {
        id: "lastName",
        labelID: "lastName",
        maxLength: "100",
        labelname: "Last Name",
        type: "lastName",
        mandatory: true,
        disabled: false,
      },

      middleName: {
        id: "middleName",
        labelID: "middleName",
        maxLength: "100",
        labelname: "Middle Name",
        type: "middleName",
        mandatory: false,
        disabled: false,
      },

      username: {
        id: "username",
        labelID: "username",
        maxLength: "200",
        labelname: "Username",
        type: "text",
        mandatory: true,
        disabled: false,
      },

      password: {
        id: "password",
        labelID: "password",
        maxLength: "200",
        labelname: "Password",
        type: "password",
        mandatory: true,
        disabled: false,
      },

      gender: {
        id: "gender",
        labelID: "gender",
        maxLength: "50",
        labelname: "Gender",
        type: "text",
        mandatory: true,
        disabled: false,
      },

      birthDate: {
        id: "birthDate",
        labelID: "birthDate",
        maxLength: "50",
        labelname: "Birth Date",
        type: "text",
        mandatory: true,
        disabled: false,
      },

      userRole: {
        id: "userRole",
        type: "select",
        labelID: "userRole",
        labelname: "User Role",
        mandatory: true,
      },

      designation: {
        id: "designation",
        type: "select",
        labelID: "designation",
        labelname: "Designation",
        mandatory: false,
      },

      speciality: {
        id: "speciality",
        labelID: "speciality",
        maxLength: "20",
        labelname: "Speciality",
        type: "text",
        mandatory: false,
        disabled: false,
      },

      phoneNo: {
        id: "phoneNo",
        labelID: "phoneNo",
        maxLength: "20",
        labelname: "Phone Number",
        type: "number",
        mandatory: true,
        disabled: false,
      },

      email: {
        id: "email",
        labelID: "email",
        maxLength: "100",
        labelname: "email",
        type: "email",
        mandatory: true,
        disabled: false,
      },

      timeZone: {
        id: "timeZone",
        labelID: "TimeZone",
        labelname: "TimeZone",
        type: "number",
        mandatory: true,
        disabled: false,
      }
    },

    FacilitiesDictionary: {
      facilityCode: {
        id: "facilityCode",
        labelID: "facilityCode",
        maxLength: "100",
        labelname: "Facility Code",
        type: "text",
        mandatory: true,
        disabled: false,
      },

      facilityName: {
        id: "facilityName",
        labelID: "facilityName",
        maxLength: "100",
        labelname: "Facility Name",
        type: "text",
        mandatory: true,
        disabled: false,
      },

      contactPerson: {
        id: "contactPerson",
        labelID: "contactPerson",
        maxLength: "50",
        labelname: "Contact Person",
        type: "text",
        mandatory: false,
        disabled: false,
      },

      contactPersonPhoneNo: {
        id: "contactPersonPhoneNo",
        labelID: "contactPersonPhoneNo",
        maxLength: "15",
        labelname: "Contact Person Phone Number",
        type: "number",
        mandatory: false,
        disabled: false,
      },

      contactPersonEmail: {
        id: "contactPersonEmail",
        labelID: "contactPersonEmail",
        maxLength: "100",
        labelname: "Contact Person Email",
        type: "email",
        mandatory: false,
        disabled: false,
      },

      address: {
        id: "address",
        labelID: "address",
        maxLength: "100",
        labelname: "Address",
        type: "text",
        mandatory: false,
        disabled: false,
      },

      zipCode: {
        id: "zipCode",
        labelID: "zipCode",
        maxLength: "10",
        labelname: "Zip Code",
        type: "text",
        mandatory: false,
        disabled: false,
      },

      city: {
        id: "city",
        labelID: "city",
        maxLength: "100",
        labelname: "City",
        type: "text",
        mandatory: true,
        disabled: false,
      },

      region: {
        id: "region",
        labelID: "region",
        labelname: "Region",
        type: "select",
        mandatory: true,
      },

      country: {
        id: "country",
        labelID: "country",
        labelname: "Country",
        type: "select",
        mandatory: true,
      },
    },
 
  },
};

import { config } from "../../../configs/config";
import { requestAxios } from "../../../services/axiosClient";
import { updateConditionQuery } from "../../../helpers/utils";

const showErrorMessage = (responseError, showAlert, setLoading) => {
  const errorMessage = responseError?.message
    ? responseError?.message
    : "Bad Request";
  showAlert({ open: true, color: "error", message: [errorMessage] });
  setLoading(false);
};

const createUserAction = async (item, showAlert, setLoading) => {
  const url = "users";
  const params = {
    ...item,
  };
  const apiUrl = `${config.API_URL}/${url}`;
  const requestOptions = {
    requestType: "POST",
  };
  const [response, responseError] = await requestAxios(
    apiUrl,
    params,
    null,
    requestOptions,
  );

  if (responseError) {
    showErrorMessage(responseError, showAlert, setLoading);
    return;
  }

  showAlert({ open: true, color: "success", message: ["User was created"] });
  setLoading(false);

  return response;
};

const updateUserAction = async (
  item,
  showAlert = () => {},
  setLoading = () => {},
) => {
  const url = "users";
  const params = {
    ...item,
  };
  const apiUrl = `${config.API_URL}/${url}/${item.id}`;
  const requestOptions = {
    requestType: "PUT",
  };
  const [response, responseError] = await requestAxios(
    apiUrl,
    params,
    null,
    requestOptions,
  );

  if (responseError) {
    showErrorMessage(responseError, showAlert, setLoading);
    return;
  }

  showAlert({ open: true, color: "success", message: ["User was updated"] });
  setLoading(false);

  return response;
};

const deleteUserAction = async (
  item,
  showAlert = () => {},
  setLoading = () => {},
) => {
  const url = "users";

  let apiUrl;
  if (item.id) {
    apiUrl = `${config.API_URL}/${url}/${item.id}`;
  } else {
    showErrorMessage(null, showAlert, setLoading);
    return;
  }

  const requestOptions = {
    requestType: "DELETE",
  };
  const [response, responseError] = await requestAxios(
    apiUrl,
    null,
    null,
    requestOptions,
  );

  if (responseError) {
    showErrorMessage(responseError, showAlert, setLoading);
    return;
  }

  showAlert({ open: true, color: "success", message: ["User was deleted"] });
  setLoading(false);

  return response;
};

const getUserAction = async (
  item,
  showAlert = () => {},
  setLoading = () => {},
) => {
  const url = "users";

  let apiUrl;
  if (item.id) {
    apiUrl = `${config.API_URL}/${url}/id/${item.id}`;
  } else if (item.name) {
    apiUrl = `${config.API_URL}/${url}/username/${item.name}`;
  } else {
    showErrorMessage(null, showAlert, setLoading);
    return;
  }

  const requestOptions = {
    requestType: "GET",
  };
  const [response, responseError] = await requestAxios(
    apiUrl,
    null,
    null,
    requestOptions,
  );

  if (responseError) {
    showErrorMessage(responseError, showAlert, setLoading);
    return;
  }

  setLoading(false);

  return response;
};

const getProvidersListAction = async (
  itemsFilters,
  currentPaginationParams,
  showAlert = () => {},
  setLoading = () => {},
) => {
  const url = "users/providers/";

  let conditionQuery = '';

  conditionQuery = updateConditionQuery(conditionQuery, "statuses", itemsFilters?.usersFilters?.statuses);

  const paginationQuery = `page_no=${currentPaginationParams.pageNo}&get_per_page=${currentPaginationParams.getPerPage}`;
  const apiUrl = `${config.API_URL}/${url}?${paginationQuery}${conditionQuery}`;
  const requestOptions = {
    requestType: "GET",
  };
  const [response, responseError] = await requestAxios(
    apiUrl,
    null,
    null,
    requestOptions,
  );

  if (responseError) {
    showErrorMessage(responseError, showAlert, setLoading);
    return;
  }

  setLoading(false);

  return response;
};

const getListUserAction = async (
  itemsFilters,
  currentPaginationParams,
  showAlert = () => {},
  setLoading = () => {},
) => {
  const url = "users";

  let conditionQuery = '';

  conditionQuery = updateConditionQuery(conditionQuery, "statuses", itemsFilters?.usersFilters?.statuses);

  const paginationQuery = `page_no=${currentPaginationParams.pageNo}&get_per_page=${currentPaginationParams.getPerPage}`;
  const apiUrl = `${config.API_URL}/${url}?${paginationQuery}${conditionQuery}`;
  const requestOptions = {
    requestType: "GET",
  };
  const [response, responseError] = await requestAxios(
    apiUrl,
    null,
    null,
    requestOptions,
  );

  if (responseError) {
    showErrorMessage(responseError, showAlert, setLoading);
    return;
  }

  setLoading(false);

  return response;
};

const searchUserAction = async (
  searchQuery,
  showAlert = () => {},
  setLoading = () => {},
) => {
  const url = "users";

  const apiUrl = `${config.API_URL}/${url}/search/${searchQuery}`;
  const requestOptions = {
    requestType: "GET",
  };
  const [response, responseError] = await requestAxios(
    apiUrl,
    null,
    null,
    requestOptions,
  );

  if (responseError) {
    showErrorMessage(responseError, showAlert, setLoading);
    return;
  }

  setLoading(false);

  return response;
};

const api2FrontMapper = (params) => {
  return {
    EmployeeIDPK: params.id,
    EmployeeCode: params.employeeCode,
    EmpFirstName: params.firstName,
    EmpLastName: params.lastName,
    PhoneNo1: params.phoneNo,
    PhoneNo2: params.phoneNo,
    EmpEmail: params.email,
    UserName: params.username,
    UserPassword: params.password,
    userRole: params.userRole,
    PersonalEmail: params.personalEmail,
    RegisterNo: params.registerNo,
    Gender: params.gender,
    DateofBirth: params.birthDate,
    Description: params.description,
    DesignationID: "params.designationId",
    DesignationName: params.designation,
    SpecialityID: "params.speciality",
    SpecialityName: params.speciality,
    timeZone: params.timeZone,

    IsActive: params.isActive,
    DeleStat: params.isDeleted,
    CreatedUserID: params.createdUserId,
    CreatedTtm: params.createdAt,
    UpdatedUserID: params.updatedUserId,
    UpdatedTtm: params.updatedAt,
  };
};

const front2ApiMapper = (params) => {
  return {
    id: !!params.id ? params.id : null,
    firstName: params.firstName,
    lastName: params.lastName,
    middleName: params.middleName,
    username: params.username,
    password: !!params.password ? params.password : null,
    userRole: params.userRole,
    designation: params.designation,
    speciality: params.speciality,
    gender: params.gender,
    birthDate: params.birthDate,
    phoneNo: params.phoneNo,
    email: params.email,
    timeZone: params.timeZone
      ? params.timeZone
      : "UTC-05:00 - Eastern Standard Time",

    isActive: params.isActive
  };
};

export {
  getUserAction,
  getListUserAction,
  searchUserAction,
  createUserAction,
  updateUserAction,
  deleteUserAction,
  getProvidersListAction,
  api2FrontMapper,
  front2ApiMapper,
};

import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authReduxReducer from "./reducers/authReduxReducer";
// import formReduxReducer from "./reducers/formReduxReducer";

function configureStore(initialState = {}) {
  const rootReducer = combineReducers({
    auth: persistReducer(
      {
        key: "auth",
        version: 1,
        storage,
        debug: false,
        whitelist: ['userId', 'username', 'userRole', 'timeZone', 'menuData'],
        // blacklist: [""],
      },
      authReduxReducer,
    ),
    // form: persistReducer(
    //   {
    //     key: "form",
    //     storage,
    //     debug: false,
    //     blacklist: [""],
    //   },
    //   formReduxReducer,
    // ),
  });

  const store = createStore(
    persistReducer(
      {
        key: "root",
        debug: false,
        version: 1,
        storage,
        whitelist: ['auth'],
      },
      rootReducer,
    ),
    initialState,
    applyMiddleware(thunk),
  );

  const persistor = persistStore(store/*, null, () => {
    // if you want to get restoredState
  }*/);

  return { store, persistor };
}

export default configureStore;
